import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'

export class NotRequiredSegment extends RequiredProperty<string | null> {
  constructor(validatedValue: string | null) {
    super(validatedValue, { errorMessage: '' })
  }

  validate() {
    this.errors = []
    if (NotRequiredSegment.hasValue(this.validatedValue)) {
      if (this.validatedValue.length > 128) {
        this.errors.push('un segment doit faire moins de 128 caractères')
      }
      if (!/^[a-z0-9 -_]+$/g.test(this.validatedValue)) {
        this.errors.push('un segment ne peut contenir que des caractères alphanumériques, des espaces, des - ou des _')
      }
    }
  }

  private static hasValue(value): value is string {
    return value !== null && value?.length !== 0
  }
}
