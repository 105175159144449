import { isSameDay } from '../date/date.filter'

const toHours = (timeInMillis) => timeInMillis / 1000 / 3600

const toMinutes = (timeInMillis) => timeInMillis / 1000 / 60

const getHoursAgo = (date, now) => toHours(now.getTime() - date.getTime())

const getMinutesAgo = (date, now) => toMinutes(now.getTime() - date.getTime())

const isLessThanAnHour = (hoursAgo) => hoursAgo < 1

const isLessThanAMinute = (minsAgo) => minsAgo < 1

export const timeAgo = (date, now) => {
  const hoursAgo = getHoursAgo(date, now)
  if (isLessThanAnHour(hoursAgo)) {
    const minsAgo = getMinutesAgo(date, now)
    if (isLessThanAMinute(minsAgo)) {
      return "à l'instant"
    }
    return `Il y a ${Math.floor(minsAgo)}m`
  }
  return `Il y a ${Math.floor(hoursAgo)}h`
}

export default function time(value, { locale = 'fr-FR', now = new Date() } = {}) {
  if (!value) {
    return ''
  }
  const date = new Date(value)
  if (isSameDay(date, now)) {
    return timeAgo(date, now)
  }
  const localeTime = date.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
  return `à ${localeTime}`
}
