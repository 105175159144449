
import { defineComponent, PropType, toRefs } from 'vue'
import { IdfInput } from '@invivodf/ui-kit'
import { IUseDiscountInput } from '../../composables/useDiscountInput'
import { useUsesInputs } from '../../composables/useUsesInputs'

export default defineComponent({
  name: 'CUsesInputs',
  components: { IdfInput },
  props: {
    nbOverallUses: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    nbCustomerUses: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
  setup(props) {
    const { nbOverallUses, nbCustomerUses } = toRefs(props)
    const { isOverallUsesEnabled, isCustomerUsesEnabled } = useUsesInputs(
      nbOverallUses.value.value,
      nbCustomerUses.value.value,
    )
    return { isOverallUsesEnabled, isCustomerUsesEnabled }
  },
})
