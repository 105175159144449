import { DiscountTypes, DiscountScopes } from '@invivodf/discount-sdk/admin'
import { DiscountPolicy } from '../DiscountPolicy'

export interface DisplayedDiscountPolicies {
  readonly value: DiscountPolicy
  readonly text: string
  readonly description: string
  readonly example: string
  readonly dataTest: string
}

const simpleArticle = new DiscountPolicy(DiscountTypes.Simple, DiscountScopes.Article)
const simpleCart = new DiscountPolicy(DiscountTypes.Simple, DiscountScopes.Cart)
const simpleGroup = new DiscountPolicy(DiscountTypes.Simple, DiscountScopes.Group)
const revenueArticle = new DiscountPolicy(DiscountTypes.OnRevenue, DiscountScopes.Article)
const revenueCart = new DiscountPolicy(DiscountTypes.OnRevenue, DiscountScopes.Cart)
const quantitativeArticle = new DiscountPolicy(DiscountTypes.Quantitative, DiscountScopes.Article)
const fixedPriceArticle = new DiscountPolicy(DiscountTypes.FixedPrice, DiscountScopes.Article)

export const discountPoliciesById: { [key: string]: DisplayedDiscountPolicies } = {
  [simpleArticle.id]: {
    value: simpleArticle,
    text: 'simple sur des articles',
    description: 'une remise sur des articles associés indépendamment de la quantité ou du prix commandé',
    example: `-100€ sur l'article X`,
    dataTest: 'link-to-create-simple-article',
  },
  [simpleCart.id]: {
    value: simpleCart,
    text: 'simple sur un panier',
    description: 'une remise sur un panier indépendamment de la quantité ou du prix commandé',
    example: `-10€ ou -10% sur le panier`,
    dataTest: 'link-to-create-simple-cart',
  },
  [simpleGroup.id]: {
    value: simpleGroup,
    text: 'Xème à -X',
    description: `une remise sur des articles associés qui s'active après un nombre définit d’articles commandés`,
    example: 'le 3ème à -15%',
    dataTest: 'link-to-create-simple-group',
  },
  [revenueArticle.id]: {
    value: revenueArticle,
    text: 'CA sur des articles',
    description: 'la remise CA est accordée selon le chiffre d’affaires commandé sur les articles associés',
    example: `100€ de produits achetés, 10€ offerts`,
    dataTest: 'link-to-create-revenue-article',
  },
  [revenueCart.id]: {
    value: revenueCart,
    text: 'CA sur un panier',
    description: 'la remise CA est accordée selon le chiffre d’affaires commandé sur le total du panier',
    example: 'à partir de 100€ dans le panier, 10€ offerts',
    dataTest: 'link-to-create-revenue-cart',
  },
  [quantitativeArticle.id]: {
    value: quantitativeArticle,
    text: 'quantitative sur des articles',
    description: 'la remise quantitative est accordée selon le volume commandé sur les articles associés',
    example: '200 kg de produits achetés, 10€ offerts',
    dataTest: 'link-to-create-quanti-article',
  },
  [fixedPriceArticle.id]: {
    value: fixedPriceArticle,
    text: 'prix barré sur des articles',
    description:
      'la remise prix barré permet de donner un nouveau prix aux articles associés, le prix original est barré',
    example: '200€, prix de départ: <strike>300€</strike>',
    dataTest: 'link-to-create-fixed-price-article',
  },
}
