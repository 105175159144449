import axios from 'axios'

class UsagesService {
  constructor({ usagesApiHost }) {
    this.api = axios.create({
      baseURL: `${usagesApiHost}/v1`,
    })
  }

  async findByProductIdAndCropId(productId, cropId) {
    const response = await this.api.get('/usages', {
      params: {
        product_id: productId,
        crop_id: cropId,
      },
    })
    return response.data
  }
}

export default UsagesService
