<template>
  <div class="custom-control custom-switch toggle-switch">
    <input @change="change" type="checkbox" class="toggle custom-control-input" id="toggle" v-model="toggleValue" />
    <label for="toggle" class="custom-control-label">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input'],
  methods: {
    change() {
      this.$emit('input', this.toggleValue)
    },
  },
  data() {
    return {
      toggleValue: this.value,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.toggleValue = newValue
      },
    },
  },
}
</script>

<style lang="scss">
.toggle {
  & + .custom-control-label::before {
    background-color: #adb7be;
    border: #adb7be solid 1px;
  }
  &.custom-control-input {
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #adb7be;
    }
    &:focus ~ .custom-control-label::before {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:checked ~ .custom-control-label::before {
      border-color: #68bd3c;
      background-color: #68bd3c;
    }
    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: lighten(#68bd3c, 30%);
      border-color: lighten(#68bd3c, 30%);
    }
  }
}
.toggle-switch {
  .custom-control-label {
    cursor: pointer;
  }
  &.custom-switch .custom-control-label::after {
    background-color: white;
  }
}
</style>
