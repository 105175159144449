export class QuotaDetail {
  private readonly reference: string

  private readonly remaining: number

  private readonly initial: number

  private readonly techId: string

  constructor({ reference, remaining, initial, techId }) {
    this.reference = reference
    this.remaining = remaining
    this.initial = initial
    this.techId = techId
  }

  get label() {
    return `${this.techId}`
  }

  toJSON(): any {
    return {
      ...this,
      label: this.label,
    }
  }
}
