<template>
  <div class="orders">
    <div class="orders__filter">
      <div class="orders__filter-header container">
        <h1>Commandes</h1>
        <IdfInput
          v-model="search"
          icon-left="search"
          :placeholder="'Rechercher une commande; ' + $t('message.membership') + '; code ERP'"
        />
        <IdfButton
          kind="link"
          @click="toggleCollapse"
          :icon-right="filterCollapsed ? 'chevron-down' : 'chevron-up'"
          data-test="btn-toggle-filters"
          >{{ this.filterCollapsed === true ? 'Afficher les filtres' : 'Réduire les filtres' }}
        </IdfButton>
      </div>
      <div class="orders__filter-panel" v-if="!filterCollapsed" data-test="orders-filter-panel">
        <div>
          <label class="orders__filter-title">Choisissez une période</label>
          <div class="d-inline-flex orders__filter-date">
            <input type="date" class="form-control" v-model="startDate" />
            <input type="date" class="form-control" v-model="endDate" :min="startDate" :max="currentDate" />
          </div>
        </div>
        <div>
          <label class="orders__filter-title">Modes de paiements</label>
          <select
            id="payment-method"
            class="form-control"
            v-model="paymentMethod"
            data-test="payment-methods-select"
            data-cy="payment-methods-select"
          >
            <option :value="null">Modes de paiements</option>
            <option v-for="(item, i) of paymentMethods" :key="i" :value="item.value">{{ item.title }}</option>
          </select>
        </div>
        <div>
          <label class="orders__filter-title">Trier par région</label>
          <select
            id="region-name"
            class="form-control"
            v-model="region"
            data-test="zones-select"
            data-cy="zones-select"
            :disabled="!zones.length"
          >
            <option :value="null" v-if="isZoneLoading">Chargement des régions...</option>
            <template v-else>
              <option :value="null">Trier par région</option>
              <option value="empty">Sans région</option>
              <option v-for="(zone, i) of zones" :key="i" :value="zone.name">{{ zone.name }}</option>
            </template>
          </select>
        </div>
      </div>
    </div>
    <div class="container__background">
      <div class="container__order">
        <nav class="container container__nav">
          <div
            v-for="item in Object.keys(orderTabs)"
            :key="item"
            class="tabs__item"
            :class="{ 'tabs__item--active': status == item }"
            @click="setDisplayStatus(orderTabs[item].displayTracking, item)"
            :data-cy="`order-tab-list-${item}`"
          >
            <div :data-test="`orders-${item}`">{{ orderTabs[item].title }} ({{ orderTabs[item].count }})</div>
          </div>
        </nav>
        <v-order-list
          ref="orderList"
          data-test="orders-list"
          :displayTracking="displayTracking"
          :region="region"
          :searchQuery="search"
          :payment-method="paymentMethod"
          :start-date="startDate"
          :end-date="endDate"
          :status="status"
          @cancelOrder="cancelOrder"
          @acceptOrder="acceptOrder"
          @fulfilledOrder="fulfilledOrder"
        ></v-order-list>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { IdfButton, IdfInput } from '@invivodf/ui-kit'
import { PaymentMethodKinds } from '@/services/payment-methods-const'
import { orderService, zoneService } from '@/services'
import VOrderList from '../VOrderList/VOrderList.vue'

const STATUS_PENDING = 'pending'
const STATUS_ACCEPTED = 'accepted'
const STATUS_FULFILLED = 'fulfilled'
const STATUS_CANCELED = 'canceled'

export default {
  name: 'VOrders',
  components: {
    VOrderList,
    IdfInput,
    IdfButton,
  },
  data: () => ({
    displayTracking: true,
    search: '',
    region: null,
    paymentMethod: null,
    startDate: null,
    endDate: null,
    zones: [],
    paymentMethods: PaymentMethodKinds,
    isZoneLoading: true,
    filterCollapsed: true,
    status: 'accepted',
    orderTabs: {
      [STATUS_PENDING]: { count: 0, title: 'En attente', displayTracking: false },
      [STATUS_ACCEPTED]: { count: 0, title: 'En cours', displayTracking: true },
      [STATUS_FULFILLED]: { count: 0, title: 'Livrées', displayTracking: true },
      [STATUS_CANCELED]: { count: 0, title: 'Annulées', displayTracking: false },
    },
  }),
  computed: {
    ...mapGetters(['cooperativeId']),
    currentDate() {
      return DateTime.now().toFormat('y-MM-dd')
    },
  },
  async mounted() {
    await this.fetchAllZones()
    Promise.all([
      this.getCount(STATUS_PENDING),
      this.getCount(STATUS_ACCEPTED),
      this.getCount(STATUS_FULFILLED),
      this.getCount(STATUS_CANCELED),
    ])
    this.debouncedRefreshOrders = debounce(() => this.updateOrders(), 500)
  },
  methods: {
    async getCount(status) {
      const { totalRows } = await orderService.listWithPaginationHead({
        cooperativeId: this.cooperativeId,
        statuses: [status],
        search: this.search,
        page: this.currentPage,
        region: this.region || undefined,
        paymentMethod: this.paymentMethod || undefined,
        startDate: this.startDate || undefined,
        endDate: this.endDate || undefined,
      })

      this.orderTabs[status].count = totalRows
    },
    setDisplayStatus(displayTracking, status) {
      this.displayTracking = displayTracking
      this.status = status
    },
    async fetchAllZones() {
      this.zones = await zoneService.findAll({ cooperativeId: this.cooperativeId })
      this.isZoneLoading = false
    },
    toggleCollapse() {
      this.filterCollapsed = !this.filterCollapsed
    },
    cancelOrder() {
      this.orderTabs.canceled.count += 1
      this.orderTabs.pending.count -= 1
    },
    acceptOrder() {
      this.orderTabs.accepted.count += 1
      this.orderTabs.pending.count -= 1
    },
    fulfilledOrder() {
      this.orderTabs.accepted.count -= 1
      this.orderTabs.fulfilled.count += 1
    },
    updateOrders() {
      Promise.all([
        this.getCount(STATUS_PENDING),
        this.getCount(STATUS_ACCEPTED),
        this.getCount(STATUS_FULFILLED),
        this.getCount(STATUS_CANCELED),
      ])
      this.$refs.orderList.resetOrders()
    },
  },
  watch: {
    search() {
      this.debouncedRefreshOrders()
    },
    region() {
      this.debouncedRefreshOrders()
    },
    paymentMethod() {
      this.debouncedRefreshOrders()
    },
    startDate() {
      this.debouncedRefreshOrders()
    },
    endDate() {
      this.debouncedRefreshOrders()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../lib/_variables.scss';

.container__background {
  background-color: #eff1f5;
  justify-content: center;
  display: flex;
  height: 100%;
}

.container__order {
  width: 90%;
}

.container__nav {
  display: flex;
  justify-content: center;
}

.tabs__item--active {
  color: #ffffff !important;
  background: #0076b4 !important;
}

.tabs__item {
  color: #536975;
  height: 39px;
  width: 20%;
  background: linear-gradient(180deg, rgba(83, 105, 117, 0) 68.75%, rgba(83, 105, 117, 0.2) 111.54%), #ffffff;
}

.orders {
  display: flex;
  flex-direction: column;
}

.orders__filter {
  border-bottom: 1px solid var(--idf-color-neutral-200);

  .orders__filter-header {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    &--background {
      background-color: #ebfaff;
    }
  }
  .orders__filter-title {
    padding-bottom: var(--idf-spacing-1);
  }

  .orders__filter-date {
    padding: 0px;

    .form-control:first-child {
      border-right: 0px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }

    .form-control:nth-child(2) {
      border-left: 0px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }
  }

  select.form-control:disabled {
    padding-left: 8px;
  }
}

@media (min-width: 992px) {
  .orders__filter {
    .orders__filter-header {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      gap: var(--idf-spacing-6);
      align-items: center;
    }
  }

  @media (min-width: 768px) {
    .orders__filter {
      .orders__nav-item {
        width: 265px;
      }
    }
  }
}
.orders__filter-panel {
  background-color: #ebfaff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: var(--idf-spacing-4);
  gap: var(--idf-spacing-6);
}
</style>
