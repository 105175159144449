import { ComputedRef, inject, provide, Ref, ref } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { QueryPage, useQueryPage } from './useQueryPage'
import { searchDiscountableGroup } from '../../../dependency-injection'
import { ServerPagination, useServerPagination } from '../pagination/useServerPagination'
import { SearchResultGroup } from '../../../domain/SearchResultPage'
import { useSeller } from '../../../../../application/composables/useSeller'
import { NotProvidedError } from '../../NotProvidedError'
import { useLoadedForm } from '../useLoadedForm'

export interface IUseSearchDiscountables {
  result: Ref<SearchResultGroup[]>
  numberOfPages: ComputedRef<number>
  currentPage: Ref<number>
  noResult: ComputedRef<boolean>
  searching
  changeWord
  changePage
}

export const SEARCH_RESULT_ASSOCIATION_KEY = Symbol('SEARCH_RESULT_ASSOCIATION_KEY')

export const provideSearchDiscountables = () => {
  const query = useQueryPage()
  const result = useServerPagination<SearchResultGroup>(query.count)

  provide(SEARCH_RESULT_ASSOCIATION_KEY, { result, query })

  return { result, query }
}

interface SearchProvided {
  result: ServerPagination<SearchResultGroup>
  query: QueryPage
}

const injectSearchResult = (): SearchProvided => {
  const provided = inject<SearchProvided>(SEARCH_RESULT_ASSOCIATION_KEY)

  if (!provided) {
    const error = new NotProvidedError(`SearchDiscountables`)
    throw error
  }

  return provided
}

export const useSearchDiscountables = (): IUseSearchDiscountables => {
  const seller = useSeller()
  const discountForm = useLoadedForm()

  const searching = ref(false)
  const { result, query } = injectSearchResult()

  const { showNotificationError } = useBoToast()

  const search = async () => {
    if (query.filled()) {
      try {
        searching.value = true
        const group = await searchDiscountableGroup.execute(
          seller.value.id,
          {
            query: query.word.value,
            page: query.page.value,
            count: query.count,
          },
          discountForm.value.toDiscount(),
        )
        result.setResult({ items: group.results || [], page: group.page, total: group.total })
        searching.value = false
      } catch (e) {
        showNotificationError(e.message)
        searching.value = false
      }
    }
  }

  const changePage = (page: number) => {
    query.page.value = page
    search()
  }

  const changeWord = (eventQuery: string) => {
    query.word.value = eventQuery
    query.page.value = 1
    search()
  }

  return {
    result: result.currentItems,
    currentPage: result.currentPage,
    numberOfPages: result.numberOfPages,
    noResult: result.noItems,
    searching,
    changeWord,
    changePage,
  }
}
