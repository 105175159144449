import { ThresholdsStock } from '../../domain/ThresholdsStock'

export interface ThresholdsStockFromApiResponse {
  id: number
  // eslint-disable-next-line camelcase
  critical_threshold: number
  // eslint-disable-next-line camelcase
  restricted_threshold: number
  // eslint-disable-next-line camelcase
  product_kinds: string[]
}

export const mapFromApiListThresholdsStocksResponse = (
  thresholdStocks: ThresholdsStockFromApiResponse[],
): ThresholdsStock[] =>
  thresholdStocks.map((thresholdStock) => ({
    id: thresholdStock.id,
    criticalThreshold: thresholdStock.critical_threshold,
    restrictedThreshold: thresholdStock.restricted_threshold,
    productKinds: thresholdStock.product_kinds,
  }))

export const mapThresholdsStockToApi = (thresholdStock: ThresholdsStock): ThresholdsStockFromApiResponse => ({
  id: thresholdStock.id,
  critical_threshold: thresholdStock.criticalThreshold,
  restricted_threshold: thresholdStock.restrictedThreshold,
  product_kinds: thresholdStock.productKinds,
})
