<template>
  <div class="create-offer-btn">
    <IdfButton
      v-if="isValidUnit"
      @click="showModal"
      data-add-button
      data-cy="createOfferButton"
      kind="secondary"
      class="create-offer-button"
      icon-left="plus"
      :disabled="disabled"
    >
      Créer une nouvelle offre
    </IdfButton>
    <IdfAlertBanner v-else ref="unavailableOfferAlertRef" kind="danger" data-unavailable-offer>
      <h4>Offre indisponible pour ce produit</h4>
      <p class="offer__error-text--info">
        Seuls les produits dont l’unité de mesure est en kg, L, T, DIFFUSEURS ou Unité peuvent être associés à une
        offre.
      </p>
    </IdfAlertBanner>
    <b-modal hide-header hide-footer ref="formModal" dialog-class="offer__right-modal" size="lg">
      <CVariantOfferForm
        :variantId="variantId"
        :measureQuantity="measureQuantity"
        :measureUnit="measureUnit"
        :isSoldAsUnit="isSoldAsUnit"
        :offer="{}"
        :productKind="productKind"
        @cancel="formModal.hide()"
        @success="formModal.hide()"
        @fail="formModal.hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import { IdfAlertBanner, IdfButton } from '@invivodf/ui-kit'
import { computed, onMounted, ref, toRefs } from 'vue'
import CVariantOfferForm from '../CVariantOfferForm/CVariantOfferForm.vue'

export default {
  name: 'CCreateVariantOffer',
  props: ['disabled', 'variantId', 'measureQuantity', 'measureUnit', 'isSoldAsUnit', 'productKind'],
  components: { CVariantOfferForm, IdfButton, IdfAlertBanner },
  setup(props) {
    const { measureUnit, disabled } = toRefs(props)
    const unavailableOfferAlertRef = ref(null)
    const formModal = ref(null)

    const isValidUnit = computed(() => {
      const unit = measureUnit.value && measureUnit.value.toLowerCase()
      return ['kg', 'l', 't', 'mg', 'diffuseurs', 'unit', 'capsules'].includes(unit)
    })

    onMounted(() => {
      if (!isValidUnit.value) {
        unavailableOfferAlertRef.value.show()
      }
    })

    const showModal = (event) => {
      event.currentTarget.blur()
      if (!disabled.value) {
        formModal.value.show()
      }
    }

    return {
      isValidUnit,
      unavailableOfferAlertRef,
      formModal,
      showModal,
    }
  },
}
</script>
<style lang="scss">
.modal-dialog.offer__right-modal {
  position: absolute;
  right: 0;
  margin: 0;
  width: 50%;
}
.create-offer-button {
  float: right;
}
</style>
