import { AdvancedRule, LogisticOfferFormData } from './interfaces'

const MAX_DELIVERY_MODE_NAME_LENGTH: number = 36
const isLogisticOfferDeliveryModeValid = (logisticOfferFormData: LogisticOfferFormData): boolean => {
  const { logisticRoute, deliveryModeName, deliveryDelay: { value } = {} } = logisticOfferFormData
  return (
    !!logisticRoute &&
    !!deliveryModeName &&
    deliveryModeName.length <= MAX_DELIVERY_MODE_NAME_LENGTH &&
    (!value || typeof +value === 'number')
  )
}

const isLogisticOfferDetailsValid = (logisticOfferFormData: LogisticOfferFormData): boolean => {
  const { reference, validityPeriod: { start, end } = {} } = logisticOfferFormData
  return !!reference && ((!end && !start) || (!!start && !end) || (!!start && !!end && end >= start))
}

const isLogisticOfferPricingValid = (logisticOfferFormData: LogisticOfferFormData): boolean => {
  const { type, standard: { value } = {}, advanced: { weight = [], revenue = [] } = {} } = logisticOfferFormData.pricing
  const isEmpty = () => {
    return !type
  }
  const isStandardPricingValid = (): boolean => {
    return !!type && !!value && type !== 'advanced' && typeof +value === 'number'
  }
  const isAdvancedPricingValid = (): boolean => {
    const checkRule = (rule: AdvancedRule, index: number, length: number) => {
      return (
        !!rule.method &&
        !!rule.rangeStart &&
        !!rule.rangeEnd &&
        !!rule.value &&
        typeof +rule.value === 'number' &&
        ((index === length - 1 && rule.rangeEnd === '-') ||
          (index !== length - 1 && typeof +rule.rangeEnd === 'number' && +rule.rangeEnd > +rule.rangeStart))
      )
    }
    return (
      !!type &&
      type === 'advanced' &&
      weight.every((rule, index) => checkRule(rule, index, weight.length)) &&
      revenue.every((rule, index) => checkRule(rule, index, revenue.length))
    )
  }
  return isEmpty() || isStandardPricingValid() || isAdvancedPricingValid()
}

const isLogisticOfferValid = (logisticOfferFormData: LogisticOfferFormData): boolean => {
  return (
    isLogisticOfferDeliveryModeValid(logisticOfferFormData) &&
    isLogisticOfferDetailsValid(logisticOfferFormData) &&
    isLogisticOfferPricingValid(logisticOfferFormData)
  )
}

const isLogisticOfferStepValid = (step: string, logisticOfferFormData: LogisticOfferFormData): boolean => {
  switch (step) {
    case 'CLogisticOfferRouteStep':
      return isLogisticOfferDeliveryModeValid(logisticOfferFormData)
    case 'CLogisticOfferDetailsStep':
      return isLogisticOfferDetailsValid(logisticOfferFormData)
    case 'CLogisticOfferPricingStep':
      return isLogisticOfferPricingValid(logisticOfferFormData)
    case 'CLogisticOfferExclusionZonesStep':
      return true
    default:
      return false
  }
}

export { isLogisticOfferValid, isLogisticOfferStepValid, MAX_DELIVERY_MODE_NAME_LENGTH }
