
import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import { IdfRadio } from '@invivodf/ui-kit'
import { defineComponent, toRefs } from 'vue'
import { useLogisticOfferWording } from '../../composables'

export default defineComponent({
  name: 'PDeliveryModeList',
  emits: ['select'],
  components: {
    IdfRadio,
  },
  props: {
    deliveryModes: {
      type: Array as () => DeliveryModeEntity[],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const { deliveryModes } = toRefs(props)
    const selectDeliveryMode = (id: string, event: Event): void => {
      const index = (event.target as HTMLInputElement).value
      emit('select', deliveryModes.value[index])
    }
    return {
      selectDeliveryMode,
      wording,
    }
  },
})
