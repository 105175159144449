const stockQuantity = {
  state: {
    stockQuantity: 0,
  },
  mutations: {
    SET_STOCKQUANTITY(state, amount) {
      state.stockQuantity = amount
    },
  },
  actions: {
    setStockQuantity(context, amount) {
      context.commit('SET_STOCKQUANTITY', amount)
    },
  },
  getters: {
    stockQuantity(state) {
      return state.stockQuantity
    },
  },
}
export default stockQuantity
