import { homeBannerApiHost } from '@/contexts/home-banner/config'
import { useAuthService } from '@invivodf/common/src/auth'
import { createHomeBannerApiClient } from '@aladin/shared-universcoop-sdk'
import { createHomeBannerRepository } from './infrastructure/home-banner.repository'
import HomeBannerService from './infrastructure/home-banner.service'
import { createCreateOrUpdateHomeBannerUsecase } from './usecase/create-or-update-home-banner.usecase'
import { createFindHomeBannerByPartnerIdUsecase } from './usecase/find-home-banner-by-partner-id.usecase'

const homeBannerService = new HomeBannerService(
  createHomeBannerApiClient({
    homeBannerApiHost,
    accessTokenSource: () => useAuthService().getAccessToken(),
  }),
)

const homeBannerRepository = createHomeBannerRepository({ homeBannerService })

const findHomeBannerByPartnerIdUsecase = createFindHomeBannerByPartnerIdUsecase({
  homeBannerRepository,
})

const createOrUpdateHomeBannerUsecase = createCreateOrUpdateHomeBannerUsecase({
  homeBannerRepository,
})

export { findHomeBannerByPartnerIdUsecase, createOrUpdateHomeBannerUsecase }
