<template>
  <div class="container">
    <PHeader
      :breadcrumb="breadcrumbs"
      title="Paramétrage des seuils"
      description="Paramétrer vos seuils de stocks pour le retrait magasin (click and collect) en fonction des familles de produit."
    />

    <section class="thresholds__not-found" v-if="isThresholdsStocksEmpty" data-test="no-thresholds-stocks">
      <img src="@/assets/no_result.svg" alt="Aucun résultat trouvé" aria-hidden="true" />
      <h3>Vous n'avez pas encore de seuils paramétrés</h3>
      <p>
        Ajouter des seuils pour que le niveau de stock de vos produits soit visible par vos
        {{ $t('message.memberships') }}.
      </p>
      <IdfButton data-test="no-thresholds-stocks-add-button" @click="createNewThresholdStocks"
        >Ajouter des seuils de stock</IdfButton
      >
    </section>

    <section data-test="thresholds-stocks-item" v-else>
      <IdfButton
        class="thresholds-stock__item"
        kind="link"
        @click="createNewThresholdStocks"
        data-test="thresholds-stocks-items-add-button"
        ><IdfTextIcon icon-left="plus">Paramétrer un nouveau seuil</IdfTextIcon>
      </IdfButton>

      <CThresholdsCard
        v-for="(thresholdsStock, i) in state.thresholdsStocks"
        class="thresholds-stock__item"
        :key="thresholdsStock.key"
        :data="thresholdsStock"
        :availableKinds="availableKinds"
        data-test="thresholds-stocks-items"
        @delete="deleteTresholds(i)"
        @updateKinds="updateUsedkinds($event, i)"
      >
      </CThresholdsCard>
    </section>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'
import { computed, reactive, defineComponent, onBeforeMount } from 'vue'
import * as l10n from '@invivodf/common/src/core/l10n'
import { IdfButton, IdfTextIcon } from '@invivodf/ui-kit'
import PHeader from '@invivodf/common/src/application/presenters/PHeader.vue'
import { PRODUCT_KINDS } from '@/core/products.constants'
import { useThresholdsStocks } from '@/composables/useThresholdsStocks'
import CThresholdsCard from '../CThresholdsCard/CThresholdsCard.vue'

export default defineComponent({
  name: 'CThresholdsStock',
  components: { PHeader, CThresholdsCard, IdfButton, IdfTextIcon },
  setup() {
    const thresholdsStocks = useThresholdsStocks()

    const breadcrumbs = [
      {
        label: 'Accueil',
        link: { name: 'home' },
        icon: 'home-filled',
      },
      {
        label: 'Paramètres',
        link: { name: 'parameters' },
      },
      {
        label: 'Seuils des stocks',
      },
    ]

    const state = reactive({
      thresholdsStocks: [],
      usedKinds: [],
    })

    onBeforeMount(async () => {
      const stocks = await thresholdsStocks.getAll()
      state.thresholdsStocks = stocks
      stocks.forEach((stock) => {
        state.usedKinds.push(stock.productKinds)
      })
    })

    const availableKinds = computed(() => {
      const productKinds = PRODUCT_KINDS.filter((kind) => !state.usedKinds.find((kinds) => kinds?.includes(kind)))

      return productKinds.reduce((available, kind) => ({ ...available, [kind]: l10n.productLabels[kind] }), {})
    })

    const isThresholdsStocksEmpty = computed(() => state.thresholdsStocks?.length === 0)

    const createNewThresholdStocks = () => {
      state.thresholdsStocks.push(
        reactive({
          key: uniqueId(),
          criticalThreshold: 0,
          restrictedThreshold: 0,
          productKinds: [],
        }),
      )
    }

    const deleteTresholds = (index) => {
      state.thresholdsStocks.splice(index, 1)
      state.usedKinds.splice(index, 1)
    }

    const updateUsedkinds = (kinds, i) => {
      state.usedKinds.splice(i, 1, kinds)
    }

    return {
      breadcrumbs,
      state,
      isThresholdsStocksEmpty,
      createNewThresholdStocks,
      deleteTresholds,
      availableKinds,
      updateUsedkinds,
    }
  },
})
</script>

<style lang="scss" scoped>
.thresholds__not-found {
  text-align: center;
}
.thresholds-stock__item {
  margin-bottom: var(--idf-spacing-2);
}
</style>
