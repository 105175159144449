/* eslint-disable camelcase */
import wording from '../wording.json'

export interface WordingInterface {
  editor: {
    title: string
    description: string
    back: string
    steps: {
      CLogisticOfferRouteStep: string
      CLogisticOfferDetailsStep: string
      CLogisticOfferPricingStep: string
      CLogisticOfferExclusionZonesStep: string
      CLogisticOfferSummaryStep: string
    }
    mandatoryFields: string
    logisticRoute: string
    deliveryName: string
    deliveryDelay: string
    preview: string
    offerId: string
    internalName: string
    offerDescription: string
    deliveryDate: string
    validityPeriod: {
      label: string
      start: string
      end: string
      reset: string
    }
    save: string
    skip: string
    reuseDeliveryMode: string
    deliveryMode: {
      route: {
        click_and_collect: string
        home_delivery: string
        pickup_point: string
      }
      delay: {
        hour: string
        day: string
        working_day: string
        week: string
        month: string
      }
    }
    pricing: {
      select: string
      logistic_order: string
      order: string
      advanced: string
      standard: string
      skip: string
      unit: {
        weight: string
        revenue: string
      }
      add_weight_pricing: string
      add_revenue_pricing: string
      add_rule: string
    }
    exclusionZones: {
      description: string
      label: string
      legend: string
    }
    summary: {
      idOffer: string
      deliveryDate: string
      description: string
    }
  }
  list: {
    title: string
    create: string
    headers: string[]
    update: string
    delete: string
  }
  modal: {
    delete: {
      header: string
      body: string
    }
    deliveryMode: {
      header: string
      body: string
    }
    confirm: string
    cancel: string
  }
  notification: {
    successSave: string
    successDelete: string
    error: string
  }
}

export function useLogisticOfferWording() {
  const variabilizeWording = (sentence: string, ...params: string[]): string => {
    if (!params.length) return sentence
    const placeholders = sentence.match(/%s/)
    if (placeholders) {
      let newSentence = sentence
      placeholders.forEach((placeholder, index) => {
        newSentence = newSentence.replace(placeholder, params[index])
      })
      return newSentence
    }

    const replacement = window.env.VUE_APP_CONTEXT === 'aladin' ? 'Aladin' : 'Farmi'

    if (window.env.VUE_APP_CONTEXT !== 'aladin') {
      sentence = sentence.replace(/aladin/gi, 'farmi')
    }

    return sentence
  }
  return { wording, variabilizeWording }
}
