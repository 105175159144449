
import { PropType } from 'vue'
import { IdfBreadcrumb, IdfTextIcon } from '@invivodf/ui-kit'
import { ImportReport } from '../../domain/ImportReport'
import PImportReportEntry from './PImportReportEntry/PImportReportEntry.vue'

export default {
  components: { PImportReportEntry, IdfBreadcrumb, IdfTextIcon },
  props: {
    error: { required: true, type: String },
    loading: { required: true, type: Boolean },
    importReports: { required: true, type: Array as PropType<ImportReport[]> },
  },
  setup() {
    const breadcrumbsItems = [
      { label: 'Accueil', link: { name: 'home' }, icon: 'home-filled' },
      { label: 'Paramètres', link: { name: 'parameters' } },
      { label: 'Historique des imports' },
    ]
    return { breadcrumbsItems }
  },
}
