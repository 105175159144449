import { ComputedRef, Ref, computed, onMounted, ref } from 'vue'
import { DateTime } from 'luxon'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { retrieveDiscount } from '../../dependency-injection'
import { Discount } from '../../domain/Discount'
import { useSeller } from '../../../../application/composables/useSeller'
import { isSuperAdmin } from './isSuperAdmin'

export function getIsArchived(discount: Discount | null) {
  return Boolean(
    (discount && discount.validityEndDate && DateTime.fromJSDate(discount.validityEndDate) < DateTime.now()) ||
      (discount && discount.disabledDate),
  )
}

export interface IUseDiscount {
  isArchived: ComputedRef<boolean>
  discount: Ref<Discount | null>
}

export const useDiscount = (discountId: string): IUseDiscount => {
  const seller = useSeller()

  const discount = ref<Discount | null>(null)

  const { showNotificationError } = useBoToast()

  const loadDiscount = async () => {
    try {
      const isRetrievingMeta = await isSuperAdmin()
      discount.value = await retrieveDiscount.execute(discountId, seller.value.id, isRetrievingMeta)
    } catch (err) {
      showNotificationError(err.message)
    }
  }

  onMounted(loadDiscount)

  const isArchived = computed(() => getIsArchived(discount.value))

  return {
    discount,
    isArchived,
  }
}
