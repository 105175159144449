
import { computed, defineComponent, Ref } from 'vue'
import { IdfIcon } from '@invivodf/ui-kit'
import PIcon from '@invivodf/common/src/application/presenters/PIcon/PIcon.vue'
import { LogisticOfferFormData, LogisticRouteOption } from '../../../domain'
import { useDeliveryModeBusinessName } from '../../composables'

export default defineComponent({
  name: 'PDeliveryNamePreview',
  components: {
    IdfIcon,
    PIcon,
  },
  props: {
    logisticOffer: {
      type: Object as () => LogisticOfferFormData,
      required: true,
    },
    label: {
      type: String,
    },
  },
  setup(props) {
    const { buildDeliveryModeBusinessName, getDeliveryModeIcon } = useDeliveryModeBusinessName()
    const deliveryModeIcon: Ref<LogisticRouteOption | undefined> = computed(() =>
      getDeliveryModeIcon(props.logisticOffer.logisticRoute),
    )
    const deliveryModeLabel: Ref<string> = computed(() =>
      buildDeliveryModeBusinessName(props.logisticOffer.deliveryModeName, props.logisticOffer.deliveryDelay),
    )
    return { deliveryModeIcon, deliveryModeLabel }
  },
})
