
import { defineComponent } from 'vue'
import { LogoAladin, LogoAladinDev, LogoAladinQua, LogoAladinTest, LogoGammVert, LogoJardiland } from './logos'
import { CooperativeFilled } from './icons'

export default defineComponent({
  name: 'PIcon',
  inheritAttrs: false,
  components: {
    CooperativeFilled,
    LogoAladin,
    LogoAladinQua,
    LogoAladinDev,
    LogoAladinTest,
    LogoGammVert,
    LogoJardiland,
  },
  props: {
    kind: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
    ariaHidden: {
      type: Boolean,
    },
  },
  data() {
    return {
      altMessage: this.alt || this.kind,
    }
  },
})
