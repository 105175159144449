
import { defineComponent, ref } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { useMetaDiscountDiscountables } from '../../composables/meta-discount/useMetaDiscountDiscountables'
import CUploadedMetaDiscountsAssociations from './CUploadedMetaDiscountAssociations.vue'
import CMetaDiscountsAssociationsModal from './CMetaDiscountsAssociationsModal.vue'
import CDiscountModal, { ModalCommands } from '../CDiscountModal.vue'

export default defineComponent({
  name: 'CUploadMetaDiscountsAssociations',
  components: {
    IdfButton,
    CUploadedMetaDiscountsAssociations,
    CDiscountModal,
    CMetaDiscountsAssociationsModal,
  },
  setup() {
    const { discountables } = useMetaDiscountDiscountables()

    const uploadAssociationsModal = ref<ModalCommands>()
    const showModal = () => uploadAssociationsModal.value!.show()
    const hideModal = () => uploadAssociationsModal.value!.hide()

    const onValidate = ({ count, file }: { count: number; file: File }) => {
      discountables.file.value = file
      discountables.count.value = count
      hideModal()
    }

    return { discountables, uploadAssociationsModal, showModal, hideModal, onValidate }
  },
})
