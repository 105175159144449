import { getDiscountablePeriodStatus } from '../../domain/statusByPeriod'

type Period = {
  validityStartDate: Date
  validityEndDate: Date | null
}

const orderByDateAsc = <T extends Period>(discountables: T[]): T[] =>
  discountables.sort((discountable1, discountable2) => {
    if (discountable1.validityStartDate === discountable2.validityStartDate) return 0
    if (discountable1.validityStartDate < discountable2.validityStartDate) return 1
    return -1
  })

export const sortPeriodByStatusAndValidityStartDate = <T extends Period>(periods: T[]): T[] => {
  const periodByDate = orderByDateAsc(periods)
  const actives = periodByDate.filter((discountable) => getDiscountablePeriodStatus(discountable) === 'active')
  const upcoming = periodByDate.filter((discountable) => getDiscountablePeriodStatus(discountable) === 'upcoming')
  const past = periodByDate.filter((discountable) => getDiscountablePeriodStatus(discountable) === 'past')
  return [...actives, ...upcoming, ...past]
}
