export const SELLER_SCOPES = [
  'openid',
  'profile',
  'email',
  'offers:manage:user',
  'offers:manage:coop',
  'offers:manage:coop:imports',
  'orders:read:cooperative',
  'orders:write:cooperative',
  'products:manage:cooperative',
  'memberships:manage:cooperative',
  'shipping-addresses:manage:cooperative',
  'shipping-rates:read',
  'shipping-rates:write',
  'technical-sales:manage:cooperative',
  'pickup-points:manage:cooperative',
  'contents:manage:cooperative',
  'zones:manage:cooperative',
  'zone-charges:manage:cooperative',
  'member-segmentation:manage:cooperative',
  'stocks:manage:cooperative',
  'stocks:manage:seller',
  'payment-due-dates:manage:cooperative',
  'quotas:manage:cooperative',
  'logistic-offers:manage:seller',
  'exclusion-zones:manage:all',
]
