import { frenchPlural, invariable } from '@invivodf/common/src/core/l10n'

export const logUnit = (unit) => (unit === 'MG' ? 'milliers de grains' : unit)

export function formatVariableUnit(unit: string) {
  switch (unit) {
    case 'Sac':
      return frenchPlural('sac')
    case 'Bigdose':
      return frenchPlural('Bigdose')
    case 'Dose':
    case 'DOSE':
      return frenchPlural('dose')
    case 'Bigbag':
      return frenchPlural('Bigbag')
    case 'MG':
      return { one: 'millier de grains', other: 'milliers de grains' }
    case 'DIFFUSEURS':
      return frenchPlural('diffuseur')
    case 'unit':
      return invariable('')
    case 'KG':
      return invariable('kg')
    case 'T':
      return invariable('t')
    case 'l':
      return invariable('L')
    default:
      return invariable(unit)
  }
}

export function formatUnit(unit: string) {
  return formatVariableUnit(unit).other
}
