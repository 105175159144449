import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { DiscountMeasureUnits } from '@invivodf/discount-sdk/admin'
import { DiscountableDTO, DiscountableDTOPreventDiscountMessage } from '../datasource/IDiscountable.datasource'
import { AssociationDTO } from '../datasource/IAssociation.datasource'
import { sortPeriodByStatusAndValidityStartDate } from './PeriodSorter'
import { Discountable } from '../../domain/Discountable'

export class DiscountableMapper {
  private readonly discountReferencesByDiscountableId: Record<string, string[]>

  constructor(associations: AssociationDTO[]) {
    this.discountReferencesByDiscountableId = mapValues(groupBy(associations, 'discountableId'), (dtoGroup) =>
      dtoGroup.map(({ discountReference }) => discountReference),
    )
  }

  fromDTO(discountableDTOs: DiscountableDTO[]): Discountable[] {
    const discountables = discountableDTOs.map((dto) => {
      return {
        id: dto.id,
        complianceErrors: [],
        unitPrice: +dto.unitPrice.toFixed(2),
        measureUnit: dto.measureUnit ? dto.measureUnit.toLowerCase() : DiscountMeasureUnits.Unit,
        validityStartDate: new Date(dto.validityStartDate),
        validityEndDate: dto.validityEndDate ? new Date(dto.validityEndDate) : null,
        discountReferences: this.discountReferencesByDiscountableId[dto.id] || [],
        isDiscountable: dto.preventDiscount !== DiscountableDTOPreventDiscountMessage.isPhyto,
        preOrder: dto.preventDiscount === DiscountableDTOPreventDiscountMessage.isPreOrder,
        nbMeasureInOneUnit: dto.measureQuantity,
        zoneCharges: dto.zoneCharges?.map((cost) => ({ cost })) || null,
      }
    })

    return sortPeriodByStatusAndValidityStartDate(discountables)
  }
}
