interface DeleteRepository {
  delete(partnerId: number, discountId: string, deleteMetaDiscount: boolean): Promise<void>
}

export class DeleteDiscount {
  constructor(private readonly repository: DeleteRepository) {}

  async execute(partnerId: number, discountId: string, deleteMetaDiscount: boolean) {
    return this.repository.delete(partnerId, discountId, deleteMetaDiscount)
  }
}
