import { inject, ComputedRef, provide } from 'vue'

export const sellerSymbol = Symbol('seller')

export interface Seller {
  id: number
  name: string
}

export const provideSeller = (seller: ComputedRef<Seller>) => {
  provide(sellerSymbol, seller)
}

export const useSeller = () => {
  const seller = inject<ComputedRef<Seller>>(sellerSymbol)!
  if (!seller?.value.id) throw new Error(`Cette page est accessible uniquement aux administrateurs des ventes`)

  return seller
}
