import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

export default class StoreService {
  constructor(storeApiHost) {
    this.storeApiHost = storeApiHost
    this.api = createAxiosClientWithAladinBearer(`${storeApiHost}/v1/cooperatives`)
  }

  async import(cooperativeId, file) {
    return ImportService.import(`${this.storeApiHost}/backoffice/v1/import/cooperatives/${cooperativeId}/stores`, file)
  }

  async downloadStores(cooperativeId) {
    const response = await this.api.get(`${cooperativeId}/stores`, {
      headers: { accept: 'text/csv' },
    })
    return response.data
  }

  async findAll(cooperativeId, params) {
    const response = await this.api.get(`/${cooperativeId}/stores`, { params })
    return response.data
  }
}
