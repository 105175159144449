import { measureUnit } from '../unit/measure'

export const { format: formatEuros } = new Intl.NumberFormat('fr-FR', {
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'EUR',
})

export const formatMeasureUnit = (n: number, unit?: string, maximumFractionDigits: number = 2) => {
  const { format: formatNumber } = new Intl.NumberFormat('fr-FR', { maximumFractionDigits })

  return unit ? `${formatNumber(n)}\xa0${measureUnit(unit, n)}` : formatNumber(n)
}
