import { quotaService } from '../services'

type OfferQuota = {
  meshId: string
  remaining: number
  initial: number
}

export default {
  namespaced: true,
  state: {
    quotas: [],
    loading: false,
    succeed: false,
  },
  getters: {
    byMeshId: (state) => (meshId: string) => {
      const initialQuota: OfferQuota = {
        initial: 0,
        remaining: 0,
        meshId,
      }
      return state.quotas
        .filter((quota) => quota.meshId === meshId)
        .reduce(
          (acc, item) => ({
            ...acc,
            initial: acc.initial + item.initial,
            remaining: acc.remaining + item.remaining,
          }),
          initialQuota,
        )
    },
    list(state) {
      return state.quotas
    },
    isLoading(state) {
      return state.loading
    },
    isSucceed(state) {
      return state.succeed
    },
  },
  mutations: {
    SET_QUOTAS(state, quotas) {
      state.quotas = quotas
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_SUCCEED(state, succeed) {
      state.succeed = succeed
    },
  },
  actions: {
    async fetch({ commit, rootState }, meshIds) {
      if (!rootState.cooperative?.id) throw new Error('Cooperative id is undefined')
      const quotas = await quotaService.list(rootState.cooperative.id, meshIds)
      commit('SET_QUOTAS', quotas)
    },
  },
}
