
import { defineComponent, PropType, toRefs } from 'vue'
import { IdfPagination } from '@invivodf/ui-kit'
import { useClientPagination } from '../../composables/pagination/useClientPagination'
import CCollectionLoader from './articles/CCollectionLoader.vue'
import CDiscountableTable from './articles/CDiscountableTable.vue'
import { Discountable } from '../../../domain/Discountable'

export default defineComponent({
  name: 'CCartAssociations',
  components: { CDiscountableTable, CCollectionLoader, IdfPagination },
  props: {
    discountables: {
      type: Array as PropType<Discountable[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { discountables } = toRefs(props)
    const { currentItems, numberOfPages, currentPage, changeCurrentPage, noItems } =
      useClientPagination<Discountable>(discountables)

    return {
      currentItems,
      numberOfPages,
      currentPage,
      changeCurrentPage,
      noItems,
      noItemText: "Aucun article n'est associé à votre remise",
      removeDiscountable: (id: string) => {
        emit('removeDiscountable', id)
      },
    }
  },
})
