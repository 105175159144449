
import { defineComponent, onBeforeMount } from 'vue'
import { useAuthService } from '../useAuth'

export default defineComponent({
  render: (createElement) => {
    return createElement('div')
  },
  setup() {
    const auth = useAuthService()

    onBeforeMount(async () => {
      await auth.doSilentRefreshCallback()
    })
  },
})
