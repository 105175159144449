
import { IdfIcon } from '@invivodf/ui-kit'
import { defineComponent, ref, onBeforeUnmount, onMounted } from 'vue'

export interface ModalCommands {
  show: () => void
  hide: () => void
}

export default defineComponent({
  name: 'CDiscountModal',
  components: { IdfIcon },
  setup() {
    const modal = ref({} as HTMLElement)
    onMounted(() => {
      document.querySelector('#app')?.insertAdjacentElement('beforeend', modal.value)
    })

    const isDiscountTypeModalDisplayed = ref(false)

    const show = () => {
      isDiscountTypeModalDisplayed.value = true
      document.body.classList.add('no-overflow')
    }
    const hide = () => {
      isDiscountTypeModalDisplayed.value = false
      document.body.classList.remove('no-overflow')
    }

    const stopEventPropagation = (event) => {
      event.stopPropagation()
    }

    onBeforeUnmount(() => {
      document.body.classList.remove('no-overflow')
      modal.value.remove()
    })

    return { isDiscountTypeModalDisplayed, show, hide, stopEventPropagation, modal }
  },
})
