
import { defineComponent, PropType } from 'vue'
import { IUseDiscountInput } from '../composables/useDiscountInput'
import CDiscountPeriodInput from './inputs/CDiscountPeriodInput.vue'
import CDiscountSegmentInput from './inputs/CDiscountSegmentInput.vue'
import CDiscountFlagCheckbox from './CDiscountFlagCheckbox.vue'
import CStepSubGroup from './CStepSubGroup.vue'

export default defineComponent({
  name: 'CEditDiscountSetting',
  components: {
    CStepSubGroup,
    CDiscountPeriodInput,
    CDiscountSegmentInput,
    CDiscountFlagCheckbox,
  },
  props: {
    validityStartDate: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    validityEndDate: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    segment: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    flashSale: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    sales: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
})
