import { QuotaSchema } from '@b2ag/types-api-schemas'

export default interface QuotaDTO {
  reference: string
  meshId: string
  initial: number
  remaining: number
  techId?: string
}

export function mapFromApiQuotasResponse(quotas: QuotaSchema[]): QuotaDTO[] {
  return quotas.map((quota) => ({
    reference: quota.reference,
    meshId: quota.mesh_id,
    initial: quota.initial,
    remaining: quota.remaining,
    techId: quota.tech_id,
  }))
}
