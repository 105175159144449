
import { computed, defineComponent, toRefs, PropType } from 'vue'

import { getDiscountPeriodStatus } from '@invivodf/common/src/contexts/discount/domain/statusByPeriod'
import { DiscountScopes } from '@invivodf/discount-sdk/admin'
import IdfStepper from './IdfStepper/IdfStepper.vue'
import CGeneralInformationsDetailStep from './CGeneralInformationsDetailStep.vue'
import CReadonlyAssociationsStep from './association/CReadonlyAssociationsStep.vue'
import CDiscountDetailHeader from './CDiscountDetailHeader.vue'
import { Discount } from '../../domain/Discount'

export default defineComponent({
  name: 'CDiscountDetail',
  components: {
    CGeneralInformationsDetailStep,
    CDiscountDetailHeader,
    CReadonlyAssociationsStep,
    IdfStepper,
  },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  setup(props) {
    const { discount } = toRefs(props)
    const showAssociationStep = computed(() =>
      [DiscountScopes.Article, DiscountScopes.Group].includes(discount.value.policy.scope),
    )

    const discountStatus = computed(() => getDiscountPeriodStatus(discount.value))

    return {
      showAssociationStep,
      discountStatus,
    }
  },
})
