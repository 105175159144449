
import { computed, ComputedRef, defineComponent, Ref } from 'vue'
import { IdfButton, IdfInput, IdfPictogram, IdfSelect } from '@invivodf/ui-kit'
import { ValidationObserver, ValidationProvider } from 'vee-validate-last'
import PPricingScale from '../PPricingScale/PPricingScale.vue'
import { useAdvancedTypes, useLogisticOfferRules, useMethods, useLogisticOfferWording } from '../../composables'
import { AdvancedRule, AdvancedType, AdvancedTypeOption } from '../../../domain'

export default defineComponent({
  name: 'PAdvancedPricing',
  components: {
    IdfButton,
    IdfInput,
    IdfSelect,
    IdfPictogram,
    PPricingScale,
    ValidationObserver,
    ValidationProvider,
  },
  emits: ['add-rule', 'delete-rule', 'update-rule-range-end'],
  props: {
    value: {
      type: Array as () => AdvancedRule[],
      default: () => [],
    },
    type: {
      type: String as () => AdvancedType,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const { advancedTypes } = useAdvancedTypes()
    const { methods } = useMethods()
    const formRules = useLogisticOfferRules()
    const rules: Ref<AdvancedRule[]> = computed(() => props.value)
    const selectedType: ComputedRef<AdvancedTypeOption | undefined> = computed(() =>
      advancedTypes.find(({ value }) => value === props.type),
    )
    const isLastBracket = (index: number): boolean => {
      return index === rules.value.length - 1
    }
    const isLastValueInfinite = (): boolean => {
      return !!rules.value.length && rules.value[rules.value.length - 1].rangeEnd === '-'
    }
    const onAddRule = (): void => {
      emit('add-rule', props.type)
    }
    const onUpdateRuleRangeEnd = (index: number, rangeEnd: string): void => {
      emit('update-rule-range-end', index, rangeEnd, props.type)
    }
    const onDeleteRule = (index: number): void => {
      emit('delete-rule', index, props.type)
    }
    return {
      advancedTypes,
      isLastBracket,
      isLastValueInfinite,
      formRules,
      methods,
      onAddRule,
      onDeleteRule,
      onUpdateRuleRangeEnd,
      rules,
      selectedType,
      wording,
    }
  },
})
