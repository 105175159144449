var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.quota.isValid)?_c('router-link',{class:{ 'quota-card': true, pricer: _vm.quota.isPricer },attrs:{"to":!_vm.quota.isPricer && _vm.quota.productLink}},[_c('idf-quota-card',{class:{ global: _vm.quota.isQuotaGlobal },attrs:{"variantName":_vm.quota.isPricer ? _vm.quota.meshId : _vm.quota.variantName,"thumbnail":_vm.quota.thumbnail,"offerValidityEndDate":_vm.formatDate(_vm.quota.offerValidityEndDate),"offerShippingDates":_vm.formatDateInterval(_vm.quota.offerShippingStartDate, _vm.quota.offerShippingEndDate),"shippingIcons":_vm.quota.shippingIcons,"offerSegments":_vm.formatList(_vm.quota.offerSegments)}},[(!_vm.quota.details)?_c('idf-quota-progress',{attrs:{"quota":{
        label: _vm.quota.label,
        remaining: _vm.quota.remaining,
        initial: _vm.quota.initial,
        meterText: _vm.quota.meterText,
        initialQuota: _vm.quota.initialQuotaText,
      }}}):_c('adn-expand-v2',{staticClass:"grey",nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"summary",fn:function(){return [_c('idf-quota-progress',{attrs:{"quota":{
            label: _vm.quota.label,
            remaining: _vm.quota.remaining,
            initial: _vm.quota.initial,
            meterText: _vm.quota.meterText,
            initialQuota: _vm.quota.initialQuotaText,
          }}})]},proxy:true},{key:"detail",fn:function(){return [_c('adn-hierarchical-list',{attrs:{"items":_vm.quota.details},scopedSlots:_vm._u([{key:"default",fn:function(quotaDetail){return [_c('idf-quota-progress',{staticClass:"detail-quota-progress",attrs:{"quota":{
              ...quotaDetail,
              meterText: _vm.formatMeasureUnit(quotaDetail.remaining, _vm.quota.unit),
              initialQuota: _vm.formatMeasureUnit(quotaDetail.initial, _vm.quota.unit),
            }}})]}}],null,false,7220023)})]},proxy:true}],null,false,3395652967)})],1)],1):_c('section',[_c('PWarningMessage',[_vm._v(_vm._s(_vm.quota.errorMessage))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }