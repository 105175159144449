import { TreeCategory } from '@invivodf/front-seller-b2ag/src/presenters/PTreeView/PTreeViewComposables'
import { categoryService } from '@invivodf/front-seller-b2ag/src/services'
import { ref } from 'vue'

class Categorie implements TreeCategory {
  id: string

  label: string

  open: boolean

  checked: boolean

  indeterminate: boolean

  nbOfCheckedChildren: number

  ancestors: Categorie[]

  children: Categorie[]

  constructor(element: any, ancestors: Categorie[]) {
    this.id = element.id
    this.label = element.label
    this.open = false
    this.checked = false
    this.indeterminate = false
    this.nbOfCheckedChildren = 0
    this.ancestors = ancestors
    this.children = []
  }

  populateChildren(categories: any[], ancestors: Categorie[], addToAncestors = true) {
    const childrenCategories = categories.filter((category) => category.parent === this.id)
    const ancestorsToAdd = addToAncestors ? [...ancestors, this] : [...ancestors]
    for (let i = 0; i < childrenCategories.sort((a, b) => a.code.localeCompare(b.code)).length; i += 1) {
      const childrenCategory = childrenCategories.sort((a, b) => a.code.localeCompare(b.code))[i]
      this.children.push(new Categorie(childrenCategory, ancestorsToAdd))
    }
    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i]
      child.populateChildren(categories, ancestorsToAdd)
    }
  }
}

export async function buildCategories(branchesToExclude: string[]) {
  const v2Categories = await categoryService.getV2Categories(branchesToExclude)
  const categoriesWithNoParent = v2Categories.filter((category) => category.parent == null)
  const master = categoriesWithNoParent[0]
  if (master != null) {
    const masterCategorie = new Categorie(master, [])
    masterCategorie.populateChildren(v2Categories, [], false)
    return masterCategorie.children
  }
  return []
}
