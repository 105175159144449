<template>
  <div>
    <label :for="name.startDate">{{ label }}</label>
    <div class="input-date_date-row">
      <validation-provider
        :ref="name.startDate"
        :vid="name.startDate"
        :name="name.startDate"
        :rules="rules.startDate"
        class="input-date__input-wrapper"
        tag="fieldset"
      >
        <input
          type="date"
          :id="name.startDate"
          :name="name.startDate"
          v-model="selectedValues.startDate"
          class="input-date__control input-date__date-input"
          :class="formErrors.startDate && formErrors.startDate.length > 0 ? 'input-date__control--error' : ''"
          data-test-validity-start-date
          data-cy="startDateField"
        />
      </validation-provider>
      <span class="input-date__date-prefix">au</span>
      <validation-provider
        :ref="name.endDate"
        :vid="name.endDate"
        :name="name.endDate"
        :rules="rules.endDate"
        class="input-date__input-wrapper"
        tag="fieldset"
      >
        <input
          type="date"
          :name="name.endDate"
          v-model="selectedValues.endDate"
          class="input-date__control input-date__date-input"
          :class="formErrors.endDate && formErrors.endDate.length > 0 ? 'input-date__control--error' : ''"
          data-test-validity-end-date
          data-cy="endDateField"
        />
      </validation-provider>
    </div>
    <PAlert v-for="error in errors" :key="error">{{ error }}</PAlert>
  </div>
</template>

<script>
import { computed, defineComponent, ref, toRefs, watch } from 'vue'
import { ValidationProvider } from 'vee-validate-last'
import { translateError } from '@/filters'
import PAlert from '@invivodf/common/src/application/presenters/PAlert.vue'

export default defineComponent({
  name: 'CInputDate',
  components: {
    ValidationProvider,
    PAlert,
  },
  props: {
    formErrors: {
      endDate: Array,
      startDate: Array,
    },
    label: String,
    name: {
      endDate: String,
      startDate: String,
    },
    rules: {
      endDate: String,
      startDate: String,
    },
    value: {
      startDate: String,
      endDate: String,
    },
  },
  setup(props, context) {
    const selectedValues = ref(props.value)
    const { formErrors } = toRefs(props)

    watch(selectedValues, (newValues) => {
      context.emit('input', newValues)
    })

    const errors = computed(() =>
      [...(formErrors.value.endDate || []), ...(formErrors.value.startDate || [])].map((e) => translateError(e)),
    )

    return { selectedValues, errors }
  },
})
</script>

<style scoped lang="scss">
.input-date_date-row {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.input-date__control {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.8rem 1.6rem;
  width: 100%;
  border-radius: var(--idf-border-radius-s);
  border-width: 1px;
  margin-right: 1rem;
  background: var(--idf-color-neutral-100);
  &--error {
    border-color: var(--idf-color-error-400);
  }
}
.input-date__input-wrapper,
.input-date__date-prefix {
  margin-bottom: 0;
}
</style>
