import { download } from '@invivodf/common/src/infrastructure/download.service'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

class SegmentService {
  constructor({ userApiHost }) {
    this.userApiHost = userApiHost
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1/cooperatives`, { timeout: 20000 })
  }

  // eslint-disable-next-line class-methods-use-this
  async import(cooperativeId, file) {
    return ImportService.import(
      `${this.userApiHost}/backoffice/v1/import/cooperatives/${cooperativeId}/member-segmentation`,
      file,
    )
  }

  async download(cooperativeId, cooperativeName) {
    const response = await this.api.get(`/${cooperativeId}/member-segmentation`, {
      headers: { accept: 'text/csv' },
      responseType: 'blob',
    })

    download(response.data, ImportService.createFileName(cooperativeName, 'segments'))
  }
}

export default SegmentService
