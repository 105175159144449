
import { IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'PDiscountConfirmationModalFooter',
  components: { IdfButton },
  props: {
    canValidate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    return {
      cancel: () => {
        emit('cancel')
      },
      validate: () => {
        emit('validate')
      },
    }
  },
}
