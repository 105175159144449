
import { defineComponent, onBeforeMount, ref } from 'vue'
import { useTabItemRegistrator } from './utils'

export default defineComponent({
  name: 'CProductTabItem',
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {},
  },
  setup(props) {
    const registrator = useTabItemRegistrator()

    const isHidden = ref(true)

    onBeforeMount(() => {
      registrator.add({
        name: props.name,
        hide() {
          isHidden.value = true
        },
        show() {
          isHidden.value = false
        },
      })
    })

    return {
      isHidden,
    }
  },
})
