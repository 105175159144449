import { onMounted } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { retrieveDiscountables } from '../../../dependency-injection'
import { injectDiscountablesSelection } from './useEditAssociationCart'
import { useSeller } from '../../../../../application/composables/useSeller'
import { useLoadedForm } from '../useLoadedForm'

export const injectPreSelectedDiscountables = () => {
  const seller = useSeller()
  const form = useLoadedForm()
  const selection = injectDiscountablesSelection()

  onMounted(async () => {
    const { showNotificationError } = useBoToast()

    try {
      const discountables = await retrieveDiscountables.execute(form.value.toDiscount(), seller.value.id)
      selection.value = discountables
    } catch (err) {
      showNotificationError(err.message)
    }
  })
}
