<template>
  <PImportReport v-if="importReport" :import-report="importReport" :error="error" />
</template>

<script>
import { mapGetters } from 'vuex'
import { importReportsService } from '@/services'
import { PImportReport } from '@invivodf/common/src/contexts/parameters'

export default {
  name: 'VImportReport',
  components: { PImportReport },
  data() {
    return {
      error: false,
      report: null,
    }
  },
  beforeMount() {
    this.getImportReport()
  },
  computed: {
    ...mapGetters(['cooperativeId']),
    importType() {
      return this.$route.params.importType
    },
    importId() {
      return this.$route.params.importId
    },
    importReport() {
      return this.report
    },
  },
  watch: {
    $route() {
      this.getImportReport()
    },
  },
  methods: {
    async getImportReport() {
      try {
        this.error = false
        this.report = await importReportsService.get(this.cooperativeId, this.importType, this.importId)
      } catch (error) {
        this.error = true
      }
    },
  },
}
</script>
