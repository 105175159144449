<template>
  <div class="container">
    <div class="product-recommended">
      <div class="product-recommended__main-block">
        <div
          v-if="!product.associatedProducts.length && !isAddingAssociatedProduct"
          class="product-recommended__zero-result"
          data-test-zero-result
        >
          <div class="product-recommended__zero-result__content">
            <div class="product-recommended__zero-result__image">
              <img :src="require(`../../assets/product_recommended_no_result.svg`)" alt="zéro resultat" />
            </div>
            <div class="product-recommended__zero-result__information">
              <h3>Aucun produit n'est suggéré avec {{ product.name }}</h3>
              <p>
                Recommandez des produits à vos
                <span style="text-transform: capitalize">{{ $t('message.memberships') }}</span> afin de les aider dans
                leurs commandes
              </p>
              <div class="product-recommended__zero-result__information__button">
                <IdfButton @click="editProduct" data-test-edit-product-btn>Ajouter un produit </IdfButton>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h3>Liste des produits suggérés avec {{ product.name }}</h3>
          <div class="product-associated-heading">
            <div class="product-associated-heading__name">Nom du produit</div>
            <div class="product-associated-heading__others">Fournisseur</div>
            <div class="product-associated-heading__categorie">Catégorie</div>
            <div class="product-associated-heading__others">Sous-catégorie</div>
            <div class="product-associated-heading__others">Code unique</div>
            <div class="product-associated-heading__remove"></div>
          </div>
          <ul>
            <li
              v-for="(associatedProduct, index) in product.associatedProducts"
              :key="index"
              class="product-associated-item"
              :class="{ 'not-available': !associatedProduct.sellable }"
              data-test-associated-products
            >
              <strong>
                <IdfIcon
                  v-if="!associatedProduct.sellable"
                  kind="exclamation-triangle-filled"
                  class="product-associated-item__warning-icon"
                  :aria-hidden="true"
                ></IdfIcon>
                {{ associatedProduct.name }}
              </strong>
              <span class="product-associated-item__others uppercase">{{ associatedProduct.supplier }}</span>
              <span class="product-associated-item__categorie">{{ associatedProduct.categories[0][0].label }}</span>
              <span class="product-associated-item__others">{{
                categorieLastLevel(associatedProduct.categories)
              }}</span>
              <span class="product-associated-item__others">{{ associatedProduct._id }}</span>
              <IdfButtonIcon
                icon="delete-filled"
                @click="showDeleteAssociationModal(associatedProduct)"
                data-test-delete-product-btn
              />
              <div class="not-available-tooltip">
                <IdfIcon
                  kind="exclamation-triangle-filled"
                  class="product-associated-item__warning-icon"
                  :aria-hidden="true"
                ></IdfIcon>
                <span class="not-available-tooltip__text"
                  >Ce produit n'est plus disponible car il a été retiré du catalogue national</span
                >
              </div>
            </li>
          </ul>
          <IdfButton v-if="!isAddingAssociatedProduct" kind="link" icon-left="plus" @click="editProduct"
            >Ajouter un produit</IdfButton
          >
        </div>
        <div v-if="isAddingAssociatedProduct" data-test-edit-product>
          <div class="product-associated-product">
            <ais-instant-search :search-client="searchClient" :index-name="algoliaIndex">
              <ais-autocomplete>
                <template slot-scope="{ indices, refine }">
                  <adn-input
                    type="select"
                    label="AJOUTER UN PRODUIT"
                    placeholder="Entrez le nom du produit"
                    left-icon-name="function-search"
                    ref="search"
                    button-kind="secondary"
                    buttonLabel="Ajouter"
                    :extract-option-value="(payload) => payload.labelWhenOptionSelected"
                    @input="refine"
                    :options="algoliaIndicesToSearchResults(indices)"
                    @select="associatedProductSelected"
                    @submit="addAssociatedProduct"
                  ></adn-input>
                </template>
              </ais-autocomplete>
            </ais-instant-search>
          </div>
        </div>
      </div>
    </div>
    <c-confirm-modal
      ref="associationDeletionModal"
      title="Suppression d'un produit suggéré"
      :message="`Vous êtes sur le point de supprimer  ${
        selectedAssociatedProduct && selectedAssociatedProduct.name
      }  de la liste des produits suggérés`"
      confirmText="Supprimer"
      cancelText="Annuler"
      @cancel="hideAssociationDeletionModal()"
      @confirm="hideAssociationDeletionModal() & removeAssociatedProduct()"
      class="offer__delete-confim-modal"
    ></c-confirm-modal>
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */ /* eslint-disable no-underscore-dangle */
import { mapGetters } from 'vuex'
import algoliasearch from 'algoliasearch/lite'
import AdnSelectOptionProps from '@b2ag/design-system/dist/components/AdnInput/AdnSelectOptionProps'
import { IdfButton, IdfButtonIcon, IdfIcon } from '@invivodf/ui-kit'
import { CROP_PROTECTION_KIND } from '@/core'
import CConfirmModal from '../CConfirmModal/CConfirmModal.vue'

export default {
  name: 'CAssociatedProducts',
  components: { CConfirmModal, IdfButton, IdfIcon, IdfButtonIcon },
  props: ['product'],
  data() {
    return {
      isAddingAssociatedProduct: false,
      searchClient: algoliasearch(window.env.ALGOLIA_APP_ID, window.env.ALGOLIA_API_KEY, { _useRequestCache: true }),
      algoliaIndexCoopPrefix: window.env.ALGOLIA_INDEX_COOP_PREFIX,
      selectedProductId: undefined,
      selectedAssociatedProduct: undefined,
    }
  },
  computed: {
    ...mapGetters(['cooperativeIndex', 'cooperativeId']),
    algoliaIndex() {
      return this.cooperativeIndex.indexName
    },
  },
  methods: {
    editProduct() {
      this.isAddingAssociatedProduct = true
    },
    algoliaIndicesToSearchResults(indices) {
      const hitsWithoutProductsAlreadyAssociated = indices
        .flatMap((indice) => indice.hits)
        .filter(
          (hit) =>
            hit._id !== this.product.id &&
            hit.kind !== CROP_PROTECTION_KIND &&
            !this.product.associatedProducts.some((associateProduct) => associateProduct._id === hit._id),
        )

      return hitsWithoutProductsAlreadyAssociated.map((hit) => {
        const labelWhenOptionSelected = hit.supplier ? `${hit.name} - ${hit.supplier}` : hit.name

        const textComplement = hit.supplier ? ` - ${hit.supplier}` : ''

        return new AdnSelectOptionProps({
          payload: {
            id: hit._id,
            labelWhenOptionSelected,
          },
          text: hit.name,
          textComplement,
        })
      })
    },
    associatedProductSelected($item) {
      this.selectedProductId = $item.id
    },
    hideAssociationDeletionModal() {
      this.$refs.associationDeletionModal.hide()
    },
    showDeleteAssociationModal(associatedProduct) {
      this.selectedAssociatedProduct = associatedProduct
      this.$refs.associationDeletionModal.show()
    },
    async addAssociatedProduct() {
      await this.$store.dispatch('catalog/setAssociatedProducts', {
        cooperativeId: this.cooperativeId,
        productId: this.product.id,
        associatedProductId: this.selectedProductId,
      })

      this.isAddingAssociatedProduct = false
    },
    categorieLastLevel(categories) {
      if (categories[0][2]) {
        return categories[0][2].label
      }
      if (categories[0][1]) {
        return categories[0][1].label
      }
      return ''
    },
    removeAssociatedProduct() {
      this.$store.dispatch('catalog/deleteAssociatedProduct', {
        cooperativeId: this.cooperativeId,
        productId: this.product.id,
        associatedProductId: this.selectedAssociatedProduct._id,
      })
    },
  },
}
</script>
<style lang="scss">
@import '../../lib/_variables.scss';
.product-recommended__main-block {
  background-color: white;
  width: 100%;
  padding: 2rem 2.5rem;
}
.product-recommended__zero-result {
  text-align: center;
  &__image {
    display: inline-block;
    vertical-align: top;
  }
  &__information {
    display: inline-block;
    text-align: left;
    margin-top: var(--idf-spacing-3);
    max-width: $idf-breakpoint-xs;
    &__button {
      text-align: center;
      margin-top: var(--idf-spacing-2);
    }
  }
}
.product-associated-heading {
  background-color: var(--idf-color-neutral-200);
  color: var(--idf-color-neutral-500);
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 2fr 2fr 0.5fr;
  align-items: center;
  padding: var(--idf-spacing-2) var(--idf-spacing-3);
  border-radius: 4px;
  text-transform: uppercase;
}
.product-associated-item {
  padding: var(--idf-spacing-2) var(--idf-spacing-3);
  border: 1px solid var(--idf-color-neutral-200);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 2fr 2fr 0.5fr;
  align-items: center;
}
.not-available {
  color: var(--idf-color-neutral-400);
}
.not-available-tooltip {
  visibility: hidden;
  position: absolute;
  left: 25%;
  z-index: 1;
  display: grid;
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--idf-shadow-dark);
  &__warning {
    color: var(--idf-color-warning-400);
    grid-column: 1;
  }
  &__text {
    grid-column: 2;
    padding-left: 7px;
  }
  &::after {
    top: calc(50% - 15px);
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -12px;
    border: 12px solid var(--idf-color-neutral-200);
    border-color: transparent transparent var(--idf-color-neutral-100) var(--idf-color-neutral-100);
    transform: rotate(45deg);
    box-shadow: -0.2rem 0.4rem 0.3rem 0 var(--idf-color-neutral-200);
  }
}
.not-available:hover .not-available-tooltip {
  visibility: visible;
  color: var(--idf-color-neutral-700);
  background-color: var(--idf-color-neutral-100);
}
.uppercase {
  text-transform: uppercase;
}
.product-associated-product {
  border: 1px solid var(--idf-color-neutral-200);
  box-sizing: border-box;
  border-radius: 4px;
  padding: var(--idf-spacing-2);
}
.adn-input {
  &--labelled {
    width: 600px;
  }
  &__label {
    color: var(--idf-color-neutral-500);
    font-size: 12px;
  }
}
.idf-icon.product-associated-item__warning-icon path {
  fill: var(--idf-color-warning-400);
}
</style>
