import { ArticleResponseApiV2 } from '@aladin/shared-universcoop-sdk'
import { Article } from '../domain/article'
import { ArticleRepository } from '../domain/article.repository'

interface UpdateArticleUsecase {
  (params: { partnerId: number; slug: string; article: Article }): Promise<void>
}

interface Dependencies {
  articleRepository: ArticleRepository
}

export function createUpdateArticleUsecase(deps: Dependencies): UpdateArticleUsecase {
  return deps.articleRepository.updateArticle
}
