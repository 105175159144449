import { Step } from '../../domain'

export function useRetailFormSteps(): Step[] {
  return [
    'CLogisticOfferRouteStep',
    'CLogisticOfferDetailsStep',
    'CLogisticOfferPricingStep',
    'CLogisticOfferExclusionZonesStep',
    'CLogisticOfferSummaryStep',
  ]
}
