const shippingLabels = {
  farm: () => 'Livraison à la ferme',
  pickup_point: (cooperativeName) => `Livraison relais ${cooperativeName}`,
  click_and_collect: (cooperativeName) => `Retrait en magasin : ${cooperativeName}`,
}

type shippingKind = 'farm' | 'pickup_point' | 'click_and_collect'

export default function shippingKindLabel(kind: shippingKind, cooperativeName = 'coopérative') {
  return shippingLabels[kind](cooperativeName)
}
