import { ArticleCreateApiV2 } from '@aladin/shared-universcoop-sdk'
import { Article } from '../domain/article'

export function articletoArticleApiV2(article: Article): ArticleCreateApiV2 {
  return {
    title: article.title,
    image_url: article.image_url,
    content: article.content,
    published_at: new Date(article.published_at),
  }
}
