import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

class ZoneService {
  constructor({ zoneApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${zoneApiHost}/v1/cooperatives`)
  }

  async findAll({ cooperativeId }) {
    const result = await this.api.get(`/${cooperativeId}/zones`)
    return result.data
  }

  async import(cooperativeId, file) {
    return ImportService.import(`${this.zoneApiHost}/v1/cooperatives/${cooperativeId}/zones`, file)
  }

  async downloadZoneTemplate({ cooperativeId }) {
    const response = await this.api.get(`/${cooperativeId}/zones/template`, { headers: { accept: 'text/csv' } })
    return response.data
  }

  async create({ cooperativeId, zone }) {
    const result = await this.api.post(`/${cooperativeId}/zones`, zone)
    return result.data
  }

  async update({ cooperativeId, zone }) {
    const result = await this.api.put(`/${cooperativeId}/zones/${zone.id}`, zone)
    return result.data
  }

  async delete({ cooperativeId, zone }) {
    const result = await this.api.delete(`/${cooperativeId}/zones/${zone.id}`)
    return result.data
  }
}

export default ZoneService
