import { computed, Ref, ref } from 'vue'
import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { LogisticOfferFormData, Pricing } from '../../domain'
import { saveLogisticOffer, getLogisticOffer } from '../../dependency-injection'
import { useLogisticOfferWording } from './useWording'

const logisticOffer = ref<LogisticOfferFormData>({
  deliveryDelay: {},
  isDeliveryDateFeatureActive: false,
  pricing: { standard: { unit: 'euro' }, advanced: { weight: [], revenue: [] } },
  exclusionZones: [],
})
const isEditingMode = ref(false)

export function useLogisticOffer() {
  const seller = useSeller()
  const { wording } = useLogisticOfferWording()
  const isLoading: Ref<boolean> = ref(false)

  const resetlogisticOffer = (): void => {
    logisticOffer.value = {
      deliveryDelay: {},
      isDeliveryDateFeatureActive: false,
      pricing: { standard: { unit: 'euro' }, advanced: { weight: [], revenue: [] } },
      exclusionZones: [],
    }
    isEditingMode.value = false
  }

  const saveOffer = async (): Promise<void> => {
    if (!seller.value.id) throw new Error(wording.notification.error)
    try {
      await saveLogisticOffer.execute(seller.value.id.toString(), logisticOffer.value)
    } catch (e) {
      throw new Error(wording.notification.error)
    }
  }

  const initOffer = async (reference?: string): Promise<void> => {
    if (!reference) return
    if (!seller.value.id) throw new Error(wording.notification.error)
    isLoading.value = true
    try {
      const offer = await getLogisticOffer.execute(seller.value.id.toString(), reference)
      logisticOffer.value = offer
      isEditingMode.value = true
    } catch (e) {
      throw new Error(wording.notification.error)
    } finally {
      isLoading.value = false
    }
  }

  const setLogisticOfferDeliveryMode = (deliveryMode: DeliveryModeEntity): void => {
    logisticOffer.value = {
      ...logisticOffer.value,
      logisticRoute: deliveryMode.logisticRoute,
      deliveryModeName: deliveryMode.name,
      deliveryDelay: {
        value: deliveryMode.delay?.value,
        unit: deliveryMode.delay?.unit,
      },
    }
  }

  const setLogisticOfferPricing = (formPricing: Pricing): void => {
    logisticOffer.value = {
      ...logisticOffer.value,
      pricing: formPricing,
    }
  }

  const setLogisticOfferExclusionZones = (exclusionZones: string[]): void => {
    logisticOffer.value = {
      ...logisticOffer.value,
      exclusionZones,
    }
  }

  return {
    logisticOffer: computed(() => logisticOffer.value),
    cooperativeId: seller.value.id,
    saveOffer,
    initOffer,
    disableReference: isEditingMode,
    resetlogisticOffer,
    isLoading,
    setLogisticOfferDeliveryMode,
    setLogisticOfferExclusionZones,
    setLogisticOfferPricing,
  }
}
