<template>
  <div class="container">
    <div class="product-variant-list-item">
      <router-link
        :to="{ name: 'variant', params: { productId: product.id, variantInvivoId: variant.id } }"
        class="product-variant-list-item__description"
      >
        <h3 class="product-variant-list-item__title">{{ variant.name }}</h3>
        <dl class="product-variant-header__data">
          <div>
            <dt data-test-invivo-id-label>ID Invivo&nbsp;:</dt>
            <dd data-test-invivo-id-value>{{ variant.id }}</dd>
          </div>
          <div v-if="variant.eanCode">
            <dt data-test-ean-label>EAN&nbsp;:</dt>
            <dd data-test-ean-value>{{ variant.eanCode }}</dd>
          </div>
          <div v-else-if="product.exclusive">
            <dt data-test-exclusive-label>Code article&nbsp;:</dt>
            <dd data-test-exclusive-value>
              {{ variant.cooperativeVariantCode }}
            </dd>
          </div>
          <div v-if="variant.articleReferenceSupplier">
            <dt data-test-article_reference_supplier-label>Référence Article Fournisseur&nbsp;:</dt>
            <dd data-test-article_reference_supplier-value>{{ variant.articleReferenceSupplier }}</dd>
          </div>
        </dl>
      </router-link>

      <div v-if="variant.associated" class="product-variant-list-item__line-information">
        <div>
          <div
            v-if="variantStockIsNotManaged"
            class="product-variant-list-item__stock-management-type--not-managed"
            data-test-stock-not-managed
          >
            Non géré
          </div>
          <IdfTextIcon
            icon-left="check"
            v-if="variantStockIsManaged"
            class="product-variant-list-item__stock-management-type--managed"
            :aria-hidden="true"
            data-test-stock-managed
            >Géré
          </IdfTextIcon>
        </div>

        <div>
          <Icon v-if="variant.published" kind="file-filled" description="Publié" />
          <IconNotAvailable v-else description="Non publié" />
        </div>

        <div>
          <Icon v-if="variantHasOffers" kind="offer-filled" description="Offre disponible" />
          <IconNotAvailable v-else description="Offre indisponible" />
        </div>

        <div>
          <Icon kind="store-filled" :description="'Variante associée au catalogue ' + $t('message.partner')" />
        </div>
      </div>

      <IconNotAvailable v-else :description="'Variante non associée au catalogue ' + $t('message.partner')" />
    </div>
  </div>
</template>

<script>
import { IdfTextIcon } from '@invivodf/ui-kit'
import { STOCK_MANAGED, STOCK_NOT_MANAGED } from '../../services/stock.const'
import IconNotAvailable from './IconNotAvailable.vue'
import Icon from './Icon.vue'

export default {
  name: 'CProductVariantListItem',
  components: { IconNotAvailable, Icon, IdfTextIcon },
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    variant: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    variantHasOffers() {
      return (
        this.variant && this.variant.offers && this.variant.offers.some((offer) => offer.validityStatus === 'active')
      )
    },
    variantStockIsManaged() {
      return this.variant?.stockManagementType === STOCK_MANAGED
    },
    variantStockIsNotManaged() {
      return this.variant?.stockManagementType === STOCK_NOT_MANAGED
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../lib/_variables.scss';
@import '../../lib/_mixins.scss';
.product-variant-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  background-color: white;
}
.product-variant-list-item__description {
  padding: 1rem 2rem;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: var(--idf-color-main-400);
  }
}
.product-variant-list-item__title {
  @include idf-font('m');
}
.product-variant-list-item__stock-management-type--managed,
.product-variant-list-item__stock-management-type--not-managed {
  border-radius: 3rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-variant-list-item__stock-management-type--managed {
  border: 1px solid var(--idf-color-success-400);
}
.product-variant-list-item__stock-management-type--not-managed {
  color: var(--idf-color-neutral-500);
}
.product-variant-list-item__stock-management-type--managed.idf-text-icon::v-deep path {
  fill: var(--idf-color-success-400);
}
.product-variant-list-item__line-information {
  display: flex;
  align-items: center;
}
.product-variant-list-item__line-information div {
  width: 100px;
}
.product-variant-header__data div {
  color: var(--idf-color-neutral-500);
  display: flex;
  gap: var(--idf-spacing-1);
}
</style>
