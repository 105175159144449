
import { computed, defineComponent, Ref } from 'vue'
import PIcon from '@invivodf/common/src/application/presenters/PIcon/PIcon.vue'
import { ValidationProvider } from 'vee-validate-last'
import { IdfIcon, IdfInput, IdfRadio, IdfStandardInputs } from '@invivodf/ui-kit'
import { LogisticRouteOption } from '../../../domain'

export default defineComponent({
  name: 'PInlineRadios',
  components: {
    IdfIcon,
    IdfInput,
    IdfRadio,
    IdfStandardInputs,
    PIcon,
    ValidationProvider,
  },
  emits: ['input'],
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array as () => LogisticRouteOption[],
    },
    value: {
      type: String,
    },
    rules: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const checkedValue: Ref<string | undefined> = computed({
      get: (): string | undefined => props.value,
      set: (newValue: string | undefined): void => emit('input', newValue),
    })
    return { checkedValue }
  },
})
