
import { defineComponent, PropType, toRefs } from 'vue'
import { IdfIcon, IdfLoader } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'CFileDownload',
  components: { IdfIcon, IdfLoader },
  props: {
    fileContent: {
      type: String as PropType<string>,
      required: false,
    },
    fileName: {
      type: String as PropType<string>,
      required: false,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const { fileContent, fileName } = toRefs(props)

    const download = () => {
      if (!fileContent?.value || !fileName?.value) {
        emit('download')
        return
      }
      const blob = new Blob([fileContent.value], { type: 'text/plain' })
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', objectUrl)
      link.setAttribute('download', fileName.value)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    return { download }
  },
})
