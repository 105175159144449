import { computed } from 'vue'
import {
  updateAssociations as updateAssociationsUseCase,
  uploadMetaDiscountAssociations,
} from '../../../dependency-injection'
import { useMetaDiscount } from '../meta-discount/useMetaDiscount'
import { useDiscountableSelected } from './useDiscountableSelected'
import { useSeller } from '../../../../../application/composables/useSeller'

export const useUpdateAssociations = () => {
  const seller = useSeller()

  const { ids: discountableIds } = useDiscountableSelected()
  const { discountables: metaDiscountDiscountables, isUsingMetaDiscount } = useMetaDiscount()

  const updateAssociations = async (discountId: string) => {
    if (isUsingMetaDiscount.value && metaDiscountDiscountables.file.value !== null)
      await uploadMetaDiscountAssociations.execute(discountId, metaDiscountDiscountables.file.value)
    else if (!isUsingMetaDiscount.value)
      await updateAssociationsUseCase.execute(seller.value.id, discountId, discountableIds.value)
  }

  const associationsCount = computed(() =>
    String(isUsingMetaDiscount.value ? metaDiscountDiscountables.count.value ?? '-' : discountableIds.value.length),
  )

  return { associationsCount, updateAssociations }
}
