import { useLogisticOfferUnicity } from '../application/composables'

const isOfferUnique = async (getOffer: Function, reference: string, sellerId: string) => {
  try {
    await getOffer(sellerId, reference)
    return false
  } catch (e) {
    return true
  }
}

export function uniqueReferenceValidator() {
  return {
    message() {
      return 'Cet identifiant est déjà associé à une offre logistique'
    },
    validate(reference: string, args: any) {
      const { getOffer } = useLogisticOfferUnicity()
      return isOfferUnique(getOffer, reference, args.sellerId)
    },
    params: ['sellerId'],
  }
}
