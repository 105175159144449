const dictionaryLast = {
  fr: {
    names: {
      shippingStartDate: 'date de début de livraison',
      shippingEndDate: 'date de fin de livraison',
      unitPrice: 'prix',
      measurementPrice: 'prix',
      measureUnitPrice: 'prix',
      shippingWays: 'mode de livraison',
      validityStartDate: 'date de début de validité',
      validityEndDate: 'date de fin de validité',
      priceType: 'type de prix',
      sellerCustomId: 'ID offre',
      newQuantity: 'quantité',
      price: 'prix',
      quantityMax: 'quantité maximale',
      quantityMin: 'quantité minimale',
      quantityStep: 'intervalle de vente',
      offerTechnicalInformation: 'référence interne',
    },
    fields: {
      quantityMax: {
        greaterThan: 'La “quantité maximum par commande” doit être égale ou supérieure à la quantité minimale',
        integer:
          'La “quantité maximale à la vente” doit être un entier positif supérieur ou égal à la quantité minimale',
      },
      quantityMin: {
        lessThan: 'La donnée ne peut être supérieure à la quantité maximale de vente',
      },
      quantityStep: {
        lessThan: 'La donnée ne peut être supérieure à la quantité maximale de vente',
      },
    },
  },
}

export default dictionaryLast
