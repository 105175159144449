import { LogisticRouteName } from '@invivodf/common/src/contexts/logistic-offer/routes/logistic-route-name'
import { DiscountRouteName } from '@invivodf/common/src/contexts/discount'
import { INavigationLinks } from '../../domain/INavigationLinks'

export function useNavigationLinks() {
  const navigationLinks: INavigationLinks[] = [
    {
      icon: 'book-outlined',
      label: 'Catalogue',
      link: { name: 'search-cooperative' },
    },
    {
      icon: 'barcode',
      label: 'Offres',
      link: { name: 'offers' },
    },
    {
      icon: 'promotion-outlined',
      label: 'Remises',
      link: { name: DiscountRouteName.LISTING },
    },
    {
      icon: 'truck-outlined',
      label: 'Offres logistiques',
      link: { name: LogisticRouteName.LIST },
    },
    {
      icon: 'trending-up',
      label: 'Quotas',
      link: { name: 'quotas' },
    },
    {
      icon: 'cart-bag-outlined',
      label: 'Commandes',
      link: { name: 'orders' },
    },
    {
      icon: 'gear-outlined',
      label: 'Paramètres',
      link: { name: 'parameters' },
    },
  ]

  return { navigationLinks }
}
