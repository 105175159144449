
import { defineComponent, onMounted } from 'vue'
import { ValidationObserver } from 'vee-validate-last'
import { IdfIcon } from '@invivodf/ui-kit'
import { useBoToast } from '@invivodf/common/src/application/composables/useBoToast'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import {
  CLogisticOfferRouteStep,
  CLogisticOfferDetailsStep,
  CLogisticOfferPricingStep,
  CLogisticOfferExclusionZonesStep,
  CLogisticOfferSummaryStep,
} from '..'
import { useLogisticOfferSteps, useLogisticOffer, useLogisticOfferWording } from '../../composables'
import { PLoader, PLogisticOfferStep } from '../../presenters'
import { Step } from '../../../domain'
import { LogisticRouteName } from '../../../routes/logistic-route-name'

export default defineComponent({
  name: 'CLogisticOfferEditor',
  components: {
    CLogisticOfferDetailsStep,
    CLogisticOfferExclusionZonesStep,
    CLogisticOfferPricingStep,
    CLogisticOfferRouteStep,
    CLogisticOfferSummaryStep,
    IdfIcon,
    PLoader,
    PLogisticOfferStep,
    ValidationObserver,
  },
  props: {
    reference: {
      type: String,
    },
  },
  setup(props) {
    const { initOffer, isLoading, logisticOffer, resetlogisticOffer, saveOffer } = useLogisticOffer()
    const { showNotificationSuccess, showNotificationError } = useBoToast()
    const { wording } = useLogisticOfferWording()
    const router = useRouter()
    const {
      activeSteps,
      isStepOpen,
      isStepValid,
      isStepDisabled,
      isStepOptional,
      goToNextStep,
      isLastStep,
      toggleStep,
      enableAllSteps,
      resetSteps,
    } = useLogisticOfferSteps()
    const getStepNumberFromIndex = (index: number): number => {
      return index + 1
    }
    const resetForm = () => {
      resetSteps()
      resetlogisticOffer()
    }
    const submitStep = async (step: Step) => {
      if (isLastStep(step)) {
        try {
          await saveOffer()
          showNotificationSuccess(wording.notification.successSave)
          router.push({ name: LogisticRouteName.LIST })
        } catch (e) {
          showNotificationError(e.message)
        }
      } else goToNextStep(step)
    }
    onMounted(() => {
      resetForm()
      if (props.reference) {
        try {
          initOffer(props.reference)
          enableAllSteps()
        } catch (e) {
          showNotificationError(e.message)
        }
      }
    })
    return {
      getStepNumberFromIndex,
      isLoading,
      isStepDisabled,
      isStepOpen,
      isStepOptional,
      isStepValid,
      logisticOffer,
      logisticOfferSteps: activeSteps,
      submitStep,
      toggleStep,
      wording,
    }
  },
})
