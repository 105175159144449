import { RouteConfig } from 'vue-router'
import VLogisticOfferEditor from '../views/VLogisticOfferEditor/VLogisticOfferEditor.vue'
import VLogisticOfferList from '../views/VLogisticOfferList/VLogisticOfferList.vue'
import VLogisticOffer from '../views/VLogisticOffer/VLogisticOffer.vue'
import { LogisticRouteName } from './logistic-route-name'
import { PHeaderEditor, PHeaderList } from '../application/presenters'

export const logisticRoutes: RouteConfig[] = [
  {
    path: 'logistic-offer',
    component: VLogisticOffer,
    children: [
      {
        path: '',
        name: LogisticRouteName.LIST,
        components: {
          default: VLogisticOfferList,
          header: PHeaderList,
        },
      },
      {
        path: 'edit/:reference?',
        name: LogisticRouteName.EDIT,
        props: {
          default: true,
        },
        components: {
          default: VLogisticOfferEditor,
          header: PHeaderEditor,
        },
      },
    ],
  },
]
