<template>
  <div class="input-select">
    <span class="adn-icon adn-icon--navigation-direction-down" aria-hidden="true"></span>
    <validation-provider :ref="id" :vid="id" :name="name" :rules="rules">
      <IdfStandardInputs
        :label="label"
        :error="formErrors && formErrors.length > 0"
        :message="formErrors && formErrors[0]"
      >
        <select
          :id="id"
          :name="name"
          class="input-select__control"
          :class="formErrors && formErrors.length > 0 ? 'input-select__control--error' : ''"
          v-model="selectedValue"
          @change="updateValue"
        >
          <option value disabled selected hidden v-if="placeholder">
            <span>{{ placeholder }}</span>
          </option>
          <option v-for="optionKey in Object.keys(options)" :value="optionKey" :key="optionKey">
            {{ options[optionKey] }}
          </option>
        </select>
      </IdfStandardInputs>
    </validation-provider>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { ValidationProvider } from 'vee-validate-last'
import { translateError } from '@/filters'
import { IdfStandardInputs } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'CInputSelect',
  components: { ValidationProvider, IdfStandardInputs },
  props: {
    id: String,
    formErrors: Array,
    label: String,
    name: String,
    options: Object,
    placeholder: String,
    rules: String,
    value: String,
  },
  setup(props, context) {
    const selectedValue = ref(props.value)

    function updateValue() {
      context.emit('input', selectedValue.value)
    }

    return { selectedValue, translateError, updateValue }
  },
})
</script>

<style scoped lang="scss">
.input-select {
  position: relative;
  width: 100%;
  @media screen and (min-width: $idf-breakpoint-md) {
    width: 50%;
  }
  .adn-icon {
    position: absolute;
    right: 0.4rem;
    top: 4rem;
    font-size: 2rem;
    pointer-events: none;
  }
  &__control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.8rem 1.6rem;
    width: 100%;
    border-radius: var(--idf-border-radius-s);
    border-width: 1px;
    background: var(--idf-color-neutral-100);
    color: var(--idf-color-neutral-500);
    margin-top: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &--error {
      border-color: var(--idf-color-error-400);
    }
  }
}
</style>
