import { createAxiosClientAnonymous } from '@invivodf/common/src/infrastructure/httpClient'
import { featureFlippingApiHost } from './config'
import { FeatureFlipCacheDataSource } from './infrastructure/FeatureFlipCacheDataSource'
import { FeatureFlipEnvDataSource } from './infrastructure/FeatureFlipEnvDataSource'
import { FeatureFlipIzanamiDataSource } from './infrastructure/FeatureFlipIzanamiDataSource'
import { FeatureFlipRepository } from './infrastructure/FeatureFlipRepository'
import { CheckFeature } from './use-cases/CheckFeature'

const axiosClient = createAxiosClientAnonymous(featureFlippingApiHost)
// eslint-disable-next-line no-console
const featureFlipIzanamiDataSource = new FeatureFlipIzanamiDataSource(axiosClient, console.error)
// eslint-disable-next-line no-console
const featureFlipEnvDataSource = new FeatureFlipEnvDataSource(console.error)
const featureFlipCacheDataSource = new FeatureFlipCacheDataSource()

const featureFlipRepository = new FeatureFlipRepository(
  featureFlipIzanamiDataSource,
  featureFlipEnvDataSource,
  featureFlipCacheDataSource,
)

export const checkFeature = new CheckFeature(featureFlipRepository)
