
import { computed, defineComponent } from 'vue'
import CElementLoading from '@invivodf/front-seller-b2ag/src/components/CElementLoading/CElementLoading.vue'
import CAssociationsStep from './association/CEditableAssociationsStep.vue'
import CSummaryStep from './CSummaryStep.vue'
import { useForm } from '../composables/useForm'
import CGeneralInformationsStep from './CGeneralInformationsStep.vue'
import IdfStepper from './IdfStepper/IdfStepper.vue'

export default defineComponent({
  name: 'CDiscountForm',
  components: {
    CElementLoading,
    IdfStepper,
    CGeneralInformationsStep,
    CAssociationsStep,
    CSummaryStep,
  },
  setup() {
    const { associationStepRequired, discountForm } = useForm()

    return {
      showAssociationStep: associationStepRequired,
      loading: computed(() => !discountForm.value),
    }
  },
})
