<template>
  <section class="variant-stock">
    <h2 class="variant-stock__title">Stock par variante</h2>
    <div v-if="hasStock()" data-test-stores>
      <p-variant-stock
        :availableQuantity="stock.availableQuantity.shippable"
        :bookedQuantity="stock.bookedQuantity.shippable"
        :importedQuantity="stock.quantity.shippable"
        title="Quantités à l'expédition"
      />
      <p-variant-stock
        :availableQuantity="stock.availableQuantity.collectable"
        :bookedQuantity="stock.bookedQuantity.collectable"
        :importedQuantity="stock.quantity.collectable"
        title="Quantités en retrait magasin"
      />
      <h4>Détail de l'import :</h4>
      Date de dernière mise à jour des stocks : {{ stockLastUpdate }}
      <ul class="card variant-stock__content-list">
        <li class="variant-stock__content-text" v-for="store in stock.stores" :key="store.code">
          <span>
            La quantité importée du magasin&nbsp;{{ store.name }}&nbsp;({{ store.code }}) est de
            {{ formatMeasureUnit(store.quantity, 'unit') }} de vente aladin <br />(dont
            {{ formatMeasureUnit(store.bookedQuantity, 'unit') }} de vente aladin
            {{ store.bookedQuantity > 1 ? 'réservées' : 'réservée' }}.)
          </span>
          <span class="variant-stock__content-text-shipping-methods">
            &nbsp;({{ getStoreShippingMethods(store) }})</span
          >
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="variant-stock__content-text py-3">
        <span data-price-text class="variant-stock__content-text-unit" data-test-display-stock>
          Il n'y a pas de stock pour cette variante.
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { formatMeasureUnit } from '@invivodf/common/src/core/l10n'
import PVariantStock from './PVariantStock.vue'
import { stockService, storeService } from '../../../services'

export default {
  name: 'CVariantStock',
  components: {
    PVariantStock,
  },
  props: {
    variantId: String,
    cooperativeId: Number,
    measureUnit: String,
    quantity: {
      default: () => 0,
      type: Number,
    },
    isSoldAsUnit: Boolean,
  },
  data() {
    return {
      stock: {},
    }
  },
  mounted() {
    this.getStockByVariant()
  },
  computed: {
    stockLastUpdate() {
      const lastUpdateString = new Date(this.stock.updatedAt).toLocaleString('fr-FR')
      return lastUpdateString.replace(' ', ' à ').replace(',', '')
    },
  },
  methods: {
    async getStockByVariant() {
      this.stock = await stockService.getStockByVariant(this.cooperativeId, this.variantId)
      const storeByCode = this.stock.stores.reduce((acc, store) => {
        // eslint-disable-next-line no-param-reassign
        acc[store.code] = store
        return acc
      }, {})
      const stores = await storeService.findAll(this.cooperativeId, { codes: Object.keys(storeByCode).join(',') })
      stores.forEach((store) => {
        this.$set(storeByCode[store.code], 'name', store.name)
        storeByCode[store.code].name = store.name
      })
    },
    hasStock() {
      return this.stock.stores?.length
    },
    formatMeasureUnit,
    getStoreShippingMethods(store) {
      return store.shippingMethods.map((shippingMethod) => shippingMethod.title.toLowerCase()).join(', ')
    },
  },
}
</script>

<style lang="scss">
@import '@/lib/_variables.scss';
.variant-stock {
  padding: 0 var(--idf-spacing-3);
}
.variant-stock__title {
  font-size: 18px;
  padding-top: 20px;
}
.variant-stock__content-text {
  display: flex;
  align-items: center;
}
.variant-stock__content-list {
  width: 100%;
  li > span {
    flex: 3;
    & + span {
      flex: 1;
    }
  }
  > .variant-stock__content-text:not(:last-child) {
    margin-bottom: var(--idf-spacing-2);
  }
}
.variant-stock__content-text-shipping-methods {
  align-self: flex-start;
  font-style: italic;
  white-space: pre;
}
</style>
