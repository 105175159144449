import { ThresholdsStock } from '@/domain/ThresholdsStock'
import { Toast } from '@/domain/Toast'
import { thresholdsStockService } from '@/services'
import { useStore } from '@/composables/useStore'

// eslint-disable-next-line import/prefer-default-export
export const makeUseThresholdsStocks = (service) => {
  return function useThresholdsStocks() {
    const store = useStore()

    const save = async (thresholdsStock: ThresholdsStock): Promise<Toast> => {
      try {
        await service.saveByCooperativeId(thresholdsStock, store.getters.cooperative.id)
        return {
          type: 'success',
          message: 'Vos modifications ont bien été enrengistrée',
        }
      } catch (e) {
        return { type: 'danger', message: "Une erreur s'est produite" }
      }
    }

    const getAll = async (): Promise<ThresholdsStock[]> => {
      return service.getAllByCooperativeId(store.getters.cooperative.id)
    }

    return {
      save,
      getAll,
    }
  }
}

export const useThresholdsStocks = makeUseThresholdsStocks(thresholdsStockService)
