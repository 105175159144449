
import { computed, defineComponent, PropType } from 'vue'
import { IUseDiscountInput } from '../composables/useDiscountInput'
import CDiscountWithAmountApplication from './inputs/CDiscountWithAmountApplication.vue'
import CPromoCodeInput from './inputs/CPromoCodeInput.vue'
import CUsesInputs from './inputs/CUsesInputs.vue'
import CDiscountScale from './CDiscountScale.vue'
import CStepSubGroup from './CStepSubGroup.vue'

export default defineComponent({
  name: 'CEditApplication',
  components: {
    CStepSubGroup,
    CDiscountScale,
    CDiscountWithAmountApplication,
    CPromoCodeInput,
    CUsesInputs,
  },
  props: {
    isDiscountWithAmount: {
      type: Boolean,
      required: true,
    },
    application: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    groupSize: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    unit: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    measureUnit: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    promoCode: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    nbOverallUsesMax: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    nbCustomerUsesMax: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    discountType: {
      type: String,
      required: true,
    },
    discountScope: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      isPromoCodeEnabled: computed(() => window.env.DISCOUNT_PROMO_CODE_FEATURE === 'yes'),
      isUsageLimitEnabled: computed(() => window.env.DISCOUNT_USAGE_LIMIT_FEATURE === 'yes'),
    }
  },
})
