/** @param {Date} date */
export function zeroTime(date) {
  date.setUTCHours(0, 0, 0, 0)
  return date
}

/** formats the given date(-time) to a short dd/mm/yyyy representation.
 * **the input's time should be ZERO** */
export function formatDate(date) {
  return date ? zeroTime(new Date(date)).toLocaleDateString('fr-FR', { timeZone: 'UTC' }) : '-'
}
