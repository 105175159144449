import { useAuthService } from '@invivodf/common/src/auth'
import sdk, { DeliveryModeEntity, LogisticDeliveryModeSDK } from '@invivodf/module-logistic-delivery-mode-sdk'

class DeliveryModeRepository {
  private logisticDeliveryModeSDK: LogisticDeliveryModeSDK

  constructor({ logisticApiHost }) {
    this.logisticDeliveryModeSDK = sdk(logisticApiHost, { accessTokenSource: () => useAuthService().getAccessToken() })
  }

  getAll(sellerId: string): Promise<DeliveryModeEntity[]> {
    return this.logisticDeliveryModeSDK.getLogisticDeliveryModes(sellerId)
  }
}

export default DeliveryModeRepository
