
import { IdfButton } from '@invivodf/ui-kit'
import { computed, defineComponent } from 'vue'
import { DiscountRouteName } from '@invivodf/common/src/contexts/discount/application/routes/discountRouteName'

export default defineComponent({
  name: 'CUpdateDiscountLinkButton',
  components: { IdfButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    discountPolicyId: {
      type: String,
      required: true,
    },
  },
  setup({ id, discountPolicyId }) {
    const route = computed(() => {
      return {
        name: DiscountRouteName.UPDATE,
        params: { discountId: id, discountPolicyId },
      }
    })

    return { route }
  },
})
