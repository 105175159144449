
import { computed, PropType, toRefs } from 'vue'
import { DiscountScopes } from '@invivodf/discount-sdk'
import PAlert from '../../../../../application/presenters/PAlert.vue'
import CMeasureUnitInput from './CMeasureUnitInput.vue'
import CAmountAndUnitInput from './CAmountAndUnitInput.vue'
import CGroupSizeInput from './CDiscountGroupSizeInput.vue'
import { useLoadedForm } from '../../composables/useLoadedForm'
import { IUseDiscountInput } from '../../composables/useDiscountInput'

export default {
  name: 'CDiscountWithAmountApplication',
  components: { PAlert, CMeasureUnitInput, CAmountAndUnitInput, CGroupSizeInput },
  props: {
    amountInput: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    unitInput: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    measureUnitInput: {
      type: Object as PropType<IUseDiscountInput | null>,
      required: true,
    },
    groupSize: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
  setup(props) {
    const { amountInput, unitInput, measureUnitInput, groupSize } = toRefs(props)
    const form = useLoadedForm()
    const isScopeGroup = computed(() => form.value.scope === DiscountScopes.Group)
    const isTypeFixedPrice = computed(() => form.value.isFixedPriceDiscount())
    const isOneInputOnError = computed(
      () =>
        amountInput.value.error.value ||
        unitInput.value.error.value ||
        measureUnitInput.value?.error.value ||
        groupSize.value.error.value,
    )

    const isScopeCart = computed(() => form.value.scope === DiscountScopes.Cart)

    return { isScopeGroup, isTypeFixedPrice, isOneInputOnError, isScopeCart }
  },
}
