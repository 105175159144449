export const AvailablePaymentMethods = {
  SEPA: 'sepa',
  COOPERATIVE_ACCOUNT: 'cooperative_account',
  CARD: 'card',
}

export const PaymentMethodKinds = [
  {
    title: 'Compte coop',
    value: AvailablePaymentMethods.COOPERATIVE_ACCOUNT,
  },
  { title: 'Prélèvement SEPA', value: AvailablePaymentMethods.SEPA },
  {
    title: 'Paiement CB',
    value: AvailablePaymentMethods.CARD,
  },
]
