import { watch, ref, Ref } from 'vue'
import { checkFeature } from '../../dependency-injection'
import { Context } from '../../domain/Context'
import { FeatureType } from '../../domain/FeatureType'

export interface ReactiveFeature {
  id: string
  context?: Ref<Context>
  type: FeatureType
}

interface FeatureFlip {
  query: ReactiveFeature
  isActive: Ref<boolean>
}

const features = new Map()

const updateFeatureFlipsStatus = async ({ query, isActive }: FeatureFlip, forceUpdate = false) => {
  // eslint-disable-next-line no-param-reassign
  isActive.value = await checkFeature.execute(
    {
      id: query.id,
      type: query.type,
      context: query.context?.value,
    },
    { forceUpdate },
  )
}

const registerFeatureFlip = (query: ReactiveFeature): FeatureFlip => {
  if (features.has(query.id)) return features.get(query.id)!

  const featureFlip = { query, isActive: ref(false) }
  features.set(query.id, featureFlip)

  updateFeatureFlipsStatus(featureFlip)
  if (query.context) {
    watch(query.context, () => updateFeatureFlipsStatus(featureFlip, true))
  }

  return featureFlip
}

export function useFeatureFlip(query: ReactiveFeature): Ref<boolean> {
  const featureFlip = registerFeatureFlip(query)
  return featureFlip.isActive
}
