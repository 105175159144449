import VueI18n from 'vue-i18n'

let i18n: VueI18n

export function provideVue18n(val: VueI18n) {
  i18n = val
}

export function use18n(): VueI18n {
  if (!i18n) {
    throw new Error('i18n was not initialized')
  }
  return i18n
}
