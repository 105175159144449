import { IAuthService } from './auth.service'

let authService: IAuthService

export function provideCommonAuthService(providedAuthService: IAuthService) {
  authService = providedAuthService
}

export function useAuthService(): IAuthService {
  if (!authService) {
    throw new Error('Common Auth Service error')
  }
  return authService
}
