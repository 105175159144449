import { inject, provide, InjectionKey, computed } from 'vue'

export enum Context {
  Retail = 'Retail',
  B2ag = 'B2ag',
}

export const contextSymbol: InjectionKey<Context> = Symbol('context')

export const provideContext = (context: Context): Context => {
  provide(contextSymbol, context)
  return context
}

export const useContext = () => {
  const context = inject(contextSymbol)
  if (!context) throw new Error(`Context is not provided`)

  const isRetail = computed(() => context === Context.Retail)
  const isB2ag = computed(() => context === Context.B2ag)

  return { isRetail, isB2ag }
}
