import { computed, inject, provide, Ref, ref } from 'vue'

export type BoToast = {
  kind?: 'success' | 'danger' | 'warning' | 'info'
  message?: string
}

export const toastNotificationSymbol = Symbol('toastNotification')

export const provideToastNotification = () => {
  provide(toastNotificationSymbol, ref<BoToast>({}))
}

export function useBoToast() {
  const notification = inject<Ref<BoToast>>(toastNotificationSymbol)!

  return {
    notification: computed(() => notification.value),
    showNotificationSuccess: (message: string) => {
      notification.value = { kind: 'success', message }
    },
    showNotificationError: (message: string) => {
      notification.value = { kind: 'danger', message }
    },
  }
}
