import { PricingTypeOption } from '../interfaces/pricing-type-option'

export const pricingTypes: PricingTypeOption[] = [
  {
    value: 'order',
    label: 'Commande globale',
  },
  {
    value: 'logistic_order',
    label: 'Commande logistique',
  },
  {
    value: 'advanced',
    label: 'Barème(s)',
  },
]
