import { logUnit } from './unit'

// eslint-disable-next-line import/prefer-default-export
export const getAddToCartTrackingData = (lineItemToAdd, currentProduct, options) => {
  const product = {
    // eslint-disable-next-line no-underscore-dangle
    _id: currentProduct._id,
    name: currentProduct.name,
    supplier: currentProduct.supplier,
    family: currentProduct.family,
    categories: currentProduct.categories,
  }

  const { variant, quantity } = lineItemToAdd
  const filterOffers = options.variant.offers.filter((offer) => offer.offerId === lineItemToAdd.offer_id)
  const cropProtectionLabel = variant.cropProtectionLabel ? `${variant.cropProtectionLabel} -` : ''
  const packagingLabel = `${cropProtectionLabel} ${variant.measure_quantity} ${logUnit(variant.measure_unit)}`
  const price = filterOffers[0].unitPrice * quantity

  return {
    product,
    quantity,
    packagingLabel,
    price,
  }
}
