import { inject, provide } from 'vue'

export const PRODUCT_TAB_SYMBOL = Symbol('ProductTabSymbol')

export interface TabItem {
  name: string
  hide: () => void
  show: () => void
}

export interface TabItemRegistrator {
  add: (item: TabItem) => void
}

export function provideTabItemRegistrator(registrator: TabItemRegistrator) {
  provide(PRODUCT_TAB_SYMBOL, registrator)
}

export function useTabItemRegistrator(): TabItemRegistrator {
  const registrator = inject<TabItemRegistrator>(PRODUCT_TAB_SYMBOL)
  if (!registrator) {
    throw Error('No Registrator found')
  }
  return registrator
}
