
import { defineComponent, PropType } from 'vue'
import { provideDiscountCreateForm } from '../application/composables/provideDiscountCreateForm'
import PBackButton from '../application/components/presenters/PBackButton.vue'
import CDiscountForm from '../application/components/CDiscountForm.vue'
import { DiscountPolicy } from '../domain/DiscountPolicy'

export default defineComponent({
  name: 'VCreateDiscount',
  components: {
    PBackButton,
    CDiscountForm,
  },
  props: {
    discountPolicyId: {
      type: String as PropType<DiscountPolicy['id']>,
      required: true,
    },
  },
  setup({ discountPolicyId }) {
    const discountPolicy = DiscountPolicy.fromId(discountPolicyId)
    provideDiscountCreateForm(discountPolicy)
  },
})
