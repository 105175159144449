import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'

export class RequiredValidityStartDate extends RequiredProperty<Date | null> {
  constructor(validatedValue: Date | null) {
    super(validatedValue, { errorMessage: '' })
  }

  validate() {
    this.errors = []
    if (this.validatedValue === null) this.errors.push('La date de début de validité est obligatoire')
    if (!(this.validatedValue instanceof Date) || Number.isNaN(+this.validatedValue))
      this.errors.push(`La date de début de validité doit être au format aaaa-mm-jj hh:mm`)
  }
}
