import { LogisticOfferFormData } from '../domain'
import { convertLogisticOfferToFormData } from '../infrastructure/logistic-offer.mapper'
import LogisticRepository from '../infrastructure/logistic.repository'

export default class GetLogisticOffer {
  private readonly repository: LogisticRepository

  constructor(repository: LogisticRepository) {
    this.repository = repository
  }

  async execute(sellerId: string, reference: string): Promise<LogisticOfferFormData> {
    const logisticOffer = await this.repository.get(sellerId, reference)
    return convertLogisticOfferToFormData(logisticOffer)
  }
}
