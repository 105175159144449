import { Ref, computed } from 'vue'
import { Discount } from '../../domain/Discount'

export const useDiscountDiscountablesCounter = (discount: Ref<Discount>) => {
  const isNumberOfActiveDiscountablesUnkown = computed(
    () => discount.value.discountableList.length !== discount.value.discountablesCount,
  )

  const uncompliantDiscountableCount = computed(() => {
    return isNumberOfActiveDiscountablesUnkown.value
      ? '-'
      : discount.value.discountableList.filter(({ discountComplianceErrors }) => discountComplianceErrors.length > 0)
          .length
  })

  const activeDiscountableCount = computed(() =>
    isNumberOfActiveDiscountablesUnkown.value
      ? '-'
      : discount.value.discountableList.filter((offer) => offer.isActive).length,
  )

  const discountablesTotalCount = computed(() => {
    const formatter = Intl.NumberFormat('fr', { notation: 'compact' })
    return formatter.format(discount.value.discountablesCount)
  })

  return { uncompliantDiscountableCount, activeDiscountableCount, discountablesTotalCount }
}
