import { computed, ComputedRef, Ref, ref } from 'vue'
import { FeatureType, useFeatureFlip } from '@invivodf/common/src/application/composables/useFeatureFlip'
import { isLogisticOfferStepValid, isLogisticOfferValid } from '../../domain/logistic-offer.validate'
import { LogisticOfferFormData, Step } from '../../domain'
import { useLogisticOfferFormSteps } from './useLogisticOfferFormSteps'

export function useLogisticOfferSteps() {
  const isLogisticPricingEnabled = useFeatureFlip({
    id: `front-seller:logistic-pricing-scale`,
    type: FeatureType.Dynamic,
  })
  const isLogisticExclusionZonesEnabled = useFeatureFlip({
    id: `front-seller:logistic-exclusion-zones`,
    type: FeatureType.Dynamic,
  })

  const allSteps: Step[] = useLogisticOfferFormSteps()

  const isStepAvailable = (step: Step): boolean => {
    switch (step) {
      case 'CLogisticOfferPricingStep':
        return isLogisticPricingEnabled.value
      case 'CLogisticOfferExclusionZonesStep':
        return isLogisticExclusionZonesEnabled.value
      default:
        return true
    }
  }

  const activeSteps: ComputedRef<Step[]> = computed(() => allSteps.filter(isStepAvailable))

  const optionalSteps: Ref<Step[]> = ref<Step[]>(['CLogisticOfferPricingStep', 'CLogisticOfferExclusionZonesStep'])

  const initialStepState = {
    currentStep: activeSteps.value[0],
    disabledSteps: activeSteps.value.slice(1),
  }

  const currentStep: Ref<Step> = ref<Step>(initialStepState.currentStep)
  const disabledSteps: Ref<Step[]> = ref<Step[]>(initialStepState.disabledSteps)

  const resetSteps = (): void => {
    currentStep.value = initialStepState.currentStep
    disabledSteps.value = initialStepState.disabledSteps
  }

  const openStep = (step: Step): void => {
    currentStep.value = step
  }

  const enableStep = (step: Step): void => {
    disabledSteps.value = disabledSteps.value.filter((disabledStep: Step) => disabledStep !== step)
  }

  const isStepOpen = (step: Step): boolean => {
    return currentStep.value === step
  }

  const isStepValid = (step: Step, logisticOfferFormData: LogisticOfferFormData): boolean => {
    return isLogisticOfferStepValid(step, logisticOfferFormData)
  }

  const isStepDisabled = (step: Step): boolean => {
    return disabledSteps.value.includes(step)
  }

  const isStepOptional = (step: Step): boolean => {
    return optionalSteps.value.includes(step)
  }

  const isLastStep = (step: Step): boolean => {
    return step === activeSteps.value[activeSteps.value.length - 1]
  }

  const goToNextStep = (step: Step): void => {
    const index = activeSteps.value.indexOf(step)
    enableStep(activeSteps.value[index + 1])
    openStep(activeSteps.value[index + 1])
  }

  const toggleStep = (step: Step): void => {
    if (isStepDisabled(step)) return
    openStep(step)
  }

  const areAllStepsValid = (logisticOfferFormData: LogisticOfferFormData): boolean => {
    return isLogisticOfferValid(logisticOfferFormData)
  }

  const enableAllSteps = (): void => {
    disabledSteps.value = []
  }

  return {
    activeSteps,
    isStepOpen,
    isStepValid,
    isStepDisabled,
    isStepOptional,
    goToNextStep,
    isLastStep,
    toggleStep,
    areAllStepsValid,
    enableAllSteps,
    resetSteps,
  }
}
