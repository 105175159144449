import VueI18n from 'vue-i18n'
import { provideVue18n } from './use18n'

import fr_farmi from './farmi/fr.json'
import fr_aladin from './aladin/fr.json'
import fr_episens from './episens/fr.json'
import fr_default from './default/fr.json'

import fr_emoji from './emoji/fr.json'

export const localeList = {
  fr: { fr_farmi, fr_aladin, fr_episens, fr_default, fr_emoji },
}

export function initPlugin(lang, Vue) {
  const customLocale = localStorage.getItem('customLocale')
  const locale = `${lang}_${customLocale || window.env.VUE_APP_CONTEXT}`

  Vue.use(VueI18n)

  const i18n = new VueI18n({
    messages: localeList[lang],
    locale,
    fallbackLocale: `${lang}_default`,
  })

  provideVue18n(i18n)

  return i18n
}
