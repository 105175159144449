
import { IdfLoader } from '@invivodf/ui-kit'

export default {
  name: 'CCollectionLoader',
  components: { IdfLoader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
    },
    noItem: {
      type: Boolean,
      required: true,
    },
    noItemText: {
      type: String,
    },
  },
}
