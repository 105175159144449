import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { ListingQuery } from '../../domain/listing/ListingQuery'

export class ListingQueryMapper {
  toDTO(query: ListingQuery, isRetrievingMetaDiscounts: boolean) {
    return this.removeUndefinedProperties({
      seller_id: !isRetrievingMetaDiscounts ? query.sellerId.toString() : undefined,
      limit: query.limit.toString(),
      page: query.page.toString(),
      key_word: query.filter.keyWord,
      status: query.filter.status,
      discount_type: query.filter.discountType,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  private removeUndefinedProperties(data: Record<string, string | undefined>) {
    return omitBy(data, isEmpty)
  }
}
