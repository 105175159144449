import { IDiscountRepository } from '../domain/IDiscountRepository'
import { MetaDiscountAssociationsValidation } from '../domain/MetaDiscountAssociationsValidation'

export class ValidateMetaDiscountAssociations {
  constructor(private readonly discountRepository: IDiscountRepository) {}

  async execute(discountReference: string, associationsFile: File): Promise<MetaDiscountAssociationsValidation> {
    return this.discountRepository.validateMetaDiscountAssociations(discountReference, associationsFile)
  }
}
