
import CTooltip from '@/components/CTooltip/CTooltip.vue'
import CToggle from '@/components/CToggle/CToggle.vue'
import AdnInput from '@b2ag/design-system/dist/components/AdnInput/AdnInput.vue'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import PProductExplosivesPrecursorMessage from '@/presenters/PProductExplosivesPrecursorMessage/PProductExplosivesPrecursorMessage.vue'
import { IdfButton, IdfImage, IdfTag } from '@invivodf/ui-kit'
import { useProductImage } from '../useProductImage'

export default defineComponent({
  name: 'CProductHeader',
  components: {
    PProductExplosivesPrecursorMessage,
    CToggle,
    CTooltip,
    IdfImage,
    AdnInput,
    IdfButton,
    IdfTag,
  },
  setup() {
    const { productImage, dispatchCustomPartnerProductImage } = useProductImage()
    onMounted(async () => {
      await dispatchCustomPartnerProductImage()
    })

    return {
      productImage,
      isEditingProduct: ref(false),
      isSavingProduct: ref(false),
      availableCategoryHierarchies: reactive([] as any[]),
      selectedCategoryCodes: reactive([] as any[]),
      error: ref(false),
    }
  },
  computed: {
    isACropProtectionPack(): any {
      return this.product && this.product.kind === 'sante_vegetal' && this.product.type === 'Pack'
    },
    isExclusiveProduct(): any {
      return this.product && this.product.exclusive
    },
    product(): any {
      return this.$store.getters['catalog/getProductById'](this.$route.params.productId)
    },
    productCategories(): any {
      return (
        this.product.categories &&
        this.product.categories
          .map((hierarchy) => hierarchy[hierarchy.length - 1])
          .map((entry) => ({ code: entry.code, label: entry.label }))
          .filter((value, index, self) => self.indexOf(self.find((v) => v.code === value.code)) === index)
      )
    },
    initialValue(): any {
      return this.productCategories.map((productCategory) => productCategory.code).join(',')
    },
    noCategoryChanged(): any {
      return this.selectedCategoryCodes === []
    },
    displayedCategories(): any {
      const useSelectionList =
        (this.isEditingProduct || this.isSavingProduct) && this.selectedCategoryCodes.length !== 0
      const categories = useSelectionList
        ? this.selectedCategoryCodes.map((selectedCategoryCode) => ({
            code: selectedCategoryCode,
            label: this.hierarchyFinalLabel(this.hierarchyByCode(selectedCategoryCode)),
          }))
        : this.productCategories
      return categories
    },
    ...mapGetters(['categories', 'categoryHierarchies']),
    isHighlighted: {
      get(): any {
        return this.product.isHighlighted
      },
      set(isHighlighted) {
        ;(this as any).debouncedUpdateProduct(isHighlighted)
      },
    },
  },
  beforeMount() {
    this.initCategories()
  },
  mounted() {
    ;(this as any).debouncedUpdateProduct = debounce(this.updateProduct, 500, {
      leading: true,
    })
  },
  methods: {
    updateProduct(isHighlighted) {
      // eslint-disable-next-line no-underscore-dangle
      this.$store.dispatch('updateCatalogProduct', { id: this.product.id, payload: { is_highlighted: isHighlighted } })
    },
    editProduct() {
      this.isEditingProduct = true
    },
    cancelCategories() {
      this.isEditingProduct = false
      this.selectedCategoryCodes = []
    },
    async saveCategories() {
      this.isEditingProduct = false
      if (this.selectedCategoryCodes.length === 0) {
        return
      }
      const categoriesToSave = this.selectedCategoryCodes.map(this.hierarchyByCode).map((hierarchy) =>
        hierarchy.map((category) => ({
          code: category.id,
          label: category.label,
        })),
      )

      try {
        this.isSavingProduct = true
        await this.$store.dispatch('saveProductCategories', {
          productId: this.product.id,
          categories: categoriesToSave,
        })
      } catch (e) {
        this.error = true
        this.cancelCategories()
        setTimeout(() => {
          this.error = false
        }, 3000)
      }
      this.isSavingProduct = false
      this.selectedCategoryCodes = []
    },
    async initCategories() {
      if (this.product && this.product.exclusive) {
        this.availableCategoryHierarchies = Object.entries((this as any).categoryHierarchies)
          .filter(
            (categoryHierarchiesByCode: [string, { id: string; label: string; parent: string }[]]) =>
              categoryHierarchiesByCode[1][0].id === this.product.kind ||
              categoryHierarchiesByCode[1][1].id === this.product.kind,
          )
          .map((categoryHierarchiesByCode: [string, { id: string; label: string; parent: string }[]]) => ({
            code: categoryHierarchiesByCode[0],
            hierarchy: categoryHierarchiesByCode[1],
          }))
          .map((categoryEntry) => {
            return {
              payload: {
                hierarchy: categoryEntry.hierarchy,
                code: categoryEntry.code,
              },
              checkbox: true,
            }
          })
          .sort(this.byHierarchyLabel)
      }
    },
    hierarchyByCode(code) {
      return this.availableCategoryHierarchies.find(
        (entry) => entry.payload.hierarchy[entry.payload.hierarchy.length - 1].id === code,
      ).payload.hierarchy
    },
    byHierarchyLabel(availabelCategoryHierarchy1, availabelCategoryHierarchy2) {
      const hierarchy1 = availabelCategoryHierarchy1.payload.hierarchy
      const hierarchy2 = availabelCategoryHierarchy2.payload.hierarchy
      if (hierarchy1[0].id !== hierarchy2[0].id) {
        return hierarchy1[0].label < hierarchy2[0].label ? -1 : 1
      }
      if (hierarchy1[0].id === hierarchy2[0].id) {
        if (!hierarchy1[1]) {
          return -1
        }
        if (!hierarchy2[1]) {
          return 1
        }
        if (hierarchy1[1].id !== hierarchy2[1].id) {
          return hierarchy1[1].label < hierarchy2[1].label ? -1 : 1
        }
        if (hierarchy1[1].id === hierarchy2[1].id) {
          if (!hierarchy1[2]) {
            return -1
          }
          if (!hierarchy2[2]) {
            return 1
          }
          if (hierarchy1[2].id !== hierarchy2[2].id) {
            return hierarchy1[2].label < hierarchy2[2].label ? -1 : 1
          }
          if (hierarchy1[2].id === hierarchy2[2].id) {
            if (!hierarchy1[3]) {
              return -1
            }
            if (!hierarchy2[3]) {
              return 1
            }
            if (hierarchy1[3].id !== hierarchy2[3].id) {
              return hierarchy1[3].label < hierarchy2[3].label ? -1 : 1
            }
            if (hierarchy1[3].id === hierarchy2[3].id) {
              if (!hierarchy1[4]) {
                return -1
              }
              if (!hierarchy2[4]) {
                return 1
              }
              if (hierarchy1[4].id !== hierarchy2[4].id) {
                return hierarchy1[4].label < hierarchy2[4].label ? -1 : 1
              }
            }
          }
        }
      }
      return 0
    },
    hierarchyPathLabel(hierarchy) {
      return `${hierarchy
        .slice(0, -1)
        .map((category) => category.label)
        .join(' / ')}`
    },
    hierarchyFinalLabel(hierarchy) {
      return hierarchy[hierarchy.length - 1].label
    },
    clearCategories() {
      ;(this.$refs.categoriesSelectInput as any).setSelection()
      this.selectedCategoryCodes = []
    },
    applyCategories() {
      ;(this.$refs.categoriesSelectInput as any).hideOptions()
    },
    deleteCategory(category) {
      let sourceCategoryCodes
      if (this.selectedCategoryCodes.length > 0) {
        sourceCategoryCodes = this.selectedCategoryCodes
      } else {
        sourceCategoryCodes = this.productCategories.map((productCategory) => productCategory.code)
      }
      const otherCategoryCodes = sourceCategoryCodes.filter((sourceCategoryCode) => sourceCategoryCode !== category)
      ;(this.$refs.categoriesSelectInput as any).setSelection(otherCategoryCodes)
      this.selectedCategoryCodes = otherCategoryCodes
    },
  },
})
