<template>
  <IdfTooltip v-if="description" position="left" :content="description">
    <template v-slot:trigger>
      <IdfIcon
        class="product-variant-list-item__not_associated"
        :kind="kind"
        :aria-hidden="true"
        data-test-img-variant-not-associated
      >
      </IdfIcon>
    </template>
  </IdfTooltip>
  <IdfIcon
    v-else
    class="product-variant-list-item__not_associated"
    :kind="kind"
    :aria-hidden="true"
    data-test-img-variant-not-associated
  >
  </IdfIcon>
</template>
<script>
import { IdfIcon, IdfTooltip } from '@invivodf/ui-kit'

export default {
  name: 'PIcon',
  components: { IdfIcon, IdfTooltip },
  props: {
    description: String,
    kind: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../lib/_variables.scss';
@import '../../lib/_mixins.scss';

.product-variant-list-item__not_associated {
  width: 100%;
}
</style>
