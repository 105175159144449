
import { defineComponent, onBeforeMount, ref } from 'vue'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { IdfButton } from '@invivodf/ui-kit'
import { useAuthService } from '../useAuth'
import { useScopesService } from '../useScopes'
import { STATE_INVALID, INVALID_SCOPE, CONTENT_ON_OAUTH_ERROR, ContentOnAuthError } from './VAuthCallback.constants'

function handleOAuthError({ errorDescription }): ContentOnAuthError {
  switch (errorDescription) {
    case STATE_INVALID:
      return CONTENT_ON_OAUTH_ERROR[STATE_INVALID]
    default:
      return CONTENT_ON_OAUTH_ERROR.default
  }
}

function handleInvalidScopesError() {
  return CONTENT_ON_OAUTH_ERROR[INVALID_SCOPE]
}

export default defineComponent({
  components: { IdfButton },
  setup() {
    const router = useRouter()
    const auth = useAuthService()
    const scopes = useScopesService()
    const error = ref<ContentOnAuthError | null>()

    onBeforeMount(async () => {
      try {
        await auth.doLoginCallback()
        const userScopes = await auth.getUserScopes()
        await scopes.validateScopes(userScopes)
        // Handle authentication success
        if (router && router.currentRoute.path !== '/') {
          await router.push({ name: 'home' })
        }
      } catch (err) {
        // Handle authentication error
        if (err.message === INVALID_SCOPE) {
          error.value = handleInvalidScopesError()
        } else {
          error.value = handleOAuthError(err)
        }
      }
    })

    async function logout() {
      await auth.doLogout()
    }

    return {
      error,
      logout,
    }
  },
})
