<template>
  <div class="order-list" data-cy="order-list">
    <div>
      <CElementLoading v-if="loading">{{ loadingMessage }}</CElementLoading>
      <div class="order__list" v-else-if="orders && orders.length">
        <div :class="{ order__list__header: true, order__list__header__action: !displayTracking }">
          <p></p>
          <p class="text-left">{{ $t('message.membership') }}</p>
          <p>N° commande</p>
          <p>N° réf. {{ $t('message.partner') }}</p>
          <p>Quantité</p>
          <p v-if="!displayTracking">Montant TTC</p>
          <p>Date commande</p>
          <p>
            <template v-if="!displayTracking">Règlement</template>
            <template v-else>Mode de livraison</template>
          </p>
        </div>
        <template v-for="(order, index) in orders">
          <c-order-row
            :key="'order-' + index"
            :data-test="'order-' + index"
            :order="order"
            :displayTracking="displayTracking"
            @fulfillOrder="fulfillOrder"
            @toggleShow="toggleShow"
            @acceptOrder="acceptOrder"
            @cancelOrder="cancelOrder"
            @updateOrder="updateOrder"
            @showPriceDetailTooltip="showPriceDetailTooltip"
            @updateLineItemsStatus="updateLineItemsStatus"
            @showModalShippingInfos="showModalShippingInfos"
            @showPriceDetailByLineItemTooltip="showPriceDetailByLineItemTooltip"
          ></c-order-row>
        </template>
        <div class="order__list__footer">
          <IdfPagination :page="currentPage - 1" :total-pages="totalPages" @click="handleChangePage" />
        </div>
      </div>
      <PInfoMessage v-else>{{ infoErrorMessage }}</PInfoMessage>
    </div>
    <c-confirm-modal
      ref="lineItemsUpdateModal"
      :title="updateLineItemsModal.title"
      :confirm-text="updateLineItemsModal.confirmText"
      cancel-text="Annuler"
      @cancel="cancelUpdateLineItems"
      @confirm="confirmUpdateLineItems"
      class="order__accept-confim-modal"
    >
      <div
        class="container"
        v-if="
          !modalError && updateLineItemsModal && updateLineItemsModal.lineItems && updateLineItemsModal.lineItems.length
        "
      >
        <label class="order-list__modal__label" for="update-status-date">{{ updateLineItemsModal.dateLabel }}</label>
        <input
          type="date"
          id="update-status-date"
          name="update-status-date"
          v-model="updateLineItemsModal.updateStatusDate"
          class="form-control form__date-input col-md-4 mr-3"
          v-validate="'date_format:yyyy-MM-dd'"
          data-test="update-status-date"
          required
        />
      </div>
      <PInfoMessage v-if="modalError">{{ modalError }}</PInfoMessage>
    </c-confirm-modal>
    <c-confirm-modal
      ref="orderAcceptModal"
      title="Attention !"
      confirm-text="Confirmer la validation"
      cancel-text="Annuler"
      @cancel="cancelOrderAcceptModal()"
      @confirm="confirmOrderAcceptModal()"
      class="order__accept-confim-modal"
    >
      <div class="container">
        <p class="order-list__modal__description">
          La commande <strong>{{ orderToValidateInModal && orderToValidateInModal.id }}</strong> ne contient pas de
          <strong>numéro de commande {{ $t('message.partner') }}</strong
          >.
        </p>
        <label class="order-list__modal__label" for="erp-modal"
          >Entrez le Nº de référence {{ $t('message.partner') }}</label
        >
        <input
          class="form-control order-list__modal__input col-md-4"
          type="text"
          name="erp-modal"
          id="erp-modal"
          data-cy="input-erp-id"
          v-model="erpNumberInModal"
        />
      </div>
      <PInfoMessage v-if="modalError">{{ modalError }}</PInfoMessage>
    </c-confirm-modal>
    <!--  TODO Create modals folder ? -->
    <c-confirm-modal
      ref="orderCancelModal"
      title="Attention !"
      confirm-text="Refuser"
      cancel-text="Conserver"
      @cancel="cancelOrderCancelModal"
      @confirm="confirmOrderCancelModal"
      class="order__cancel-confirm-modal"
    >
      <div class="container">
        <p class="order-list__modal__description">
          Vous êtes sur le point de refuser la commande n°<strong>{{
            orderToCancelInModal && orderToCancelInModal.id
          }}</strong>
          de l’{{ $t('message.membership') }}
          <strong>{{
            orderToCancelInModal && orderToCancelInModal.user && orderToCancelInModal.owner.company_name
          }}</strong
          >.
        </p>
        <p v-if="(orderToCancelInModal.payment_method || {}).kind === 'card'" data-test="cancel-confirm-card-payment">
          <strong
            >Le montant de la commande sera remboursé sur la carte bleue de l’{{ $t('message.membership') }}
            {{ (orderToCancelInModal.owner || {}).membership_number }}.</strong
          >
        </p>
      </div>
      <PInfoMessage v-if="modalError">{{ modalError }}</PInfoMessage>
    </c-confirm-modal>
    <c-confirm-modal
      ref="shippingInfosModal"
      title="Plus d'information"
      @cancel="$refs.shippingInfosModal.hide()"
      @confirm="$refs.shippingInfosModal.hide()"
      class="order__accept-confim-modal"
    >
      <div class="container" data-cy="shipping-infos-modal">
        <div class="mb-1">
          <ul class="list-unstyled mb-2">
            <li class="font-weight-bold">{{ shippingInfosModal.companyName }}</li>
            <li>N° {{ $t('message.membership') }}: {{ shippingInfosModal.membershipNumber }}</li>
            <!-- TODO Add TC infos when it's available-->
          </ul>
        </div>
        <div class="mb-1">
          <ul class="list-unstyled mb-2">
            <li class="font-weight-bold">{{ shippingInfosModal.shippingMethodTitle }}</li>
            <li>{{ shippingAddress }}</li>
          </ul>
        </div>
        <div class="mb-1">
          <ul class="list-unstyled mb-2">
            <li class="font-weight-bold">Contact sur place</li>
            <li>
              {{
                shippingInfosModal.contact ||
                'L’' + $t('message.membership') + '  n’a pas renseigné de contact de livraison'
              }}
            </li>
          </ul>
        </div>
        <div class="mb-1">
          <ul class="list-unstyled mb-2">
            <li class="font-weight-bold">Contraintes</li>
            <li>
              {{
                shippingInfosModal.constraint ||
                $t('message.membership-app-l') + ' n’a pas renseigné de contraintes de livraison'
              }}
            </li>
          </ul>
        </div>
      </div>
      <template slot="footer">
        <IdfButton @click="$refs.shippingInfosModal.hide()" data-cy="confirmButton">OK</IdfButton>
      </template>
    </c-confirm-modal>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import { mapGetters } from 'vuex'
import { IdfButton, IdfPagination } from '@invivodf/ui-kit'
import CConfirmModal from '@/components/CConfirmModal/CConfirmModal.vue'
import COrderRow from '@/components/COrderListRow/COrderRow/COrderRow.vue'
import { orderService } from '@/services'
import CElementLoading from '@/components/CElementLoading/CElementLoading.vue'
import PInfoMessage from '../../presenters/PInfoMessage.vue'

export const LOADING_MESSAGE = 'Chargement des commandes en cours…'

const UPDATE_SUCCEEDED = 'valid'
const UPDATE_FAILED = 'invalid'
const UPDATE_DONE = null
const UPDATE_TIMEOUT = 3000

const STATUS_PENDING = 'pending'
const STATUS_ACCEPTED = 'accepted'
const STATUS_FULFILLED = 'fulfilled'
const STATUS_TRANSIT = 'transit'
const STATUS_CANCELED = 'canceled'

const orderStatusesPriorities = {
  [STATUS_PENDING]: 1,
  [STATUS_CANCELED]: 2,
  [STATUS_ACCEPTED]: 3,
  [STATUS_FULFILLED]: 4,
}

const updateLineItemsModalInfos = {
  pending: {
    confirmText: "Confirmer l'expédition",
    title: "Confirmation de l'expédition",
    dateLabel: 'Veuillez renseigner la date de livraison prévue',
    status: STATUS_TRANSIT,
    prop: 'expected_delivery_date',
  },
  transit: {
    confirmText: 'Confirmer la livraison',
    title: 'Confirmation de la livraison',
    dateLabel: 'Veuillez renseigner la date de livraison',
    status: STATUS_FULFILLED,
    prop: 'delivery_date',
  },
  default: {
    title: 'Une erreur est survenue',
  },
}

export default {
  name: 'VOrderList',
  components: { PInfoMessage, CElementLoading, CConfirmModal, COrderRow, IdfButton, IdfPagination },
  props: {
    status: String,
    displayTracking: Boolean,
    searchQuery: String,
    region: String,
    paymentMethod: String,
    startDate: String,
    endDate: String,
  },
  data: () => ({
    loading: true,
    loadingMessage: LOADING_MESSAGE,
    error: null,
    dataOrders: [],
    orders: [],
    // TODO Make it component
    orderToValidateInModal: {},
    // TODO Make it component
    orderToCancelInModal: {},
    currentPriceDetailTooltip: null,
    currentPriceDetailByLineItemTooltip: null,
    erpNumberInModal: '',
    modalError: '',
    updateLineItemsModal: {
      // TODO Make it component
      confirmText: '',
      title: '',
      dateLabel: '',
      lineItem: {},
      order: {},
    },
    shippingInfosModal: {
      // TODO Make it component
      membershipNumber: '',
      shippingMethodTitle: '',
      companyName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      zipCode: '',
      addressCity: '',
      contact: '',
      constraint: '',
    },
    currentPage: 1,
    totalPages: 1,
  }),
  computed: {
    ...mapGetters(['cooperativeId']),
    shippingAddress() {
      const { addressLine1, addressLine2, addressLine3, zipCode, addressCity } = this.shippingInfosModal
      return `${addressLine1} ${addressLine2} ${addressLine3}, ${zipCode} ${addressCity}`
    },
    infoErrorMessage() {
      return this.error || this.searchQuery
        ? 'Aucun résultat pour votre recherche.'
        : `Vous n'avez plus de commande à traiter.`
    },
  },
  async mounted() {
    await this.fetchOrders([this.status])
  },
  methods: {
    async fetchOrders(acceptedStatuses = [this.status]) {
      this.loading = true
      try {
        const { totalPages, rows } = await orderService.listWithPagination({
          cooperativeId: this.cooperativeId,
          statuses: acceptedStatuses,
          search: this.searchQuery,
          page: this.currentPage,
          region: this.region || undefined,
          paymentMethod: this.paymentMethod || undefined,
          startDate: this.startDate || undefined,
          endDate: this.endDate || undefined,
        })

        this.totalPages = Math.max(totalPages, 1)
        this.dataOrders = rows

        this.orders = this.dataOrders.map((order) => ({
          ...order,
          show: false,
          updateStatus: null,
          disableButton: false,
        }))

        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages
        }

        this.sortOrders()
        if (this.error) {
          this.error = ''
        }
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    sortOrders() {
      this.orders.forEach((order) => {
        // eslint-disable-next-line no-param-reassign
        order.cart.line_items = sortBy(order.cart.line_items, 'name')
      })
      this.orders = orderBy(
        this.orders,
        [(order) => orderStatusesPriorities[order.status], (order) => new Date(order.created_at)],
        ['asc', 'desc'],
      )
    },
    toggleShow(order) {
      this.$set(order, 'show', !order.show)
    },
    async fulfillOrder(order) {
      await this.updateOrder(order, 'status', STATUS_FULFILLED)
      this.sortOrders()
    },
    async acceptOrder(order) {
      if (order.erp_order_id) {
        await this.updateOrder(order, 'status', STATUS_ACCEPTED)
        this.orders.splice(this.orders.indexOf(order), 1)
        await this.fetchOrders([this.status])
        this.$emit('acceptOrder')
      } else {
        this.orderToValidateInModal = order
        this.erpNumberInModal = ''
        this.modalError = ''
        this.$refs.orderAcceptModal.show()
      }
    },
    cancelOrder(order) {
      this.orderToCancelInModal = order
      this.$refs.orderCancelModal.show()
    },
    async updateOrder(order, prop, value) {
      try {
        if (value && value !== order[prop]) {
          const updatedOrder = await orderService.update(order.id, {
            [prop]: value,
          })
          this.$set(order, prop, value)
          this.$set(order, 'cart', { ...order.cart, line_items: updatedOrder.cart.line_items })
          this.$set(order, 'updateStatus', UPDATE_SUCCEEDED)
        }
      } catch (err) {
        this.error = err
        const errorMessage = err && err.response && err.response.data && err.response.data.message
        const cardError = errorMessage && errorMessage.raw && errorMessage.raw.message
        this.$bvToast.toast(cardError || errorMessage, {
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
        })
        this.$set(order, 'updateStatus', UPDATE_FAILED)
      } finally {
        this.$set(order, 'disableButton', false)
        setTimeout(() => {
          this.$set(order, 'updateStatus', UPDATE_DONE)
        }, UPDATE_TIMEOUT)
      }
    },
    cancelOrderAcceptModal() {
      this.orderToValidateInModal.erp_order_id = ''
      this.$refs.orderAcceptModal.hide()
    },
    async confirmOrderAcceptModal() {
      if (!this.erpNumberInModal) {
        this.modalError = "Vous n'avez pas saisi de numéro ERP"
        return
      }
      try {
        await orderService.update(this.orderToValidateInModal.id, {
          erp_order_id: this.erpNumberInModal,
          status: STATUS_ACCEPTED,
        })
        this.orders.splice(this.orders.indexOf(this.orderToValidateInModal), 1)
        this.$refs.orderAcceptModal.hide()
        await this.fetchOrders([this.status])
        this.$emit('acceptOrder')
      } catch (error) {
        this.modalError = "Une erreur s'est produite"
      }
    },
    cancelOrderCancelModal() {
      this.$refs.orderCancelModal.hide()
    },
    async confirmOrderCancelModal() {
      try {
        await this.updateOrder(this.orderToCancelInModal, 'status', STATUS_CANCELED)
        this.$refs.orderCancelModal.hide()
        this.orders.splice(this.orders.indexOf(this.orderToCancelInModal), 1)
        await this.fetchOrders([this.status])
        this.$emit('cancelOrder')
      } catch (error) {
        this.modalError = error && error.response && error.response.data && error.response.data.message
      }
    },
    resetOrders() {
      this.currentPage = 1
      this.refreshOrders()
    },
    refreshOrders() {
      this.fetchOrders([this.status])
    },
    async refreshOrder(order) {
      const refreshedOrder = await orderService.get(order.id)
      this.orders.splice(this.orders.indexOf(order), 1, {
        ...refreshedOrder,
        show: order.show,
        updateStatus: null,
        disableButton: false,
      })
    },
    updateLineItemsStatus(order, lineItems) {
      if (lineItems.length === 0) {
        return
      }
      const [updateStatusDate] = new Date().toISOString().split('T')
      const modalInfos = updateLineItemsModalInfos[lineItems[0].status] || updateLineItemsModalInfos.default
      if (!modalInfos.prop) {
        this.modalError = "Une erreur est survenue avec le statut de l'article"
      }
      this.updateLineItemsModal = { ...modalInfos, order, lineItems, updateStatusDate }
      this.$refs.lineItemsUpdateModal.show()
    },
    async confirmUpdateLineItems() {
      const { order, lineItems, status, prop, updateStatusDate } = this.updateLineItemsModal
      try {
        const updatedLineItems = []

        // eslint-disable-next-line
        for (const lineItem of lineItems) {
          // eslint-disable-next-line
          const lineItemUpdated = await orderService.updateLineItemStatus(order.id, lineItem.id, {
            [prop]: updateStatusDate,
            status,
          })
          updatedLineItems.push(lineItemUpdated)
        }
        lineItems.forEach((lineItem, index) => {
          this.$set(lineItem, 'expected_delivery_date', updatedLineItems[index].expected_delivery_date)
          this.$set(lineItem, 'delivery_date', updatedLineItems[index].delivery_date)
          this.$set(lineItem, 'status', updatedLineItems[index].status)
        })

        const isAllFulfilled = order.cart.line_items.every(
          ({ status: lineItemStatus }) => lineItemStatus === STATUS_FULFILLED,
        )
        if (isAllFulfilled) {
          this.$emit('fulfilledOrder')
          await this.refreshOrder(order)
          this.sortOrders(order)
        }
        this.$refs.lineItemsUpdateModal.hide()
      } catch (error) {
        this.error = error
      }
    },
    cancelUpdateLineItems() {
      this.$refs.lineItemsUpdateModal.hide()
    },
    showPriceDetailTooltip(tooltip) {
      if (this.currentPriceDetailTooltip === tooltip) {
        this.currentPriceDetailTooltip.toggleShow()
        this.currentPriceDetailTooltip = null
      } else {
        if (this.currentPriceDetailTooltip) {
          this.currentPriceDetailTooltip.toggleShow()
        }
        this.currentPriceDetailTooltip = tooltip
        this.currentPriceDetailTooltip.toggleShow()
      }
    },
    showPriceDetailByLineItemTooltip(tooltip) {
      if (this.currentPriceDetailByLineItemTooltip === tooltip) {
        this.currentPriceDetailByLineItemTooltip.toggleShow()
        this.currentPriceDetailByLineItemTooltip = null
      } else {
        if (this.currentPriceDetailByLineItemTooltip) {
          this.currentPriceDetailByLineItemTooltip.toggleShow()
        }
        this.currentPriceDetailByLineItemTooltip = tooltip
        this.currentPriceDetailByLineItemTooltip.toggleShow()
      }
    },
    showModalShippingInfos(order) {
      const {
        title,
        address: { company_name: companyName, line1, line2, line3, zip_code: zipCode, city },
        constraint,
        contact,
      } = order.shipping
      this.shippingInfosModal = {
        membershipNumber: order.owner.membership_number,
        shippingMethodTitle: title,
        companyName,
        addressLine1: line1,
        addressLine2: line2,
        addressLine3: line3,
        zipCode,
        addressCity: city,
        contact,
        constraint,
      }
      this.$refs.shippingInfosModal.show()
    },
    handleChangePage(value) {
      this.currentPage = value
      this.refreshOrders()
    },
  },
  watch: {
    status() {
      this.refreshOrders()
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.order-list {
  border-radius: var(--idf-border-radius-m);
  background-color: var(--idf-color-neutral-100);
  padding: 1.6rem 0;
  min-height: 50%;
  flex: 1;
  .order__list {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: auto;
    row-gap: var(--idf-spacing-3);
  }
  .order-row {
    background-color: var(--idf-color-neutral-100);
    &.order-row--active {
      & > td:first-child {
        border-left-color: var(--idf-color-main-400);
      }
    }
  }

  .order__list__header {
    display: grid;
    grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .order__list__header__action {
    grid-template-columns: 80px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;
  }

  .order__list__footer {
    display: flex;
    justify-content: center;
    margin: var(--idf-spacing-3) 0;
  }

  .order__list__header > p {
    text-align: center;
    color: var(--idf-color-neutral-500);
    font-style: normal;
    font-weight: normal;
  }

  .order-list__modal__description {
    margin-bottom: 16px;
  }
  .order-list__modal__label {
    display: inline;
    margin-right: 20px;
  }
  .order-list__modal__input {
    display: inline;
    width: auto;
  }
  .btn-update-status {
    width: 152px;
  }
}
</style>
