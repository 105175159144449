import { ComputedRef } from 'vue'
import groupBy from 'lodash/groupBy'
import { SectionName } from '../../../domain/SectionName'
import { ParametersDescription } from '../../../domain/ParametersDescription'
import { Link, Section } from '../../../domain/Section'

export const createLink = (command: ParametersDescription): Link => ({
  title: command.title,
  description: command.description,
  route: command.route,
  icon: command.icon,
  isDisabled: !(command.enableFeature ?? true),
})

export const useParametersFormatter = (items: ComputedRef<ParametersDescription>[]): Section[] => {
  const itemsBySection = groupBy(items, (item) => item.value.section)
  return Object.entries(itemsBySection).map(
    ([name, sectionItems]: [SectionName, ComputedRef<ParametersDescription>[]]) => ({
      name,
      items: sectionItems.map((item) => createLink(item.value)),
    }),
  )
}
