import { render, staticRenderFns } from "./PBackButton.vue?vue&type=template&id=57d3b270&scoped=true"
import script from "./PBackButton.vue?vue&type=script&lang=js"
export * from "./PBackButton.vue?vue&type=script&lang=js"
import style0 from "./PBackButton.vue?vue&type=style&index=0&id=57d3b270&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d3b270",
  null
  
)

export default component.exports