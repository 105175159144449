/* eslint-disable camelcase, no-use-before-define */
import jwtDecode from 'jwt-decode'
import { IAuthService } from '@invivodf/common/src/auth'
import { UserManager, WebStorageStateStore, Log } from 'oidc-client'

export interface Config {
  audience: string
  authority: string
  clientId: string
  scope: string
  extranetConnection?: string
  smagConnection?: string
}

export class AuthService implements IAuthService {
  private accessToken: string | null = null

  private readonly oidcUserManager: UserManager

  private readonly config: Config

  constructor(config: Config) {
    Log.logger = console
    Log.level = Log.INFO
    this.config = config
    this.oidcUserManager = new UserManager({
      userStore: new WebStorageStateStore({
        prefix: 'aladin.auth.',
      }),
      extraQueryParams: {
        audience: config.audience,
      },
      authority: config.authority,
      client_id: config.clientId,
      redirect_uri: `${window.location.origin}/oauth/callback`,
      response_type: 'token',
      scope: config.scope,
      silent_redirect_uri: `${window.location.origin}/oauth/callback`,
      accessTokenExpiringNotificationTime: 10,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: false,
      metadata: {
        issuer: `https://${config.authority}/`,
        authorization_endpoint: `https://${config.authority}/authorize`,
        userinfo_endpoint: `https://${config.authority}/userinfo`,
        end_session_endpoint: `https://${config.authority}/v2/logout`,
        jwks_uri: `https://${config.authority}/.well-known/jwks.json`,
      },
    })
  }

  async getAccessToken(): Promise<string | null> {
    const user = await this.oidcUserManager.getUser()
    return user?.access_token ?? null
  }

  async doLogin(): Promise<void> {
    await this.oidcUserManager.signinRedirect()
  }

  async doExtranetLogin(): Promise<void> {
    await this.oidcUserManager.signinRedirect({
      extraQueryParams: {
        audience: this.config.audience,
        connection: this.config.extranetConnection,
      },
    })
  }

  async doSmagLogin(): Promise<void> {
    await this.oidcUserManager.signinRedirect({
      extraQueryParams: {
        audience: this.config.audience,
        connection: this.config.smagConnection,
      },
    })
  }

  async doRegister(): Promise<void> {
    await this.oidcUserManager.signinRedirect({
      extraQueryParams: {
        audience: this.config.audience,
        screen_hint: 'signup',
      },
    })
  }

  async doLogout(): Promise<void> {
    const logoutUrl = `https://${this.oidcUserManager.settings.authority}/v2/logout?client_id=${this.oidcUserManager.settings.client_id}&returnTo=${window.location.origin}`
    await this.oidcUserManager.removeUser()
    window.location.assign(logoutUrl)
  }

  async doSilentRefreshCallback() {
    await this.oidcUserManager.signinSilentCallback()
  }

  async doLoginCallback(): Promise<void> {
    const user = await this.oidcUserManager.signinCallback()
    this.accessToken = user.access_token
  }

  async isAuthenticated(): Promise<boolean> {
    return (await this.oidcUserManager.getUser()) !== null
  }

  async isExpired(): Promise<boolean> {
    return (await this.oidcUserManager.getUser())?.expired === true
  }

  async getUserScopes(): Promise<string[]> {
    const user = await this.oidcUserManager.getUser()
    return user?.scopes || []
  }

  async getAladinAccessToken(): Promise<AladinAccessToken | null> {
    const accessToken = await this.getAccessToken()
    if (!accessToken) {
      return null
    }
    return jwtDecode(accessToken)
  }

  async getAladinSellerRole(): Promise<AladinRole | null> {
    const accessToken = await this.getAladinAccessToken()
    if (!accessToken) {
      return null
    }
    return (
      accessToken[`${this.config.audience}/v1/roles`]?.filter((role) => role.type === AladinRoleType.seller)?.[0] ??
      null
    )
  }
}

/**
 * Basic contents of any Aladin access token
 */
interface BaseAccessToken {
  iss: string
  sub: string
  aud: string[]
  iat: number
  exp: number
  azp: string
  scope: string
  /**
   * @deprecated
   */
  permissions: string[]
}

/**
 * Role contents of any Aladin access token
 */
interface RoleAccessToken {
  [key: string]: AladinRole[] | undefined
}

/**
 * Available role types
 */
export enum AladinRoleType {
  farmer = 'farmer',
  seller = 'seller',
  tech = 'tech',
  supplier = 'supplier',
  machine = 'machine',
}

export interface AladinProfile {
  id?: string
  uid?: string
  membership_number?: string

  cooperative: {
    id: number
    name: string
  }
}

export interface AladinRole {
  /**
   * @deprecated
   */
  type: AladinRoleType
  permissions: string[]
  profiles: AladinProfile[]
}

export type AladinAccessToken = BaseAccessToken & RoleAccessToken
