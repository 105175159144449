import { MethodOption } from '../interfaces/method-option'

export const methods: MethodOption[] = [
  {
    value: 'increase',
    label: 'Majorée',
  },
  {
    value: 'decrease',
    label: 'Minorée',
  },
  {
    value: 'equals',
    label: 'Egale',
  },
]
