<template>
  <c-import-base
    :csvProperties="csvProperties"
    :importFileService="quotaService"
    :descriptionLines="descriptionLines"
    :csvColumnDetails="csvColumnDetails"
    pageTitle="Import quotas"
    uploadButtonLabel="Pour mettre à jour vos quotas, importez le fichier CSV"
    reportWarning="Vos quotas seront mis à jour dans quelques instants."
  >
    <c-import-information
      title="Informations sur vos quotas"
      nbResultText="Nombre de quotas :"
      exportText="Exporter vos quotas"
      :importInformation="importInformation"
      :downloadCSV="downloadCSV"
    />
  </c-import-base>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { quotaService } from '../../services'

const csvFileProperties = {
  name: 'quotas.csv',
  full_path: '/assets/csv/quotas.csv',
}
export default {
  name: 'CImportQuotas',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      quotaService,
      descriptionLines: [
        'L’extension attendue est : <b>.csv</b>',
        'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
        'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
        "L'encoding attendu est : <b>UTF8</b>.",
      ],
      csvColumnDetails: [
        {
          header: 'quota_reference',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>QUOTA_REF1</code>',
          description: '<p>Référence unique du quota (Reference Quota).</p>',
          mandatory: 'Oui',
        },
        {
          header: 'mesh_id',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>6:INVXCKLET7V7CT16PHY:REF_OFR</code>',
          description: '<p>ID de l’objet métier (offre dans un premier temps) sur lequel le quota s’applique.</p>',
          mandatory: 'Oui',
        },
        {
          header: 'value',
          format: 'DECIMAL',
          exampleValue: 'exemple : <code>5</code>',
          description: `<p>Valeur du quota en unité de vente.</p>`,
          mandatory: 'Oui',
        },
        {
          header: 'tech_id',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>TC0001</code>',
          description: `<p>Identifiant du ${i18n.t(
            'message.technician',
          )} (tc_uid) sur lequel le quota s’applique, à renseigner pour un quota à la maille ${i18n.t(
            'message.technician',
          )}(fonctionnalité à venir).</p>`,
          mandatory: 'Non',
        },
      ],
      importInformation: {
        count: null,
        lastImportAt: null,
      },
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return quotaService.downloadQuotas(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
