import { AdvancedTypeOption } from '../interfaces/advanced-type-option'

export const advancedTypes: AdvancedTypeOption[] = [
  {
    value: 'weight',
    label: 'Poids',
  },
  {
    value: 'revenue',
    label: 'Montant',
  },
]
