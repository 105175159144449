import { Quota } from '../../domain/Quota'

export interface QuotaFromApiResponse {
  reference: string
  // eslint-disable-next-line camelcase
  mesh_id: string
  initial: number
  remaining: number
  // eslint-disable-next-line camelcase
  tech_id?: string
}

export const mapFromApiListQuotasResponse = (quotas: QuotaFromApiResponse[]): Quota[] =>
  quotas.map((quota) => ({
    reference: quota.reference,
    meshId: quota.mesh_id,
    initial: quota.initial,
    remaining: quota.remaining,
    techId: quota.tech_id,
  }))
