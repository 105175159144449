<template>
  <div class="page-not-found">
    <div class="container page-not-found__body">
      <img src="../../assets/404_illustration.svg" class="page-not-found__illustration img-fluid" alt="404 not found" />
      <p class="page-not-found__message" data-test-message><strong>Oups...</strong></p>
      <p>Vous vous êtes trompés de champ !</p>
      <IdfButton tag="router-link" :route="{ name: 'home' }" class="page-not-found__btn">Retour à l'accueil</IdfButton>
    </div>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'VPageNotFound',
  components: {
    IdfButton,
  },
}
</script>

<style lang="scss">
.page-not-found__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.page-not-found__message {
  margin-top: 30px;
}
.page-not-found__btn {
  width: 100%;
  margin-top: 20px;
  @media screen and (min-width: 300px) {
    max-width: 300px;
  }
}
</style>
