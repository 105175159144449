
import { computed, defineComponent, Ref, toRefs, PropType } from 'vue'
import CResultFolder from './CResultFolder.vue'
import { useFolders } from '../../../composables/association/useFolders'
import { SearchResultGroup } from '../../../../domain/SearchResultPage'

const headers = ['NOM ARTICLE', 'EAN 13/SKU']

export default defineComponent({
  name: 'CResultFolders',
  components: { CResultFolder },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    resultFolders: {
      type: Array as PropType<SearchResultGroup[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { resultFolders } = toRefs(props)
    const folders = computed(() => useFolders(resultFolders))

    const setActive = (idToFold: Ref<string>) => {
      const foundedFold = folders.value.find(({ id }) => id === idToFold.value)
      if (!foundedFold) return
      foundedFold.toggle()
      folders.value.filter((fold) => fold !== foundedFold).forEach((fold) => fold.fold())
    }

    const folderToggled = (id) => {
      emit('articleToggled', id)
    }

    return { setActive, folders, headers, folderToggled }
  },
})
