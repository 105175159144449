<template>
  <ais-toggle-refinement :attribute="attribute" :label="label" :on="on">
    <div @click.prevent="refine(value)" slot-scope="{ value, refine }" class="search-filters__item-refiner">
      <CAttributeFilter :refine="refine" :value="value" :label="label" />
    </div>
  </ais-toggle-refinement>
</template>

<script>
import CAttributeFilter from '../CAttributeFilter/CAttributeFilter.vue'

export default {
  name: 'CInstantSearchToggle',
  props: ['attribute', 'label', 'on'],
  components: { CAttributeFilter },
}
</script>
