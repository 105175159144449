
import { mapGetters } from 'vuex'
import { useBrandData } from '@/contexts/menu/application/composables/useBrandData'
import { useNavigationLinks } from '@/contexts/menu/application/composables'
import PSidebar from '@invivodf/common/src/application/presenters/PSidebar/PSidebar.vue'
import PSelect from '@/contexts/menu/application/presenters/PSelect/PSelect.vue'
import PBoToast from '@invivodf/common/src/application/presenters/PBoToast/PBoToast.vue'
import { defineComponent, ref } from 'vue'
import { provideToastNotification } from '@invivodf/common/src/application/composables'
import { useStore } from '@/composables/useStore'

export default defineComponent({
  name: 'VLayoutMain',
  components: {
    PBoToast,
    PSidebar,
    PSelect,
  },
  setup() {
    provideToastNotification()

    const hasOpenSidebar = ref(false)
    const { brandData } = useBrandData()
    const { navigationLinks } = useNavigationLinks()
    const store = useStore()

    function onSidebarOpen(value: boolean): void {
      hasOpenSidebar.value = value
    }
    async function onLogout() {
      await store.dispatch('logout')
    }
    return { hasOpenSidebar, brandData, navigationLinks, onSidebarOpen, onLogout }
  },
  computed: {
    ...mapGetters(['toast']),
  },
  watch: {
    toast(toast) {
      this.$bvToast.toast(toast.message, {
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 3000,
        headerClass: 'd-none',
        bodyClass: `toast__${toast.type}`,
      })
    },
  },
})
