import { createHttpClientWithBearer } from '../../infrastructure/httpClient'
import AssociationDatasource from './infra/datasource/Association.datasource'
import { DiscountDataSource } from './infra/datasource/Discount.datasource'
import { DiscountableDatasource } from './infra/datasource/Discountable.datasource'
import DiscountRepository from './infra/Discount.repository'
import { CreateDiscount } from './use-cases/CreateDiscount.usecase'
import { ValidateGeneralInformations } from './use-cases/ValidateGeneralInformations.usecase'
import { RetrieveDiscount } from './use-cases/RetrieveDiscount.usecase'
import { RetrieveDiscountables } from './use-cases/RetrieveDiscountables.usecase'
import { UpdateDiscount } from './use-cases/UpdateDiscount.usecase'
import { discountApiHost, offerWithProductApiHost } from './config'
import { RetrieveAuthorizedDiscountPolicies } from './use-cases/RetrieveAuthorizedDiscountPolicies.usecase'
import { SearchDiscountableGroup } from './use-cases/SearchDiscountableGroup.usecase'
import { DiscountableRepository } from './infra/Discountable.repository'
import { UpdateAssociations } from './use-cases/UpdateAssociations.usecase'
import { DeleteDiscount } from './use-cases/DeleteDiscount.usecase'
import { RetrieveDiscountList } from './use-cases/RetrieveDiscountList'
import { RetrieveImportReports } from './use-cases/RetrieveImportReports'
import { RetrieveImportReport } from './use-cases/RetrieveImportReport'
import ImportReportDataSource from './infra/datasource/ImportReport.dataSource'
import { ImportAssociations } from './use-cases/ImportAssociations.usecase'
import { DownloadAssociations } from './use-cases/DownloadAssociations.usecase'
import AssociationImportReportRepository from './infra/AssociationImportReportRepository'
import DiscountImportReportRepository from './infra/DiscountImportReportRepository'
import { DownloadMetaDiscountAssociations } from './use-cases/DownloadMetaDiscountAssociations.usecase'
import { UploadMetaDiscountAssociations } from './use-cases/UploadMetaDiscountAssociations.usecase'
import { ValidateMetaDiscountAssociations } from './use-cases/ValidateMetaDiscountAssociations.usecase'
import { ValidateMetaDiscountParticipantFile } from './use-cases/ValidateMetaDiscountParticipantFile.usecase'

const discountableHttpClient = createHttpClientWithBearer(offerWithProductApiHost)
const discountHttpClient = createHttpClientWithBearer(discountApiHost, { accept: 'application/json' })

const discountDataSource = new DiscountDataSource(discountHttpClient)
const discountableDatasource = new DiscountableDatasource(discountableHttpClient)
const associationDatasource = new AssociationDatasource(discountHttpClient)
const importReportDataSource = new ImportReportDataSource(discountHttpClient)

const discountRepository = new DiscountRepository(discountDataSource, associationDatasource)
const discountableRepository = new DiscountableRepository(discountableDatasource, associationDatasource)
const associationsImportReportRepository = new AssociationImportReportRepository(importReportDataSource)
const discountsImportReportRepository = new DiscountImportReportRepository(importReportDataSource)

const retrieveDiscount = new RetrieveDiscount(discountRepository)
const retrieveDiscountables = new RetrieveDiscountables(discountableRepository)
const validateGeneralInformations = new ValidateGeneralInformations(discountRepository)
const createDiscount = new CreateDiscount(discountRepository)
const updateDiscount = new UpdateDiscount(discountRepository)
const deleteDiscount = new DeleteDiscount(discountRepository)
const retrieveAuthorizedDiscountPolicies = new RetrieveAuthorizedDiscountPolicies(discountRepository)
const searchDiscountableGroup = new SearchDiscountableGroup(discountableRepository)
const updateAssociations = new UpdateAssociations(discountRepository)
const uploadMetaDiscountAssociations = new UploadMetaDiscountAssociations(discountRepository)
const retrieveDiscountList = new RetrieveDiscountList(discountRepository)
const downloadMetaDiscountAssociations = new DownloadMetaDiscountAssociations(discountRepository)
const retrieveAssociationImportReports = new RetrieveImportReports(associationsImportReportRepository)
const retrieveAssociationImportReport = new RetrieveImportReport(associationsImportReportRepository)
const retrieveDiscountImportReports = new RetrieveImportReports(discountsImportReportRepository)
const retrieveDiscountImportReport = new RetrieveImportReport(discountsImportReportRepository)
const validateMetaDiscountAssociations = new ValidateMetaDiscountAssociations(discountRepository)
const validateMetaDiscountParticipantFile = new ValidateMetaDiscountParticipantFile()

const importAssociations = new ImportAssociations(discountRepository)
const downloadAssociations = new DownloadAssociations(discountRepository)

export {
  retrieveDiscountList,
  retrieveDiscount,
  retrieveDiscountables,
  validateGeneralInformations,
  createDiscount,
  updateDiscount,
  deleteDiscount,
  retrieveAuthorizedDiscountPolicies,
  searchDiscountableGroup,
  updateAssociations,
  uploadMetaDiscountAssociations,
  retrieveAssociationImportReports,
  retrieveAssociationImportReport,
  retrieveDiscountImportReports,
  retrieveDiscountImportReport,
  downloadMetaDiscountAssociations,
  validateMetaDiscountAssociations,
  validateMetaDiscountParticipantFile,
  importAssociations,
  downloadAssociations,
}
