import { AxiosInstance, AxiosResponse } from 'axios'

type FeatureFlipIzanamiContextDTO = Record<string, string>

interface FeatureFlipIzanamiResponseDTO {
  active: boolean
}

export class FeatureFlipIzanamiDataSource {
  constructor(
    private readonly client: AxiosInstance,
    private readonly errorLogger: (e: Error) => void,
  ) {}

  async check(id: string, context: FeatureFlipIzanamiContextDTO = {}): Promise<boolean> {
    try {
      const result = await this.client.post<FeatureFlipIzanamiContextDTO, AxiosResponse<FeatureFlipIzanamiResponseDTO>>(
        `/api/features/${id}/check`,
        context,
      )
      return result.data.active
    } catch (error) {
      this.errorLogger(error)
      return false
    }
  }
}
