import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'

export class NullableRequiredUses extends RequiredProperty<number | null> {
  constructor(validatedValue: number | null) {
    super(validatedValue, { errorMessage: '' })
  }

  validate() {
    this.errors = []
    if (this.validatedValue !== null && this.validatedValue <= 0) {
      this.errors.push(`le nombre d'utilisations doit être positif`)
    }
  }
}
