
import { defineComponent, ref, PropType } from 'vue'
import { IdfIcon, IdfInput } from '@invivodf/ui-kit'
import { TreeCategory } from '@/presenters/PTreeView/PTreeViewComposables'
import PCheckbox from '../PCheckbox/PCheckbox.vue'
/* eslint-disable no-param-reassign */
function deselectionnerNoeud(item) {
  if (item.checked) {
    item.checked = false
  }
  item.indeterminate = false
  item.nbOfCheckedChildren = 0
  item.children.forEach((child) => deselectionnerNoeud(child))
}
/* eslint-enable no-param-reassign */

export default defineComponent({
  name: 'PTreeView',
  components: {
    PCheckbox,
    IdfIcon,
    IdfInput,
  },
  props: {
    categoriesNiveau0: {
      type: Array as PropType<TreeCategory[]>,
      required: true,
    },
  },
  setup(props) {
    return {
      categories: ref(props.categoriesNiveau0),
      totalSelectionnes: 0,
      idsCategoriesSelectionnees: ref({}),
      successCopyClipboard: false,
    }
  },
  computed: {
    libelleElementsSelectionnes() {
      if (this.totalSelectionnes > 1) {
        return ` Sélectionnées`
      }
      return ` Sélectionnée`
    },
    affichageCategoriesSelectionnees(): string {
      return Object.keys(this.idsCategoriesSelectionnees).join('|')
    },
  },
  methods: {
    openCloseNode(item) {
      // $set est inutile si la propriete existe deja dans l'objet
      // eslint-disable-next-line no-param-reassign
      item.open = !item.open
    },
    displayChevron(item) {
      return item.children.length && item.open ? 'chevron-down' : 'chevron-right'
    },
    toutDeSelectionner() {
      this.idsCategoriesSelectionnees = {}
      this.totalSelectionnes = 0
      this.categories.forEach((categorie) => {
        deselectionnerNoeud(categorie)
      })
    },
    clickCheckbox(item: TreeCategory) {
      const itemIsBeingChecked = !item.checked
      /* eslint-disable no-param-reassign */
      if (itemIsBeingChecked) {
        item.checked = true
        item.indeterminate = false
        this.totalSelectionnes += 1
        this.$set(this.idsCategoriesSelectionnees, item.id, [...item.ancestors.map((a) => a.id), item.id])
        for (let i = 0; i < item.ancestors.length; i += 1) {
          const ancestor = item.ancestors[i]
          ancestor.nbOfCheckedChildren += 1
          ancestor.indeterminate = true
          if (ancestor.checked) {
            for (let j = 0; j < ancestor.ancestors.length; j += 1) {
              const supAncestor = ancestor.ancestors[j]
              supAncestor.nbOfCheckedChildren -= 1
            }
            ancestor.checked = false
            this.$delete(this.idsCategoriesSelectionnees, ancestor.id)
            this.totalSelectionnes -= 1
          }
        }
      } else {
        item.checked = false
        item.indeterminate = false
        this.$delete(this.idsCategoriesSelectionnees, item.id)
        this.totalSelectionnes -= 1
        for (let i = 0; i < item.ancestors.length; i += 1) {
          const ancestor = item.ancestors[i]
          ancestor.nbOfCheckedChildren -= 1
          if (ancestor.nbOfCheckedChildren === 0) {
            ancestor.indeterminate = false
          }
        }
      }
      /* eslint-enable no-param-reassign */
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.affichageCategoriesSelectionnees)
      this.successCopyClipboard = true
      setTimeout(() => {
        this.successCopyClipboard = false
      }, 1000)
    },
  },
})
