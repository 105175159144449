
import { computed, defineComponent, Ref } from 'vue'
import { ValidationObserver } from 'vee-validate-last'
import { IdfSelect, IdfStandardInputs, IdfTextIcon } from '@invivodf/ui-kit'
import PInput from '../PInput/PInput.vue'
import { DelayUnitOption, DeliveryDelay } from '../../../domain'

export default defineComponent({
  name: 'PLogisticDelay',
  components: {
    IdfSelect,
    IdfStandardInputs,
    IdfTextIcon,
    PInput,
    ValidationObserver,
  },
  emits: ['input'],
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    rules: {
      type: String,
    },
    delayUnits: {
      type: Array as () => DelayUnitOption[],
    },
    value: {
      type: Object as () => DeliveryDelay,
    },
  },
  setup(props, { emit }) {
    const deliveryDelay: Ref<DeliveryDelay | undefined> = computed({
      get: (): DeliveryDelay | undefined => props.value,
      set: (newDelay: DeliveryDelay): void => {
        emit('input', newDelay)
      },
    })
    return { deliveryDelay }
  },
})
