<template>
  <div class="variant-taxes">
    <p class="variant-taxes__title">Taxes</p>
    <PWarningMessage v-if="!taxes.tva" data-test-info-message
      >L’article est indisponible à la vente car les taxes n’ont pas été importées.</PWarningMessage
    >
    <div class="fake-row variant-taxes__block border-card">
      <div class="variant-taxes__block-item">
        TVA : <span class="variant-taxes__tva">{{ tvaText }}</span>
      </div>
      <div class="variant-taxes__block-item">
        RPD : <span class="variant-taxes__rpd">{{ rpdText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import PWarningMessage from '../../../presenters/PWarningMessage.vue'

export default {
  name: 'CVariantTaxes',
  components: { PWarningMessage },
  props: ['taxes'],
  computed: {
    tvaText() {
      return this.taxes.tva ? `${this.taxes.tva} %` : '-'
    },
    rpdText() {
      return this.taxes.rpd ? `${this.taxes.rpd} ${this.taxes.rpdUnit}` : '-'
    },
  },
}
</script>

<style lang="scss">
@import '@/lib/_variables.scss';
.variant-taxes__block {
  background-color: var(--idf-color-neutral-100);
  align-items: center;
  margin-bottom: 3em;
}
.variant-taxes__block-item {
  width: 100%;
  background-color: var(--idf-color-neutral-100);
  border-bottom: solid var(--idf-color-neutral-200) 1px;
  padding: 20px;
  align-items: center;
}
.variant-taxes__block:last-child {
  border-bottom: 0;
}
.variant-taxes__title {
  font-weight: bold;
  font-size: 18px;
  padding-top: 24px;
  padding-bottom: 15px;
}
.variant-taxes__tva {
  font-weight: bold;
}
.variant-taxes__rpd {
  font-weight: bold;
}
</style>
