/* eslint-disable camelcase, no-underscore-dangle */
import { CooperativeProductResponse } from '@b2ag/types-api-schemas'
import * as variantMapper from './variant.mapper'

export const mapFromApiV2 = (product: CooperativeProductResponse = {} as CooperativeProductResponse) => {
  const { variants = [] } = product
  return {
    id: product._id,
    cooperativeProductCode: product.cooperative_product_code,
    isHighlighted: product.is_highlighted,
    isRestrictedExplosivesPrecursor: product.is_restricted_explosives_precursor,
    exclusive: product.exclusive,
    activeSubstances: product.active_substances,
    bioControl: product.bio_control,
    formulation: product.formulation,
    images: product.images,
    kind: product.kind,
    marketAuthorization: product.market_authorization,
    name: product.name,
    organic: product.organic,
    usagesByCrops: product.usages_by_crops,
    cooperativeReview: product.cooperative_review,
    // @ts-ignore EXPLIQUEZ POURQUOI VOUS IGNOREZ CETTE ERREUR
    supplier: product.supplier,
    targets: product.targets,
    associatedProducts: product.associated_products || [],
    variants: variants.map(variantMapper.mapFromApiV2),
    categories: product.categories,
    family: product.family,
    description: product.description,
    characteristics: product.characteristics,
    documentation: product.documentation,
  }
}

export const mapFromCustomApi = (product: any = {}) => {
  return {
    description: product.description,
  }
}
