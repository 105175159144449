
import { debounce } from 'lodash'
import { reactive, UnwrapNestedRefs, watch } from 'vue'
import { DiscountTypes } from '@invivodf/discount-sdk/admin'
import CSearchKeywordInput from './CSearchKeywordInput.vue'
import CSelectFilterType from './CSelectFilterType.vue'
import CSelectFilterPeriodStatus from './CSelectFilterPeriodStatus.vue'
import { FilterStatusOption, ListingFilter } from '../../../domain/listing/ListingFilter'

interface ListingFilterDTO {
  keyWord: string
  status: FilterStatusOption | ''
  discountType: DiscountTypes | ''
}

export default {
  name: 'CDiscountFilterInputs',
  components: { CSelectFilterPeriodStatus, CSelectFilterType, CSearchKeywordInput },
  setup(_, context) {
    const toDomain = (dto: ListingFilterDTO): ListingFilter => ({
      keyWord: dto.keyWord || undefined,
      status: dto.status || undefined,
      discountType: dto.discountType || undefined,
    })

    const filters: UnwrapNestedRefs<ListingFilterDTO> = reactive({
      keyWord: '',
      status: '',
      discountType: '',
    })

    const debouncedEmitFilter = debounce(() => {
      context.emit('filterDiscounts', toDomain(filters))
    }, 500)

    watch(filters, debouncedEmitFilter)

    return { filters }
  },
}
