/* eslint-disable class-methods-use-this */

const CSV_HEADER = 'seller_id'
const SELLER_ID_REGEX = /^\d+$/

export class ValidateMetaDiscountParticipantFile {
  async execute(participantsFile: File): Promise<number[]> {
    const fileContent = await this.readFile(participantsFile)
    const [header, ...rows] = fileContent
      .trim()
      .split('\n')
      .map((line) => line.trim())

    this.validateHeader(header)
    this.validateRows(rows)
    const sellerIds = rows.map((sellerId) => Number(sellerId))
    this.validateParticipants(sellerIds)

    return sellerIds
  }

  private async readFile(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => resolve(<string>reader.result)
      reader.readAsText(file)
    })
  }

  private validateHeader(header: string) {
    if (header !== CSV_HEADER) throw new Error(`En-tête CSV invalide: attendu "${CSV_HEADER}", reçu "${header}"`)
  }

  private validateRows(rows: string[]) {
    rows.forEach((row, index) => {
      if (!row.match(SELLER_ID_REGEX)) throw new Error(`ID de structure invalide à la ligne ${index + 2}: "${row}"`)
    })
  }

  private validateParticipants(sellerIds: number[]) {
    sellerIds.reduce((sellerIdMap, sellerId, index) => {
      const lineNumber = index + 2

      if (sellerIdMap[sellerId]) {
        throw new Error(
          `Doublon détecté à la ligne ${lineNumber}: "${sellerId}" a déjà été défini à la ligne ${sellerIdMap[sellerId]}`,
        )
      }

      sellerIdMap[sellerId] = lineNumber
      return sellerIdMap
    }, {})
  }
}
