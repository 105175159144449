
import { IdfSelect } from '@invivodf/ui-kit'
import { ref, watch } from 'vue'
import { FilterStatusOption } from '../../../domain/listing/ListingFilter'

export default {
  name: 'CSelectFilterPeriodStatus',
  components: { IdfSelect },
  setup(props, { emit }) {
    const options = [
      { value: '', label: 'Choisir un statut de remise' },
      { value: FilterStatusOption.Active, label: 'Active' },
      { value: FilterStatusOption.Upcoming, label: 'À venir' },
      { value: FilterStatusOption.Expired, label: 'Expirée' },
    ]

    const periodStatus = ref(options[0].value)

    watch(periodStatus, () => {
      emit('input', periodStatus.value)
    })

    return { periodStatus, options }
  },
}
