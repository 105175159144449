<template>
  <IdfAlertBanner ref="active" kind="danger" class="p-info-error-message">
    <slot></slot>
  </IdfAlertBanner>
</template>
<script>
import { onMounted, ref } from 'vue'
import { IdfAlertBanner } from '@invivodf/ui-kit'

export default {
  name: 'PErrorMessage',
  components: { IdfAlertBanner },
  setup() {
    const active = ref(null)

    onMounted(() => {
      active.value.show()
    })

    return {
      active,
    }
  },
}
</script>
<style lang="scss">
.p-info-error-message {
  margin-top: var(--idf-spacing-2);
}
</style>
