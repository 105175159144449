
import { IdfButton } from '@invivodf/ui-kit'
import { defineComponent, PropType } from 'vue'
import CDiscountTitle from './CDiscountTitle.vue'
import { useDeleteDiscount } from '../composables/useDeleteDiscount'
import CDiscountDetailHeaderLinkButtonUpdate from './CDiscountDetailHeaderLinkButtonUpdate.vue'
import CDiscountDetailHeaderLinkButtonDuplicate from './CDiscountDetailHeaderLinkButtonDuplicate.vue'
import { DiscountPeriodStatusEnum } from '../../domain/statusByPeriod'
import CPeriodStatusIcon from './CPeriodStatusIcon.vue'
import PBackButton from './presenters/PBackButton.vue'
import { DiscountPolicy } from '../../domain/DiscountPolicy'

export default defineComponent({
  name: 'CDiscountDetailHeader',
  components: {
    CPeriodStatusIcon,
    CDiscountDetailHeaderLinkButtonUpdate,
    CDiscountDetailHeaderLinkButtonDuplicate,
    IdfButton,
    CDiscountTitle,
    PBackButton,
  },
  props: {
    internalName: {
      type: String,
      required: true,
    },
    period: {
      type: String as PropType<DiscountPeriodStatusEnum>,
      required: true,
    },
    policy: {
      type: Object as PropType<DiscountPolicy>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { deleteDiscount } = useDeleteDiscount(props.id)
    return { deleteDiscount }
  },
})
