<template>
  <div class="articles">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'VArticles',
}
</script>
