import { ArticleResponseApiV2 } from '@aladin/shared-universcoop-sdk'
import { ArticleRepository } from '../domain/article.repository'

interface GetAnchoredArticlesUsecase {
  (params: { partnerId: number }): Promise<ArticleResponseApiV2 | null>
}

interface Dependencies {
  articleRepository: ArticleRepository
}

export function createGetAnchoredArticlesUseCase(deps: Dependencies): GetAnchoredArticlesUsecase {
  return deps.articleRepository.getAnchoredArticle
}
