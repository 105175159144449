import ImportService from './import.service'

export default class ShippingAddressesService {
  constructor(membershipApiHost) {
    this.membershipApiHost = membershipApiHost
  }

  async import(cooperativeId, file) {
    return ImportService.import(
      `${this.membershipApiHost}/backoffice/v1/import/cooperatives/${cooperativeId}/shipping_addresses`,
      file,
    )
  }
}
