
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PLink',
  props: {
    link: {
      type: [Object, String],
      default: null,
    },
  },
  computed: {
    attributes(): object {
      switch (this.tag) {
        case 'button':
          return {}
        case 'a':
          return {
            href: this.link,
          }
        default:
          return {
            to: this.link,
          }
      }
    },
    tag(): string {
      if (!this.link) {
        return 'button'
      }
      if (typeof this.link === 'string' && this.link.match(/^(http(s)?|ftp):\/\//)) {
        return 'a'
      }
      return 'RouterLink'
    },
  },
})
