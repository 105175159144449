
import { PropType } from 'vue'
import CDiscountFlashSaleInput from './inputs/CDiscountFlashSaleInput.vue'
import CDiscountSalesInput from './inputs/CDiscountSalesInput.vue'
import { IUseDiscountInput } from '../composables/useDiscountInput'

export default {
  name: 'CDiscountFlagCheckbox',
  components: { CDiscountFlashSaleInput, CDiscountSalesInput },
  props: {
    flashSale: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    sales: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
}
