import { render, staticRenderFns } from "./CImportHomeBanner.vue?vue&type=template&id=23375690&scoped=true"
import script from "./CImportHomeBanner.vue?vue&type=script&lang=ts"
export * from "./CImportHomeBanner.vue?vue&type=script&lang=ts"
import style0 from "./CImportHomeBanner.vue?vue&type=style&index=0&id=23375690&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23375690",
  null
  
)

export default component.exports