import { ShippingWay } from '@/core/offer'
import { defaultShippingWays } from '@/core/mapper/product.mapper'
import { OfferSchema } from '@b2ag/types-api-schemas'

export interface OfferDTO {
  offerId: string
  productUId: string
  validityEndDate?: Date
  shippingStartDate?: Date
  shippingEndDate?: Date
  shippingMethodList: ShippingWay[]
  segmentList?: string[]
}

export const mapOfferFromOfferApi = (offer: OfferSchema): OfferDTO => ({
  offerId: offer.offer_id,
  productUId: offer.product_uid ?? '',
  validityEndDate: offer.validity_end_date ? new Date(offer.validity_end_date) : undefined,
  shippingStartDate: offer.shipping_start_date ? new Date(offer.shipping_start_date) : undefined,
  shippingEndDate: offer.shipping_end_date ? new Date(offer.shipping_end_date) : undefined,
  shippingMethodList: offer.shipping_method_list?.map((shippingMethod) => defaultShippingWays[shippingMethod] || {}),
  segmentList: offer.segment_list,
})
