
import { defineComponent } from 'vue'
import { IdfSelect } from '@invivodf/ui-kit'
import { useLogisticOfferWording } from '../../composables'
import { SelectOption } from '../../../domain'

export default defineComponent({
  name: 'PPricingScale',
  emits: ['input'],
  components: {
    IdfSelect,
  },
  props: {
    options: {
      type: Array as () => SelectOption[],
      default: () => [],
    },
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const onInput = (pricingType: string): void => {
      emit('input', pricingType)
    }
    return { onInput, wording }
  },
})
