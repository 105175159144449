export const unitLabel = {
  l: 'L',
  kg: 'Kg',
  unit: 'unité',
  t: 'T',
}

export default function measureUnit(unit) {
  return (unit && (unitLabel[unit] || unit.charAt(0).toUpperCase() + unit.slice(1))) || ''
}
