
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    added: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    return {
      emitClick: () => {
        emit('click')
      },
    }
  },
})
