import { Quota } from './quota'

const minThreshold = (value: number): number => {
  return value < 0 ? 0 : value
}

const maxThreshold =
  (threshold: number) =>
  (value: number): number => {
    return threshold < value ? threshold : value
  }

const threshold =
  (max = Infinity) =>
  (value: number) => {
    const highThresold = maxThreshold(max)
    return highThresold(minThreshold(value))
  }

export function isQuotaReached(value: number) {
  return value <= 0
}

export function numberOfReachedQuotas(quotas: Quota[]): number {
  return quotas.filter((quota) => isQuotaReached(quota.remaining)).length
}

export function numberOfActiveQuotas(quotas: Quota[]): number {
  return quotas.length - numberOfReachedQuotas(quotas)
}
