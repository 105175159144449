
import { defineComponent, ref, watch } from 'vue'
import { ValidationProvider } from 'vee-validate-last'
import { translateError } from '@/filters'
import PAlert from '@invivodf/common/src/application/presenters/PAlert.vue'

export default defineComponent({
  name: 'CInputQuantity',
  emit: ['input'],
  components: { ValidationProvider, PAlert },
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: '',
    },
    formErrors: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    rules: String,
    quantityWithUnit: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const inputValue = ref(props.value)
    const validationInput = ref(null)

    watch(
      () => props.formErrors,
      (newValue: string[]) => {
        // @ts-ignore EXPLIQUEZ POURQUOI CE TS IGNORE
        validationInput.value?.setErrors(newValue)
      },
    )

    watch(
      () => props.value,
      (newValue: string) => {
        inputValue.value = newValue
      },
    )

    watch(inputValue, (newValue: string) => {
      context.emit('input', newValue)
    })

    return { inputValue, translateError, validationInput }
  },
})
