import { offerService } from '../services/index'
import enrichOffers from '../services/offer.utils'

/** @typedef {Partial<{expiresBefore, variantName, variantIdList, isTechExclusive}>} Filters */
/** @typedef {{variants: {name: string, id: string}[]}[]} ProductHits */

async function addOffer({ dispatch, commit, rootGetters }, upsertedOffer) {
  await dispatch('catalog/fetchProductsByVariantInvivoId', [upsertedOffer.variantInvivoId], { root: true })
  const [offer] = enrichOffers([upsertedOffer], rootGetters['catalog/getProductInvivoIdMap'])

  commit('ADD_OFFER', offer)
  dispatch('setToast', { type: 'success', message: 'L’offre a été créée' }, { root: true })
}

function updateOffer({ dispatch, commit, rootGetters }, upsertedOffer) {
  const [offer] = enrichOffers([upsertedOffer], rootGetters['catalog/getProductInvivoIdMap'])
  commit('UPDATE_OFFER', offer)
  dispatch('setToast', { type: 'success', message: 'L’offre a été modifiée' }, { root: true })
}

// eslint-disable-next-line import/prefer-default-export
export const getOfferStoreModule = () => ({
  namespaced: true,
  state: {
    offers: [],
    totalPages: 1,
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    SET_OFFERS(state, offers) {
      state.offers = offers
    },
    ADD_OFFER(state, offer) {
      state.offers = state.offers.concat([offer])
    },
    DELETE_OFFER(state, offer) {
      const deletedOfferIndex = state.offers.findIndex((element) => offer.id === element.id)
      if (deletedOfferIndex >= 0) state.offers.splice(deletedOfferIndex, 1)
    },
    UPDATE_OFFER(state, offer) {
      const indexOfTheOfferToReplace = state.offers.indexOf(state.offers.find((element) => element.id === offer.id))
      if (indexOfTheOfferToReplace >= 0) state.offers.splice(indexOfTheOfferToReplace, 1, offer)
    },
    SET_TOTAL_PAGES(state, totalPages) {
      state.totalPages = totalPages
    },
  },
  actions: {
    // eslint-disable-next-line no-shadow
    setOffers({ commit }, offers) {
      commit('SET_OFFERS', offers)
    },

    async upsertOffer({ dispatch, commit, rootGetters }, { offer, logisticOffers }) {
      const { createdOffer, updatedOffer } = await offerService.upsert(offer, rootGetters.cooperativeId)

      if (createdOffer) {
        await addOffer({ dispatch, commit, rootGetters }, { ...createdOffer, logisticOffers })
      } else {
        updateOffer({ dispatch, commit, rootGetters }, { ...updatedOffer, logisticOffers })
      }
    },

    deleteOffer({ dispatch, commit }, offer) {
      commit('DELETE_OFFER', offer)
      dispatch('setToast', { type: 'success', message: 'L’offre a été supprimée' }, { root: true })
    },

    /** @param _ @param {{page?: number, limit?: number, filters?: Filters}} options */
    async fetchOffers({ dispatch, commit, rootGetters }, { page = 1, limit = 10, filters = {} } = {}) {
      const { variantName, ...sqlFilters } = filters
      if (variantName) {
        /** @type {{hits: ProductHits, nbHits: number}} */
        const { hits, nbHits } = await rootGetters.cooperativeIndex.search(variantName, {
          restrictSearchableAttributes: ['variants.name'],
          attributesToRetrieve: ['variants.name', 'variants.id'],
          hitsPerPage: 50,
        })
        if (nbHits === 0) {
          commit('SET_OFFERS', [])
          commit('SET_TOTAL_PAGES', 1)
          return
        }
        if (nbHits <= 100) {
          sqlFilters.variantIdList = hits.flatMap(({ variants }) => variants.map(({ id }) => id))
        }
      }
      const { offers = [], totalPages = 1 } =
        (await offerService.listV3({ cooperativeId: rootGetters.cooperativeId, page, limit, filters: sqlFilters })) ||
        {}
      const variantInvivoIdList = offers.map((offer) => offer.variantInvivoId)
      await dispatch('catalog/fetchProductsByVariantInvivoId', variantInvivoIdList, { root: true })
      const productInvivoIdMap = rootGetters['catalog/getProductInvivoIdMap']
      const offerList = enrichOffers(offers, productInvivoIdMap)
      commit('SET_OFFERS', offerList)
      commit('SET_TOTAL_PAGES', totalPages)
    },
  },
  getters: {
    offers(state) {
      return state.offers
    },
    totalPages(state) {
      return state.totalPages
    },
    getOffersByVariantId: (state) => (id) => {
      return state.offers.filter((offer) => offer.variantInvivoId === id)
    },
  },
})
