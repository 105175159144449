import { deliveryModeBusinessName } from '@invivodf/module-logistic-offer-client-sdk'
import { LogisticRouteOption, logisticRoutes } from '../../domain'
import { LogisticRoute } from '../../domain/types/logistic-route'

export function useDeliveryModeBusinessName() {
  const buildDeliveryModeBusinessName = (deliveryName, deliveryDelay): string => {
    if (!deliveryName) return ''
    if (!deliveryDelay.value || !deliveryDelay.unit) return deliveryModeBusinessName(deliveryName, undefined)
    return deliveryModeBusinessName(deliveryName, deliveryDelay)
  }
  const getDeliveryModeIcon = (logisticRoute?: LogisticRoute): LogisticRouteOption | undefined => {
    return logisticRoutes.find((route) => route.value === logisticRoute)
  }
  return { buildDeliveryModeBusinessName, getDeliveryModeIcon }
}
