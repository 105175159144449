import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import { computed, ref } from 'vue'
import { getDeliveryModeList } from '../../dependency-injection'
import { useLogisticOfferWording } from './useWording'

export function useDeliveryModeList() {
  const seller = useSeller()
  const deliveryModeList = ref<DeliveryModeEntity[]>([])
  const selectedDeliveryMode = ref<DeliveryModeEntity>()
  const isLoading = ref(false)
  const { wording } = useLogisticOfferWording()

  const loadDeliveryModeList = async () => {
    if (!seller.value.id) throw new Error('Cooperative id is undefined')
    isLoading.value = true
    try {
      deliveryModeList.value = await getDeliveryModeList.execute(seller.value.id.toString())
    } catch (e) {
      throw new Error(wording.notification.error)
    } finally {
      isLoading.value = false
    }
  }

  const setSelectedDeliveryMode = (deliveryMode: DeliveryModeEntity) => {
    selectedDeliveryMode.value = deliveryMode
  }

  return {
    deliveryModeList: computed(() => deliveryModeList.value),
    selectedDeliveryMode: computed(() => selectedDeliveryMode.value),
    isLoading,
    loadDeliveryModeList,
    setSelectedDeliveryMode,
  }
}
