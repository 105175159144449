import { SearchQuery } from '../domain/SearchQuery'
import { IDiscountableRepository } from '../domain/IDiscountableRepository'
import { SearchResultPage } from '../domain/SearchResultPage'
import { setComplianceErrors } from '../domain/Discountable'
import { Discount } from '../domain/Discount'

export class SearchDiscountableGroup {
  constructor(private readonly discountableRepository: IDiscountableRepository) {}

  async execute(partnerId: number, searchQueryPage: SearchQuery, discount: Discount): Promise<SearchResultPage> {
    const search = await this.discountableRepository.searchDiscountableGroup(partnerId, searchQueryPage)
    search.results.forEach((result) => {
      // eslint-disable-next-line no-param-reassign
      result.discountables = result.discountables.map((offer) => setComplianceErrors(offer, discount))
    })
    return search
  }
}
