import { useBoToast } from '@invivodf/common/src/application/composables/useBoToast'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { DiscountRouteName } from '../routes/discountRouteName'
import { deleteDiscount } from '../../dependency-injection'
import { useSeller } from '../../../../application/composables/useSeller'
import { isSuperAdmin } from './isSuperAdmin'

const DELETE_MESSAGE = `La remise a été supprimée avec succès`

export const useDeleteDiscount = (id: string) => {
  const router = useRouter()
  const { showNotificationSuccess, showNotificationError } = useBoToast()
  const seller = useSeller()

  const usecase = async () => {
    try {
      const deleteMetaDiscount = await isSuperAdmin()
      await deleteDiscount.execute(seller.value.id, id, deleteMetaDiscount)
      showNotificationSuccess(DELETE_MESSAGE)
      router.replace({ name: DiscountRouteName.LISTING })
    } catch (e) {
      showNotificationError(e.message)
    }
  }

  return {
    deleteDiscount: usecase,
  }
}
