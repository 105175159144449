import { computed } from 'vue'
import { useStore } from '@/composables/useStore'

export function useTech() {
  const store = useStore()

  const isTech = computed<boolean | undefined>(() => store.getters.isTech)

  return { isTech }
}
