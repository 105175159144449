import { onMounted, Ref, ref } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { retrieveDiscountables } from '../../dependency-injection'
import { Discount } from '../../domain/Discount'
import { useClientPagination } from './pagination/useClientPagination'
import { Discountable } from '../../domain/Discountable'
import { useSeller } from '../../../../application/composables/useSeller'

export const useDiscountables = (discount: Discount) => {
  const seller = useSeller()

  const discountables: Ref<Discountable[]> = ref([])
  const isLoading = ref(false)

  const { currentItems, numberOfPages, currentPage, changeCurrentPage, noItems } =
    useClientPagination<Discountable>(discountables)
  const { showNotificationError } = useBoToast()
  onMounted(async () => {
    try {
      isLoading.value = true
      discountables.value = await retrieveDiscountables.execute(discount, seller.value.id)
    } catch (err) {
      showNotificationError(err.message)
    } finally {
      isLoading.value = false
    }
  })

  return { isLoading, noItems, numberOfPages, discountables: currentItems, changeCurrentPage, currentPage }
}
