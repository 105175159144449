
import { defineComponent, ref } from 'vue'

import { IdfIcon } from '@invivodf/ui-kit'
import PMainNavigation from '@invivodf/common/src/application/presenters/PMainNavigation/PMainNavigation.vue'
import PUserConnectionLink from '@invivodf/common/src/application/presenters/PUserConnectionLink/PUserConnectionLink.vue'

export default defineComponent({
  name: 'VSidebar',
  components: {
    IdfIcon,
    PMainNavigation,
    PUserConnectionLink,
  },
  props: {
    brandData: {
      type: Object,
      default: () => ({}),
    },
    mainNavigationLinks: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const activeIndex = ref(null)
    const isBusy = ref(false)
    const isOpen = ref(false)

    return { activeIndex, isBusy, isOpen }
  },
  methods: {
    handleOpenStatus(value): void {
      this.isBusy = true
      this.isOpen = value
      this.$emit('open', value)
    },
    onBrandSelect(id): void {
      const { link, isExternal } = this.brandData.items.find((item) => item.id === id)
      if (!link) return
      if (isExternal) {
        window.open(link, '_blank')
      } else {
        this.brandData.current = id
        this.$router.push({ name: link })
      }
    },
    onBrandToggle(): void {
      this.handleOpenStatus(true)
    },
    onNavigationClick(): void {
      this.handleOpenStatus(this.isOpen)
    },
    onToggleClick(): void {
      this.handleOpenStatus(!this.isOpen)
    },
    onTransitionEnd(): void {
      this.isBusy = false
    },
    onLogout(): void {
      this.$emit('logout')
    },
  },
})
