import { DelayUnitOption } from '../interfaces/delay-unit-option'

export const delayUnits: DelayUnitOption[] = [
  {
    value: 'hour',
    label: 'Heures',
  },
  {
    value: 'day',
    label: 'Jours',
  },
  {
    value: 'working_day',
    label: 'Jours ouvrés',
  },
  {
    value: 'week',
    label: 'Semaines',
  },
  {
    value: 'month',
    label: 'Mois',
  },
]
