import { computed, ComputedRef, inject, provide, ref, Ref } from 'vue'
import { NotProvidedError } from '../../NotProvidedError'
import { useLoadedForm } from '../useLoadedForm'

export interface MetaDiscountDiscountablesProvided {
  count: Ref<number | null>
  file: Ref<File | null>
}

export interface IUseMetaDiscount {
  isUsingMetaDiscount: ComputedRef<boolean>
  setParticipation: (sellers: number[]) => void
  sellers: ComputedRef<number[]>
  discountables: MetaDiscountDiscountablesProvided
}

export const metaDiscountDiscountablesInjectionKey = Symbol('metaDiscountDiscountables')

export const provideMetaDiscountDiscountables = () => {
  const discountables = { count: ref<number | null>(null), file: ref<File | null>(null) }
  provide(metaDiscountDiscountablesInjectionKey, { count: ref(null), file: ref(null) })
  return discountables
}

export const injectMetaDiscountDiscountables = () => {
  const provided = inject<MetaDiscountDiscountablesProvided>(metaDiscountDiscountablesInjectionKey)

  if (!provided) throw new NotProvidedError('MetaDiscountDiscountables')

  return provided
}

export const useMetaDiscount = (): IUseMetaDiscount => {
  const discountables = injectMetaDiscountDiscountables()
  const form = useLoadedForm()

  const isMeta = computed(() => form.value.isMeta)
  const participation = computed(() => form.value.participants || [])

  const setParticipation = (sellers: number[]) => {
    form.value.setParticipants(sellers)
  }

  return { isUsingMetaDiscount: isMeta, sellers: participation, discountables, setParticipation }
}
