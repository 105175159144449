<template>
  <div class="import-stores" xmlns:v-on="http://www.w3.org/1999/xhtml">
    <c-import-base
      :importFileService="storeService"
      :csvProperties="csvProperties"
      pageTitle="Import des sites de stockage"
      uploadButtonLabel="Pour mettre à jour votre base de sites de stockage, importez le fichier CSV"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        title="Informations sur vos sites de stockage"
        nbResultText="Nombre de sites de stockage :"
        exportText="Exporter vos sites de stockage"
        :importInformation="importInformation"
        :downloadCSV="downloadStoresCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { storeService } from '../../services'
import ImportService from '../../services/import.service'

const csvFileProperties = {
  name: 'stores.csv',
  full_path: '/assets/csv/stores.csv',
}
export default {
  name: 'CImportStores',
  components: {
    CImportBase,
    CImportInformation,
  },
  methods: {
    afterStoresImport() {
      this.$store.dispatch('setStoresImportInformation')
    },
    async downloadStoresCSV() {
      const storesCSV = await storeService.downloadStores(this.cooperative.id)
      const url = window.URL.createObjectURL(new Blob([storesCSV]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', ImportService.createFileName(this.cooperative.name, 'stores'))
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    },
  },
  computed: {
    ...mapGetters(['storesImportInformation', 'cooperative']),
    importInformation() {
      const { storesImportInformation: { storesCount, lastStoresImportAt } = {} } = this
      return { count: storesCount, lastImportAt: lastStoresImportAt }
    },
  },
  data() {
    return {
      csvProperties: csvFileProperties,
      storeService,
      csvColumnDetails: [
        {
          header: 'store_name',
          description: 'Nom du site de stockage ou raison sociale',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: 'Rassot S.A',
        },
        {
          header: 'store_code',
          description:
            "Identifiant du site de stockage dans le SI serait envoyé dans le flux commande dans le cas d'enlèvement en magasin",
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: '1234AZE',
        },
        {
          header: 'store_schedule',
          description: 'Horaires site de stockage',
          format: 'BLOB TEXT',
          mandatory: 'Non',
          exampleValue: 'Tous les jours de 14h à 18h Sauf le dimanche de 10h à 11h',
        },
        {
          header: 'store_address_line1',
          description: 'Numéro et libellé de la voie',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: 'Chemin des Esses',
        },
        {
          header: 'store_address_line2',
          description: 'Mentions spéciales de distribution – Boite Postale, Tri Spécial Arrivée…',
          format: 'VARCHAR',
          mandatory: 'Non',
        },
        {
          header: 'store_address_line3',
          description:
            'Complément d’identification du point géographique : entrée, tour, bâtiment, immeuble, résidence, zone industrielle',
          format: 'VARCHAR',
          mandatory: 'Non',
        },
        {
          header: 'store_postal_code',
          description: ' ',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: '69000',
        },
        {
          header: 'store_city',
          description: ' ',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: 'Adoncourt',
        },
        {
          header: 'store_region',
          description: 'Code région',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: 'CODE_SUD',
        },
        {
          header: 'store_gps_location_lat',
          description: 'Latitude Coordonnées GPS du magasin',
          format: 'FLOAT',
          mandatory: 'Non',
          exampleValue: '43.34342',
        },
        {
          header: 'store_gps_location_long',
          description: 'Longitude Coordonnées GPS du magasin',
          format: 'FLOAT',
          mandatory: 'Non',
          exampleValue: '-23.42657',
        },
        {
          header: 'store_contact_phone',
          description: ' ',
          format: 'VARCHAR',
          mandatory: 'Non',
          exampleValue: '123456787',
        },
        {
          header: 'store_accept_crop_protection',
          description: 'Flag indiquant si le site de stockage accepte les produits santé végétal',
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'TRUE',
        },
        {
          header: 'store_accept_seeds',
          description: `Flag indiquant si le site de stockage accepte les semences`,
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'TRUE',
        },
        {
          header: 'store_accept_fertilizer_bigbag',
          description: 'Flag indiquant si le site de stockage accepte les engrais en sac et bigbag',
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'FALSE',
        },
        {
          header: 'store_accept_fertilizer_tank',
          description: 'Flag indiquant si le site de stockage accepte les engrais en cuve',
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'FALSE',
        },
        {
          header: 'store_accept_fertilizer_bulk',
          description: 'Flag indiquant si le site de stockage accepte les engrais en vrac',
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'FALSE',
        },
        {
          header: 'store_accept_fertilizer_other_packaging',
          description:
            'Flag indiquant si le site de stockage accepte les engrais en bidons, cartons, boites et autres packaging hormis big bag, vrac et cuve',
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'FALSE',
        },
        {
          header: 'store_is_pickup_point',
          description: `Indicateur permettant de savoir si le site permet l'accueil pour retirer de la marchandise`,
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'TRUE/FALSE',
        },
        {
          header: 'store_is_click_and_collect',
          description: 'Indicateur permettant de savoir si le site permet le click and collect',
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'TRUE/FALSE',
        },
        {
          header: 'store_is_departure_point',
          description: `Indicateur permettant de savoir si le site est un point de départ de marchandises pour les livraisons à la ferme ou en point de retrait`,
          format: 'BOOLEAN',
          mandatory: 'Oui',
          exampleValue: 'TRUE/FALSE',
        },
      ],
      descriptionLines: [
        'L’extension attendue est : <b>.csv</b>',
        'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
        'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
        "L'encoding attendu est : <b>UTF8</b>.",
      ],
    }
  },
}
</script>
