import { useAuthService, useScopesService } from '@/auth'
import { Route } from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { isRouteAuthorized } from '@invivodf/common/src/auth/isRouteAuthorized'

const AUTHENTICATION_NOT_REQUIRED_ROUTES = ['oauth-callback', 'not-found']

export default async function routerBeforeEach(to: Route, from: Route, next) {
  if (AUTHENTICATION_NOT_REQUIRED_ROUTES.includes(to.name!)) return next()
  const authService = useAuthService()
  const isAuthenticated = await authService.isAuthenticated()
  const isExpired = await authService.isExpired()
  if (!isAuthenticated || isExpired) {
    await authService.doLogin()
    return false
  }
  const scopesService = useScopesService()
  const userScopes = await authService.getUserScopes()
  try {
    scopesService.validateScopes(userScopes)
  } catch (err) {
    await authService.doLogout()
    return false
  }

  const isAuthorized = await isRouteAuthorized(to)
  if (!isAuthorized) return false

  // eslint-disable-next-line import/no-named-as-default-member, prefer-destructuring
  const isUserLoaded = store.getters.isUserLoaded
  if (isAuthenticated && !isExpired && !isUserLoaded) {
    await store.dispatch('fetchUser')
  }
  return next()
}
