import { ArticleResponseApiV2 } from '@aladin/shared-universcoop-sdk'
import { ArticleRepository } from '../domain/article.repository'

interface GetArticlesUsecase {
  (params: { partnerId: number }): Promise<ArticleResponseApiV2[]>
}

interface Dependencies {
  articleRepository: ArticleRepository
}

export function createGetArticlesUseCase(deps: Dependencies): GetArticlesUsecase {
  return deps.articleRepository.getArticles
}
