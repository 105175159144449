<template>
  <b-modal ref="confirmModal" centered size="lg" :title="title">
    <slot>
      <p>{{ message }}</p>
    </slot>
    <div slot="modal-footer" data-cy="confirmMessage">
      <slot name="footer">
        <IdfButton kind="link" @click="cancel()">{{ cancelText }}</IdfButton>
        <IdfButton class="confirm-modal__btn__confirm" @click="confirm()" data-cy="confirmButton">{{
          confirmText
        }}</IdfButton>
      </slot>
    </div>
  </b-modal>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'CConfirmModal',
  components: { IdfButton },
  props: {
    title: {
      default: () => '',
      type: String,
    },
    message: {
      default: () => 'Êtes vous sûr de vouloir confirmer votre choix ?',
      type: String,
    },
    confirmText: {
      default: () => 'Oui',
      type: String,
    },
    cancelText: {
      default: () => 'Non',
      type: String,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    },
    show() {
      this.$refs.confirmModal.show()
    },
    hide() {
      this.$refs.confirmModal.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-modal__btn__confirm {
  margin-left: 2rem;
}
</style>
