import { inject, provide, Ref, ref } from 'vue'
import { Discountable } from '../../../domain/Discountable'

export const ASSOCIATION_SELECTION_KEY = Symbol('cartAssociationsKey')

export const provideAssociationSelection = () => {
  const selection = ref<Discountable[]>([])
  provide(ASSOCIATION_SELECTION_KEY, selection)
}

export const injectDiscountablesSelection = () => {
  return inject<Ref<Discountable[]>>(ASSOCIATION_SELECTION_KEY, ref([]))
}
