<template>
  <div>
    <div class="idf-info-card__text-container">
      <PProductExclusivesPrecursorMessageArticle class="idf-info-card__text" />
      <IdfButton kind="link" @click="showModal">Lire la suite</IdfButton>
    </div>
    <IdfModal v-show="isVisible" @close="hideModal">
      <template v-slot:body>
        <PProductExclusivesPrecursorMessageArticle />
      </template>
    </IdfModal>
  </div>
</template>
<script>
import { IdfButton, IdfModal } from '@invivodf/ui-kit'
import PProductExclusivesPrecursorMessageArticle from './PProductExclusivesPrecursorMessageArticle.vue'

export default {
  name: 'PProductExplosivesPrecursorMessage',
  components: {
    PProductExclusivesPrecursorMessageArticle,
    IdfModal,
    IdfButton,
  },
  data: () => {
    return {
      isVisible: false,
    }
  },
  methods: {
    showModal() {
      this.isVisible = true
      document.body.classList.add('modal-open')
    },
    hideModal() {
      this.isVisible = false
      document.body.classList.remove('modal-open')
    },
  },
}
</script>

<style lang="scss" scoped>
.idf-info-card__text-container {
  margin-top: 2rem;
}
.idf-info-card__text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 93%;
  @media (max-width: $idf-breakpoint-sm) {
    -webkit-line-clamp: 2;
    width: 100%;
  }
}
.idf-info-card__read-more {
  color: var(--idf-color-main-400);
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: var(--idf-color-main-600);
  }
}
</style>
