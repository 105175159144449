<template>
  <div class="container container--full-width-on-mobile dashboard-quota">
    <h1 class="dashboard-quota__header">Suivi de vos quotas</h1>
    <c-quota-list />
  </div>
</template>

<script>
import CQuotaList from '@/contexts/quota-dashboard/application/components/CQuotaList/CQuotaList.vue'

export default {
  name: 'VQuota',
  components: { CQuotaList },
}
</script>

<style scoped lang="scss">
.dashboard-quota__header {
  margin: var(--idf-spacing-5) 0 var(--idf-spacing-4);
}
.dashboard-quota {
  padding: 0 var(--idf-spacing-2);
  @media screen and (max-width: $idf-breakpoint-md) {
    max-width: 100%;
    padding: 0;
  }
}
</style>
