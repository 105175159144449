
import { IdfBreadcrumb } from '@invivodf/ui-kit'
import { PropType } from 'vue'

interface Item {
  label: string
  link?: { name: string }
  icon?: string
}

export default {
  name: 'PHeader',
  components: { IdfBreadcrumb },
  props: {
    breadcrumb: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
}
