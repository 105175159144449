
import { computed, defineComponent, Ref, ref } from 'vue'

export default defineComponent({
  name: 'PContextMenu',
  setup() {
    const left: Ref<number> = ref(0)
    const top: Ref<number> = ref(0)
    const show: Ref<boolean> = ref(false)
    const context: Ref = ref()
    const style: Ref<Object> = computed(() => {
      return { top: `${top.value}px`, left: `${left.value}px` }
    })
    const close = (): void => {
      show.value = false
      left.value = 0
      top.value = 0
    }
    const open = (evt: MouseEvent): void => {
      left.value = (evt.pageX || evt.clientX) - 220
      top.value = evt.pageY || evt.clientY
      setTimeout(() => {
        context.value.focus()
      })
      show.value = true
    }
    return { close, context, left, open, show, style, top }
  },
})
