<template>
  <div class="tabs">
    <div class="tabs__header">
      <div
        v-for="(tabName, index) in tabs"
        :key="'tab-' + index"
        class="col-2 tabs__item"
        :class="tabName === activeTab ? 'tabs__item--active' : ''"
        @click="selectTab(tabName)"
        :data-test="'tab-' + index"
      >
        {{ tabName }}
      </div>
    </div>
    <div class="tabs__content container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTabs',
  props: {
    activeTab: {
      type: String,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectTab(tabName) {
      this.$emit('tabChanged', tabName)
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables';
.tabs__item {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid var(--idf-color-neutral-200);
  text-align: center;
  cursor: pointer;
  display: inline-block;
  box-shadow: inset 0 -15px 10px -12px var(--idf-color-neutral-200);
}
.tabs__item--active {
  background-color: var(--idf-color-main-400);
  color: white;
  font-weight: bold;
  box-shadow: none;
}
.tabs__content {
  background-color: white;
  border: 1px solid var(--idf-color-neutral-200);
}
</style>
