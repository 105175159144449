import { provide, inject, ComputedRef, computed } from 'vue'
import { Store as VuexStore } from 'vuex'

// eslint-disable-next-line symbol-description
export const storeSymbol = Symbol('store')

export function provideStore(store) {
  provide(storeSymbol, store)
}

export function useStore(): VuexStore<any> {
  const store: VuexStore<any> = inject(storeSymbol) as VuexStore<any>
  if (!store) throw new Error('Store was not provided')
  return store
}
