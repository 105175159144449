import { ShippingWay } from './offer'
import { SHIPPING_FARM, SHIPPING_ICONS, SHIPPING_TITLES } from './shipping-method.constants'

type ShippingPresenter = {
  icon: string
  title: string
}

export const presentShippingMethods = (shippingMethods: ShippingWay[]) => {
  return shippingMethods?.reduce((acc: ShippingPresenter[], shippingMethod) => {
    return acc.concat({
      icon: SHIPPING_ICONS[shippingMethod.kind] || SHIPPING_ICONS[SHIPPING_FARM],
      title: SHIPPING_TITLES[shippingMethod.kind] || shippingMethod.title,
    })
  }, [])
}
