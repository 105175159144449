/* eslint-disable no-underscore-dangle */
import { AxiosInstance } from 'axios'
import QuotaDTO, { mapFromApiQuotasResponse } from '@/contexts/quota-dashboard/infrastructure/quotaDTO'
import { mapOfferFromOfferApi, OfferDTO } from '@/contexts/quota-dashboard/infrastructure/offerDTO'
import { CooperativeProduct } from '@/core/product'
import { mapProductFromApiAndFlattenPackProduct } from '@/core/mapper/product.mapper'
import { CooperativeProductResponse, OfferSchema, QuotaSchema } from '@b2ag/types-api-schemas'
import { splitRequest } from '@invivodf/common/src/infrastructure/splitRequest'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

export default class QuotaDashboardDatasource {
  private readonly quotaAPI: AxiosInstance

  private readonly offerAPI: AxiosInstance

  private readonly productApi: AxiosInstance

  constructor({ quotaApiHost, offerApiHost, productApiHost }) {
    this.quotaAPI = createAxiosClientWithAladinBearer(`${quotaApiHost}/v1`, { timeout: 20000 })
    this.offerAPI = createAxiosClientWithAladinBearer(`${offerApiHost}/v3`, { timeout: 20000 })
    this.productApi = createAxiosClientWithAladinBearer(`${productApiHost}/v1`, { timeout: 20000 })
  }

  async findQuotas(partnerId: number): Promise<QuotaDTO[]> {
    const { data } = await this.quotaAPI.get<QuotaSchema[]>('/quotas', {
      params: {
        partner_id: partnerId,
      },
    })
    return mapFromApiQuotasResponse(data)
  }

  private async _findOffers(partnerId: number, offerIds: readonly string[]): Promise<OfferDTO[]> {
    const { data } = await this.offerAPI.get<OfferSchema[]>('/offers', {
      params: {
        pagination: false,
        partner_id: partnerId,
        ids: offerIds.join(','),
      },
    })
    return data.map(mapOfferFromOfferApi)
  }

  findOffers(offerIds: readonly string[], partnerId: number): Promise<OfferDTO[]> {
    return splitRequest(offerIds, this._findOffers.bind(this, partnerId))
  }

  async _findProducts(partnerId: number, variantInvivoIdList: string[]): Promise<CooperativeProduct[]> {
    const response = await this.productApi.get<CooperativeProductResponse[]>(`/cooperatives/${partnerId}/products`, {
      params: { invivo_ids: variantInvivoIdList.join(',') },
    })
    return response.data.map(mapProductFromApiAndFlattenPackProduct)
  }

  findProducts(variantInvivoIdList: string[], partnerId: number): Promise<CooperativeProduct[]> {
    return splitRequest(variantInvivoIdList, this._findProducts.bind(this, partnerId), { maxLen: 3000 })
  }
}
