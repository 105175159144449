
import { defineComponent } from 'vue'
import { IdfIcon } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'PSummaryLine',
  components: {
    IdfIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    data: {
      type: String,
      default: '-',
    },
    icon: {
      type: String,
    },
  },
})
