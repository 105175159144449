
import { defineComponent } from 'vue'
import CAssociationsInputsHeader from './CAssociationsInputsHeader.vue'
import CSearchResult from './CSearchResult.vue'
import CCartAssociations from './CCartAssociations.vue'
import { useCartAssociations } from '../../composables/association/useCartAssociations'
import { injectPreSelectedDiscountables } from '../../composables/association/injectPreSelectedDiscountables'

export default defineComponent({
  name: 'CEditAssociations',
  components: { CCartAssociations, CAssociationsInputsHeader, CSearchResult },
  setup() {
    injectPreSelectedDiscountables()

    const { cart, toggleAddable, addDiscountable, removeDiscountable } = useCartAssociations()

    return {
      discountables: cart,
      toggleAddable,
      addDiscountable,
      removeDiscountable,
    }
  },
})
