import { MAX_DELIVERY_MODE_NAME_LENGTH } from '@invivodf/common/src/contexts/logistic-offer/domain/logistic-offer.validate'

type LogisticOfferRules = {
  logisticRouteRules: string
  deliveryNameRules: string
  deliveryDelayRules: string
  offerIdRules: (sellerId: string) => Record<string, any>
  pricingValueRules: () => Record<string, any>
  pricingMethodRules: string
  pricingRangeEndRules: (rangeStart: string) => Record<string, any>
  validityPeriodDateStartRules: (start: Date, end: Date) => Record<string, any>
  validityPeriodDateEndRules: (start: Date, end: Date) => Record<string, any>
}

export function useLogisticOfferRules(): LogisticOfferRules {
  return {
    logisticRouteRules: 'required',
    deliveryNameRules: `max:${MAX_DELIVERY_MODE_NAME_LENGTH}|required`,
    deliveryDelayRules: 'integer',
    offerIdRules: (sellerId: string) => ({
      regex: {
        regex: /^[a-zA-Z0-9_-]+$/,
        literalAuthorizedChar: 'des caractères alpha-numériques sans accents et les caractères spéciaux _ et -',
      },
      required: true,
      uniqueReferenceValidator: sellerId,
    }),
    pricingValueRules: () => ({
      regex: { regex: /^\d+(\.\d{1,2})?$/ },
      required: true,
    }),
    pricingMethodRules: 'required',
    pricingRangeEndRules: (rangeStart: string) => ({
      regex: { regex: /^[0-9-]+$/ },
      required: true,
      validRangeEndValidator: rangeStart,
    }),
    validityPeriodDateStartRules: (start: Date, end: Date) => ({
      validDateStartValidator: { start, end },
    }),
    validityPeriodDateEndRules: (start: Date, end: Date) => ({
      validDateEndValidator: { start, end },
    }),
  }
}
