
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import { downloadMetaDiscountAssociations } from '../../../dependency-injection'
import { Discount } from '../../../domain/Discount'
import CFileDownload from '../CFileDownload.vue'

export default defineComponent({
  name: 'CReadonlyMetaDiscountAssociations',
  components: { CFileDownload },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  setup(props) {
    const { discount } = toRefs(props)

    const isLoading = ref(false)
    const downloadAssociationFile = async () => {
      isLoading.value = true
      await downloadMetaDiscountAssociations.execute(discount.value.reference, discount.value.participants!)
      isLoading.value = false
    }

    return {
      hasAssociations: computed(() => Boolean(discount.value.discountablesCount)),
      placeholder: computed(() => `${discount.value.discountablesCount} identifiants d'offres importés`),
      downloadAssociationFile,
      isLoading,
    }
  },
})
