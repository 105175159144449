import { DiscountForm } from '../domain/discountForm/DiscountForm'
import { IDiscountRepository } from '../domain/IDiscountRepository'

export class ValidateGeneralInformations {
  constructor(private readonly discountRepository: IDiscountRepository) {}

  async execute(discount: DiscountForm): Promise<void> {
    await this.discountRepository.validateGeneralInformations(discount)
  }
}
