
import { defineComponent } from 'vue'
import { IdfPagination } from '@invivodf/ui-kit'
import CResultFolders from './articles/CResultFolders.vue'
import CCollectionLoader from './articles/CCollectionLoader.vue'
import { useSearchDiscountables } from '../../composables/association/useSearchDiscountables'

export default defineComponent({
  name: 'CSearchResult',
  components: { CCollectionLoader, CResultFolders, IdfPagination },
  setup(_, { emit }) {
    const { result, currentPage, numberOfPages, noResult, searching, changeWord, changePage } = useSearchDiscountables()

    return {
      articleToggled: (id) => {
        emit('articleToggled', id)
      },
      result,
      currentPage,
      numberOfPages,
      noItems: noResult,
      searching,
      changeWord,
      changePage,
    }
  },
})
