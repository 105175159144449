import { DiscountMeasureUnits, DiscountTypes, DiscountUnits } from '@invivodf/discount-sdk/admin'
import { BaseDiscountForm } from './BaseDiscountForm'
import { RequiredAmount } from './fields/RequiredAmount'
import { DISCOUNT_UNITS } from '../constants/DISCOUNT_UNITS'
import { Discount } from '../Discount'
import { IDiscountFormProps } from './IDiscountFormProps'
import { DiscountPolicy } from '../DiscountPolicy'

export class DiscountFormWithAmount extends BaseDiscountForm {
  public readonly type: DiscountTypes

  public amount: RequiredAmount

  constructor(props: IDiscountFormProps) {
    super(props)
    this.amount = new RequiredAmount(props.amount || null)
    this.type = props.policy.type
  }

  static initForCreate(policy: DiscountPolicy, isMeta: boolean, sellerId: number) {
    return new DiscountFormWithAmount({ policy, isMeta, sellerId })
  }

  static initForUpdate(discount: Discount, sellerId: number) {
    return new DiscountFormWithAmount({ ...discount, sellerId })
  }

  static initForDuplicate(discount: Discount, sellerId: number) {
    return new DiscountFormWithAmount({
      ...discount,
      sellerId,
      id: undefined,
      reference: undefined,
    })
  }

  public hasErrors(): boolean {
    return this.hasGeneralInformationsErrors() || this.hasAmountErrors() || this.hasPromoCodeErrors()
  }

  private hasAmountErrors() {
    return this.amount.errors.length > 0
  }

  public isMeasureUnitSetToAnyAndNotEditable(): boolean {
    return this.isSimpleDiscount() && this.unit.validatedValue === DISCOUNT_UNITS[1].value
  }

  public isMeasureUnitSetToUnitAndNotEditable(): boolean {
    return this.isFixedPriceDiscount()
  }

  public isUnitSetToEuroAndNotEditable(): boolean {
    return this.isFixedPriceDiscount()
  }

  public toDiscount(): Discount {
    this.validate()
    if (this.hasErrors()) {
      throw new Error(`Can't get a discount from an incomplete form.`)
    }

    return {
      id: this.id ?? '',
      internalName: this.internalName.validatedValue,
      measureUnit: this.measureUnit ? (this.measureUnit.validatedValue as DiscountMeasureUnits) : null,
      discountableList: [],
      discountablesCount: 0,
      publicName: this.publicName.validatedValue,
      reference: this.reference.validatedValue,
      segment: this.segment.validatedValue,
      amount: this.amount.validatedValue!,
      unit: this.unit.validatedValue as DiscountUnits,
      validityEndDate: this.validityEndDate.validatedValue,
      validityStartDate: this.validityStartDate.validatedValue!,
      disabledDate: null,
      promoCode: this.promoCode.validatedValue,
      isFlash: this.isFlash.validatedValue,
      isSales: this.isSales.validatedValue,
      isMeta: this.isMeta,
      nbOverallUsesMax: this.nbOverallUsesMax.validatedValue,
      nbCustomerUsesMax: this.nbCustomerUsesMax.validatedValue,
      participants: this.participants,
      policy: new DiscountPolicy(this.type, this.scope),
      groupSize: this.groupSize.validatedValue,
    }
  }

  validate(): void {
    this.validateGeneralInformations()
    this.amount.validate(this.unit.validatedValue)
  }
}
