import { ArticleRepository } from '../domain/article.repository'

interface DeleteArticleUsecase {
  (params: { partnerId: number; slug: string }): Promise<void>
}

interface Dependencies {
  articleRepository: ArticleRepository
}

export function createDeleteArticleUseCase(deps: Dependencies): DeleteArticleUsecase {
  return deps.articleRepository.deleteArticle
}
