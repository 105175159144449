import { DateTime } from 'luxon'
import { DiscountScopes, DiscountTypes, DiscountUnits } from '@invivodf/discount-sdk/admin'
import { RequiredString } from './fields/validator/RequiredString'
import { RequiredValidityStartDate } from './fields/RequiredValidityStartDate'
import { NotRequiredValidityEndDate } from './fields/NotRequiredValidityEndDate'
import { RequiredAllowedString } from './fields/RequiredAllowedString'
import { MEASURE_UNITS } from '../constants/MEASURE_UNITS'
import { IDiscountFormProps } from './IDiscountFormProps'
import { NotRequiredPromoCode } from './fields/NotRequiredPromoCode'
import { Discount } from '../Discount'
import { NotRequiredSegment } from './fields/NotRequiredSegment'
import { RequiredProperty } from './fields/validator/RequiredProperty'
import { FormModes } from '../constants/FormModes'
import { NullableRequiredUses } from './fields/NullableRequiredUses'
import { NullableRequiredGroupSize } from './fields/NullableRequiredGroupSize'

export abstract class BaseDiscountForm {
  public id?: string

  public isMeta: boolean

  public participants: number[]

  public reference: RequiredString

  public segment: NotRequiredSegment

  public validityStartDate: RequiredValidityStartDate

  public validityEndDate: NotRequiredValidityEndDate

  public internalName: RequiredString

  public publicName: RequiredString

  public measureUnit: RequiredAllowedString | null

  public unit: RequiredAllowedString

  public scope: DiscountScopes

  public promoCode: NotRequiredPromoCode

  public isFlash: RequiredProperty<boolean>

  public isSales: RequiredProperty<boolean>

  public nbOverallUsesMax: NullableRequiredUses

  public nbCustomerUsesMax: NullableRequiredUses

  public groupSize: NullableRequiredGroupSize

  public type: DiscountTypes

  protected constructor(props: IDiscountFormProps) {
    this.id = props.id
    this.isMeta = props.isMeta
    this.scope = props.policy.scope
    this.type = props.policy.type
    this.participants = this.isMeta ? props.participants ?? [] : [props.sellerId]
    this.reference = new RequiredString(props.reference || '', { errorMessage: 'Le champ référence est obligatoire' })
    this.segment = new NotRequiredSegment(props.segment || null)
    this.internalName = new RequiredString(props.internalName || '', {
      errorMessage: 'Le champ nom interne est obligatoire',
    })
    this.publicName = new RequiredString(props.publicName || '', {
      errorMessage: 'Le champ nom public est obligatoire',
    })
    this.unit = new RequiredAllowedString(props.unit || DiscountUnits.Euro, {
      errorMessage: `Le champ type d'unité de la remise est obligatoire`,
      allowed: Object.values(DiscountUnits),
      allowedErrorMessage: `Le champ type d'unité de la remise doit être € ou %`,
    })
    this.validityStartDate = new RequiredValidityStartDate(
      props.validityStartDate || DateTime.now().startOf('day').plus({ day: 1 }).toJSDate(),
    )
    this.validityEndDate = new NotRequiredValidityEndDate(props.validityEndDate || null)
    this.measureUnit =
      this.scope === DiscountScopes.Cart
        ? null
        : new RequiredAllowedString(props.measureUnit || '', {
            errorMessage: `Le champ unité de mesure est obligatoire`,
            allowed: Object.values(MEASURE_UNITS).map((unit) => unit.value),
            allowedErrorMessage: `Le champ unité de mesure n'est pas valide`,
          })
    this.promoCode = new NotRequiredPromoCode(props.promoCode || null)
    this.isFlash = new RequiredProperty<boolean>(props.isFlash || false, {
      errorMessage: `Le champ vente flash est obligatoire`,
    })
    this.isSales = new RequiredProperty<boolean>(props.isSales || false, {
      errorMessage: `Le champ soldes est obligatoire`,
    })
    this.nbOverallUsesMax = new NullableRequiredUses(props.nbOverallUsesMax || null)
    this.nbCustomerUsesMax = new NullableRequiredUses(props.nbCustomerUsesMax || null)
    this.groupSize = new NullableRequiredGroupSize(props.groupSize || null)
  }

  public get mode(): FormModes {
    return this.id ? FormModes.UPDATE : FormModes.CREATE
  }

  public isPromoCodeEditable(): boolean {
    return this.mode === FormModes.CREATE || this.isUpcoming()
  }

  public isPromoCodeOptional(): boolean {
    return !this.promoCode.validatedValue || this.mode === FormModes.CREATE
  }

  public hasGeneralInformationsErrors(): boolean {
    return (
      this.reference.failed ||
      this.validityStartDate.failed ||
      this.validityEndDate.failed ||
      this.internalName.failed ||
      this.publicName.failed ||
      (this.measureUnit ? this.measureUnit.failed : false) ||
      this.unit.failed ||
      this.isFlash.failed ||
      this.isSales.failed
    )
  }

  public hasPromoCodeErrors(): boolean {
    return this.promoCode.failed
  }

  public isDiscountWithAmount(): boolean {
    return [DiscountTypes.FixedPrice, DiscountTypes.Simple].includes(this.type)
  }

  public isSimpleDiscount(): boolean {
    return this.type === DiscountTypes.Simple
  }

  public isFixedPriceDiscount(): boolean {
    return this.type === DiscountTypes.FixedPrice
  }

  abstract isMeasureUnitSetToAnyAndNotEditable(): boolean

  abstract isMeasureUnitSetToUnitAndNotEditable(): boolean

  abstract isUnitSetToEuroAndNotEditable(): boolean

  abstract validate(): void

  public validateGeneralInformations(): void {
    this.reference.validate()
    this.validityStartDate.validate()
    this.validityEndDate.validate()
    this.internalName.validate()
    this.publicName.validate()
    if (this.measureUnit) this.measureUnit.validate()
    this.unit.validate()
    this.isFlash.validate()
    this.isSales.validate()
  }

  public validatePromoCode(): void {
    this.promoCode.validate()
  }

  public associationStepRequired(): boolean {
    return [DiscountScopes.Article, DiscountScopes.Group].includes(this.scope)
  }

  public isUpcoming(): boolean {
    if (this.validityStartDate.validatedValue) {
      return this.validityStartDate.validatedValue > new Date()
    }
    return false
  }

  setMeta(value: boolean) {
    this.isMeta = value
  }

  setParticipants(participants: number[]) {
    if (this.isMeta) {
      this.participants = participants
    }
  }
}
