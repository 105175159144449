export const isSameDay = (value, now) =>
  value.getDate() === now.getDate() && value.getMonth() === now.getMonth() && value.getFullYear() === now.getFullYear()

const isDayBefore = (value, now) => {
  // Copy "now"
  const dayBeforeNow = new Date(now)
  dayBeforeNow.setUTCDate(now.getUTCDate() - 1)
  return (
    value.getDate() === dayBeforeNow.getDate() &&
    value.getMonth() === dayBeforeNow.getMonth() &&
    value.getFullYear() === dayBeforeNow.getFullYear()
  )
}

export default function date(value, { locale = 'fr-FR', now = new Date() } = {}) {
  if (!value) {
    return ''
  }
  const dateValue = new Date(value)
  if (isSameDay(dateValue, now)) {
    return "Aujourd'hui"
  }
  if (isDayBefore(dateValue, now)) {
    return 'Hier'
  }
  return dateValue.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}
