/* eslint-disable class-methods-use-this */
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import * as stockMapper from './stock.mapper'

class StockMovementService {
  constructor({ productApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1/stock-movements`, { timeout: 20000 })
  }

  async post(stockMovement) {
    const response = await this.api.post('', [stockMapper.mapToApi(stockMovement)])
    return stockMapper.mapFromApi((response.data && response.data[0]) || {})
  }
}

export default StockMovementService
