import { ArticleResponseApiV2 } from '@aladin/shared-universcoop-sdk'
import { Article } from '../domain/article'
import { ArticleRepository } from '../domain/article.repository'

interface CreateArticleUsecase {
  (params: { partnerId: number; article: Article }): Promise<ArticleResponseApiV2>
}

interface Dependencies {
  articleRepository: ArticleRepository
}

export function createCreateArticleUsecase(deps: Dependencies): CreateArticleUsecase {
  return deps.articleRepository.createArticle
}
