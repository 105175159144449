<template>
  <div class="parameters-list">
    <div class="parameters-list__heading">
      <div class="container">
        <h1>{{ $t('ctx.parameters.title') }}</h1>
      </div>
    </div>
    <PParametersList :sections="sections" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { PParametersList } from '@invivodf/common/src/contexts/parameters'
import { useParameters } from '../../application/composables/useParameters'

export default defineComponent({
  name: 'VParametersList',
  components: { PParametersList },
  setup() {
    const { sections } = useParameters()

    return {
      sections,
    }
  },
})
</script>

<style scoped lang="scss">
.parameters-list {
  display: flex;
  flex-direction: column;
}
.parameters-list__heading {
  padding: 3em 0;
  h1 {
    font-weight: bold;
    font-size: 1.8em;
  }
}
</style>
