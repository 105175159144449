import { ImportReport } from '../../parameters'
import { IImportReportRepository } from '../domain/IImportReportRepository'

export class RetrieveImportReport {
  constructor(public readonly importReportRepository: IImportReportRepository) {}

  async execute(sellerId: number, id: number): Promise<ImportReport> {
    return this.importReportRepository.retrieveImportReport(sellerId, id)
  }
}
