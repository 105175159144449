import { DiscountTypes } from '@invivodf/discount-sdk'
import { computed, ComputedRef, inject, onMounted, provide, ref, Ref, watch } from 'vue'
import { useDiscount } from './useDiscount'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import { DiscountFormWithAmount } from '../../domain/discountForm/DiscountFormWithAmount'
import { DiscountFormWithScale } from '../../domain/discountForm/DiscountFormWithScale'
import { Discount } from '../../domain/Discount'
import { isSuperAdmin } from './isSuperAdmin'
import { DiscountPolicy } from '../../domain/DiscountPolicy'
import { useSeller } from '../../../../application/composables/useSeller'

export const DISCOUNT_FORM_KEY = Symbol('discountForm')

interface DiscountFormProvided {
  discountForm: Ref<DiscountForm | undefined>
}

export interface ReactiveDiscountForm extends DiscountFormProvided {
  associationStepRequired: ComputedRef<boolean>
  discountType: ComputedRef<DiscountTypes | undefined>
}

const getDiscountForm = (type: DiscountTypes) =>
  ({
    [DiscountTypes.Simple]: DiscountFormWithAmount,
    [DiscountTypes.FixedPrice]: DiscountFormWithAmount,
    [DiscountTypes.Quantitative]: DiscountFormWithScale,
    [DiscountTypes.OnRevenue]: DiscountFormWithScale,
  })[type]

const createFormFactory = (discountPolicy: DiscountPolicy, isMeta: boolean, sellerId: number) =>
  getDiscountForm(discountPolicy.type).initForCreate(discountPolicy, isMeta, sellerId)

const updateFormFactory = (discount: Discount, sellerId: number) =>
  getDiscountForm(discount.policy.type).initForUpdate(discount, sellerId)

const duplicateFormFactory = (discount: Discount, sellerId: number) =>
  getDiscountForm(discount.policy.type).initForDuplicate(discount, sellerId)

export const loadForm = (
  id: string | null,
  discountForm: Ref<DiscountForm | undefined>,
  policy: DiscountPolicy,
  shouldDuplicate: boolean,
) => {
  const seller = useSeller()

  if (id) {
    const { discount } = useDiscount(id)
    watch(discount, () => {
      if (!discount.value) return
      if (discount.value.policy.id !== policy.id) throw new Error('Discount policy mismatch')

      discountForm.value = shouldDuplicate
        ? duplicateFormFactory(discount.value, seller.value.id)
        : updateFormFactory(discount.value, seller.value.id)
    })
  } else {
    onMounted(async () => {
      const isMeta = await isSuperAdmin()
      discountForm.value = createFormFactory(policy, isMeta, seller.value.id)
    })
  }
}

export const provideForm = (policy: DiscountPolicy, id: string | null, duplicate: boolean) => {
  const discountForm = ref(undefined) as Ref<DiscountForm | undefined>

  loadForm(id, discountForm, policy, duplicate)
  provide<DiscountFormProvided>(DISCOUNT_FORM_KEY, { discountForm })
}

export const useForm = (): ReactiveDiscountForm => {
  const { discountForm } = inject<DiscountFormProvided>(DISCOUNT_FORM_KEY)!

  const isAssociationStepRequired = computed(() => !!discountForm.value?.associationStepRequired())
  const discountType = computed(() => discountForm.value?.type)

  return {
    discountForm,
    associationStepRequired: isAssociationStepRequired,
    discountType,
  }
}
