
import { computed, ComputedRef, defineComponent } from 'vue'
import { IdfPictogram } from '@invivodf/ui-kit'
import { useLogisticOfferRules, usePickerLocaleData, useLogisticOfferWording } from '../../composables'
import { PickerProps, ValidityPeriod } from '../../../domain'
import PDatePicker from '../PDatePicker/PDatePicker.vue'

export default defineComponent({
  name: 'PDateRangePicker',
  emits: ['input'],
  components: {
    IdfPictogram,
    PDatePicker,
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: Object as () => ValidityPeriod,
      default: () => {
        return { start: undefined, end: undefined }
      },
    },
  },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const { localeData } = usePickerLocaleData()
    const pickerStartDate: ComputedRef<PickerProps> = computed(() => {
      return { startDate: props.value.start, endDate: props.value.start }
    })
    const pickerEndDate: ComputedRef<PickerProps> = computed(() => {
      return { startDate: props.value.end, endDate: props.value.end }
    })
    const onSelect = ({ startDate, endDate }: PickerProps): void => {
      emit('input', { start: startDate, end: endDate })
    }
    const onSelectStartDate = ({ startDate }: PickerProps): void => {
      onSelect({ startDate, endDate: pickerEndDate.value.endDate })
    }
    const onSelectEndDate = ({ endDate }: PickerProps): void => {
      onSelect({ startDate: pickerStartDate.value.startDate, endDate })
    }
    const onReset = (): void => {
      onSelect({ startDate: undefined, endDate: undefined })
    }
    return {
      localeData,
      onReset,
      onSelectEndDate,
      onSelectStartDate,
      pickerStartDate,
      pickerEndDate,
      rules: useLogisticOfferRules(),
      wording,
    }
  },
})
