import { ComputedRef, Ref, computed } from 'vue'
import { DISCOUNT_UNITS } from '../../domain/constants/DISCOUNT_UNITS'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import { useDiscountInput } from './useDiscountInput'
import { DiscountUnitDisplayed, getAllowedUnits } from '../../domain/getAllowedUnits'

export interface IUseDiscountUnitInput {
  error: Ref<string>
  value: Ref<string>
  validate: () => void
  discountUnits: ComputedRef<DiscountUnitDisplayed[]>
  isInputDisabled: ComputedRef<boolean>
}

export const useDiscountUnitInput = (discountForm: Ref<DiscountForm>): IUseDiscountUnitInput => {
  const { error, value, validate } = useDiscountInput(discountForm, 'unit')

  const discountUnits = computed(() => getAllowedUnits(discountForm.value.type))

  const isInputDisabled = computed(() => discountForm.value.isUnitSetToEuroAndNotEditable())

  return { error, value, validate, discountUnits, isInputDisabled }
}
