import { render, staticRenderFns } from "./CAmountAndUnitInput.vue?vue&type=template&id=6cf04b68&scoped=true"
import script from "./CAmountAndUnitInput.vue?vue&type=script&lang=js"
export * from "./CAmountAndUnitInput.vue?vue&type=script&lang=js"
import style0 from "./CAmountAndUnitInput.vue?vue&type=style&index=0&id=6cf04b68&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf04b68",
  null
  
)

export default component.exports