/* eslint-disable camelcase */

import {
  CooperativeProductResponse,
  CooperativeVariantSchema,
  OfferSchema,
  ProductInPackSchema,
} from '@b2ag/types-api-schemas'

import { CooperativeProduct, CooperativeVariant, FlattenedPackProduct, Product, PRODUCT_TYPES } from '../product'

import { OfferForQuota } from '../quota-config.business'

export const defaultShippingWays = {
  pickup_point: {
    title: 'Livraison relais',
    kind: 'pickup_point',
  },
  farm: {
    title: 'Livraison à la ferme',
    kind: 'farm',
  },
  click_and_collect: {
    title: 'Retrait en magasin',
    kind: 'click_and_collect',
  },
}

function mapShippingWayFromApi(shippingWay: string): { kind: string; title: string } {
  return defaultShippingWays[shippingWay] || {}
}

const mapVariantFromApi = (
  variant: CooperativeVariantSchema,
  productKind: string,
  productId: string,
): CooperativeVariant => {
  const { taxes } = variant as any
  return {
    id: variant.id,
    articleReferenceSupplier: variant.article_reference_supplier,
    ballType: variant.ball_type,
    ean_code: variant.ean_code,
    supplier: variant.supplier,
    measure_quantity: variant.measure_quantity,
    measure_unit: variant.measure_unit,
    name: variant.name,
    type: variant.type,
    sellable: variant.sellable,
    weight: variant.weight,
    published: variant.published,
    packaging: variant.packaging,
    associated: variant.associated,
    cooperativeVariantId: variant.cooperative_variant_id,
    stockManagementType: variant.stock_management_type,
    taxes: taxes && {
      tva: taxes.tva,
      rpd: taxes.rpd,
      rpdUnit: taxes.rpd_unit,
    },
    tearResistance: variant.tear_resistance,
    knotResistance: variant.knot_resistance,
    perforationResistance: variant.perforation_resistance,
    flowRateInLPerMn: variant.flow_rate_in_l_per_mn,
    weightInGrammPerSquareMeter: variant.weight_in_gramm_per_square_meter,
    isSoldAsUnit: variant.is_sold_as_unit,
    cropProtectionLabel: variant.crop_protection_label,
    precocity: variant.precocity,
    productKind,
    productId,
  }
}

function isPackProduct(product: CooperativeProduct): boolean {
  return product.type === PRODUCT_TYPES.PACK
}

function flattenPackProduct(
  packProduct: Product | CooperativeProduct,
  productsInPack: ProductInPackSchema[],
): FlattenedPackProduct {
  const flattenedProduct = { ...packProduct } as any as FlattenedPackProduct

  const fieldsToFlatten = ['formulation', 'market_authorization', 'toxicities', 'usages_by_crops', 'active_substances']

  fieldsToFlatten.forEach((field) => {
    flattenedProduct[field] = productsInPack.map((product) => {
      if (field === 'usages_by_crops') {
        return { id: product.id, product_name: product.name, value: product[field] }
      }
      return { product_name: product.name, value: product[field] }
    })
  })

  // eslint-disable-next-line camelcase
  flattenedProduct.safetySheetUrls = productsInPack
    // eslint-disable-next-line camelcase
    .filter((product) => product.safety_sheet_urls)
    .map((product) => ({
      product_name: product.name,
      // eslint-disable-next-line camelcase
      value: product.safety_sheet_urls,
    }))

  return flattenedProduct
}

export const mapFromApi = (product?: CooperativeProductResponse): CooperativeProduct => {
  if (!product) return {} as CooperativeProduct
  const {
    _id,
    type,
    active_substances,
    nutritive_elements,
    bio_control,
    // @ts-ignore EXPLIQUER POURQUOI CE TS IGNORE
    supplier,
    supplier_logo,
    supplier_universe_page_link,
    supplier_score,
    is_supplier_highlighted,
    formulation,
    images,
    safety_sheet_urls,
    documentation,

    // special Equipment
    characteristics,

    // special Seed
    provider,
    profile,
    chlortoluron,
    resistance,
    generation,
    alternativity,
    mix_components,

    // special Nutrition Amendment
    cultures_with_vegetative_stage,
    origin,
    mixed,
    contribution_mode,
    standard,
    stimulating_substance,
    nutritive_elements_unit,
    is_restricted_explosives_precursor,

    kind,
    market_authorization,
    name,
    organic,
    targets,
    usages_by_crops,
    variants = [],
    categories,
    family,
    toxicities,
    cooperative_review,
    is_highlighted,
    associated_products,
    exclusive,
    crop_name,
    destination,
    description,
    shape,
  } = product
  return {
    _id: _id!,
    type: type as PRODUCT_TYPES,
    active_substances,
    nutritive_elements,
    bio_control,
    supplier,
    supplierLogo: supplier_logo,
    supplierUniversePageLink: supplier_universe_page_link,
    supplierScore: supplier_score,
    formulation,
    images,
    // @ts-ignore EXPLIQUER POURQUOI CE TS IGNORE
    isRestrictedExplosivesPrecursor: is_restricted_explosives_precursor,
    isSupplierHighlighted: is_supplier_highlighted,
    safetySheetUrls: safety_sheet_urls || (documentation && documentation.safety_sheet_urls),
    productSheetUrls: documentation && documentation.product_sheet_urls,
    productManualUrls: documentation && documentation.product_manual_urls,
    characteristics,
    kind,
    market_authorization,
    name,
    organic,
    targets,
    usages_by_crops,
    variants: variants.map((variant) => mapVariantFromApi(variant, kind, _id!)),
    categories,
    family,
    toxicities,
    cooperativeReview: cooperative_review,
    isHighlighted: is_highlighted,
    // @ts-ignore EXPLIQUER POURQUOI CE TS IGNORE
    associatedProducts: associated_products,
    exclusive,
    cropName: crop_name,
    destination,
    provider,
    profile,
    chlortoluron,
    resistance,
    generation,
    alternativity,
    mixComponents: mix_components,
    description,
    shape,
    culturesWithVegetativeStage: cultures_with_vegetative_stage,
    origin,
    mixed,
    contributionMode: contribution_mode,
    standard,
    stimulatingSubstance: stimulating_substance,
    nutritiveElementsUnit: nutritive_elements_unit,
  }
}

export const mapProductFromApiAndFlattenPackProduct = (
  responseProduct?: CooperativeProductResponse,
): CooperativeProduct => {
  const product = mapFromApi(responseProduct)
  return isPackProduct(product) ? flattenPackProduct(product, responseProduct?.products_in_pack!) : product
}

export const mapMeasureUnit = (str: string): string => (str === 'l' ? 'L' : str)

export const mapOfferFromOfferApi = (offer: OfferSchema): OfferForQuota => ({
  offerId: offer.offer_id,
  validityEndDate: offer.validity_end_date ? new Date(offer.validity_end_date) : undefined,
  shippingStartDate: offer.shipping_start_date ? new Date(offer.shipping_start_date) : undefined,
  shippingEndDate: offer.shipping_end_date ? new Date(offer.shipping_end_date) : undefined,
  shippingMethodList: offer.shipping_method_list?.map(mapShippingWayFromApi),
  segmentList: offer.segment_list,
})
