
import { defineComponent, toRefs, PropType, computed } from 'vue'
import { DateTime } from 'luxon'
import PAlert from '../../../../../application/presenters/PAlert.vue'
import { IUseDiscountInput } from '../../composables/useDiscountInput'
import { luxonISOOptionsForDatetimeInput } from '../../luxon.constants'

export default defineComponent({
  name: 'CDiscountPeriodInput',
  components: { PAlert },
  props: {
    validityStartDate: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    validityEndDate: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
  setup(props) {
    const { validityStartDate, validityEndDate } = toRefs(props)

    return {
      validityStartDateInput: computed<string>({
        get() {
          return DateTime.fromJSDate(validityStartDate.value.value.value).toISO(luxonISOOptionsForDatetimeInput)
        },
        set(newValue) {
          validityStartDate.value.value.value = new Date(newValue)
        },
      }),
      validityEndDateInput: computed<string>({
        get() {
          return DateTime.fromJSDate(validityEndDate.value.value.value).toISO(luxonISOOptionsForDatetimeInput)
        },
        set(newValue) {
          validityEndDate.value.value.value = new Date(newValue)
        },
      }),
    }
  },
})
