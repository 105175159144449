import CHeaderLogo from '@/components/CHeaderLogo/CHeaderLogo.vue'
import PAppHeader from '@/presenters/PAppHeader/PAppHeader.vue'

export default {
  name: 'VMinimalLayout',
  components: {
    CHeaderLogo,
    PAppHeader,
  },
  data() {
    return {
      errorMessage: '',
    }
  },
  methods: {
    onChildError(errorMessage) {
      this.errorMessage = errorMessage
    },
  },
  beforeRouteLeave(to, from, next) {
    this.errorMessage = ''
    next()
  },
}
