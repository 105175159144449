export function mapFromApi(cooperativeVariant = {}) {
  return {
    id: cooperativeVariant.id,
    stockManagementType: cooperativeVariant.stock_management_type,
    cooperativeId: cooperativeVariant.cooperative_id,
    published: cooperativeVariant.published,
    variantId: cooperativeVariant.variant_id,
  }
}

export function mapToApi(cooperativeVariant = {}) {
  return {
    id: cooperativeVariant.id,
    stock_management_type: cooperativeVariant.stockManagementType,
    cooperative_id: cooperativeVariant.cooperativeId,
    published: cooperativeVariant.published,
    variant_id: cooperativeVariant.variantId,
  }
}
