
import { computed, ref, PropType } from 'vue'
import { PaymentDueDate } from '@/core'

import { useValidateForm } from '@/composables/useValidateForm'
import { useStore } from '@/composables/useStore'
import { usePaymentDueDates } from '@/composables/usePaymentDueDates'
import { IdfButton } from '@invivodf/ui-kit'
import PWarningMessage from '../../presenters/PWarningMessage.vue'

export default {
  name: 'CPaymentDueDatesForm',
  components: { PWarningMessage, IdfButton },
  props: {
    paymentDueDate: {
      type: Object as PropType<PaymentDueDate>,
      default: () => {},
    },
    step: {
      type: String,
    },
  },
  setup(props, { emit, slots }) {
    const store = useStore()
    const { savePaymentDueDate, updatePaymentDueDate } = usePaymentDueDates()
    const { fields, form } = useValidateForm({
      rate: {
        value: props.paymentDueDate?.rate || null,
        validators: {
          required: true,
        },
      },
      title: {
        value: props.paymentDueDate?.title || '',
        validators: {
          required: true,
        },
      },
      reference: {
        value: props.paymentDueDate?.reference || '',
        validators: {
          required: true,
        },
      },
      description: {
        value: props.paymentDueDate?.description || '',
        validators: {
          required: true,
        },
      },
    })

    const descriptionInput = ref(null)

    const addPaymentDueDateHandler = () => emit('paymentDueDateAdded')

    const reset = () => {
      Object.keys(fields).forEach((name) => {
        fields[name].value = null
      })
      // @ts-ignore EXPLIQUEZ POURQUOI CE TS IGNORE
      descriptionInput.value.value = null
      emit('canceledForm')
    }

    const onSubmit = async () => {
      if (!form.state.invalid) {
        const payload = {
          rate: fields.rate.value,
          title: fields.title.value,
          reference: fields.reference.value,
          description: fields.description.value,
          // startDate: startDate.value,
          // endDate: endDate.value,
          // isDefault: isDefault.value,
        }

        let toastObject
        if (props.step === 'create') {
          toastObject = await savePaymentDueDate(payload)
        }
        if (props.step === 'update') {
          toastObject = await updatePaymentDueDate(payload)
        }
        if (toastObject.type === 'success') {
          addPaymentDueDateHandler()
          reset()
        }
        await store.dispatch('setToast', toastObject)
      }
    }

    const hasHeaderSlot = computed(() => {
      return !!slots.header
    })

    const disableReferenceUpdates = computed(() => {
      return props.step === 'update'
    })

    return {
      ...fields,
      onSubmit,
      reset,
      addPaymentDueDateHandler,
      form,
      hasHeaderSlot,
      disableReferenceUpdates,
      descriptionInput,
    }
  },
}
