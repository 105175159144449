import { Ref, ref, watch } from 'vue'

export const useUsesInputs = (nbOverallUses: Ref<number | null>, nbCustomerUses: Ref<number | null>) => {
  const isOverallUsesEnabled = ref(!!nbOverallUses.value)
  const isCustomerUsesEnabled = ref(!!nbCustomerUses.value)

  watch(isOverallUsesEnabled, (value) => {
    if (!value) nbOverallUses.value = null
  })
  watch(isCustomerUsesEnabled, (value) => {
    if (!value) nbCustomerUses.value = null
  })

  return { isOverallUsesEnabled, isCustomerUsesEnabled }
}
