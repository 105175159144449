<template>
  <PImportReports :loading="loading" :import-reports="importReports" :error="error" />
</template>

<script>
import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { PImportReports } from '@invivodf/common/src/contexts/parameters'
import { importReportsService } from '@/services'

export default {
  name: 'VImportReports',
  components: { PImportReports },
  setup() {
    const seller = useSeller()
    return { sellerId: seller.value.id }
  },
  data: () => ({
    error: '',
    loading: true,
    importReports: [],
  }),
  async mounted() {
    await this.fetchReports()
  },
  methods: {
    async fetchReports() {
      this.loading = true
      try {
        this.importReports = await importReportsService.listAll(this.sellerId)
        if (this.error) {
          this.error = ''
        }
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
