import { ImportReport } from '@invivodf/common/src/contexts/parameters'
import { IImportReportRepository } from '../domain/IImportReportRepository'
import { TranslateAndDateReportMapper } from './mappers/TranslateAndDateReportMapper'
import { ImportReportDTO } from './datasource/ImportReportDTO'

interface AssociationImportReportDataSource {
  findOneAssociationImportReport(sellerId: number, id: number): Promise<ImportReportDTO>
  findAllAssociationImportReports(sellerId: number): Promise<ImportReportDTO[]>
}

export default class AssociationImportReportRepository implements IImportReportRepository {
  private readonly mapper = new TranslateAndDateReportMapper()

  constructor(private readonly dataSource: AssociationImportReportDataSource) {}

  async listImportReports(sellerId: number): Promise<ImportReport[]> {
    const reports = await this.dataSource.findAllAssociationImportReports(sellerId)
    return reports.map((report) => this.mapper.map(report))
  }

  async retrieveImportReport(sellerId: number, id: number): Promise<ImportReport> {
    const report = await this.dataSource.findOneAssociationImportReport(sellerId, id)
    return this.mapper.map(report)
  }
}
