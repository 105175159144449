import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import { mapFromApi } from './cooperative.mapper'
import ImportService from './import.service'

class CooperativeService {
  constructor({ userApiHost }) {
    this.userApiHost = userApiHost
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1/cooperatives`)
  }

  async get(id) {
    const response = await this.api.get(`/${id}`)
    return mapFromApi(response.data)
  }

  async downloadMemberships(cooperativeId, cooperativeName) {
    const response = await this.api.get(`/${cooperativeId}/memberships`, { headers: { accept: 'text/csv' } })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(cooperativeName, 'memberships'))
    document.body.appendChild(link)
    link.click()
  }

  async downloadShippingAddresses(cooperativeId, cooperativeName) {
    const response = await this.api.get(`/${cooperativeId}/shipping_addresses`, { headers: { accept: 'text/csv' } })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(cooperativeName, 'addresses'))
    document.body.appendChild(link)
    link.click()
  }

  async downloadTechnicalSales(cooperativeId, cooperativeName) {
    const response = await this.api.get(`/${cooperativeId}/technical-sales`, { headers: { accept: 'text/csv' } })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(cooperativeName, 'technicians'))
    document.body.appendChild(link)
    link.click()
  }
}

export default CooperativeService
