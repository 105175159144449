
import { defineComponent } from 'vue'
import CLogisticOfferEditor from '@invivodf/common/src/contexts/logistic-offer/application/components/CLogisticOfferEditor/CLogisticOfferEditor.vue'

export default defineComponent({
  name: 'VLogisticOfferEditor',
  components: { CLogisticOfferEditor },
  props: {
    reference: {
      type: String,
    },
  },
})
