
import { ref, watch } from 'vue'
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { useSearchDiscountables } from '../../composables/association/useSearchDiscountables'

export default {
  name: 'CAssociationsInputsHeader',
  components: { IdfInput, IdfStandardInputs },
  setup() {
    const query = ref('')

    let timeout

    const { changeWord } = useSearchDiscountables()

    watch(query, () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        changeWord(query.value)
      }, 500)
    })

    return {
      labelKeywordResearcher: 'Ajouter à votre catalogue (référence, marque, famille)',
      labelCSVImporter: "Ou ajouter une liste d'articles (import fichier csv)",
      CSVFile: ref(''),
      query,
    }
  },
}
