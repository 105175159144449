import { IDiscountableRepository } from '../domain/IDiscountableRepository'
import { IAssociationDatasource } from './datasource/IAssociation.datasource'
import { IDiscountableDatasource } from './datasource/IDiscountable.datasource'
import { SearchQuery } from '../domain/SearchQuery'
import { SearchResultPage } from '../domain/SearchResultPage'
import { DiscountableMapper } from './mappers/Discountable.mapper'
import { SearchResultGroupMapper } from './mappers/SearchResultGroupMapper'
import { Discountable } from '../domain/Discountable'

export class DiscountableRepository implements IDiscountableRepository {
  constructor(
    private readonly discountableDatasource: IDiscountableDatasource,
    private readonly associationDataSource: IAssociationDatasource,
  ) {}

  async searchDiscountableGroup(partnerId: number, { page, count, query }: SearchQuery): Promise<SearchResultPage> {
    const { total, results: resultGroupDTOs } = await this.discountableDatasource.searchDiscountableGroups(
      partnerId,
      query,
      page,
      count,
    )

    const discountableIDs = resultGroupDTOs
      .flatMap((groupDTO) => groupDTO.discountables)
      .map((discountableDTO) => discountableDTO.id)

    const [discountables, associations] = await Promise.all([
      this.discountableDatasource.retrieveDiscountablesByIds(partnerId, discountableIDs),
      this.associationDataSource.getAssociationByDiscountableIds(partnerId, discountableIDs),
    ])

    const results = new SearchResultGroupMapper().fromDTO(resultGroupDTOs, discountables, associations)

    return { page, count, total, results }
  }

  async getDiscountablesByReference(sellerId: number, reference: string): Promise<Discountable[]> {
    const associationDTOs = await this.associationDataSource.getAssociationsByReference(sellerId, reference)
    const discountableIds = associationDTOs.map((dto) => dto.discountableId)

    const discountableDTOs = discountableIds.length
      ? await this.discountableDatasource.retrieveDiscountablesByIds(sellerId, discountableIds)
      : []

    return new DiscountableMapper(associationDTOs).fromDTO(discountableDTOs)
  }
}
