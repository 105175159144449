/* eslint-disable */

/** @returns the plural version of the input, according to French morphology */
export function makePlural(word: string) {
  if (/[szx]$/.test(word)) return word

  if (/^(?:ble|éme|landa|lie|pne|sarra)u$/.test(word)) return word + 's'
  if (/au$|eu$|^(?:bij|ch|gen|caill|hib|jouj|p|rip)ou$/.test(word)) return word + 'x'

  if (/^(?:b|cor|ém|gemm|soupir|trav|vant|vitr)ail$/.test(word)) return word.slice(0, -3) + 'aux'
  if (word === 'ail') return 'aulx'

  if (/^(?:av|b|ban|banc|c|carnav|cérémoni|chac|chor|ét|fat|festiv|nat|nav|p|récit|rég|ton|v|virgin)al$/.test(word))
    return word + 's'
  if (/al$/.test(word)) return word.slice(0, -2) + 'aux'

  if (/^(?:œ|oe)il$/.test(word)) return 'yeux'

  return word + 's'
}

export type AgreementObject = Partial<Record<Intl.LDMLPluralRule, string>> & { other: string }

export function frenchPlural<T extends string>(word: T) {
  return { one: word, other: makePlural(word) }
}

export function invariable<T extends string>(word: T) {
  return { one: word, other: word }
}

if (!('PluralRules' in Intl)) {
  // @ts-ignore
  Intl.PluralRules = class PluralRules {
    constructor() {}

    select(n: number) {
      return n >= 0 && n < 2 ? 'one' : 'other'
    }
  }
}

const rules = new Intl.PluralRules('fr-FR')

/** @param word the singular form of a word OR an object with number variations (at least `other`)
 * @returns the singular or plural form, in agreement with the given number */
export function pluralize(word: string | AgreementObject, n: number) {
  if (typeof word === 'string') return rules.select(+n) === 'one' ? word : makePlural(word)
  return word[rules.select(+n)] || word.other
}
