
import { computed, ref, defineComponent, onBeforeMount } from 'vue'
import { IdfTextIcon, IdfCheckbox, IdfButton } from '@invivodf/ui-kit'
import { use18n } from '@invivodf/locales/src/use18n'
import { useUploadCondition } from '../../composables/useUploadCondition/useUploadCondition'
import fileService from '../../../file.service'

export default defineComponent({
  name: 'CUploadCsv',
  components: {
    IdfButton,
    IdfCheckbox,
    IdfTextIcon,
  },
  props: ['csvProperties', 'label', 'url', 'sectionTitle'],
  setup(_, context) {
    const { uploadCondition, toggleUploadAllowed, hasCondition } = useUploadCondition()
    const file: any = ref(null)
    const error = ref('')
    const i18n = use18n()
    const filename = computed(() => {
      return file.value?.name
    })

    const handleFileUpload = (event) => {
      // eslint-disable-next-line prefer-destructuring
      file.value = event.target.files[0]
      error.value = ''
    }
    const submitFile = async () => {
      if (await fileService.isUtf8(file.value)) {
        context.emit('send-file', file.value)
      } else {
        error.value = `${i18n.t('ctx.import.base__default-page-title-prop')} UTF8.`
      }
    }

    onBeforeMount(() => {
      if (uploadCondition.value.allowed) toggleUploadAllowed()
    })

    return { uploadCondition, toggleUploadAllowed, hasCondition, filename, error, handleFileUpload, submitFile }
  },
})
