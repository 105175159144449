import { inject, InjectionKey, provide } from 'vue'
import { ImportReport } from '../../../domain/ImportReport'

export interface ImportReportFetcher {
  fetchOne(id: number): Promise<ImportReport>
  fetchAll(): Promise<ImportReport[]>
  type: string
}

export const importReportSymbol = Symbol('useImportReports')

export const provideImportReportFetchers = (...fetchers: ImportReportFetcher[]) => {
  provide(importReportSymbol, fetchers)
}

export const useImportReports = () => {
  const importReportFetchers: ImportReportFetcher[] = inject(importReportSymbol)!

  const fetchOne = async (type: string, id: number): Promise<ImportReport> => {
    const importReportFetcher = importReportFetchers.find((fetcher) => fetcher.type === type)
    if (!importReportFetcher) throw Error(`Un type de rapport d'import valide doit être utilisé`)
    return importReportFetcher.fetchOne(id)
  }

  const fetchAll = async (): Promise<ImportReport[]> => {
    const importReports = await Promise.all(
      importReportFetchers.map(async (fetcher) => {
        try {
          return await fetcher.fetchAll()
        } catch (err) {
          return []
        }
      }),
    )

    return importReports.flat()
  }

  return { fetchOne, fetchAll }
}
