import { Discount } from '../domain/Discount'
import { DiscountForm } from '../domain/discountForm/DiscountForm'
import { IDiscountRepository } from '../domain/IDiscountRepository'
import { IDiscountDatasource } from './datasource/IDiscount.datasource'
import {
  mapDiscountFormToDiscountForApi,
  mapDiscountFromApiToDiscount,
  mapDiscountPolicyDTOToDomain,
} from './mappers/Discount.mapper'
import { Discountable } from '../domain/Discountable'
import { ListingQuery } from '../domain/listing/ListingQuery'
import { ImportResult } from '../domain/ImportResult'
import { IAssociationDatasource } from './datasource/IAssociation.datasource'
import { mapMetaDiscountAssociationsValidationFromDTO } from './mappers/Association.mapper'
import { MetaDiscountAssociationsValidation } from '../domain/MetaDiscountAssociationsValidation'
import { DiscountPolicy } from '../domain/DiscountPolicy'

export default class DiscountRepository implements IDiscountRepository {
  constructor(
    private readonly discountDataSource: IDiscountDatasource,
    private readonly associationDataSource: IAssociationDatasource,
  ) {}

  async retrieve(id: string, sellerId: number, isRetrievingMeta: boolean): Promise<Discount> {
    const discount = await this.discountDataSource.get(id, sellerId, isRetrievingMeta)
    return mapDiscountFromApiToDiscount(discount)
  }

  async create(discountForm: DiscountForm): Promise<Discount> {
    const discountDTO = mapDiscountFormToDiscountForApi(discountForm)
    const result = await this.discountDataSource.create(discountDTO, discountForm.participants)
    return mapDiscountFromApiToDiscount(result)
  }

  async update(discountForm: DiscountForm): Promise<Discount> {
    const discountDTO = mapDiscountFormToDiscountForApi(discountForm)
    const result = await this.discountDataSource.update(discountDTO)
    return mapDiscountFromApiToDiscount(result)
  }

  async validateGeneralInformations(discountForm: DiscountForm) {
    const discountDTO = mapDiscountFormToDiscountForApi(discountForm)
    await this.discountDataSource.validateGeneralInformations(discountDTO, discountForm.participants)
  }

  async retrieveAuthorizedDiscountPolicy(sellerId: number): Promise<DiscountPolicy[]> {
    const discountPolicyDTO = await this.discountDataSource.getAuthorizedDiscountPolicies(sellerId)
    return discountPolicyDTO.map((dto) => mapDiscountPolicyDTOToDomain(dto))
  }

  async upsertAssociations(
    sellerId: number,
    reference: Discount['reference'],
    discountableIds: Discountable['id'][],
  ): Promise<void> {
    await this.associationDataSource.upsertAssociations(sellerId, reference, discountableIds)
  }

  async delete(sellerId: number, discountId: string, deleteMetaDiscount: boolean) {
    await this.discountDataSource.delete(sellerId, discountId, deleteMetaDiscount)
  }

  async list(query: ListingQuery, isRetrievingMetaDiscounts: boolean) {
    const { discountList, total } = await this.discountDataSource.list(query, isRetrievingMetaDiscounts)
    return { discountList: discountList.map((dto) => mapDiscountFromApiToDiscount(dto)), total }
  }

  async importAssociations(sellerId: number, file: File): Promise<ImportResult> {
    return this.associationDataSource.importAssociations(sellerId, file)
  }

  async downloadAssociations(sellerId: number, sellerName: string): Promise<void> {
    await this.associationDataSource.downloadAssociations(sellerId, sellerName)
  }

  async downloadMetaDiscountAssociations(reference: string, participants: number[]): Promise<void> {
    await this.associationDataSource.downloadMetaDiscountAssociationsByReference(reference, participants)
  }

  async validateMetaDiscountAssociations(
    discountReference: string,
    associationsFile: File,
  ): Promise<MetaDiscountAssociationsValidation> {
    const validation = await this.associationDataSource.validateMetaDiscountAssociations(
      discountReference,
      associationsFile,
    )
    return mapMetaDiscountAssociationsValidationFromDTO(validation)
  }

  async uploadMetaDiscountAssociations(discountId: string, associationsFile: File): Promise<void> {
    await this.associationDataSource.uploadMetaDiscountAssociations(discountId, associationsFile)
  }
}
