
import { defineComponent, ref, watch } from 'vue'
import { IdfTextIcon, IdfButton, IdfLoader } from '@invivodf/ui-kit'
import IdfStep from './IdfStep/IdfStep.vue'
import { useSummaryStep } from '../composables/useSummaryStep'
import { useFormSteps } from '../composables/useFormSteps'
import CDiscountModal from './CDiscountModal.vue'

export default defineComponent({
  name: 'CSummaryStep',
  components: { IdfStep, IdfTextIcon, IdfButton, IdfLoader, CDiscountModal },
  setup() {
    const {
      sellers,
      publicName,
      reference,
      internalName,
      promoCode,
      formattedStartDate,
      formattedEndDate,
      associationsCount,
      saveDiscount,
      isUsingMetaDiscount,
      isLoading,
    } = useSummaryStep()
    const { summaryStep, lockValidSteps, unlockValidSteps } = useFormSteps()

    watch(isLoading, () => {
      if (isLoading.value) lockValidSteps()
      else unlockValidSteps()
    })

    const sellersModal = ref({ show: () => {} })
    const showSellers = () => sellersModal.value.show()

    return {
      sellers,
      publicName,
      reference,
      internalName,
      promoCode,
      formattedStartDate,
      formattedEndDate,
      associationsCount,
      saveDiscount,
      summaryStep,
      sellersModal,
      showSellers,
      isUsingMetaDiscount,
      isLoading,
    }
  },
})
