<template>
  <div class="home">
    <div class="container">
      <h1 class="home__heading">
        {{ welcomeMessage }}
        <p-icon class="home__heading-logo" width="50" height="50" :kind="currentIcon" />
      </h1>
    </div>
    <div class="container-grey">
      <div class="container">
        <div class="home__block">
          <h2 class="home__block-title">1. Ajouter le profil de vos {{ $t('message.memberships') }}</h2>
          <p>
            Pour que vos {{ $t('message.memberships') }} puissent commander sur la plateforme via
            {{ $t('message.yourPartner') }}, vous devez importer un fichier (au format CSV) avec la
            <strong>liste de vos {{ $t('message.memberships') }}</strong
            >. Vous trouverez l’ensemble des informations nécessaires pour constituer votre fichier sur la page d’import
            {{ $t('message.memberships') }}.
          </p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'import-members' }" icon-left="upload"
              >Importer un fichier {{ $t('message.memberships') }}</IdfButton
            >
          </div>
        </div>

        <div class="home__block">
          <h2 class="home__block-title">2. Ajouter les adresses de livraison des {{ $t('message.memberships') }}</h2>
          <p>
            Pour que vos {{ $t('message.memberships') }} puissent choisir une <strong>adresse de livraison</strong> au
            moment de leur achat, vous devez référencer ces adresses en les important.
          </p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'import-shipping-addresses' }" icon-left="upload"
              >Importer un fichier d’adresses de livraison</IdfButton
            >
          </div>
        </div>

        <div class="home__block">
          <h2 class="home__block-title">3. Enrichir votre catalogue</h2>
          <p>
            Afficher à vos {{ $t('message.memberships') }} vos articles en constituant <strong>votre catalogue</strong>.
            Votre catalogue est constitué à partir d’un import article portant les identifiants InVivo disponible dans
            votre référentiel.
          </p>
          <p></p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'import-variants' }" icon-left="upload"
              >Importer vos articles</IdfButton
            >
            <IdfButton kind="link" tag="router-link" :route="{ name: 'search-national' }" icon-left="book-outlined"
              >Consulter le référentiel national</IdfButton
            >
            <IdfButton
              kind="link"
              tag="router-link"
              :route="{ name: 'import-cooperative-custom-products' }"
              icon-left="star"
              >Créer vos produits exclusifs</IdfButton
            >
          </div>
        </div>

        <div class="home__block">
          <h2 class="home__block-title">4. Créer des offres</h2>
          <p>
            Une fois votre catalogue {{ $t('message.partner') }} constitué et une fois l'ensemble des paramètres
            nécessaires ajouté ({{ $t('message.membership') }}, barême de frais de livraison, etc...), vous pouvez
            commencer à <strong>créer des offres</strong> sur vos articles. Consulter vos articles à partir des produits
            disponibles en catalogue.
          </p>
          <div class="home__block-buttons">
            <IdfButton kind="link" tag="router-link" :route="{ name: 'search-cooperative' }" icon-left="store-outlined"
              >Consulter votre catalogue {{ $t('message.partner') }}</IdfButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'
import { computed, defineComponent } from 'vue'
import PIcon from '@invivodf/common/src/application/presenters/PIcon/PIcon.vue'
import { use18n } from '@invivodf/locales/src/use18n'

export default defineComponent({
  name: 'VHome',
  components: {
    PIcon,
    IdfButton,
  },
  setup() {
    const i18n = use18n()
    const environment = window.env.ENVIRONMENT_NAME || ''
    const environmentInformation = ['DEV', 'QUA', 'TEST'].includes(environment) ? `environnement de ${environment}` : ''
    const welcomeMessage = `Bienvenue sur le front ${i18n.$t('message.technician')} ${environmentInformation}`

    const currentIcon = computed(() => {
      const logo = 'logo-aladin'
      if (['DEV', 'QUA', 'TEST'].includes(environment)) {
        return `${logo}-${environment.toLowerCase()}`
      }
      return logo
    })

    return {
      welcomeMessage,
      currentIcon,
    }
  },
})
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.home__heading {
  text-align: center;
  font-weight: bold;
  margin: 2em 0;
}
.home__heading-logo.ds-bo-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-top: -5px;
}
.home__block {
  padding: 30px;
  border: 1px solid var(--idf-color-neutral-200);
  margin-bottom: 20px;
  background-color: white;
}
.home__block-disabled {
  opacity: 0.6;
  background-color: var(--idf-color-neutral-200);
}
.home__block-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1em;
}
.home__block-buttons {
  padding: var(--idf-spacing-4) 0 0;
  display: flex;
  justify-content: center;
  gap: var(--idf-spacing-4);
}
</style>
