import { DiscountUnits, DiscountMeasureUnits, DiscountTypes } from '@invivodf/discount-sdk/admin'
import { DiscountPolicy } from './DiscountPolicy'

export interface ISlice {
  min: number
  max: number | null
  discount: number
}

export interface CompliantDiscountable {
  id: string
  discountComplianceErrors: string[]
  isActive: boolean
}

export interface IDiscountCommon {
  id: string
  reference: string
  segment: string | null
  internalName: string
  publicName: string
  unit: DiscountUnits
  validityStartDate: Date
  validityEndDate: Date | null
  measureUnit: DiscountMeasureUnits | null
  discountablesCount: number
  discountableList: CompliantDiscountable[]
  disabledDate: string | null
  promoCode: string | null
  isFlash: boolean
  isSales: boolean
  isMeta: boolean
  participants?: number[]
  nbOverallUsesMax: number | null
  nbCustomerUsesMax: number | null
  policy: DiscountPolicy
  groupSize: number | null
}

export interface IDiscountWithScale extends IDiscountCommon {
  scale: ISlice[]
}

export interface IDiscountWithAmount extends IDiscountCommon {
  amount: number
}

export type Discount = IDiscountWithAmount | IDiscountWithScale

export const isDiscountWithScale = (discount: Discount): discount is IDiscountWithScale =>
  [DiscountTypes.OnRevenue, DiscountTypes.Quantitative].includes(discount.policy.type)
