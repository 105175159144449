
import { defineComponent } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { useLogisticOfferWording, useRouterRouteNames } from '../../composables'

export default defineComponent({
  name: 'PHeaderEditor',
  components: {
    IdfButton,
  },
  setup() {
    const { wording } = useLogisticOfferWording()
    const { routes } = useRouterRouteNames()
    return { routes, wording }
  },
})
