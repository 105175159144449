<template>
  <div class="product-variant-list">
    <div class="container-fluid product-variant-list__header">
      <div class="container">
        <div class="offset-7 col-5">
          <div class="row product-variant-list__header--vertical-align">
            <div class="col-3 product-variant-list__header-item">Stock</div>
            <div class="col-3 product-variant-list__header-item">Publié</div>
            <div class="col-3 product-variant-list__header-item">Offre en cours</div>
            <div class="col-3 product-variant-list__header-item">Catalogue coop</div>
          </div>
        </div>
      </div>
    </div>
    <c-product-variant-list-item
      v-for="variant in sellableVariants"
      :key="variant.id"
      :variant="variant"
      :product="product"
    />
  </div>
</template>

<script>
import CProductVariantListItem from '../CProductVariantListItem/CProductVariantListItem.vue'

export default {
  name: 'CProductVariantList',
  props: ['product'],
  components: {
    CProductVariantListItem,
  },
  computed: {
    sellableVariants() {
      return this.product.variants
        .filter((variant) => variant.sellable)
        .sort((a, b) => a.measureQuantity - b.measureQuantity)
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.product-variant-list__header {
  background-color: var(--idf-color-neutral-200);
  border-bottom: 1px solid var(--idf-color-neutral-200);
  padding: 0.5rem;
  line-height: 14px;
}
.product-variant-list__header-item {
  color: var(--idf-color-neutral-500);
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
}
.product-variant-list__header--vertical-align {
  align-items: center;
}
</style>
