import pick from 'lodash/pick'
import { AxiosResponse, AxiosInstance } from 'axios'
import { CooperativeProduct, FlattenedPackProduct, Product, PRODUCT_TYPES } from '@/core/product'
import { mapFromApi as mapProductFromApi } from '@/core/mapper/product.mapper'
import { CooperativeProductResponse, ProductGuestResponse, ProductInPackSchema } from '@b2ag/types-api-schemas'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import * as productMapper from './product.mapper'

class ProductService {
  private readonly productApiHost: string

  private readonly api: AxiosInstance

  constructor({ productApiHost }) {
    this.productApiHost = productApiHost
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1`, { timeout: 20000 })
  }

  // Dev note: change product structure in front app
  async getV2(id: string, cooperativeId?: number) {
    const response = await this.api.get<CooperativeProductResponse | ProductGuestResponse>(
      // @todo : il faut supprimer /products/${id} pcq il n'y a aucun usage coté front seller. Un "vendeur" n'a qu'une cooperative
      !cooperativeId ? `/products/${id}` : `/cooperatives/${cooperativeId}/products/${id}`,
    )
    return productMapper.mapFromApiV2(response.data)
  }

  async updateCatalogProduct(id: number, cooperativeId: string, cooperativeProduct) {
    const payload = pick(cooperativeProduct, ['tips', 'upsides', 'is_highlighted'])
    const response = await this.api.put(`/cooperatives/${cooperativeId}/products/${id}`, payload)
    return productMapper.mapFromApiV2(response.data)
  }

  async updateReferentialProduct(id: number, cooperativeId: string, cooperativeProduct) {
    const response = await this.api.put(`/cooperatives/${cooperativeId}/custom-products/${id}`, cooperativeProduct)
    return productMapper.mapFromCustomApi(response.data)
  }

  async downloadNationalReferentialCsvProductsByCooperative(cooperativeId: number): Promise<AxiosResponse> {
    return this.api.get(`/products`, {
      params: { distributor_id: cooperativeId },
      baseURL: `${this.productApiHost}/v1`,
      headers: { accept: 'text/csv' },
    })
  }

  async getProductsByInvivoIds(variantInvivoIdList: string[], cooperativeId: string) {
    const reponse = await this.api.get(
      `/cooperatives/${cooperativeId}/products?invivo_ids=${variantInvivoIdList.join(',')}`,
    )
    return reponse.data.map((product) => productMapper.mapFromApiV2(product))
  }

  async associateProducts(cooperativeId, productId, associatedProductId: Promise<void>) {
    await this.api.put(`/cooperatives/${cooperativeId}/products/${productId}/associated-product`, {
      associated_product_id: associatedProductId,
    })
  }

  async unassociateProducts(cooperativeId, productId, associatedProductId: Promise<void>) {
    await this.api.delete(`/cooperatives/${cooperativeId}/products/${productId}/associated-product`, {
      data: { associated_product_id: associatedProductId },
    })
  }

  static isPackProduct(product: CooperativeProduct): boolean {
    return product.type === PRODUCT_TYPES.PACK
  }

  static flattenPackProduct(
    packProduct: Product | CooperativeProduct,
    productsInPack: ProductInPackSchema[],
  ): FlattenedPackProduct {
    const flattenedProduct = { ...packProduct } as any as FlattenedPackProduct

    const fieldsToFlatten = [
      'formulation',
      'market_authorization',
      'toxicities',
      'usages_by_crops',
      'active_substances',
    ]

    fieldsToFlatten.forEach((field) => {
      flattenedProduct[field] = productsInPack!.map((product) => {
        if (field === 'usages_by_crops') {
          return { id: product.id, product_name: product.name, value: product[field] }
        }
        return { product_name: product.name, value: product[field] }
      })
    })

    // eslint-disable-next-line camelcase
    flattenedProduct.safetySheetUrls = productsInPack!
      // eslint-disable-next-line camelcase
      .filter((product) => product.safety_sheet_urls)
      .map((product) => ({
        product_name: product.name,
        // eslint-disable-next-line camelcase
        value: product.safety_sheet_urls,
      }))

    return flattenedProduct
  }

  async findProductsWithINVIVOIds(idList: string[], cooperativeId?: number): Promise<CooperativeProduct[]> {
    const response = await this.api.get<CooperativeProductResponse[]>(`/cooperatives/${cooperativeId}/products`, {
      params: { invivo_ids: idList.join(',') },
    })
    return response.data.map((responseProduct) => {
      const product = mapProductFromApi(responseProduct)
      return ProductService.isPackProduct(product)
        ? ProductService.flattenPackProduct(product, responseProduct.products_in_pack!)
        : product
    })
  }
}
export default ProductService
