<template>
  <div class="variant-zone-charges">
    <p class="variant-zone-charges__title">Frais de zone</p>
    <PWarningMessage v-if="!variantHasZoneCharges" data-test-info-message
      >Pas de frais de zones pour cet article.</PWarningMessage
    >
    <div class="fake-row variant-zone-charges__block border-card">
      <div
        v-for="zoneCharge in zoneCharges"
        :key="zoneCharge.zoneName"
        class="variant-zone-charges__block-item"
        data-test="variant-zone-charges"
      >
        {{ zoneCharge.zoneName }} :
        <span class="variant-zone-charges__zone-charge-cost"
          >{{ zoneCharge.cost | currency }} / {{ measureUnit | formatMeasureUnit }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { currency } from '../../../filters'
import measureUnit from '../../../filters/unit/measure.filter'
import PWarningMessage from '../../../presenters/PWarningMessage.vue'

export default {
  name: 'CVariantZoneCharges',
  components: { PWarningMessage },
  props: ['zoneCharges', 'measureUnit'],
  filters: {
    currency,
    formatMeasureUnit: measureUnit,
  },
  computed: {
    variantHasZoneCharges() {
      return !!(this.zoneCharges && this.zoneCharges.length > 0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/lib/_variables.scss';
.variant-zone-charges__block {
  background-color: var(--idf-color-neutral-100);
  align-items: center;
  margin-bottom: 3em;
}
.variant-zone-charges__block-item {
  width: 100%;
  background-color: var(--idf-color-neutral-100);
  border-bottom: solid var(--idf-color-neutral-200) 1px;
  padding: 20px;
  align-items: center;
}
.variant-zone-charges__block:last-child {
  border-bottom: 0;
}
.variant-zone-charges__title {
  font-weight: bold;
  font-size: 18px;
  padding-top: 24px;
  padding-bottom: 15px;
}
.variant-zone-charges__zone-charge-cost {
  font-weight: bold;
}
</style>
