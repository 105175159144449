export function validDateStartValidator() {
  return {
    message() {
      return 'La date de début est obligatoire'
    },
    validate(pickerStart: Object, { start, end }) {
      if (!end) return true
      return Boolean(start)
    },
    params: ['end', 'start'],
  }
}

export function validDateEndValidator() {
  return {
    message() {
      return 'La date de fin doit être ultérieure à la date de début'
    },
    validate(pickerEnd: Object, { start, end }) {
      if (!start || !end) return true
      return end >= start
    },
    params: ['end', 'start'],
  }
}
