<template>
  <div>
    <header class="articles-header">
      <div class="articles-header__container">
        <h1>{{ $t('ctx.articles.liste-title') }}</h1>
        <IdfButton class="articles-header__container__creation-cta" @click="goToCreation">{{
          $t('ctx.articles.ajouter')
        }}</IdfButton>
      </div>
    </header>
    <div class="articles-container">
      <h1>{{ $t('ctx.articles.actifs') }}</h1>
      <div class="articles-cards-container" style="--article-lg-height: 33rem; --article-sm-height: 33rem">
        <Idf-article-thumbnail
          v-if="anchoredArticleV2"
          :key="anchoredArticleV2.id"
          :truncateTitle="55"
          :article="getFormatedArticle(anchoredArticleV2)"
          miniMode
          useSpacer
          withManagement
        ></Idf-article-thumbnail>
        <Idf-article-thumbnail
          v-for="article in articlesV2"
          :key="article.id"
          :truncateTitle="55"
          :article="getFormatedArticle(article)"
          miniMode
          useSpacer
          withManagement
        >
          <template #management>
            <div class="article-management-button-container">
              <IdfButton
                class="article-management-button__delete"
                type="button"
                icon-left="delete-filled"
                kind="secondary"
                @click="
                  {
                    openDeleteModal(article.slug)
                  }
                "
              >
                Supprimer
              </IdfButton>
              <IdfButton
                class="article-management-button__modify"
                type="button"
                icon-left="edit-filled"
                kind="secondary"
                @click="
                  {
                    goToEdit(article.slug)
                  }
                "
              >
                Modifier
              </IdfButton>
            </div>
          </template>
        </Idf-article-thumbnail>
      </div>
    </div>
    <idf-modal v-show="slugArticleToDelete" @close="closeDeleteModal">
      <template v-slot:header>
        <h2>Suppresion de l'article</h2>
      </template>
      <template v-slot:body>
        <p id="content">Êtes-vous sûr de vouloir supprimer cet article ?</p>
      </template>
      <template v-slot:footer>
        <div class="modal-button-container">
          <IdfButton class="modal-button-left" kind="secondary" @click="closeDeleteModal">Annuler</IdfButton>
          <IdfButton class="modal-button-right" kind="secondary" @click="deleteArticle">Supprimer</IdfButton>
        </div>
      </template>
    </idf-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getArticlesUseCase,
  deleteArticleUsecase,
  getAnchoredArticlesUseCase,
} from '@/contexts/articles/dependency-injection'
import { IdfButton, IdfModal } from '@invivodf/ui-kit'
import IdfArticleThumbnail from '@b2ag/design-system/dist/components/IdfArticleThumbnail/IdfArticleThumbnail.vue'
import { checkFeature, FeatureType } from '@invivodf/common/src/contexts/feature-flipping'

export default {
  name: 'CArticleListV2',
  components: { IdfButton, IdfArticleThumbnail, IdfModal },
  data() {
    return { articlesV2: [], anchoredArticleV2: null, slugArticleToDelete: null }
  },
  async mounted() {
    await this.getArticles()
  },
  methods: {
    getFormatedArticle(article) {
      return {
        slug: article.slug,
        title: article.title,
        summary: '',
        header: {
          author: article.author,
          image: null,
          richBoxTag: '',
          imageUrl: article.image_url,
          publishedAt: '',
        },
      }
    },
    async getArticles() {
      const isAnchoredArticleFeatureEnabled = await checkFeature.execute({
        id: 'article:anchored-articles',
        type: FeatureType.Dynamic,
        context: { id: this.cooperativeId?.toString() },
      })

      const articles = await getArticlesUseCase({ partnerId: this.cooperativeId })
      this.articlesV2 = articles
      if (isAnchoredArticleFeatureEnabled) {
        const anchoredArticle = await getAnchoredArticlesUseCase({ partnerId: this.cooperativeId })
        this.anchoredArticleV2 = anchoredArticle
      }
    },
    goToCreation() {
      this.$router.push({ name: 'article-creation' })
    },
    goToEdit(slug) {
      this.$router.push({ name: 'articles-v2-edit', params: { articleSlug: slug } })
    },
    async deleteArticle() {
      const { slugArticleToDelete } = this
      await deleteArticleUsecase({ partnerId: this.cooperativeId, slug: slugArticleToDelete })
      await this.getArticles()
      this.closeDeleteModal()
    },
    openDeleteModal(slug) {
      this.slugArticleToDelete = slug
    },
    closeDeleteModal() {
      this.slugArticleToDelete = null
    },
  },
  computed: {
    ...mapGetters(['cooperativeId']),
  },
}
</script>

<style lang="scss" scoped>
@import '../../lib/_variables.scss';
.articles-header {
  background-color: var(--idf-color-neutral-100);
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 84px;
    padding-left: var(--idf-spacing-5);
    padding-right: var(--idf-spacing-5);
    padding-bottom: var(--idf-spacing-5);
    gap: var(--idf-spacing-5);
    &__creation-cta {
      @media screen and (max-width: $idf-breakpoint-sm) {
        height: var(--idf-spacing-8) !important;
      }
    }
  }
}
.articles-container {
  padding-top: var(--idf-spacing-5);
  padding-left: var(--idf-spacing-5);
}
.articles-cards-container {
  display: flex;
  flex-wrap: wrap;
}
.article-management-button-container {
  display: flex;
  justify-content: space-between;
}
.article-management-button__modify {
  width: 49%;
  margin-top: var(--idf-spacing-3);
  border-radius: 4px !important;
}

.article-management-button__delete {
  width: 49%;
  margin-top: var(--idf-spacing-3);
  border-radius: 4px !important;
  border-color: var(--idf-color-warning-600) !important;
  color: var(--idf-color-warning-600) !important;
}

.article-management-button__delete:hover,
.article-management-button__delete:focus,
.article-management-button__delete:active {
  background-color: white !important;
}

.modal-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modal-button-left,
.modal-button-right {
  width: 12.9rem;
  border-radius: 4px !important;
}

.modal-button-right {
  margin-left: var(--idf-spacing-3);
  border-color: var(--idf-color-warning-600) !important;
  color: var(--idf-color-warning-600) !important;
}

.modal-button-right,
.modal-button-right,
.modal-button-right {
  background-color: white !important;
}
</style>
