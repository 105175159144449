import { HomeBanner } from '../domain/home-banner'
import { HomeBannerRepository } from '../infrastructure/home-banner.repository'

interface CreateOrUpdateHomeBannerUsecase {
  (params: { partnerId: number; homeBanner: HomeBanner }): Promise<HomeBanner>
}

interface Dependencies {
  homeBannerRepository: HomeBannerRepository
}

export function createCreateOrUpdateHomeBannerUsecase(deps: Dependencies): CreateOrUpdateHomeBannerUsecase {
  return async (params: { partnerId: number; homeBanner: HomeBanner }): Promise<HomeBanner> => {
    return deps.homeBannerRepository.createOrUpdateHomeBanner(params.partnerId, params.homeBanner)
  }
}
