import LogisticRepository from '../infrastructure/logistic.repository'

export default class DeleteLogisticOffer {
  private readonly repository: LogisticRepository

  constructor(repository: LogisticRepository) {
    this.repository = repository
  }

  async execute(sellerId: string, reference: string): Promise<void> {
    await this.repository.delete(sellerId, reference)
  }
}
