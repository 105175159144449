
import { computed, defineComponent, PropType } from 'vue'
import { IdfIcon } from '@invivodf/ui-kit'
import { use18n } from '@invivodf/locales/src/use18n'
import { Section } from '../../../domain/Section'
import { SectionName } from '../../../domain/SectionName'

export default defineComponent({
  name: 'CParametersList',
  components: { IdfIcon },
  props: {
    sections: {
      type: Array as PropType<Section[]>,
      required: true,
    },
  },
  setup() {
    const i18n = use18n()

    const getTranslatedSectionName = (sectionName: SectionName) => {
      const translations = {
        [SectionName.Transversal]: computed(() => i18n.t('ctx.parameters.transversal')),
        [SectionName.Product]: computed(() => i18n.t('ctx.parameters.products')),
        [SectionName.StockAndQuota]: computed(() => i18n.t('ctx.parameters.stock-and-quota')),
        [SectionName.Payment]: computed(() => i18n.t('ctx.parameters.payment')),
        [SectionName.Delivery]: computed(() => i18n.t('ctx.parameters.delivery')),
        [SectionName.Marketing]: computed(() => i18n.t('ctx.parameters.marketing')),
        [SectionName.Users]: computed(() => i18n.t('ctx.parameters.users')),
      }
      return translations[sectionName] || computed(() => '')
    }
    return {
      getTranslatedSectionName,
    }
  },
})
