<template>
  <div class="container p-4">
    <p>Sélectionner une ou plusieurs catégories pour leur ajouter des seuils des stocks</p>
    <adn-items-selector v-model="fields.productKinds.value" :items="availableKinds" @change="selectKind" />
    <div class="grid">
      <div class="grid__row">
        <p>En rupture de stock si :</p>
        <p>Minimum :</p>
        <p>0</p>
        <p class="justify-content-center">unités</p>
        <p class="grid__row__dash">—</p>
        <p>Maximum :</p>
        <input
          type="number"
          step="any"
          placeholder="-"
          v-model="fields.criticalThreshold.value"
          :class="{ 'input--on-error': showErrorClass(fields.criticalThreshold.state) }"
          @change="fields.restrictedThreshold.validate"
          data-test="critical-threshold"
        />
        <p class="justify-content-center">unités</p>
      </div>
      <div class="grid__row">
        <p>En stock limité si :</p>
        <p>Minimum :</p>
        <p data-test="restricted-threshold-min">{{ restrictedThresholdMin }}</p>
        <p class="justify-content-center">unités</p>
        <p class="grid__row__dash">—</p>
        <p>Maximum :</p>
        <input
          name="restrictedTreshold"
          type="number"
          step="any"
          placeholder="-"
          v-model="fields.restrictedThreshold.value"
          :class="{ 'input--on-error': showErrorClass(fields.restrictedThreshold.state) }"
          data-test="restricted-threshold-max"
        />
        <p class="justify-content-center">unités</p>
      </div>
      <div class="grid__row">
        <p>Disponible si :</p>
        <p>Minimum :</p>
        <p data-test="availability">{{ availability }}</p>
        <p class="justify-content-center">unités</p>
      </div>
      <PAlert v-if="errorMessage" data-test="show-error">{{ errorMessage }}</PAlert>
      <div class="grid__actions">
        <IdfButton kind="secondary" icon-left="close" @click="deleteThresholdsStock" data-test="delete-action"
          >Supprimer</IdfButton
        >
        <IdfButton :disabled="!form.state.dirty || form.state.invalid" @click="save" data-test="save-action"
          >Sauvegarder
        </IdfButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useValidateForm } from '@/composables/useValidateForm'
import { useThresholdsStocks } from '@/composables/useThresholdsStocks'
import { useStore } from '@/composables/useStore'
import { IdfButton } from '@invivodf/ui-kit'
import PAlert from '@invivodf/common/src/application/presenters/PAlert.vue'

export default defineComponent({
  name: 'CThresholdsCard',
  components: { IdfButton, PAlert },
  props: ['data', 'availableKinds'],
  setup(props, context) {
    const store = useStore()
    const thresholdsStocks = useThresholdsStocks()
    const { fields, form, addFields } = useValidateForm({
      productKinds: {
        value: props.data.productKinds,
        validators: {
          required: true,
        },
      },
      criticalThreshold: {
        value: props.data.criticalThreshold,
        validators: {
          required: true,
          minValue: 0,
        },
      },
    })

    addFields({
      restrictedThreshold: {
        value: props.data.restrictedThreshold,
        validators: {
          required: true,
          minValue: computed(() => Number(fields.criticalThreshold.value) + 1),
        },
      },
    })

    const showErrorClass = (fieldState) => {
      return fieldState.dirty && fieldState.invalid
    }

    const errorMessage = computed(() => {
      if (fields.productKinds.state.dirty && fields.productKinds.state.invalid) {
        return 'Il vous faut sélectionner au moins une catégorie'
      }
      if (form.state.dirty && (fields.criticalThreshold.state.invalid || fields.restrictedThreshold.state.invalid)) {
        return 'Le maximum rupture de stock doit être supérieur à 0 et inférieur au maximum du stock limité'
      }
      return null
    })

    const save = async () => {
      if (!form.state.invalid) {
        const toast = await thresholdsStocks.save({
          id: props.data.id,
          productKinds: fields.productKinds.value,
          criticalThreshold: fields.criticalThreshold.value,
          restrictedThreshold: fields.restrictedThreshold.value,
        })
        if (toast.type === 'success') {
          form.reset()
        }
        await store.dispatch('setToast', toast)
      }
    }

    const selectKind = (productKinds) => {
      fields.productKinds.value = Object.keys(productKinds)
      context.emit('updateKinds', fields.productKinds.value)
    }

    const deleteThresholdsStock = () => {
      context.emit('delete')
    }

    const restrictedThresholdMin = computed(() => Number(fields.criticalThreshold.value) + 1)

    const availability = computed(() => Number(fields.restrictedThreshold.value) + 1)

    return {
      fields,
      form,
      showErrorClass,
      errorMessage,
      save,
      selectKind,
      deleteThresholdsStock,
      restrictedThresholdMin,
      availability,
    }
  },
})
</script>

<style lang="scss" scoped>
.container {
  background: var(--idf-color-neutral-100);
  border: 1px solid var(--idf-color-neutral-200);
}

.grid {
  display: grid;
  margin-top: var(--idf-spacing-2);
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.grid .grid__row {
  border-top: 1px solid var(--idf-color-neutral-200);
}

.grid__row {
  display: grid;
  padding: var(--idf-spacing-2);
  grid-template-columns: 25% 10% 5% 10% 5% 10% 10% 10%;
}

.grid__row > * {
  display: flex;
  align-items: center;
}

.grid__row__dash {
  color: var(--idf-color-neutral-200);
}

.grid div:first-child {
  border: none;
}

.grid__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--idf-spacing-2);
}

.grid__link__action {
  display: flex;
  color: var(--idf-color-main-400);
  align-items: center;
}

.grid__link__action:hover {
  cursor: pointer;
}
.grid__link__action:hover p {
  text-decoration: underline var(--idf-color-main-400);
}

.grid__actions div {
  margin-right: var(--idf-spacing-2);
}

.input--on-error {
  border: 1px solid var(--idf-color-error-400);
  color: var(--idf-color-error-400);
}
</style>
