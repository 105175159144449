/* eslint-disable camelcase */
import { NUTRITION_AMENDMENTS_KIND, SEED_KIND, CROP_PROTECTION_KIND } from './products.constants'
import { Packaging } from './packaging.business'
/**
 * This corresponds to the stores fetched from Univers Coop.
 * The values are the `accept_*` with the prefix removed.
 *
 * @see StoreModel
 */
export enum Fertilizer {
  BIG_BAG = 'fertilizer_bigbag',
  TANK = 'fertilizer_tank',
  BULK = 'fertilizer_bulk',
  OTHERS = 'fertilizer_other_packaging',
}

export interface StoreModel {
  id: number
  name: string
  code: string
  schedule: string
  address_line1: string
  address_line2: string
  address_line3: string
  postal_code: string
  city: string
  region: string
  gps_location_lat: number
  gps_location_long: number
  contact_phone: string
  accept_crop_protection: boolean
  accept_seeds: boolean
  accept_fertilizer_bigbag: boolean
  accept_fertilizer_tank: boolean
  accept_fertilizer_bulk: boolean
  is_pickup_point: boolean
  is_click_and_collect: boolean
  is_departure_point: boolean
}

/**
 * @example
 * toAccept(Packaging.BIG_BAG) // 'fertilizer_big_bag'
 */
function toAccept(packaging: string): string {
  switch (packaging.toLowerCase()) {
    case Packaging.BIG_BAG:
    case Packaging.SAC:
    case Packaging.CAMION_BIG_BAG:
      return Fertilizer.BIG_BAG
    case Packaging.CUVE:
    case Packaging.FUT:
      return Fertilizer.TANK
    case Packaging.CAMION_VRAC:
    case Packaging.VRAC:
      return Fertilizer.BULK
    default:
      return Fertilizer.OTHERS
  }
}

function parseStore(predicate: (key: string) => boolean, mapKey: (key: string) => string) {
  return (store: StoreModel): string[] => {
    return Object.entries(store)
      .filter(([key, value]) => predicate(key) && !!value)
      .map(([key]) => mapKey(key))
  }
}

export function storeAccepts({ accepts, packaging }: { accepts: string[]; packaging: string }): boolean {
  return accepts.includes(toAccept(packaging))
}

function stripPrefix(prefix: string) {
  return (str: string): string => str.replace(RegExp(`^${prefix}`), '')
}

const isAccept = (key: string): boolean => /^accept_fertilizer_/.test(key)

export const parseAccepts = parseStore(isAccept, stripPrefix('accept_'))

export const canSellVariantInClickAndCollect = (variant) => {
  return (cooperativeStore) => {
    if (cooperativeStore.is_click_and_collect) {
      switch (variant.productKind) {
        case NUTRITION_AMENDMENTS_KIND:
          return storeAccepts({ accepts: parseAccepts(cooperativeStore), packaging: variant.packaging })
        case SEED_KIND:
          return cooperativeStore.accept_seeds
        case CROP_PROTECTION_KIND:
          return cooperativeStore.accept_crop_protection
        default:
          return true
      }
    } else {
      return false
    }
  }
}
