
import { defineComponent } from 'vue'
import { IdfPictogram } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'PContextButton',
  emits: ['click'],
  components: { IdfPictogram },
  setup(props, { emit }) {
    const handleClick = (e: MouseEvent): void => {
      emit('click', e)
    }
    return { handleClick }
  },
})
