import { computed, ComputedRef } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { ParametersDescription } from '../../domain/ParametersDescription'
import { SectionName } from '../../domain/SectionName'

export const useTransversalParameters = (): ComputedRef<ParametersDescription>[] => {
  const i18n = use18n()
  return [
    computed(() => ({
      title: `${i18n.t('ctx.parameters.transversal__import-history__title')}`,
      description: `${i18n.t('ctx.parameters.transversal__import-history__description')}`,
      route: 'import-reports',
      icon: 'clock-outlined',
      section: SectionName.Transversal,
    })),
  ]
}
