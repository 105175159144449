
import { defineComponent } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { PAdvancedPricing } from '../../presenters'
import { useLogisticOfferWording } from '../../composables'
import { AdvancedPricing, AdvancedType } from '../../../domain'

export default defineComponent({
  name: 'CAdvancedPricing',
  components: {
    IdfButton,
    PAdvancedPricing,
  },
  emits: ['add-rule', 'delete-rule', 'update-rule-range-end'],
  props: {
    value: {
      type: Object as () => AdvancedPricing,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const onAddRule = (advancedType: AdvancedType): void => {
      emit('add-rule', advancedType)
    }
    const onDeleteRule = (index: number, advancedType: AdvancedType): void => {
      emit('delete-rule', index, advancedType)
    }
    const onUpdateRuleRangeEnd = (index: number, value: string, advancedType: AdvancedType): void => {
      emit('update-rule-range-end', index, value, advancedType)
    }
    return {
      onAddRule,
      onDeleteRule,
      onUpdateRuleRangeEnd,
      wording,
    }
  },
})
