import { AxiosInstance } from 'axios'
import { download } from '@invivodf/common/src/infrastructure/download.service'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import { mapFromApiListQuotasResponse } from './quota.mapper'
import { Quota } from '../../domain/Quota'
import ImportService from '../import.service'

export default class QuotaService {
  private readonly api: AxiosInstance

  private readonly quotaApiHost: string

  constructor({ quotaApiHost }) {
    this.quotaApiHost = quotaApiHost
    this.api = createAxiosClientWithAladinBearer(`${quotaApiHost}/v1`, { timeout: 20000 })
  }

  async import(cooperativeId, file) {
    const { data } = await this.api.post(`${this.quotaApiHost}/backoffice/v1/quotas-importer`, file, {
      headers: { 'Content-Type': 'text/csv' },
      params: { partner_id: cooperativeId },
    })
    return data
  }

  async list(partnerId: number, meshIds: string[]): Promise<Quota[]> {
    if (window.env.IMPORT_QUOTA_FEATURE === 'no') return []
    const { data } = await this.api.get('/quotas', {
      params: {
        partner_id: partnerId,
        ...(meshIds.length && { mesh_ids: `${meshIds}` }),
      },
    })
    return mapFromApiListQuotasResponse(data)
  }

  async downloadQuotas(partnerId, partnerName) {
    const response = await this.api.get(`${this.quotaApiHost}/backoffice/v1/quotas-importer?partner_id=${partnerId}`, {
      headers: { accept: 'text/csv' },
      responseType: 'blob',
    })
    download(response.data, ImportService.createFileName(partnerName, 'quotas'))
  }
}
