export class FeatureFlipEnvDataSource {
  constructor(private readonly errorLogger: (e: Error) => void) {}

  check(id: string): boolean {
    const featureFlip: string | undefined = window.env[id]
    if (featureFlip === undefined) {
      this.errorLogger(new Error(`Feature flip ${id} non trouvé dans les variables d'environnement`))
      return false
    }

    return featureFlip === 'yes'
  }
}
