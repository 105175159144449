
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { ImportReport } from '../../domain/ImportReport'
import PImportReportDetail from './PImportReportDetail/PImportReportDetail.vue'
import PHeader from '../../../../application/presenters/PHeader.vue'

export default defineComponent({
  name: 'PImportReport',
  components: { PHeader, PImportReportDetail },
  props: {
    importReport: {
      type: Object as PropType<ImportReport>,
      required: true,
    },
    error: { type: Boolean, required: true },
  },
  setup(props) {
    const { importReport } = toRefs(props)

    const importReportTypeLabel = computed(() => importReport.value.translated_type)

    const breadcrumbsItems = computed(() => [
      {
        label: 'Accueil',
        link: { name: 'home' },
        icon: 'home-filled',
      },
      {
        label: 'Paramètres',
        link: { name: 'parameters' },
      },
      {
        label: 'Historique des imports',
        link: { name: 'import-reports' },
      },
      { label: `Détail Import ${importReportTypeLabel.value}` },
    ])

    const title = computed(() => `Détail import ${importReportTypeLabel.value}`)

    return {
      breadcrumbsItems,
      title,
    }
  },
})
