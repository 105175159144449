
import { defineComponent, PropType } from 'vue'
import { IUseDiscountInput } from '../composables/useDiscountInput'
import CDiscountInternalNameInput from './inputs/CDiscountInternalNameInput.vue'
import CDiscountPublicNameInput from './inputs/CDiscountPublicNameInput.vue'
import CDiscountReferenceInput from './inputs/CDiscountReferenceInput.vue'
import CStepSubGroup from './CStepSubGroup.vue'

export default defineComponent({
  name: 'CEditIdentificationInformation',
  components: { CStepSubGroup, CDiscountInternalNameInput, CDiscountPublicNameInput, CDiscountReferenceInput },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    internalName: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    publicName: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
})
