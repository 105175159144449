
import { computed, defineComponent, Ref } from 'vue'
import { ValidationProvider } from 'vee-validate-last'
import { IdfStandardInputs, IdfInput } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'PInput',
  emits: ['input'],
  components: {
    IdfStandardInputs,
    ValidationProvider,
    IdfInput,
  },
  props: {
    id: {
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
    },
    rules: {
      type: [String, Object],
    },
    value: {
      type: [String, Number],
    },
    debounce: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayError: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const inputValue: Ref<string | number | undefined> = computed({
      get: (): string | number | undefined => props.value?.toString() || '',
      set: (newValue: string | number | undefined): void => emit('input', newValue),
    })
    return { inputValue }
  },
})
