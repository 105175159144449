import { AxiosInstance } from 'axios'
import parse from 'parse-link-header'
import { DateTime } from 'luxon'
import { ListingQuery } from '../../domain/listing/ListingQuery'
import {
  DiscountResponseDTO,
  DiscountablesFromApi,
  DiscountApiPayloadDTO,
  IDiscountDatasource,
  ListingFromApi,
  DiscountPolicyDTO,
} from './IDiscount.datasource'
import { ListingQueryMapper } from '../mappers/ListingQuery.mapper'
import { getCustomOrGenericError } from './getCustomOrGenericError'

export class DiscountDataSource implements IDiscountDatasource {
  private readonly listingQueryMapper = new ListingQueryMapper()

  constructor(private readonly httpClient: AxiosInstance) {}

  async get(id: string, sellerId: number, isRetrievingMeta: boolean): Promise<DiscountResponseDTO> {
    try {
      const url = encodeURI(isRetrievingMeta ? `v2/meta-discounts/${id}` : `v2/discounts/${id}`)
      const params = { seller_id: sellerId }
      const { data } = await this.httpClient.get<DiscountResponseDTO>(url, { params })
      return data
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async create(discountDTO: DiscountApiPayloadDTO, sellerIds: number[]): Promise<DiscountResponseDTO> {
    try {
      if (discountDTO.is_meta) return this.createMeta(discountDTO, sellerIds)
      return await this.createDiscount(discountDTO, sellerIds)
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  private async createMeta(discountDTO: DiscountApiPayloadDTO, sellerIds: number[]): Promise<DiscountResponseDTO> {
    const url = encodeURI(`v2/meta-discounts`)
    const { data } = await this.httpClient.post<DiscountResponseDTO>(url, {
      seller_ids: sellerIds,
      discount: discountDTO,
    })
    return data
  }

  private async createDiscount(discountDTO: DiscountApiPayloadDTO, sellerIds: number[]): Promise<DiscountResponseDTO> {
    const url = encodeURI(`v2/discounts`)
    const params = { seller_id: sellerIds[0] }
    const { data } = await this.httpClient.post<DiscountResponseDTO>(url, discountDTO, { params })
    return data
  }

  async update(discount: DiscountApiPayloadDTO): Promise<DiscountResponseDTO> {
    try {
      if (discount.is_meta) return this.updateMetaDiscount(discount)
      return this.updateDiscount(discount)
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  private async updateDiscount(discountDTO: DiscountApiPayloadDTO) {
    const url = encodeURI(`v2/discounts/${discountDTO.id}`)
    const params = { seller_id: discountDTO.participants[0] }
    const { data } = await this.httpClient.put<DiscountResponseDTO>(url, discountDTO, { params })
    return data
  }

  private async updateMetaDiscount(discountDTO: DiscountApiPayloadDTO) {
    const url = encodeURI(`v2/meta-discounts/${discountDTO.id}`)
    const { data } = await this.httpClient.put<DiscountResponseDTO>(url, discountDTO)
    return data
  }

  async validateGeneralInformations(discountDTO: DiscountApiPayloadDTO, sellerIds: number[]): Promise<void> {
    try {
      if (discountDTO.is_meta) {
        await this.validateMetaGeneralInformations(discountDTO, sellerIds)
      } else {
        await this.validateDiscountGeneralInformations(discountDTO, sellerIds)
      }
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  private async validateMetaGeneralInformations(
    discountDTO: DiscountApiPayloadDTO,
    sellerIds: number[],
  ): Promise<void> {
    const url = encodeURI(`v2/meta-discounts/validate-step/general-infos`)
    await this.httpClient.post<DiscountablesFromApi>(url, {
      seller_ids: sellerIds,
      discount: discountDTO,
    })
  }

  private async validateDiscountGeneralInformations(
    discountDTO: DiscountApiPayloadDTO,
    sellerIds: number[],
  ): Promise<void> {
    const url = encodeURI(`v2/discounts/validate-step/general-infos`)
    const params = { seller_id: sellerIds[0] }
    await this.httpClient.post<DiscountablesFromApi>(url, discountDTO, { params })
  }

  async getAuthorizedDiscountPolicies(sellerId: number): Promise<DiscountPolicyDTO[]> {
    try {
      const url = encodeURI(`v2/authorized-discount-logics`)
      const params = { seller_id: sellerId }
      const { data } = await this.httpClient.get<DiscountPolicyDTO[]>(url, { params })
      return data
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async delete(sellerId: number, discountId: string, deleteMetaDiscount: boolean): Promise<void> {
    try {
      const url = encodeURI(!deleteMetaDiscount ? `v2/discounts/${discountId}` : `v2/meta-discounts/${discountId}`)
      const params = !deleteMetaDiscount ? { seller_id: sellerId } : {}
      await this.httpClient.delete(url, { params })
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async list(query: ListingQuery, isRetrievingMetaDiscounts: boolean): Promise<ListingFromApi> {
    try {
      const url = encodeURI(isRetrievingMetaDiscounts ? `v2/meta-discounts` : `v2/discounts`)
      const params = this.listingQueryMapper.toDTO(query, isRetrievingMetaDiscounts)
      const { data, headers } = await this.httpClient.get(url, { params })

      const parsedHeaders = parse(headers.link)
      const total = +(parsedHeaders && parsedHeaders.last && parsedHeaders.last.page * 10) || 1
      return { discountList: data, total }
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  protected static createFileName(sellerName: string, exportName: string): string {
    const date = DateTime.now().toFormat('yMMddHHmm')
    return `${sellerName}_export_${exportName}_${date}.csv`
  }
}
