import { render, staticRenderFns } from "./VSearch.vue?vue&type=template&id=4fb8e9ea&scoped=true"
import script from "./VSearch.vue?vue&type=script&lang=js"
export * from "./VSearch.vue?vue&type=script&lang=js"
import style0 from "./VSearch.vue?vue&type=style&index=0&id=4fb8e9ea&prod&lang=scss&scoped=true"
import style1 from "./VSearch-ais.scss?vue&type=style&index=1&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb8e9ea",
  null
  
)

export default component.exports