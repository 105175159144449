interface SplitOptions {
  /** combien d’octets peuvent être alloués à la liste avant de découper la requête */ maxLen?: number
  /** le point (`.`) est moins cher en encodage d’URL, si l’API le permet */ separatorLength?: 1 | 3
  /** par défaut Axios encode les espaces avec un `+`, mais sinon `%20` fait 3 octets */ spaceLength?: 1 | 3
}
const defaultOptions: Required<SplitOptions> = {
  maxLen: 5000,
  separatorLength: 3,
  spaceLength: 1,
}
/** Découpe une requête en plusieurs si la liste à passer en paramètre est trop longue.
 * La longueur est calculée en octets encodés, et prend en compte le séparateur. */
export async function splitRequest<T extends string, U>(
  fullList: readonly T[],
  f: (list: readonly T[]) => Promise<U[]>,
  options: SplitOptions = {},
) {
  if (fullList.length === 0) return []
  // eslint-disable-next-line no-param-reassign
  options = Object.assign({}, defaultOptions, options)
  const space = 'a'.repeat(options.spaceLength!)
  const encodedLengths = fullList.map((s) => encodeURIComponent(s.replace(/ /g, space)).length)
  const promises: Promise<U[]>[] = []
  let start = 0
  for (let i = 0, length = -options.separatorLength!; i < fullList.length; i++) {
    const toAdd = encodedLengths[i] + options.separatorLength!
    if (length + toAdd <= options.maxLen!) length += toAdd
    else {
      promises.push(f(fullList.slice(start, i)))
      length = encodedLengths[i]
      start = i
    }
  }
  promises.push(f(fullList.slice(start)))
  return (await Promise.all(promises)).flat(1)
}
