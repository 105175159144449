
import { mapActions, mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import CImportCustomProductCategories from '@invivodf/common/src/contexts/parameters/application/components/CImportCustomProductCategories/CImportCustomProductCategories.vue'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { useStore } from '@/composables/useStore'
import { cooperativeCustomProductsV2Service } from '../../services'

const csvFileProperties = {
  name: 'cooperative_custom_products.csv',
  full_path: '/assets/csv/cooperative_custom_products.csv',
}

export default defineComponent({
  name: 'CImportCooperativeCustomProducts',
  components: {
    CImportCustomProductCategories,
    CImportBase,
    CImportInformation,
  },
  setup() {
    const store = useStore()
    const i18n = use18n()
    onBeforeMount(async () => {
      await store.dispatch('setCustomProductImportInformation')
    })
    const csvColumnDetailsData = [
      {
        header: 'item_uid',
        format: 'VARCHAR',
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-uid-description')}`,
        mandatory: `${i18n.t('message.no')} <b>${i18n.t('ctx.import.custom-products__csv-column-details__item-uid-mandatory-1')}</b> <br><br>
           ${i18n.t('ctx.import.custom-products__csv-column-details__item-uid-mandatory-2')} <br> <br>
           ${i18n.t('ctx.import.custom-products__csv-column-details__item-uid-mandatory-3')} <br>`,
      },
      {
        header: 'product_id',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>Za_345</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-id-description')}`,
        mandatory: `${i18n.t('message.yes')}. ${i18n.t('ctx.import.custom-products__csv-column-details__product-id-mandatory')} [a,…,z,A,…,Z,0,…,9,_]`,
      },
      {
        header: 'product_name',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>Tour de plaine</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-name-description')}`,
        mandatory: `${i18n.t('message.yes')}. ${i18n.t('ctx.import.custom-products__csv-column-details__product-name-mandatory')}`,
      },
      {
        header: 'item_id',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>Za_345</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-id-description')}`,
        mandatory: `${i18n.t('message.yes')}. ${i18n.t('ctx.import.custom-products__csv-column-details__item-id-mandatory')} [a,…,z,A,…,Z,0,…,9,_]`,
      },
      {
        header: 'item_family',
        format: 'VARCHAR',
        exampleValue: `
        ${i18n.t('ctx.import.custom-products__csv-column-details__item-family-examples')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-1')}</code>,
        <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-2')}</code>,
        <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-3')}</code>,
        <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-4')}</code>,
        <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-5')}</code>,
        <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-6')}</code>,
        <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-family-example-7')}</code>,
`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-family-description')}`,
        mandatory: `${i18n.t('message.yes')}.`,
      },
      {
        header: 'product_category_code_list',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>cereales|grandes_cultures|nutrition_mais</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-category-code-list-description')}.`,
        mandatory: `${i18n.t('message.yes')}.`,
      },
      {
        header: 'item_needs_human_assistance',
        format: 'BOOLEAN',
        exampleValue: `<code>TRUE</code> ${i18n.t('message.or')} <code>FALSE</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-description-1')} <br>
           ${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-description-2')}
           <code>TRUE</code>${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-description-3')} <br>
           <code>FALSE</code>${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-description-4')} <br><br>
           ${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-description-5')}
        `,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-mandatory-1')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-needs-human-assistance-mandatory-2')}</code>.`,
      },
      {
        header: 'item_measure_quantity',
        format: 'DECIMAL',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <br> <code>1</code> <br> <code>1.5</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-quantity-description')}`,
        mandatory: `${i18n.t('message.yes')}. ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-quantity-mandatory-1')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-quantity-mandatory-2')}</code> ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-quantity-mandatory-3')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-quantity-mandatory-4')}</code> ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-quantity-mandatory-5')}`,
      },
      {
        header: 'item_measure_unit',
        format: 'VARCHAR',
        exampleValue: `
          <code>${i18n.t('ctx.import.common__csv-column-details__volume-unit')}</code><br>
          <code>${i18n.t('ctx.import.common__csv-column-details__light-weight-unit')}</code><br>
          <code>${i18n.t('ctx.import.common__csv-column-details__unit')}</code><br>
          <code>${i18n.t('ctx.import.common__csv-column-details__heavy-weight-unit')}</code><br>
          <code>${i18n.t('ctx.import.common__csv-column-details__seed-unit')}</code>
`,
        description: `
          ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-unit-description-1')} <br><br>
          ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-unit-description-1')} <code>${i18n.t('ctx.import.common__csv-column-details__unit')}</code><br>
          ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-unit-description-1')} <code>${i18n.t('ctx.import.common__csv-column-details__heavy-weight-unit')}</code>, <code>${i18n.t('ctx.import.common__csv-column-details__volume-unit')}</code> ${i18n.t('message.or')} <code>${i18n.t('ctx.import.common__csv-column-details__light-weight-unit')}</code><br>
          ${i18n.t('ctx.import.custom-products__csv-column-details__item-mesure-unit-description-1')} <code>${i18n.t('ctx.import.common__csv-column-details__light-weight-unit')}</code> ${i18n.t('message.or')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__seed-unit')}</code>
`,
        mandatory: `${i18n.t('message.yes')}.`,
      },
      {
        header: 'item_usage',
        format: 'VARCHAR',
        exampleValue: `
           ${i18n.t('ctx.import.common__csv-column-details__example')} <br>
          <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-usage-example-1')} </code><br> <br>
          <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-usage-example-2')}</code><br>
`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-usage-description')}`,
        mandatory: `${i18n.t('message.yes')}  ${i18n.t('ctx.import.custom-products__csv-column-details__item-usage-mandatory-1')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-usage-mandatory-2')}</code>.`,
      },
      {
        header: 'item_weight',
        format: 'DECIMAL',
        exampleValue: `${i18n.t('ctx.import.custom-products__csv-column-details__item-weight-example')}`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-weight-description-1')} <br> <br> ${i18n.t('ctx.import.custom-products__csv-column-details__item-weight-description-2')}`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__item-weight-mandatory')}`,
      },
      {
        header: 'item_packaging',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__examples')} : ${i18n.t('ctx.import.custom-products__csv-column-details__item-packaging-example')}`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-packaging-description')}`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__item-packaging-mandatory')}`,
      },
      {
        header: 'product_description',
        format: 'VARCHAR',
        exampleValue: `
      ${i18n.t('ctx.import.custom-products__csv-column-details__product-description-example-1')} (<b>${i18n.t('ctx.import.custom-products__csv-column-details__product-description-example-2')}</b>). <br>
      ${i18n.t('ctx.import.custom-products__csv-column-details__product-description-example-3')}`,
        description: `
      ${i18n.t('ctx.import.custom-products__csv-column-details__product-description-description')}  <br> <br>`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__product-description-mandatory')}`,
      },
      {
        header: 'product_technical_sheet',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>https://www.fichetechnique.com/pdf</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-technical-sheet-description-1')} <br>${i18n.t('ctx.import.custom-products__csv-column-details__product-technical-sheet-description-2')}`,
        mandatory: `${i18n.t('message.no')}.`,
      },
      {
        header: 'product_manual',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>https://www.noticeproduit.com/pdf</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-manual-description-1')} <br>${i18n.t('ctx.import.custom-products__csv-column-details__product-manual-description-1')}`,
        mandatory: `${i18n.t('message.no')}.`,
      },
      {
        header: 'product_safety_sheet',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__example')} <code>https://www.fds.com/pdf</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-safety-sheet-description-1')} <br>${i18n.t('ctx.import.custom-products__csv-column-details__product-safety-sheet-description-2')}`,
        mandatory: `${i18n.t('message.no')}.`,
      },
      {
        header: 'item_seed_treatment',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.common__csv-column-details__examples')}. <code>ATT/VIBR</code>, <code>NT</code>, <code>VIBRANCE</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-seed-treatment-description-1')} <br> <br> ${i18n.t('ctx.import.custom-products__csv-column-details__item-seed-treatment-description-2')}`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__item-seed-treatment-mandatory-1')} <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-seed-treatment-mandatory-2')}</code>. <br> ${i18n.t('ctx.import.custom-products__csv-column-details__item-seed-treatment-mandatory-3')}`,
      },
      {
        header: 'item_is_organic_farming',
        format: 'BOOLEAN',
        exampleValue: `<code>TRUE</code> ${i18n.t('message.or')}<code>FALSE</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__item-is-organic-farming-description')}`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__item-is-organic-farming-mandatory-1')}  <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-is-organic-farming-mandatory-2')}</code> ou <code>${i18n.t('ctx.import.custom-products__csv-column-details__item-is-organic-farming-mandatory-3')}</code>. <br> ${i18n.t('ctx.import.custom-products__csv-column-details__item-is-organic-farming-mandatory-4')}`,
      },
      {
        header: 'product_characteristics',
        format: 'VARCHAR',
        exampleValue: `${i18n.t('ctx.import.custom-products__csv-column-details__product-description-example-1')} (<b>${i18n.t('ctx.import.custom-products__csv-column-details__product-description-example-2')}</b>). <br>
        ${i18n.t('ctx.import.custom-products__csv-column-details__product-description-example-3')}`,
        description: `
        ${i18n.t('ctx.import.custom-products__csv-column-details__product-characteristics-description-1')} <br>
        ${i18n.t('ctx.import.custom-products__csv-column-details__product-characteristics-description-2')} <br> <br>
        ${i18n.t('ctx.import.custom-products__csv-column-details__product-characteristics-description-3')} <br> <br>`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__product-characteristics-mandatory')}`,
      },
      {
        header: 'product_is_restricted_explosives_precursor',
        format: 'BOOLEAN',
        exampleValue: `<code>TRUE</code> ${i18n.t('message.or')} <code>FALSE</code>`,
        description: `${i18n.t('ctx.import.custom-products__csv-column-details__product-is-restricted-explosives-precursor-description')}`,
        mandatory: `${i18n.t('ctx.import.custom-products__csv-column-details__product-is-restricted-explosives-precursor-mandatory-1')}  <code>${i18n.t('ctx.import.custom-products__csv-column-details__product-is-restricted-explosives-precursor-mandatory-2')}</code>. <br> ${i18n.t('ctx.import.custom-products__csv-column-details__product-is-restricted-explosives-precursor-mandatory-1')}`,
      },
    ]

    const descriptionLines = [
      `${i18n.t('ctx.import.common__description-line-1')} <b>.csv</b>`,
      `${i18n.t('ctx.import.common__description-line-2')} <code>;</code>.`,
      `${i18n.t('ctx.import.common__description-line-3')}`,
      `${i18n.t('ctx.import.custom-products__description-line-4')} <code>UNIQUE</code>`,
      `${i18n.t('ctx.import.common__description-line-4')}<b>UTF8</b>.`,
    ]

    const pageTitle = computed(
      () => `${i18n.t('ctx.import.custom-products__page-title')}  ${i18n.t('message.partner')}`,
    )
    const uploadButtonLabel = computed(
      () => `${i18n.t('ctx.import.custom-products__upload-button-label-1')}
      ${i18n.t('message.partner')}
      ${i18n.t('ctx.import.custom-products__upload-button-label-2')}`,
    )
    const reportWarning = computed(() => `${i18n.t('ctx.import.custom-products__report-warning')}`)
    const informationTitle = computed(() => `${i18n.t('ctx.import.custom-products__information-title')}`)
    const nbResultText = computed(() => `${i18n.t('ctx.import.custom-products__information-result-text')}`)
    const exportText = computed(() => `${i18n.t('ctx.import.custom-products__information-export-text')}`)
    const csvColumnDetails = computed(() => csvColumnDetailsData)

    const csvProperties = computed(() => csvFileProperties)

    const disableCustomProductsImport = computed(() => {
      const excluded_cooperative_ids = window.env.PRODUITS_EXCLUSIFS_EXCLUDED_COOPERATIVE_IDS?.split('|')

      if (excluded_cooperative_ids && excluded_cooperative_ids.includes(store.getters.cooperativeId.toString())) {
        return true
      }
      return false
    })

    return {
      store,
      csvProperties,
      cooperativeCustomProductsService: cooperativeCustomProductsV2Service,
      csvColumnDetails,
      descriptionLines,
      pageTitle,
      uploadButtonLabel,
      reportWarning,
      informationTitle,
      nbResultText,
      exportText,
      disableCustomProductsImport,
    }
  },
  computed: {
    ...mapGetters(['customProductImportInformation', 'cooperativeId', 'cooperative']),
    importCsvInformation() {
      const { customProductImportInformation: { customProductCount, lastCustomProductImportAt } = {} as any } = this
      return { count: customProductCount, lastImportAt: lastCustomProductImportAt }
    },
  },
  methods: {
    ...mapActions(['setCustomProductImportInformation']),
    downloadCSV() {
      return cooperativeCustomProductsV2Service.downloadCustomProducts(
        this.cooperativeId,
        (this.cooperative as any).name,
      )
    },
  },
})
