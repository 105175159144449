
import { defineComponent, ref } from 'vue'

import { IdfIcon } from '@invivodf/ui-kit'
import PLink from '@invivodf/common/src/application/presenters/PLink/PLink.vue'

export default defineComponent({
  name: 'PMainNavigation',
  components: {
    IdfIcon,
    PLink,
  },
  props: {
    hasVisibleLabels: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const activeIndex = ref(null)
    const isOpen = ref(false)

    return { activeIndex, isOpen }
  },
  methods: {
    onLinkClick(index): void {
      this.$emit('clicked')
      this.activeIndex = index
    },
  },
})
