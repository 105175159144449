import { AxiosInstance } from 'axios'
import { DateTime } from 'luxon'
import { download } from '@invivodf/common/src/infrastructure/download.service'
import {
  AssociationApi,
  AssociationDTO,
  IAssociationDatasource,
  MetaDiscountAssociationsValidationDTO,
} from './IAssociation.datasource'
import { mapAssociationDTOFromApi } from '../mappers/Association.mapper'
import { ImportResultDTO } from './IImportResultDTO'
import { getCustomOrGenericError } from './getCustomOrGenericError'

export default class AssociationDatasource implements IAssociationDatasource {
  constructor(private readonly httpClient: AxiosInstance) {}

  async getAssociationByDiscountableIds(sellerId: number, discountableIds: string[]): Promise<AssociationDTO[]> {
    try {
      if (!discountableIds.length) {
        return []
      }

      const url = encodeURI('v2/discount-associations/query')
      const params = { seller_id: sellerId }
      const { data } = await this.httpClient.post<AssociationApi[]>(
        url,
        { discountable_ids: discountableIds },
        { params },
      )
      return data.map(mapAssociationDTOFromApi)
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async getAssociationsByReference(sellerId: number, reference: string): Promise<AssociationDTO[]> {
    try {
      const url = encodeURI('v2/discount-associations/query')
      const params = { seller_id: sellerId }
      const { data } = await this.httpClient.post<AssociationApi[]>(
        url,
        { discount_references: [reference] },
        { params },
      )
      return data.map(mapAssociationDTOFromApi)
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async importAssociations(sellerId: number, file: File): Promise<ImportResultDTO> {
    try {
      const url = encodeURI('backoffice/v2/discount-associations/import')
      const params = { seller_id: sellerId }
      const { data } = await this.httpClient.post(url, file, {
        headers: { 'Content-Type': 'text/csv' },
        params,
      })
      return data
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async downloadAssociations(sellerId: number, sellerName: string) {
    try {
      const url = encodeURI('backoffice/v2/discount-associations/export')
      const params = { seller_id: sellerId }
      const response = await this.httpClient.get(url, {
        headers: { accept: 'text/csv' },
        responseType: 'blob',
        params,
      })

      download(response.data, AssociationDatasource.createFileName(sellerName, 'discount_association'))
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async downloadMetaDiscountAssociationsByReference(reference: string, participants: number[]): Promise<void> {
    try {
      const url = encodeURI(`v2/meta-discounts/${reference}/associations/query`)
      const { data } = await this.httpClient.post(
        url,
        { participants },
        { headers: { accept: 'text/csv' }, responseType: 'blob' },
      )
      download(data, `discount_associations.csv`)
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async upsertAssociations(sellerId: number, discountId: string, discountableIds: string[]): Promise<void> {
    try {
      const url = encodeURI(`v2/discounts/${discountId}/associations`)
      const params = { seller_id: sellerId }
      await this.httpClient.put(url, { discountable_ids: discountableIds }, { params })
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async validateMetaDiscountAssociations(
    discountReference: string,
    file: File,
  ): Promise<MetaDiscountAssociationsValidationDTO> {
    try {
      const url = encodeURI(`/v2/meta-discounts/validate-step/associations`)
      const params = { reference: discountReference }
      const { data } = await this.httpClient.post<MetaDiscountAssociationsValidationDTO>(url, file, {
        headers: { 'Content-Type': 'text/csv' },
        params,
      })
      return data
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async uploadMetaDiscountAssociations(discountId: string, file: File): Promise<void> {
    try {
      const url = encodeURI(`/backoffice/v2/meta-discounts/${discountId}/associations`)
      await this.httpClient.put<MetaDiscountAssociationsValidationDTO>(url, file, {
        headers: { 'content-type': 'text/csv' },
      })
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  protected static createFileName(sellerName, exportName) {
    const date = DateTime.now().toFormat('yMMddHHmm')
    return `${sellerName}_export_${exportName}_${date}.csv`
  }
}
