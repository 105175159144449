import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'
import * as productMapper from './product.mapper'

export default class CooperativeCustomProductsService {
  constructor({ productApiHost }) {
    this.productApiHost = productApiHost
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1/cooperatives`)
  }

  async count(cooperativeId) {
    try {
      const response = await this.api.get(`${cooperativeId}/custom-products/count`)
      return response.data
    } catch (error) {
      return Promise.resolve(0)
    }
  }

  import(cooperativeId, file) {
    return ImportService.import(`${this.productApiHost}/v1/cooperatives/${cooperativeId}/custom-products`, file)
  }

  async downloadCustomProducts(cooperativeId, cooperativeName) {
    const response = await this.api.get(`/${cooperativeId}/custom-products`, { headers: { accept: 'text/csv' } })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(cooperativeName, 'custom-products'))
    document.body.appendChild(link)
    link.click()
  }

  async updateReferentialProduct(id, cooperativeId, cooperativeProduct) {
    const response = await this.api.put(`/${cooperativeId}/custom-products/${id}`, cooperativeProduct)
    return productMapper.mapFromCustomApi(response.data)
  }
}
