
import { defineComponent } from 'vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { IdfIcon, IdfStandardInputs } from '@invivodf/ui-kit'
import { ValidationProvider } from 'vee-validate-last'
import { PickerProps } from '../../../domain'
import { usePickerLocaleData } from '../../composables'

export default defineComponent({
  name: 'PDatePicker',
  components: {
    DateRangePicker,
    IdfIcon,
    IdfStandardInputs,
    ValidationProvider,
  },
  emits: ['select'],
  props: {
    rules: {
      type: Object as () => Record<string, any>,
    },
    value: {
      type: Object as () => PickerProps,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { localeData } = usePickerLocaleData()
    const onSelect = ({ startDate, endDate }: PickerProps) => {
      emit('select', { startDate, endDate })
    }
    return { localeData, onSelect }
  },
})
