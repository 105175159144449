import { DiscountMeasureUnits } from '@invivodf/discount-sdk/admin'

export const MEASURE_UNITS = {
  DEFAULT: { text: 'Sélectionnez une unité de mesure', value: '' },
  ANY: { text: 'Toutes les unités', value: DiscountMeasureUnits.Any },
  TON: { text: 'T - Tonne', value: DiscountMeasureUnits.Ton },
  UNIT: { text: 'U - Unité', value: DiscountMeasureUnits.Unit },
  KILO: { text: 'Kg - Kilogramme', value: DiscountMeasureUnits.Kilo },
  LITER: { text: 'L - Litre', value: DiscountMeasureUnits.Liter },
} as const
