
import { DateTime } from 'luxon'
import { computed, PropType, defineComponent } from 'vue'
import { luxonDateDisplayFormat } from '../luxon.constants'
import CStepSubGroup from './CStepSubGroup.vue'
import { Discount } from '../../domain/Discount'

export default defineComponent({
  name: 'CDiscountSettingsInfos',
  components: { CStepSubGroup },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  setup() {
    return {
      isSegmentFeatureEnabled: computed(() => window.env.DISCOUNT_SEGMENT_FEATURE === 'yes'),
      isSalesFeatureEnabled: computed(() => window.env.DISCOUNT_SALES_FEATURE === 'yes'),
      isFlashFeatureEnabled: computed(() => window.env.DISCOUNT_FLASH_SALE_FEATURE === 'yes'),
      formatDate(date) {
        return DateTime.fromJSDate(date).isValid ? DateTime.fromJSDate(date).toFormat(luxonDateDisplayFormat) : ''
      },
      valueOrDefault(value) {
        if (typeof value === 'boolean') return value ? 'oui' : 'non'
        return value || '-'
      },
    }
  },
})
