
import { PropType } from 'vue'
import { useMetaDiscount } from '../composables/meta-discount/useMetaDiscount'
import CDiscountSellersUpload from './inputs/CDiscountSellersUpload/CDiscountSellersUpload.vue'
import CEditIdentificationInformation from './CEditIdentificationInformation.vue'
import CEditDiscountSetting from './CEditDiscountSetting.vue'
import CEditApplication from './CEditApplication.vue'
import { IUseGeneralInformationStep } from '../composables/useGeneralInformationStep'

export default {
  name: 'CEditInformation',
  components: {
    CEditIdentificationInformation,
    CEditDiscountSetting,
    CEditApplication,
    CDiscountSellersUpload,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    inputs: {
      type: Object as PropType<IUseGeneralInformationStep['inputs']>,
      required: true,
    },
    isDiscountWithAmount: {
      type: Boolean,
      required: true,
    },
    discountType: {
      type: String,
      required: true,
    },
    discountScope: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isUsingMetaDiscount } = useMetaDiscount()
    return { isUsingMetaDiscount }
  },
}
