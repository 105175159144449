
import { defineComponent, ref } from 'vue'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { LogisticOfferLineItem } from '../../../domain'
import { useLogisticOfferWording } from '../../composables'
import PContextMenu from '../PContextMenu/PContextMenu.vue'
import PContextButton from '../PContextButton/PContextButton.vue'
import PDeletionModal from '../PDeletionModal/PDeletionModal.vue'

export default defineComponent({
  name: 'PLogisticOfferList',
  emits: ['delete-offer'],
  components: {
    PContextButton,
    PContextMenu,
    PDeletionModal,
  },
  props: {
    logisticOffers: {
      type: Array as () => LogisticOfferLineItem[],
    },
  },
  setup(props, { emit }) {
    const menu = ref()
    const selectedOffer = ref<LogisticOfferLineItem>()
    const isModalVisible = ref(false)
    const { wording } = useLogisticOfferWording()
    const router = useRouter()
    const openContextMenu = (e: MouseEvent, logisticOffer: LogisticOfferLineItem): void => {
      selectedOffer.value = logisticOffer
      menu.value.open(e)
    }
    const onModalClose = (): void => {
      isModalVisible.value = false
    }
    const onModalOpen = (): void => {
      isModalVisible.value = true
    }
    const deleteOffer = (): void => {
      emit('delete-offer', selectedOffer.value)
      onModalClose()
    }
    const goToOffer = (): void => {
      if (selectedOffer.value)
        router.push({ name: 'edit-logistic-offer', params: { reference: selectedOffer.value.reference } })
    }
    return {
      deleteOffer,
      goToOffer,
      isModalVisible,
      menu,
      onModalClose,
      onModalOpen,
      openContextMenu,
      selectedOffer,
      wording,
    }
  },
})
