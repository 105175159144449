import { GuardAuthType, isAuthorized } from './isAuthorized'

type RouteAuth = { permissions: string[]; type: GuardAuthType }

export interface Route {
  meta?: { auth?: RouteAuth }
}

export const isRouteAuthorized = async (route: Route): Promise<Boolean> => {
  if (route.meta?.auth) {
    return isAuthorized(route.meta.auth)
  }

  return true
}
