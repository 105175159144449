
import { defineComponent } from 'vue'
import { useLogisticOfferWording } from '../../composables'

export default defineComponent({
  name: 'PMandatory',
  setup() {
    const { wording } = useLogisticOfferWording()
    return { wording }
  },
})
