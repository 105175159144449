export function validRangeEndValidator() {
  return {
    message() {
      return 'La valeur doit être supérieure à {rangeStart}'
    },
    validate(rangeEnd: string, { rangeStart }) {
      if (rangeEnd === '-') return true
      return +rangeEnd > +rangeStart
    },
    params: ['rangeStart'],
  }
}
