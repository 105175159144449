
import { defineComponent, PropType } from 'vue'
import { provideDiscountUpdateForm } from '../application/composables/provideDiscountUpdateForm'
import PBackButton from '../application/components/presenters/PBackButton.vue'
import CDiscountForm from '../application/components/CDiscountForm.vue'
import { DiscountPolicy } from '../domain/DiscountPolicy'

export default defineComponent({
  name: 'VUpdateDiscount',
  components: {
    PBackButton,
    CDiscountForm,
  },
  props: {
    discountId: {
      type: String,
      required: true,
    },
    discountPolicyId: {
      type: String as PropType<DiscountPolicy['id']>,
      required: true,
    },
  },
  setup({ discountId, discountPolicyId }) {
    const discountPolicy = DiscountPolicy.fromId(discountPolicyId)
    provideDiscountUpdateForm(discountId, discountPolicy)
  },
})
