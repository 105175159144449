/* eslint-disable camelcase */
import type { OfferSchema } from '@b2ag/types-api-schemas'
import { enhanceLogisticOffersForConfig } from '@invivodf/module-logistic-offer-client-sdk'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

export function decomposeOfferId(id: string) {
  if (!id || typeof id !== 'string' || (typeof id === 'string' && (!id.length || !/:/g.test(id)))) {
    return {}
  }
  return {
    variantInvivoId: id.split(':')[1],
    sellerCustomId: id.split(':')[2],
  }
}

export function mapFromApiV3(offer = {} as OfferSchema) {
  const { variantInvivoId, sellerCustomId } = decomposeOfferId(offer.offer_id)
  return {
    id: offer.offer_id,
    measurementPrice: offer.base_measurement_price,
    unitPrice: offer.base_unit_price,
    sellerCustomId,
    isTechExclusive: offer.is_tech_exclusive,
    shippingStartDate: offer.shipping_start_date,
    shippingEndDate: offer.shipping_end_date,
    shippingMethodList: offer.shipping_method_list,
    validityStartDate: offer.validity_start_date,
    validityEndDate: offer.validity_end_date,
    validityStatus: offer.validity_status,
    priceType: camelCase(offer.price_type),
    quantityMin: offer.quantity_min?.toString(),
    quantityMax: offer.quantity_max?.toString(),
    quantityStep: offer.quantity_step?.toString(),
    intervalName: offer.interval_name,
    segmentList: offer.segment_list,
    offerType: offer.type || '',
    description: offer.description,
    allowedWithoutStock: offer.allowed_without_stock,
    measureUnit: offer.measure_unit,
    offerTechnicalInformation: offer.technical_information,
    variantInvivoId,
    measureUnitPrice: offer.measure_unit_price?.toString(),
    paymentDueDateReferences: offer.payment_due_date_references,
    restrictedSalesMax: offer.restricted_sales_max?.toString(),
    restrictedSalesMin: offer.restricted_sales_min?.toString(),
  }
}

export function mapToApiV3(offer: any = {}): OfferSchema {
  return {
    variant_id: offer.variantId,
    base_measurement_price: offer.measurementPrice,
    base_unit_price: offer.unitPrice,
    seller_custom_id: offer.sellerCustomId,
    is_tech_exclusive: offer.isTechExclusive,
    shipping_start_date: offer.shippingStartDate || '',
    shipping_end_date: offer.shippingEndDate || '',
    shipping_method_list: offer.shippingMethodList,
    validity_start_date: offer.validityStartDate,
    validity_end_date: offer.validityEndDate || '',
    // @ts-ignore EXPLIQUEZ POURQUOI VOUS IGNOREZ CETTE ERREUR
    price_type: snakeCase(offer.priceType),
    quantity_min: +offer.quantityMin || undefined,
    quantity_max: +offer.quantityMax || undefined,
    quantity_step: +offer.quantityStep || undefined,
    interval_name: offer.intervalName?.trim() || undefined,
    segment_list: offer.segmentList,
    type: offer.offerType || undefined,
    description: offer.description || undefined,
    allowed_without_stock: offer.allowedWithoutStock,
    technical_information: offer.offerTechnicalInformation || '',
    measure_unit_price: +offer.measureUnitPrice,
    measure_unit: offer.measureUnit,
    payment_due_date_references: offer.paymentDueDateReferences,
    restricted_sales_max: +offer.restrictedSalesMax || null,
    restricted_sales_min: +offer.restrictedSalesMin || null,
  }
}

export function mapListFromApi(offers: any[] = []) {
  return offers.map((offer) => {
    const { variantInvivoId, sellerCustomId } = decomposeOfferId(offer.offerId)

    return {
      id: offer.offerId,
      measurementPrice: offer.baseMeasurementPrice,
      unitPrice: offer.baseUnitPrice,
      sellerCustomId,
      isTechExclusive: offer.isTechExclusive,
      shippingStartDate: offer.shippingStartDate,
      shippingEndDate: offer.shippingEndDate,
      shippingMethodList: offer.shippingMethodList,
      logisticOffers: enhanceLogisticOffersForConfig(offer.logisticOffers),
      validityStartDate: offer.validityStartDate,
      validityEndDate: offer.validityEndDate,
      validityStatus: offer.validityStatus,
      priceType: camelCase(offer.priceType),
      quantityMin: offer.quantityMin?.toString(),
      quantityMax: offer.quantityMax?.toString(),
      quantityStep: offer.quantityStep?.toString(),
      intervalName: offer.intervalName,
      segmentList: offer.segmentList,
      offerType: offer.type || '',
      description: offer.description,
      allowedWithoutStock: offer.allowedWithoutStock,
      measureUnit: offer.measureUnit,
      offerTechnicalInformation: offer.technicalInformation,
      variantInvivoId,
      measureUnitPrice: offer.measureUnitPrice?.toString(),
      paymentDueDateReferences: offer.paymentDueDateReferences,
      restrictedSalesMax: offer.restrictedSalesMax?.toString(),
      restrictedSalesMin: offer.restrictedSalesMin?.toString(),
    }
  })
}
