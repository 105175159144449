<template>
  <div class="element-loader">
    <p><slot></slot></p>
    <IdfLoader />
  </div>
</template>
<script>
import { IdfLoader } from '@invivodf/ui-kit'

export default {
  name: 'CElementLoading',
  components: {
    IdfLoader,
  },
}
</script>
<style lang="scss" scoped>
.element-loader {
  @include idf-font('l');
  margin-top: var(--idf-spacing-5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--idf-color-neutral-500);
}
</style>
