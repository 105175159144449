
/* eslint-disable camelcase */
import { computed, defineComponent, PropType, Ref, ref, toRefs } from 'vue'
import { IdfBreadcrumb, IdfLoader, IdfButton, IdfTextIcon } from '@invivodf/ui-kit'
import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { use18n } from '@invivodf/locales/src/use18n'
import CImportHelp from '../CImportHelp/CImportHelp.vue'
import CImportReport from '../CImportReport/CImportReport.vue'
import CUploadCsv from '../CUploadCsv/CUploadCsv.vue'

interface Report {
  id_name: string
  summary: {
    imported_count: number
    added_count: number
    updated_count: number
    deleted_count: number
    line_error_count: number
  }
  line_errors: {
    line: number
    id: string
    messages: string[]
  }[]
  file_errors: string[]
}

interface ImportService {
  // eslint-disable-next-line no-unused-vars
  import: (sellerId: number, file: any) => Promise<Report>
}

interface ColumnDocumentation {
  header: string
  format: string
  exampleValue?: string
  description: string
  mandatory: string
}

export default defineComponent({
  name: 'CImportBase',
  components: {
    CImportHelp,
    CImportReport,
    CUploadCsv,
    IdfLoader,
    IdfButton,
    IdfTextIcon,
    IdfBreadcrumb,
  },
  props: {
    importFileService: {
      type: Object as PropType<ImportService>,
      required: true,
    },
    csvProperties: {
      type: Object as PropType<{ name: any; full_path: string }>,
      default: () => ({ name: {}, full_path: '' }),
    },
    pageTitle: {
      type: String,
      default: '',
    },
    importSectionTitle: {
      type: String,
      default: '',
    },
    uploadButtonLabel: {
      type: String,
      default: '',
    },
    csvColumnDetails: {
      type: Array as PropType<ColumnDocumentation[]>,
      default: () => [],
    },
    possibleImportCases: {
      type: Object,
      default: () => {},
    },
    reportWarning: {
      type: String,
    },
    descriptionLines: {
      type: Array,
      default: () => [],
    },
    importDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { importFileService, pageTitle, importSectionTitle, uploadButtonLabel } = toRefs(props)
    const seller = useSeller()
    const router = useRouter()
    const loadingInProgress = ref(false)
    const report: Ref<Report | null> = ref(null)
    const i18n = use18n()
    const emptyReportWithTechnicalError = {
      id_name: '',
      summary: {
        imported_count: 0,
        updated_count: 0,
        added_count: 0,
        deleted_count: 0,
        line_error_count: 0,
      },
      file_errors: [`${i18n.t('ctx.import.base__file-errors')}`],
      line_errors: [],
    }
    const importCsv = async (file) => {
      loadingInProgress.value = true
      try {
        report.value = await importFileService.value.import(seller.value.id, file)
      } catch (error) {
        report.value = error?.response?.data?.file_errors?.length ? error.response.data : emptyReportWithTechnicalError
      } finally {
        loadingInProgress.value = false
        emit('afterLoading')
      }
    }

    const back = () => {
      if (report.value) {
        report.value = null
      } else {
        router.back()
      }
    }

    const breadcrumbsItems = computed(() => {
      return [
        {
          label: `${i18n.t('breadcrumbs.home')}`,
          link: { name: 'home' },
          icon: 'home-filled',
        },
        {
          label: `${i18n.t('breadcrumbs.parameters')}`,
          link: { name: 'parameters' },
        },
        {
          label: pageTitle.value || `${i18n.t('ctx.import.base__default-page-title-prop')}`,
        },
      ]
    })
    const localizedPageTitle = computed(
      () => pageTitle.value || `${i18n.t('ctx.import.base__default-page-title-prop')}`,
    )
    const localizedImportSectionTitle = computed(
      () => importSectionTitle.value || `${i18n.t('ctx.import.base__default-import-section-title-prop')}`,
    )
    const localizedUploadButtonLabel = computed(
      () => uploadButtonLabel.value || `${i18n.t('ctx.import.base__default-upload-button-label-prop')}`,
    )

    return {
      sellerId: computed(() => seller.value.id),
      importCsv,
      loadingInProgress,
      breadcrumbsItems,
      back,
      report,
      localizedPageTitle,
      localizedImportSectionTitle,
      localizedUploadButtonLabel,
    }
  },
})
