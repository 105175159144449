
import { computed, ComputedRef, defineComponent, ref, watchEffect } from 'vue'
import { IdfAlertBanner, IdfButton, IdfTextIcon } from '@invivodf/ui-kit'
import { ValidationObserver } from 'vee-validate-last'
import {
  useLogisticOffer,
  useLogisticOfferPricing,
  useLogisticOfferSteps,
  useLogisticOfferWording,
} from '../../composables'
import { PPricingChoice } from '../../presenters'
import CAdvancedPricing from '../CAdvancedPricing/CAdvancedPricing.vue'

export default defineComponent({
  name: 'CLogisticOfferPricingStep',
  emits: ['submit-step'],
  components: {
    CAdvancedPricing,
    IdfAlertBanner,
    IdfButton,
    IdfTextIcon,
    PPricingChoice,
    ValidationObserver,
  },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const { logisticOffer, setLogisticOfferPricing } = useLogisticOffer()
    const { isStepValid } = useLogisticOfferSteps()
    const { addRule, deleteRule, updateRuleRangeEnd } = useLogisticOfferPricing()
    const banner = ref()
    const isStandardPricingType: ComputedRef<boolean> = computed(() =>
      ['order', 'logistic_order'].includes(logisticOffer.value.pricing.type || ''),
    )
    const isAdvancedPricingType: ComputedRef<boolean> = computed(() => logisticOffer.value.pricing.type === 'advanced')
    const onSubmit = (): void => {
      emit('submit-step')
    }
    const onSkip = (): void => {
      setLogisticOfferPricing({})
      onSubmit()
    }

    watchEffect((): void => {
      if (isStandardPricingType.value) {
        banner.value?.show()
      } else {
        banner.value?.hide()
      }
    })
    return {
      addRule,
      banner,
      deleteRule,
      isAdvancedPricingType,
      isStandardPricingType,
      isStepValid,
      logisticOffer,
      onSkip,
      onSubmit,
      updateRuleRangeEnd,
      wording,
    }
  },
})
