<template>
  <div class="details-paiements-cb__heading container">
    <PHeader :breadcrumb="breadcrumbsItems" title="Résumé des paiements par CB" />

    <section v-if="loaded">
      <PInfoMessage v-if="error" data-test-error-message
        >Vous n’avez pas encore créé un compte Stripe, veuillez contacter l'équipe {{ $t('message.brand') }} pour
        obtenir un lien de création de compte sur Stripe
      </PInfoMessage>
      <p v-else data-test-success-message>
        Accédez à votre dashboard Stripe pour visualiser les transactions par carte bancaire sur
        {{ $t('message.frontAgri') }}
      </p>
      <div class="details-paiements-cb__section">
        <IdfButton
          :disabled="error"
          title="Ouvrir le dashboard Stripe dans un nouvel onglet"
          @click="redirectToDashboard"
          data-test-button
        >
          Accéder au dashboard Stripe
        </IdfButton>
      </div>
    </section>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'
import PHeader from '@invivodf/common/src/application/presenters/PHeader.vue'
import { stripeDashboardRedirect } from '@/services'
import PInfoMessage from '../../presenters/PInfoMessage.vue'

export default {
  name: 'CDetailsPaiementsCb',
  components: { PInfoMessage, PHeader, IdfButton },
  data() {
    return {
      dashboardUrl: '',
      error: false,
      breadcrumbsItems: [
        {
          label: 'Accueil',
          link: { name: 'home' },
          icon: 'home-filled',
        },
        {
          label: 'Paramètres',
          link: { name: 'parameters' },
        },
        {
          label: 'Résumé des paiements par CB',
        },
      ],
    }
  },
  computed: {
    loaded() {
      return this.dashboardUrl || this.error
    },
  },
  async created() {
    try {
      this.dashboardUrl = await stripeDashboardRedirect.dashboardLink(this.$store.getters.cooperativeId)
      // This call will both check if the coop have a Stripe account AND activate 2FA if so
      await this.callFetch(this.dashboardUrl)
    } catch (e) {
      this.error = true
    }
  },
  methods: {
    async redirectToDashboard() {
      window.open(this.dashboardUrl, '_blank')
    },
    callFetch(url) {
      return fetch(`${url}`, { mode: 'no-cors' })
    },
  },
}
</script>
<style lang="scss" scoped>
.details-paiements-cb__section {
  margin-top: var(--idf-spacing-4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
