import { reactive, watch } from 'vue'

export const VALIDATORS = {
  required: (data) =>
    !(
      data === null ||
      data === '' ||
      (Array.isArray(data) && data.length === 0) ||
      (typeof data === 'object' && Object.keys(data).length === 0)
    ),
  minValue: (data, minValue) => data > minValue,
  maxValue: (data, maxValue) => data < maxValue,
}

type Validators = {
  required?: boolean
  minValue?: string | number
  maxValue?: string | number
}

interface Data {
  [propName: string]: {
    value: any
    validators: Validators
  }
}

type State = {
  dirty: boolean
  invalid: boolean
}

type Field = {
  value: any
  state: State
  errors: string[]
  validators: Validators
  validate: () => boolean
}

interface Fields {
  [propName: string]: Field
}

interface Form {
  fields: Fields
  state: State
  reset: () => void
}

const initField = ({ value, validators }): Field => {
  const field = {
    value,
    errors: [],
    state: {
      dirty: false,
      invalid: false,
    },
    validators,
    validate: () => true,
  } as Field

  field.validate = () => {
    field.errors = Object.keys(field.validators).filter((key) => !VALIDATORS[key](field.value, field.validators[key]))
    if (field.errors.length > 0) {
      field.state.invalid = true
      return false
    }
    field.state.invalid = false
    return true
  }

  return reactive<Field>(field)
}

// eslint-disable-next-line import/prefer-default-export
export function useValidateForm(data: Data) {
  const fields = reactive<Fields>({})

  const form = reactive<Form>({
    fields,
    state: {
      dirty: false,
      invalid: false,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reset: () => {},
  })

  form.reset = () => {
    Object.values(fields).forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      field.state.dirty = false
    })
    form.state.dirty = false
  }

  const formStateWatch = ['dirty', 'invalid']

  const checkFormState = (state) => {
    form.state[state] = !!Object.keys(fields)
      .map((key) => fields[key].state[state])
      .find((fieldState) => fieldState)
  }

  const addFields = (additionalFields) => {
    Object.keys(additionalFields).forEach((key) => {
      fields[key] = initField(additionalFields[key])

      watch(
        () => fields[key].value,
        (current, old) => {
          if (current !== old) {
            fields[key].state.dirty = true
            fields[key].validate()
          }
        },
      )

      formStateWatch.forEach((state) => {
        watch(
          () => fields[key].state[state],
          () => {
            checkFormState(state)
          },
        )
      })

      fields[key].validate()
    })
  }

  addFields(data)

  return { fields, form, addFields }
}
