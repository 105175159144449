import { ImportReport } from '@invivodf/common/src/contexts/parameters'
import { IImportReportRepository } from '../domain/IImportReportRepository'

export class RetrieveImportReports {
  constructor(public readonly importReportRepository: IImportReportRepository) {}

  async execute(sellerId: number): Promise<ImportReport[]> {
    return this.importReportRepository.listImportReports(sellerId)
  }
}
