
import { defineComponent } from 'vue'

import { IdfIcon } from '@invivodf/ui-kit'
import PLink from '@invivodf/common/src/application/presenters/PLink/PLink.vue'

export default defineComponent({
  name: 'PUserConnectionLink',
  components: {
    IdfIcon,
    PLink,
  },
  props: {
    hasVisibleLabels: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    logout(): void {
      this.$emit('logout')
    },
  },
})
