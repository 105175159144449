/* eslint-disable global-require, import/no-dynamic-require */

// Dev note: move those constants to its own file to avoid tests crashing when using products constants
export const defaultImagePerProductKind = {
  sante_vegetal: require(`@/assets/images/product/product_default_crop_protection.jpg`),
  semences: require(`@/assets/images/product/product_default_seed.jpg`),
  nutrition_vegetal_amendements: require(`@/assets/images/product/product_default_nutrition_amendments.jpg`),
  services: require(`@/assets/images/product/product_default_service.jpg`),
  equipements_exploitant: require(`@/assets/images/product/product_default_farmer_equipment.jpg`),
  equipements_culture: require(`@/assets/images/product/product_default_culture_equipment.jpg`),
  equipement_vigne_arbo: require(`@/assets/images/product/product_default_vine_equipement.jpg`),
  amenagement_exploitation: require(`@/assets/images/product/product_default_farm_development.jpg`),
}
const santeVegetalPack = require(`@/assets/images/product/product_default_crop_protection_pack.jpg`)

export function getProductDefaultImageFull(kind: string, type?: string): string {
  return type === 'Pack' ? santeVegetalPack : defaultImagePerProductKind[kind]
}

export const defaultImagePerProductKind160Px = {
  sante_vegetal: require(`@/assets/images/product/product_default_160_crop_protection.jpg`),
  semences: require(`@/assets/images/product/product_default_160_seed.jpg`),
  nutrition_vegetal_amendements: require(`@/assets/images/product/product_default_160_nutrition_amendments.jpg`),
  services: require(`@/assets/images/product/product_default_160_service.jpg`),
  equipements_exploitant: require(`@/assets/images/product/product_default_160_farmer_equipment.jpg`),
  equipements_culture: require(`@/assets/images/product/product_default_160_culture_equipment.jpg`),
  equipement_vigne_arbo: require(`@/assets/images/product/product_default_160_vine_equipement.jpg`),
  amenagement_exploitation: require(`@/assets/images/product/product_default_160_farm_development.jpg`),
}
const santeVegetalPack160Px = require(`@/assets/images/product/product_default_160_crop_protection_pack.jpg`)

export function getProductDefaultImage160Px(kind, type) {
  return type === 'Pack' ? santeVegetalPack160Px : defaultImagePerProductKind160Px[kind]
}
