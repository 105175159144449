/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { inject, provide, Ref, ref } from 'vue'
import { DiscountScopes } from '@invivodf/discount-sdk'
import { DISCOUNT_TYPES_TEXT } from '../../domain/constants/DISCOUNT_TYPES'
import { StepNames } from './StepNames'
import { DiscountPolicy } from '../../domain/DiscountPolicy'

export interface FormStep {
  valid: Ref<boolean>
  disabled: Ref<boolean>
  index: string
  title: string
  name: StepNames
}

interface FormSteps {
  discountFormStep: FormStep
  associationsFormStep: FormStep | null
  summaryStep: FormStep
  currentStepName: Ref<StepNames>
  shouldShowStep: Ref<boolean>
}

const DISCOUNT_FORM_STEPS = Symbol('DISCOUNT_FORM_STEPS')

const initFormStep = (index: string, title: string, name: StepNames): FormStep => {
  const disabled = index !== '1'
  return { valid: ref(false), disabled: ref(disabled), index, title, name }
}

export const provideFormSteps = (discountPolicy: DiscountPolicy) => {
  const types = [DISCOUNT_TYPES_TEXT.QUANTITATIVE, DISCOUNT_TYPES_TEXT.ON_REVENUE, DISCOUNT_TYPES_TEXT.SIMPLE]
  const typeText = types.find((type) => type.value === discountPolicy.type)?.text

  let index = 1

  const discountFormStep = initFormStep(
    String(index++),
    `Édition des informations de la remise ${typeText || ''}`,
    StepNames.DiscountForm,
  )

  const associationsFormStep =
    discountPolicy.scope === DiscountScopes.Cart
      ? null
      : initFormStep(String(index++), `Association d'articles`, StepNames.AssociationsForm)

  const summaryStep = initFormStep(String(index++), 'Récapitulatif et validation', StepNames.Summary)

  const currentStepName = ref(StepNames.DiscountForm)

  provide(DISCOUNT_FORM_STEPS, {
    discountFormStep,
    associationsFormStep,
    summaryStep,
    currentStepName,
  })
}

export const useFormSteps = () => {
  const { discountFormStep, associationsFormStep, summaryStep, currentStepName } = inject(
    DISCOUNT_FORM_STEPS,
    {} as FormSteps,
  )

  const steps = associationsFormStep
    ? [discountFormStep, associationsFormStep, summaryStep]
    : [discountFormStep, summaryStep]

  const goBackToStep = (stepName: StepNames) => {
    const currentStepIndex = steps.findIndex((step) => step.name === currentStepName.value)
    const currentStep = steps[currentStepIndex]
    const stepBack = steps.find((step) => step.name === stepName)

    if (stepBack && steps.indexOf(stepBack) < currentStepIndex) {
      currentStep.valid.value = false
      currentStep.disabled.value = true
      stepBack.valid.value = false
      stepBack.disabled.value = false
      currentStepName.value = stepBack.name
    }
  }

  const goToNextStep = () => {
    const currentStepIndex = steps.findIndex((step) => step.name === currentStepName.value)
    const currentStep = steps[currentStepIndex]
    const nextStep = steps[currentStepIndex + 1]

    currentStep.valid.value = true
    if (nextStep) {
      nextStep.valid.value = false
      nextStep.disabled.value = false
      currentStepName.value = nextStep.name
    }
  }

  const lockValidSteps = () => {
    steps
      .filter((step) => step.valid.value)
      .forEach((step) => {
        step.disabled.value = true
      })
  }
  const unlockValidSteps = () => {
    steps
      .filter((step) => step.valid.value)
      .forEach((step) => {
        step.disabled.value = false
      })
  }

  return {
    discountFormStep,
    associationsFormStep,
    summaryStep,
    goBackToStep,
    goToNextStep,
    lockValidSteps,
    unlockValidSteps,
  }
}
