
import { defineComponent, onMounted } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { useBoToast } from '@invivodf/common/src/application/composables/useBoToast'
import { useExclusionZones, useLogisticOffer, useLogisticOfferWording } from '../../composables'
import { PLoader } from '../../presenters'

export default defineComponent({
  name: 'CLogisticOfferExclusionZonesStep',
  components: {
    IdfButton,
    PLoader,
  },
  emits: ['submit-step'],
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const { logisticOffer, setLogisticOfferExclusionZones } = useLogisticOffer()
    const { isLoading, exclusionZones, loadExclusionZones } = useExclusionZones()
    const { showNotificationError } = useBoToast()
    const initialExclusionZones = logisticOffer.value.exclusionZones
    const onSubmit = (): void => {
      emit('submit-step')
    }
    const onSkip = (): void => {
      setLogisticOfferExclusionZones(initialExclusionZones)
      onSubmit()
    }
    onMounted(() => {
      try {
        loadExclusionZones()
      } catch (e) {
        showNotificationError(e.message)
      }
    })
    return { exclusionZones, isLoading, logisticOffer, onSkip, onSubmit, wording }
  },
})
