import { computed, ComputedRef, Ref } from 'vue'
import { DiscountTypes } from '@invivodf/discount-sdk'
import { DiscountScopes } from '@invivodf/discount-sdk/admin'
import { IUseDiscountInput, useDiscountInput } from './useDiscountInput'
import { IUseDiscountMeasureUnitInput, useDiscountMeasureUnitInput } from './useDiscountMeasureUnitInput'
import { IUseDiscountUnitInput, useDiscountUnitInput } from './useDiscountUnitInput'
import { IUseDiscountAmountInput, useDiscountAmountInput } from './useDiscountAmountInput'
import { IUseDiscountScaleInput, useDiscountScaleInput } from './useDiscountScaleInput'
import { useValidateGeneralInformations } from './useValidateGeneralInformations'
import { DiscountFormWithAmount } from '../../domain/discountForm/DiscountFormWithAmount'
import { DiscountFormWithScale } from '../../domain/discountForm/DiscountFormWithScale'
import { useLoadedForm } from './useLoadedForm'

export interface IUseGeneralInformationStep {
  inputs: {
    reference: IUseDiscountInput
    internalName: IUseDiscountInput
    unit: IUseDiscountUnitInput
    validityStartDate: IUseDiscountInput
    application: IUseDiscountAmountInput | IUseDiscountScaleInput
    publicName: IUseDiscountInput
    segment: IUseDiscountInput
    isSales: IUseDiscountInput
    measureUnit: IUseDiscountMeasureUnitInput | null
    validityEndDate: IUseDiscountInput
    isFlash: IUseDiscountInput
    promoCode: IUseDiscountInput
    nbOverallUsesMax: IUseDiscountInput
    nbCustomerUsesMax: IUseDiscountInput
    groupSize: IUseDiscountInput
  }
  isDiscountWithAmount: ComputedRef<boolean>
  discountType: ComputedRef<DiscountTypes>
  discountScope: ComputedRef<DiscountScopes>
  validateDiscountStep: () => Promise<void>
}

export const useGeneralInformationStep = (): IUseGeneralInformationStep => {
  const form = useLoadedForm()

  const isDiscountWithAmount = computed(() => form.value.isDiscountWithAmount())
  const discountType = computed(() => form.value.type)
  const discountScope = computed(() => form.value.scope)

  const reference = useDiscountInput(form, 'reference')
  const internalName = useDiscountInput(form, 'internalName')
  const validityStartDate = useDiscountInput(form, 'validityStartDate')
  const validityEndDate = useDiscountInput(form, 'validityEndDate')
  const publicName = useDiscountInput(form, 'publicName')
  const segment = useDiscountInput(form, 'segment')
  const measureUnit = form.value.measureUnit ? useDiscountMeasureUnitInput(form) : null
  const unit = useDiscountUnitInput(form)
  const application = isDiscountWithAmount.value
    ? useDiscountAmountInput(form as Ref<DiscountFormWithAmount>)
    : useDiscountScaleInput(form as Ref<DiscountFormWithScale>)
  const isFlash = useDiscountInput(form, 'isFlash')
  const isSales = useDiscountInput(form, 'isSales')
  const promoCode = useDiscountInput(form, 'promoCode')
  const nbOverallUsesMax = useDiscountInput(form, 'nbOverallUsesMax')
  const nbCustomerUsesMax = useDiscountInput(form, 'nbCustomerUsesMax')
  const groupSize = useDiscountInput(form, 'groupSize')

  const inputs = {
    reference,
    internalName,
    segment,
    validityStartDate,
    validityEndDate,
    publicName,
    unit,
    measureUnit,
    application,
    isFlash,
    isSales,
    promoCode,
    nbOverallUsesMax,
    nbCustomerUsesMax,
    groupSize,
  }

  const validateForm = () => {
    Object.keys(inputs).forEach((key) => {
      if (inputs[key]) inputs[key].validate()
    })
  }

  const { validateDiscountStep } = useValidateGeneralInformations(validateForm, form)

  return {
    inputs,
    isDiscountWithAmount,
    discountType,
    discountScope,
    validateDiscountStep,
  }
}
