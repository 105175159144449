import { LogisticOfferLineItem } from '../domain'
import { convertLogisticOffertoLogisticOfferItem } from '../infrastructure/logistic-offer.mapper'
import LogisticRepository from '../infrastructure/logistic.repository'

export default class GetLogisticOfferList {
  private readonly repository: LogisticRepository

  constructor(repository: LogisticRepository) {
    this.repository = repository
  }

  async withoutAssociations(sellerId: string): Promise<LogisticOfferLineItem[]> {
    const logisticOfferList = await this.repository.getAll(sellerId)
    return logisticOfferList.map(convertLogisticOffertoLogisticOfferItem)
  }

  async withAssociations(sellerId: string): Promise<LogisticOfferLineItem[]> {
    const logisticOfferList = await this.repository.getAllWithAssociations(sellerId)
    return logisticOfferList.map(convertLogisticOffertoLogisticOfferItem)
  }
}
