import { extend } from 'vee-validate-last'
import { validRangeEndValidator } from '@invivodf/common/src/contexts/logistic-offer/rules/price-range.validator'
import {
  validDateEndValidator,
  validDateStartValidator,
} from '@invivodf/common/src/contexts/logistic-offer/rules/validity-period.validator'
import { uniqueReferenceValidator } from '../../contexts/logistic-offer/rules/unique-reference.validator'

export const installCommonCustomValidateRules = () => {
  extend('uniqueReferenceValidator', uniqueReferenceValidator())
  extend('validRangeEndValidator', validRangeEndValidator())
  extend('validDateStartValidator', validDateStartValidator())
  extend('validDateEndValidator', validDateEndValidator())
}
