import { getStore } from '@/store'
import { computed } from 'vue'

export function usePartner() {
  const store = getStore()

  const partnerId = computed<number | undefined>(() => store.getters.cooperativeId)
  const partnerName = computed<string | undefined>(() => store.getters.cooperativeName)

  return { partnerId, partnerName }
}
