export const luxonDateDisplayFormat = 'dd/MM/y HH:mm'

/**
 * Options à passer en param de la méthode de luxon `DateTime.toISO()` pour avoir un format "2022-12-14T00:00"
 * Ce format est le seul format de date accepté par l'input de type `<input type="datetime-local">`
 * - `{ includePrefix: true }` ajoute le `T` au début de la partie horaire de la date
 * - `{ supressSeconds: true }` supprime les secondes de la partie horaire de la date
 * - `{ includeOffset: false }` supprime le décalage horaire (ex: `Z` ou `+01`) de la partie horaire de la date
 */
export const luxonISOOptionsForDatetimeInput = {
  includePrefix: true,
  suppressSeconds: true,
  includeOffset: false,
} as const
