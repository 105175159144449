import { computed, inject, provide, Ref, ref } from 'vue'

interface IConditionTerm {
  title: string
  term: string
}

interface IUploadCondition {
  allowed: boolean
  content: IConditionTerm
}

export enum ConditionType {
  ADVICE_SALE_SEPARATION_LAW = 'ADVICE_SALE_SEPARATION_LAW',
}

const technicians = window.env.VUE_APP_CONTEXT === 'aladin' ? 'techniciens' : 'ARCs'

export const IMPORT_CONDITION_TERMS = {
  ADVICE_SALE_SEPARATION_LAW: {
    title: 'Loi séparation conseil-vente',
    term: `J'atteste avoir informé et formé mes ${technicians} sur l'ordonnance n° 2019-361 du 24 avril 2019 relative à l'indépendance des activités de conseil à l'utilisation des produits phytopharmaceutiques et au dispositif de certificats d'économie de produits phytopharmaceutiques.`,
  },
}

export const UploadConditionSymbol = Symbol('Upload condition state')

export function useUploadCondition() {
  const defaultConditionState = {
    uploadCondition: ref({
      allowed: true,
      content: {},
    }),
    hasCondition: false,
    toggleUploadAllowed: () => false,
  }
  return inject(UploadConditionSymbol, defaultConditionState)
}

export function provideUploadCondition() {
  const refUploadCondition = {
    allowed: true,
    content: {
      title: '',
      term: '',
    },
  }
  const uploadCondition: Ref<IUploadCondition> = ref(refUploadCondition)

  const setUploadCondition = (conditionType: ConditionType): void => {
    refUploadCondition.content = IMPORT_CONDITION_TERMS[conditionType]
    refUploadCondition.allowed = false
  }

  const toggleUploadAllowed = (): void => {
    refUploadCondition.allowed = !refUploadCondition.allowed
  }

  const hasCondition = () => computed(() => !!uploadCondition.value.content.title)

  const uploadConditionState = {
    uploadCondition,
    hasCondition,
    setUploadCondition,
    toggleUploadAllowed,
  }

  provide(UploadConditionSymbol, uploadConditionState)

  return uploadConditionState
}
