
import { computed, defineComponent, PropType } from 'vue'
import { DISCOUNT_TYPES_TEXT } from '@invivodf/common/src/contexts/discount/domain/constants/DISCOUNT_TYPES'
import IdfStep from './IdfStep/IdfStep.vue'
import CDiscountSettingsInfos from './CDiscountSettingsInfos.vue'
import CDiscountApplicationDetail from './CDiscountApplicationDetail.vue'
import CDiscountIdentificationInfos from './CDiscountIdentificationInfos.vue'
import CAssociatedParticipants from './CAssociatedParticipants.vue'
import CStepSubGroup from './CStepSubGroup.vue'
import { Discount } from '../../domain/Discount'
import { DiscountPeriodStatusEnum } from '../../domain/statusByPeriod'

export default defineComponent({
  name: 'CGeneralInformationsDetailStep',
  components: {
    CDiscountSettingsInfos,
    CDiscountApplicationDetail,
    CDiscountIdentificationInfos,
    CAssociatedParticipants,
    CStepSubGroup,
    IdfStep,
  },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
    discountStatus: {
      type: String as PropType<DiscountPeriodStatusEnum>,
      required: true,
    },
  },
  setup({ discount }) {
    const findExistDiscountType = computed(() => {
      return [DISCOUNT_TYPES_TEXT.QUANTITATIVE, DISCOUNT_TYPES_TEXT.ON_REVENUE, DISCOUNT_TYPES_TEXT.SIMPLE].find(
        (type) => type.value === discount.policy.type,
      )
    })

    return { findExistDiscountType }
  },
})
