import parse from 'parse-link-header'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

export default class OrderService {
  constructor({ orderApiHost }) {
    this.orderApiHost = orderApiHost
    this.api = createAxiosClientWithAladinBearer(`${orderApiHost}/v3/orders`)
  }

  async get(orderId) {
    const result = await this.api.get(`/${orderId}`)
    return result.data
  }

  /**
   * Deprecated - Use listWithPagination without giving page instead
   * @param cooperativeId
   * @param statuses
   * @param search
   * @returns {Promise<Object>}
   */
  async list({ cooperativeId, statuses, search } = {}) {
    const { rows } = await this.listWithPagination({ cooperativeId, statuses, search })
    return rows
  }

  /**
   * Return object containing the total of pages and the rows corresponding to the given page.
   * @param cooperativeId
   * @param statuses
   * @param search
   * @param page
   * @param region
   * @param paymentMethod
   * @param startDate
   * @param endDate
   * @returns {Promise<{totalPages: Number, rows: Object}>}
   */
  async listWithPagination({ cooperativeId, statuses, search, page, region, paymentMethod, startDate, endDate } = {}) {
    const status = statuses && statuses.join(',')
    const params = {
      cooperative_id: cooperativeId,
      status,
      page,
      region,
      payment_method: paymentMethod,
      start_date: startDate,
      end_date: endDate,
    }
    if (search) {
      params.q = search
    }
    const response = await this.api.get('/search', {
      params,
    })
    const parsedHeaders = parse(response.headers.link)
    const totalPages = (parsedHeaders && parsedHeaders.last && parsedHeaders.last.page) || 1
    return { totalPages: Number.parseInt(totalPages, 10), rows: response.data }
  }

  async listWithPaginationHead({
    cooperativeId,
    statuses,
    search,
    page,
    region,
    paymentMethod,
    startDate,
    endDate,
  } = {}) {
    const status = statuses && statuses.join(',')
    const params = {
      cooperative_id: cooperativeId,
      status,
      page,
      region,
      payment_method: paymentMethod,
      start_date: startDate,
      end_date: endDate,
    }
    if (search) {
      params.q = search
    }
    const response = await this.api.head('/search', {
      params,
    })
    const totalPages = response.headers['content-range'].split('/')[1]
    return { totalRows: Number.parseInt(totalPages, 10) }
  }

  async update(id, props) {
    return this.api.patch(`/${id}`, props).then((response) => response.data)
  }

  async updateLineItemStatus(orderId, lineItemId, props) {
    const result = await this.api.patch(`/${orderId}/line-items/${lineItemId}`, props)
    return result.data
  }
}
