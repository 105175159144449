import { computed, ComputedRef, reactive, ref, Ref, toRefs, watch } from 'vue'
import { ISlice } from '../../domain/Discount'
import { FormSlice } from '../../domain/discountForm/fields/RequiredScale'
import { DiscountFormWithScale } from '../../domain/discountForm/DiscountFormWithScale'

export type ReactiveSlice = FormSlice
export interface IUseDiscountScaleInput {
  slices: Ref<ReactiveSlice[]>
  unit: ComputedRef<string>
  validate: () => void
  addSlice: (slice?: ISlice | undefined) => void
  removeSlice: (index: number) => void
}

type SliceWatchStopHandler = () => void

export const useDiscountScaleInput = (discountForm: Ref<DiscountFormWithScale>): IUseDiscountScaleInput => {
  const validate = () => {
    discountForm.value.scale.validate(discountForm.value.unit.validatedValue)
  }

  const slices = ref(discountForm.value.scale.validatedValue)
  const watchStopHandlers: SliceWatchStopHandler[] = []
  const watchSlice = (slice: FormSlice, index: number) => {
    const { min, max, discount } = toRefs(reactive(slice))

    const stopWatchingMin = watch(min, validate)
    const stopWatchingMax = watch(max, () => {
      const currentIndex = slices.value.indexOf(slice)
      discountForm.value.scale.refreshSliceMin(currentIndex + 1)
      validate()
    })
    const stopWatchingDiscount = watch(discount, validate)

    watchStopHandlers[index] = () => {
      stopWatchingMin()
      stopWatchingMax()
      stopWatchingDiscount()
    }
  }
  slices.value.forEach(watchSlice)

  const addSlice = () => {
    discountForm.value.scale.addSlice()
    const lastIndex = slices.value.length - 1
    watchSlice(slices.value[lastIndex], lastIndex)
  }

  const removeSlice = (index: number) => {
    discountForm.value.scale.removeSlice(index)

    watchStopHandlers[index]()
    watchStopHandlers.splice(index, 1)
  }

  const unit = computed(() => discountForm.value.unit.validatedValue)

  return { slices, validate, unit, addSlice, removeSlice }
}
