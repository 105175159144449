import { LogisticRouteName } from '../../routes/logistic-route-name'

export function useRouterRouteNames(): Record<string, Record<string, LogisticRouteName>> {
  return {
    routes: {
      list: LogisticRouteName.LIST,
      edit: LogisticRouteName.EDIT,
    },
  }
}
