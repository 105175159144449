
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { PropType } from 'vue'
import { IUseDiscountInput } from '../../composables/useDiscountInput'

export default {
  name: 'CDiscountReferenceInput',
  components: {
    IdfStandardInputs,
    IdfInput,
  },
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
