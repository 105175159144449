import DeliveryModeRepository from './infrastructure/delivery-mode.repository'
import ExclusionZonesRepository from './infrastructure/exclusion-zones.repository'
import LogisticRepository from './infrastructure/logistic.repository'
import DeleteLogisticOffer from './use-cases/DeleteLogisticOffer.usecase'
import GetDeliveryModeList from './use-cases/GetDeliveryModeList.usecase'
import GetExclusionZones from './use-cases/GetExclusionZones.usecase'
import GetLogisticOffer from './use-cases/GetLogisticOffer.usecase'
import GetLogisticOfferList from './use-cases/GetLogisticOfferList.usecase'
import SaveLogisticOffer from './use-cases/SaveLogisticOffer.usecase'

window.env = window.env || {}

const logisticApiHost = window.env.LOGISTIC_API_HOST

const logisticRepository = new LogisticRepository({ logisticApiHost })
const saveLogisticOffer = new SaveLogisticOffer(logisticRepository)
const getLogisticOfferList = new GetLogisticOfferList(logisticRepository)
const getLogisticOffer = new GetLogisticOffer(logisticRepository)
const deleteLogisticOffer = new DeleteLogisticOffer(logisticRepository)

const deliveryModeRepository = new DeliveryModeRepository({ logisticApiHost })
const getDeliveryModeList = new GetDeliveryModeList(deliveryModeRepository)

const exclusionZonesRepository = new ExclusionZonesRepository({ logisticApiHost })
const getExclusionZones = new GetExclusionZones(exclusionZonesRepository)

export {
  saveLogisticOffer,
  getLogisticOfferList,
  getLogisticOffer,
  deleteLogisticOffer,
  getDeliveryModeList,
  getExclusionZones,
}
