import { useTech } from '@/composables/useTech'
import { useEnvironment } from '@/composables/useEnvironment'
import { use18n } from '@invivodf/locales/src/use18n'
import { IBrandData } from '../../domain/IBrandData'

const getAladinIcon = () => {
  let logo = 'logo-aladin'
  const { environment } = useEnvironment()
  if (environment && ['QUA', 'TEST'].includes(environment)) logo += `-${environment.toLowerCase()}`
  return logo
}

export function useBrandData() {
  const i18n = use18n()

  const { isTech } = useTech()
  const items = [
    {
      icon: getAladinIcon(),
      id: 'aladin',
      label: i18n.t('message.brand').toString(),
      link: 'home',
      isExternal: false,
      allowed: true,
    },
    {
      id: 'frontAgri',
      label: i18n.t('message.frontAgri').toString(),
      link: window.env.FRONT_AGRI_URL,
      isExternal: true,
      allowed: true,
    },
    {
      id: 'frontTech',
      label: i18n.t('message.frontTech').toString(),
      link: window.env.FRONT_TECH_URL,
      isExternal: true,
      allowed: !!isTech.value && i18n.t('message.frontTech').toString() !== 'message.frontTech',
    },
    {
      id: 'support',
      label: i18n.t('message.baseDeConnaissances').toString(),
      link: window.env.SUPPORT_URL,
      isExternal: true,
      allowed: i18n.t('message.baseDeConnaissances').toString() !== 'message.baseDeConnaissances',
    },
  ]
  const brandData: IBrandData = {
    current: 'aladin',
    items: items.filter((item) => !!item.allowed),
  }
  return { brandData }
}
