import { DISCOUNT_UNITS } from '../../constants/DISCOUNT_UNITS'

export type FormSlice = { min: number | null; max: number | null; discount: number | null; errors: string[] }
export type FormScale = FormSlice[]

export class RequiredScale {
  public validatedValue: FormScale

  constructor(props: Omit<FormSlice, 'errors'>[]) {
    this.validatedValue = props.map((slice) => ({ ...slice, errors: [] }))
  }

  private validateMin(index: number) {
    const currentSlice = this.validatedValue[index]
    if (typeof this.validatedValue[index].min !== 'number') {
      currentSlice.errors.push('Le minimum de la tranche est obligatoire')
    }
    if (this.validatedValue[index].min! < 0) currentSlice.errors.push('Le minimum doit être un chiffre positif')
    if (index > 0 && this.validatedValue[index].min !== this.validatedValue[index - 1].max)
      currentSlice.errors.push('Le minimum de la tranche doit être égal au maximum de la tranche précédente')
  }

  private validateMax(index: number) {
    const currentSlice = this.validatedValue[index]
    const isNotLastSlice = Boolean(index !== this.validatedValue!.length - 1)
    // eslint-disable-next-line no-restricted-globals
    if (isNotLastSlice && (this.validatedValue[index].max === null || isNaN(this.validatedValue[index].max!)))
      currentSlice.errors.push('Le maximum de la tranche doit être renseigné')
    if (
      this.validatedValue[index].max !== null &&
      this.validatedValue[index].max !== undefined &&
      typeof this.validatedValue[index].max !== 'number'
    )
      currentSlice.errors.push(
        'Le maximum de la tranche doit être un nombre lorsqu‘il ne s‘agit pas de la dernière tranche',
      )
    if (
      typeof this.validatedValue[index].max === 'number' &&
      typeof this.validatedValue[index].min === 'number' &&
      this.validatedValue[index].max! <= this.validatedValue[index].min!
    )
      currentSlice.errors.push(
        `La valeur maximum d'une tranche de barème doit être strictement supérieur à la valeur minimum`,
      )
  }

  private validateDiscount(index: number, discountUnit: string) {
    const currentSlice = this.validatedValue[index]
    if (typeof this.validatedValue[index].discount !== 'number') {
      currentSlice.errors.push('Un montant est requis pour chaque tranche du barème')
    }
    if (this.validatedValue[index].discount! <= 0) currentSlice.errors.push('Le montant doit être un chiffre positif')
    if (discountUnit === DISCOUNT_UNITS[1].value && this.validatedValue[index].discount! >= 100)
      currentSlice.errors.push(`La valeur de remise d'une tranche de barème doit être inférieur à 100%`)
  }

  public validate(discountUnit: string) {
    this.validatedValue.forEach((_slice, index) => {
      this.validateSlice(index, discountUnit)
    })
  }

  public validateSlice(index: number, discountUnit: string) {
    const currentSlice = this.validatedValue[index]
    currentSlice.errors = []
    this.validateMin(index)
    this.validateMax(index)
    this.validateDiscount(index, discountUnit)
  }

  public hasErrors(): boolean {
    return this.validatedValue.some((slice) => slice.errors.length)
  }

  public setMin(min: number | null, index: number) {
    this.validatedValue[index].min = min
  }

  public addSlice() {
    const previousSliceMax = this.validatedValue[this.validatedValue.length - 1].max
    this.validatedValue.push({ min: previousSliceMax, max: null, discount: null, errors: [] })
  }

  public removeSlice(index: number) {
    this.validatedValue.splice(index, 1)
  }

  public refreshSliceMin(index: number) {
    if (index === 0 || index >= this.validatedValue.length) {
      return
    }
    const previousMax = this.validatedValue[index - 1].max
    this.setMin(previousMax, index)
  }
}
