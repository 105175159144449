import { ImportReportDTO } from './ImportReportDTO'
import { HttpClient } from './HttpClient'
import { IImportReportDataSource } from './IImportReport.dataSource'
import { getCustomOrGenericError } from './getCustomOrGenericError'

export default class ImportReportDataSource implements IImportReportDataSource {
  constructor(private readonly httpClient: HttpClient) {}

  private async findAll(sellerId: number, resource: string) {
    const { data } = await this.httpClient.get<ImportReportDTO[]>(`v2/${resource}/import-reports?seller_id=${sellerId}`)
    return data
  }

  private async findOne(sellerId: number, id: number, resource: string): Promise<ImportReportDTO> {
    try {
      const { data } = await this.httpClient.get<ImportReportDTO>(
        `v2/${resource}/import-reports/${id}?seller_id=${sellerId}`,
      )
      return data
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  public findOneAssociationImportReport(sellerId: number, id: number): Promise<ImportReportDTO> {
    return this.findOne(sellerId, id, 'discount-associations')
  }

  public async findAllAssociationImportReports(sellerId: number): Promise<ImportReportDTO[]> {
    try {
      return await this.findAll(sellerId, 'discount-associations')
    } catch (err) {
      throw getCustomOrGenericError(err, `Les rapports d'imports d'association de remises n'ont pas pu être récupérés.`)
    }
  }

  public findOneDiscountImportReport(sellerId: number, id: number): Promise<ImportReportDTO> {
    return this.findOne(sellerId, id, 'discounts')
  }

  public async findAllDiscountImportReports(sellerId: number): Promise<ImportReportDTO[]> {
    try {
      return await this.findAll(sellerId, 'discounts')
    } catch (err) {
      throw getCustomOrGenericError(err, `Les rapports d'imports de remises n'ont pas pu être récupérés.`)
    }
  }
}
