import { Discount } from '../domain/Discount'
import { Discountable, setComplianceErrors } from '../domain/Discountable'
import { IDiscountableRepository } from '../domain/IDiscountableRepository'

export class RetrieveDiscountables {
  constructor(public readonly discountRepository: IDiscountableRepository) {}

  async execute(discount: Discount, sellerId: number): Promise<Discountable[]> {
    const discountables = await this.discountRepository.getDiscountablesByReference(sellerId, discount.reference)
    return discountables.map((discountable) => setComplianceErrors(discountable, discount))
  }
}
