export const offerColumnDetails = (i18nService) => [
  {
    notice:
      '<strong class="import-help__important">Les champs en rouge ne seront pas traités. En revanche, il est nécessaire d’indiquer les entêtes ainsi que les délimiteurs pour chacun de ces champs</strong>',
  },
  {
    header: 'offer_reference',
    format: 'VARCHAR',
    exampleValue: 'Exemple : <code>OFR23450395029</code>',
    description:
      '<strong>Référence unique de l’offre (ID offre)</strong>' +
      '<br>Elle est obligatoire et unique par variante' +
      '<br>Il ne pourra y avoir qu’une seule offer_reference <code>OFR1</code> associée à la variante “IDINVIVO2”.' +
      '<br>Si vous avez plusieurs offres associées à une même variante: avec des dates différentes, des modes de livraison différents, des types de prix différents pour être sûrs que vous ayez réellement une offer reference unique vous aurez la liberté d’avoir une offer_reference de type: OFR12_IDINVIVO2_datedebut_date_de_fin_mode_de_livraison…',
    mandatory: 'Oui',
  },
  {
    header: 'offer_campaign_reference',
    ignoredField: true,
    format: 'VARCHAR',
    exampleValue: 'Exemple : <code>SVPG2019</code>',
    description: `<strong>Référence de la campagne de ${i18nService.t('message.thePartner')}</strong>`,
    mandatory: '- Oui si le nom de la campagne est indiqué (colonne suivante)<br>- Non sinon',
  },
  {
    header: 'offer_campaign_name',
    ignoredField: true,
    format: 'VARCHAR',
    exampleValue: 'Exemple : Santé du végétal et petites graines 2019',
    description:
      '<strong>Nom de la campagne</strong>' +
      '<br>Le couple référence/nom campagne doit être identique. Si tel n’est pas le cas, le dernier nom de la campagne associée à la référence sera retenu.' +
      '<br><br><strong>Note : </strong>Ce champs pourra être déprécié lorsque l’import campagne sera réalisé.',
    mandatory: '- Oui si la référence de campagne est indiqué (colonne suivante)<br>- Non sinon',
  },
  {
    header: 'offer_product_uid',
    format: 'VARCHAR',
    exampleValue: 'Exemple : <code>INVVQURQ56HVNFBGPHY</code>',
    description:
      '<strong>Identifiant de la variante à laquelle l’offre est appliquée (ID INVIVO)</strong> <br>La variante doit être préalablement associée au catalogue via l’import catalogue. <br> Si tel n’est pas le cas, l’offre ne pourra pas être créée ou modifiée.',
    mandatory: 'Oui',
  },
  {
    header: 'offer_shipping_method_list',
    format: 'VARCHAR',
    exampleValue:
      'Valeurs possibles :' +
      '<br>Toute chaîne de caractères correspondant à la référence unique (ID) d’une offre logistique peut être renseignée afin d’associer ladite offre logistique à une offre commerciale.' +
      '<br> Exemple: <br><code>click_and_collect</code>',
    description:
      '<strong>Mode(s) de livraison lié(s) à l’offre</strong>' +
      '<br>Il s’agit de la liste des modes de livraisons possibles pour une offre donnée séparés par une virgule ‘,’ si plusieurs modes sont attribués.' +
      '<br><br><strong>Note : </strong>au moins un mode de livraison doit être indiqué',
    mandatory: 'Oui',
  },
  {
    header: 'offer_validity_start_date',
    format: 'DATE ISO8601',
    exampleValue: 'Exemple : <br><code>2019-02-14T00:00:00Z+0200</code>',
    description:
      '<strong>Date de début de validité de l’offre</strong>' +
      '<br>La date de validité de l’offre exprime la date à partir de laquelle votre offre sera valide. La prise d’effet se fait à 00h01.' +
      '<br><br><strong>Note : </strong>L’heure n’est pour le moment pas exploitée.',
    mandatory: 'Oui',
  },
  {
    header: 'offer_validity_end_date',
    format: 'DATE ISO8601',
    exampleValue: 'Exemple : <br><code>2019-02-14T00:00:00Z+0200</code>',
    description:
      '<strong>Date de fin de validité de l’offre</strong>' +
      `<br><strong>Pour une création ou une mise à jour de l'offre : </strong>La date de fin de validité doit être supérieure ou égale à la date de début de validité de l’offre et à la date courante` +
      '<br><br>Si la date de fin de validité d’une offre est identique à celle de la date de début de validité alors l’offre est valable pour la journée courante.' +
      '<br><br><strong>Note : </strong>L’heure n’est pour le moment pas exploitée.',
    mandatory: '- Oui si une date de fin de livraison est indiquée<br>- Non sinon',
  },
  {
    header: 'offer_shipping_start_date',
    format: 'DATE ISO8601',
    exampleValue: 'Exemple : <br><code>2019-02-14T00:00:00Z+0200</code>',
    description:
      '<strong>Date de début de livraison de l’offre</strong>' +
      `<br><strong>Pour une création ou une mise à jour de l'offre : </strong>` +
      'La date de début de livraison doit être supérieure ou égale à la date de début de validité de l’offre.' +
      '<br><br><strong>Note : </strong>L’heure n’est pour le moment pas exploitée.',

    mandatory: 'Non',
  },
  {
    header: 'offer_shipping_end_date',
    format: 'DATE ISO8601',
    exampleValue: 'Exemple : <br><code>2019-02-14T00:00:00Z+0200</code>',
    description:
      '<strong>Date de fin de livraison de l’offre</strong>' +
      `<br><strong>Pour une création ou une mise à jour de l'offre : </strong> ` +
      '<br>- La date de fin de livraison, si présente, doit être supérieure ou égale à la date de début de validité de l’offre, si elle est présente.' +
      '<br><br>- La date de fin de livraison doit être supérieure ou égale à la date de début de livraison de l’offre si celle-ci est indiquée.',
    mandatory: 'Non',
  },
  {
    header: 'offer_mesure_unit_price',
    format: 'DECIMAL',
    exampleValue: 'Exemple : <code>10.90</code>',
    description: '<strong>Prix de l’offre à l’unité de vente</strong><br>Le prix doit être strictement supérieur à 0.',
    mandatory: 'Oui',
  },
  {
    header: 'offer_mesure_unit',
    format: 'VARCHAR',
    exampleValue: 'Valeurs possibles: <br>- L<br>- KG<br>- T<br>- unit',

    description:
      '<strong>Unité de mesure de l’offre</strong>' +
      `<br>L’unité de mesure de l’offre doit correspondre à la même unité de mesure que celle de la variante.` +
      '<br><br><strong>Note : </strong>Il est possible que nous modifions cette règle pour adresser une flexibilité de vente ultérieurement. <br>Les unités de mesure ne sont pas sensibles à la casse',
    mandatory: 'Oui',
  },
  {
    header: 'offer_price_type',
    format: 'VARCHAR',
    exampleValue:
      'Valeurs possibles: ' +
      '<br>- fixed_price' +
      '<br>- temporary_price' +
      '<br>- preorder_price' +
      '<br><br>Définition : ' +
      '<br>- fixed_price : prix fixe' +
      '<br>- temporary_price : prix provisoire' +
      '<br>- preorder_price : réservation',
    description:
      '<strong>Type de prix pour l’offre</strong>' +
      '<br><br>Les types de prix temporary_price et preorder_price ne sont pas compatibles avec des articles de la gamme sante_vegetal',
    mandatory: 'Oui',
    isPrestaServiceDependent: false,
  },
  {
    header: 'offer_price_type',
    format: 'VARCHAR',
    exampleValue:
      'Valeurs possibles: ' +
      '<br>- fixed_price' +
      '<br>- temporary_price' +
      '<br>- preorder_price' +
      '<br>- indicative_price' +
      '<br><br>Définition : ' +
      '<br>- fixed_price : prix fixe' +
      '<br>- temporary_price : prix provisoire' +
      '<br>- preorder_price : réservation' +
      '<br>- indicative_price : prix indicatif',
    description:
      '<strong>Type de prix pour l’offre</strong>' +
      '<br><br>Les types de prix temporary_price et preorder_price ne sont pas compatibles avec des articles de la gamme sante_vegetal',
    mandatory: 'Oui',
    isPrestaServiceDependent: true,
  },
  {
    header: 'offer_quota_max',
    ignoredField: true,
    format: 'INTEGER',
    exampleValue: 'Exemple : <code>1000</code><i>(Tonnes)</>',
    description: '<strong>Limite de stock en unité pour l’offre</strong><br><strong>Valeur par défaut : </strong>vide',
    mandatory: 'Non',
  },
  {
    header: 'offer_quantity_min_purchase',
    format: 'INTEGER',
    exampleValue:
      'Exemple : <code>50 </code><i>x (unité de la variante: Urée 46 Vrac 1 T)<br><br>La quantité minimale commandable sera donc de 50 T</i>',
    description:
      '<strong>Quantité minimum d’achat par commande</strong><br><strong>Valeur par défaut : </strong>1' +
      '<br><br>La quantité minimum d’achat doit être un entier positif strictement supérieur à 0. Cette valeur ne peut pas être supérieure à la quantité maximale.' +
      '<br><p>Attention: si le champs n’est pas rempli il sera par défaut à 1</p>',
    mandatory: 'Oui (Si vide, l’intervalle sera par défaut à 1)',
  },
  {
    header: 'offer_quantity_max_purchase',
    format: 'INTEGER',
    exampleValue: 'Exemple : <code>1000</code><i> x (unité de la variante: Urée 46 Vrac 1 T)</>',
    description:
      `${
        '<strong>Quantité maximum d’achat par commande</strong>' +
        '<br><strong>Valeur par défaut : </strong>vide' +
        '<br><br>La quantité maximum d’achat par commande '
      }${i18nService.t('message.membership-app-d')}. Le rôle est de limiter la quantité d’achat d’un ${i18nService.t(
        'message.membership',
      )} par commande sur une offre.` +
      `<br><br>La quantité maximale d’achat doit être un entier positif strictement supérieur à 0 et supérieur ou égal à la quantité minimale.`,
    mandatory: 'Non',
  },
  {
    header: 'offer_step',
    format: 'INTEGER',
    exampleValue: 'Exemple : <code>32</code><i>(Tonnes)</>',
    description:
      '<strong>Intervalle ou “pas” de quantité en unité de mesure pour une offre donnée</strong>' +
      '<br><strong>Valeur par défaut : </strong>1' +
      '<br><br>L’objectif est de pouvoir attribuer un incrément d’achat de votre choix.' +
      '<br>Ex: vous souhaitez vendre par intervalle de 10T votre Urée 46 Vrac 1T. Vous indiquerez:' +
      '<ul><li>Une quantité minimale : 10</li>' +
      '<li>Un intervalle: 10</li></ul>',
    mandatory: 'Oui (Si vide, l’intervalle sera par défaut à 1)',
  },
  {
    header: 'offer_interval_name',
    format: 'VARCHAR',
    exampleValue: 'Exemple : palette',
    description: `Champs libre limité à 255 caractères maximum et vous permet de nommer le champ offer_step.
Ce message s’affichera sur la fiche produit au niveau de l’offre.`,
    mandatory: 'Non',
  },
  {
    header: 'offer_type',
    format: 'VARCHAR',
    exampleValue: "Valeurs possibles: <br>Pour l'instant <br>- off_season",
    description: `Le type d’offre pourra être utilisé pour identifier celles faisant parti d’un type d'événement spécifique pour s’intégrer à un dispositif de mise en avant. Par exemple, pour les offres du moment il vous suffit de remplir le champ "offer_type" avec la valeur : off_season quand vous créez ou modifiez une offre.`,
    mandatory: 'Non <br><br>Si vide l’offre sera de type “catalogue” (soit standard)',
  },
  {
    header: 'offer_description',
    format: 'VARCHAR',
    exampleValue: 'Remboursement du surplus sous un délai d’un an à partir de la date de commande',
    description:
      '<strong>Message marketing ou complémentaire d’une offre</strong><br><br><strong>Caractères maximum</strong> (selon <a href="https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/String/length">le décompte de JavaScript</a>) : 180<br><br>Ce message s’affichera sur la fiche produit au niveau de l’offre, dans le panier, dans les mails de commandes, le détail de la commande dans l’historique.',
    mandatory: 'Non',
  },
  {
    header: 'offer_is_tech_exclusive',
    format: 'BOOLEAN',
    exampleValue: '<code>TRUE</code> ou <code>FALSE</code>',
    description:
      `<strong>Offre exclusive ${i18nService.t('message.technician')}</strong>` +
      `<br><br>Cette information va vous permettre d’identifier si l’offre ne doit être visible que par vos ${i18nService.t(
        'message.technicians',
      )}: la valeur sera à <code>true</code>, sinon elle sera à <code>false</code>.`,
    mandatory: 'Non <br><br>Si l’info est vide, l’offre sera considérée par défaut comme à <code>false</code>. ',
  },
  {
    header: 'offer_segments_list',
    format: 'VARCHAR',
    exampleValue: '<code>PREMIUM,Jeune éleveur 001</code>',
    description:
      `<strong>Listes de segments d’${i18nService.t('message.memberships')} concernés par cette offre</strong>` +
      `<br><br>Les segments ne doivent pas contenir le caractère \`;\` et chaque segment est séparé par un caractère \`,\`. <br/>Les segments sont ceux définis dans le champ \`segment_code\` de l’import SEGMENT. <br/>Si le champ est vide l’offre concerne tous les ${i18nService.t(
        'message.memberships',
      )}.<br/>Si un segment mentionné ici n’est pas défini, l’offre est créé ou mise à jour avec un avertissement. <br/>Si le segment est créé à posteriori l’offre sera activée pour le segment.<br/><strong>Note : </strong>Le caractère \`+\` est dédié à une évolution future et ne doit donc pas faire partie des segments ${i18nService.t(
        'message.memberships-app-d',
      )} pour le moment`,
    mandatory: 'Non <br><br>',
  },
  {
    header: 'offer_allowed_without_stock',
    format: 'BOOLEAN',
    exampleValue: '<code>TRUE</code> ou <code>FALSE</code>',
    description:
      '<strong>Offre disponible sans stock</strong>' +
      '<br><br>Cette information va vous permettre d’identifier si l’offre est autorisée à être achetée sans stock: la valeur sera à <code>true</code>, sinon elle sera à <code>false</code>.',
    mandatory: 'Non <br><br>Si l’info est vide, l’offre sera considérée par défaut comme à <code>false</code>. ',
  },
  {
    header: 'offer_technical_information',
    format: 'VARCHAR',
    exampleValue: '<code>offre speciale printemps 2021 - 567799765</code>',
    description: `Champs libre limité à 255 caractères maximum qui vous permet de caractériser techniquement votre offre. Ce champs ne sera jamais visible ni par vos ${i18nService.t(
      'message.memberships',
    )}, ni par vos ${i18nService.t(
      'message.technicians',
    )}. Il sera remonté dans l’API order si une offre d’une commande a cette information au moment de la commande.`,
    mandatory: 'Non',
  },
  {
    header: 'offer_payment_due_date_references',
    format: 'VARCHAR',
    exampleValue: '<code>ECH30, ECH60, ECH90</code>',
    description: `Liste des codes des échéances de paiement possibles pour l’offre. Les codes des échéances ne doivent pas contenir le caractère <code>;</code> et chaque code est séparé par un caractère <code>,</code>. Si le champs est vide ou la colonne inexistante, le paiement à échéance n’est pas actif pour l’offre. Si un des codes échéance de la liste n’est pas défini dans l'écran de paramétrage des échéances du front vendeur, cette échéance ne sera pas proposée. Les échéances seront proposées si les codes définis dans cette liste sont bien les mêmes que ceux dans l'écran de paramétrage des échéances du front vendeur.`,
    mandatory: 'Non',
  },
  {
    header: 'offer_restricted_sales_min',
    format: 'DECIMAL',
    exampleValue: 'Exemple : <code>2.64</code>',
    description: 'Intervalle de commercialisation de l’offre commerciale (borne basse). Deux décimales maximum.',
    mandatory: 'Non',
    isPrestaServiceDependent: true,
  },
  {
    header: 'offer_restricted_sales_max',
    format: 'DECIMAL',
    exampleValue: 'Exemple : <code>7.21</code>',
    description: 'Intervalle de commercialisation de l’offre commerciale (borne haute). Deux décimales maximum.',
    mandatory: 'Non',
    isPrestaServiceDependent: true,
  },
]

export const offerDescriptionLines = [
  'L’extension attendue est : <b>.csv</b>',
  'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
  'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
  "L'encoding attendu est : <b>UTF8</b>.",
  'A la différence de l’import catalogue, si une ligne est en erreur, elle est alors supprimée en base.',
]
