import IconBase from '@/icons/IconBase.vue'
import IconPrevious from '@/icons/IconPrevious.vue'

export default {
  name: 'CHeaderLogo',
  components: {
    IconBase,
    IconPrevious,
  },
  props: {
    cooperativeName: {
      type: String,
      default: null,
    },
    showPreviousButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    environmentName() {
      return window.env.ENVIRONMENT_NAME
    },
    isHomePage() {
      if (!this.$route) return false
      return this.$route.name === 'home'
    },
    notProductionEnvironment() {
      return this.environmentName !== 'PRD'
    },
  },
}
