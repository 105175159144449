import { presentShippingMethods } from './shipping-method.business'
import { ShippingWay } from './offer'

export interface QuotaForConfig {
  reference?: string
  meshId: string
  remaining: number
  initial: number
  productId: string
  techId?: string
  productKind: string
  productType: string
  thumbnail?: string
  isSoldAsUnit: boolean
  variantName: string
  variantId: string
  measureUnit: string
  quotaDetails?: {
    reference: string
    remaining: number
    initial: number
    techId: string
  }[]
  offerValidityEndDate?: Date
  offerShippingStartDate?: Date
  offerShippingEndDate?: Date
  offerShippingMethods: ShippingWay[]
  offerSegments?: string[]
}

export interface OfferForQuota {
  offerId: string
  validityEndDate?: Date
  shippingStartDate?: Date
  shippingEndDate?: Date
  shippingMethodList: ShippingWay[]
  segmentList?: string[]
}

function compareByRemainingPercentage(quota1, quota2) {
  return quota2.remaining / quota2.initial - quota1.remaining / quota1.initial
}

export function getTechQuotas(quotaList: QuotaForConfig[], technicianUid: string) {
  return quotaList.filter((quota) => quota.techId === technicianUid)
}

export function getPartnerQuota(quotaList: QuotaForConfig[]) {
  return quotaList.filter((quota) => quota.techId === undefined)
}

export function sortQuotas(quotas: QuotaForConfig[]): QuotaForConfig[] {
  return quotas.sort(compareByRemainingPercentage)
}

export function getShippingIcons(quota: QuotaForConfig): string[] {
  return presentShippingMethods(quota.offerShippingMethods)?.map((shippingPresenter) => shippingPresenter.icon)
}

export function groupQuotasByOffer(quotas: QuotaForConfig[]): QuotaForConfig[] {
  const groupedQuotas: Map<string, QuotaForConfig[]> = quotas.reduce((acc, quota) => {
    const quotasForOffer: QuotaForConfig[] = acc.get(quota.meshId) || []
    quotasForOffer.push(quota)
    acc.set(quota.meshId, quotasForOffer)
    return acc
  }, new Map<string, QuotaForConfig[]>())
  return Array.from(groupedQuotas.values()).map(
    (entry) =>
      ({
        ...entry[0],
        initial: entry.reduce((sum, quota) => sum + quota.initial, 0),
        remaining: entry.reduce((sum, quota) => sum + quota.remaining, 0),
        techId: entry.length > 1 ? undefined : entry[0].techId,
        reference: entry.length > 1 ? undefined : entry[0].reference,
        quotaDetails: entry.length > 1 ? entry.map((quotaForConfig) => ({ ...quotaForConfig })) : undefined,
      }) as QuotaForConfig,
  )
}
