<template>
  <div class="product-characteristics">
    <div class="product-characteristics__main-block" :class="{ 'edit-characteristics': isEditingCharacteristics }">
      <h4 class="product-characteristics__title">Caractéristiques</h4>

      <div
        v-if="!isEditingCharacteristics && !this.hasCharacteristics"
        class="product-characteristics__empty-characteristics"
        data-test-empty-characteristics
      >
        <div class="product-characteristics__text-block" data-test-characteristics-txt>
          Vous n'avez pas encore écrit de caractéristiques pour cet article
        </div>
        <IdfButton @click="editCharacteristics" data-test-characteristics-btn="create"
          >Écrire des caractéristiques</IdfButton
        >
      </div>

      <template v-else-if="!isEditingCharacteristics && this.hasCharacteristics">
        <adn-text-area :initialValue="inEditCharacteristics" data-test-text-area :edition="false" />
        <div class="product-characteristics__buttons-block">
          <IdfButton
            kind="link"
            icon-left="edit-outlined"
            @click="editCharacteristics"
            data-test-characteristics-btn="edit"
            >Modifier</IdfButton
          >
        </div>
      </template>

      <div v-else class="product-characteristics__edit-characteristics" data-test-edit-block>
        <adn-text-area
          v-model="inEditCharacteristics"
          :initialValue="inEditCharacteristics"
          :placeholder="this.placeholder"
          :maxContentLength="maxContentLength"
          data-test-text-area-edit
          :wysiwyg="true"
        >
          <template v-slot:content-length="{ visibleContentLength }">
            {{ charactersLeft(visibleContentLength) }}
          </template>
        </adn-text-area>
        <div class="product-characteristics__buttons-block">
          <IdfButton kind="link" @click="cancelCharacteristics" data-test-characteristics-btn="cancel">
            Annuler
          </IdfButton>
          <IdfButton @click="saveCharacteristics" data-test-characteristics-btn="save">Enregistrer</IdfButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'CProductCharacteristics',
  components: {
    IdfButton,
  },
  data() {
    return {
      editedCharacteristics: this.characteristics || '',
      inEditCharacteristics: this.characteristics || '',
      isEditingCharacteristics: false,
      placeholder: '',
      maxContentLength: 700,
    }
  },
  computed: {
    hasCharacteristics() {
      return this.editedCharacteristics !== ''
    },
  },
  props: {
    characteristics: {
      type: String,
      default: '',
    },
  },
  methods: {
    editCharacteristics() {
      this.isEditingCharacteristics = true
    },
    cancelCharacteristics() {
      this.isEditingCharacteristics = false
      this.inEditCharacteristics = this.editedCharacteristics
    },
    saveCharacteristics() {
      this.$emit('save-characteristics', this.inEditCharacteristics)
      this.editedCharacteristics = this.inEditCharacteristics
      this.isEditingCharacteristics = false
    },
    charactersLeft(contentLength) {
      const charactersLeftVar = this.maxContentLength - contentLength
      if (charactersLeftVar <= 1) {
        return `${charactersLeftVar} caractère restant`
      }
      return `${charactersLeftVar} caractères restants`
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.product-characteristics {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  &__empty-characteristics {
    display: flex;
    align-items: center;
  }
  &__edit-characteristics {
    display: flex;
    flex-direction: column;
  }
}
.product-characteristics__title {
  font-size: 18px;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-characteristics__main-block {
  background-color: var(--idf-color-neutral-100);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 20px 25px;
  max-width: 1070px;
  &.edit-characteristics {
    background: transparent;
    border: none;
  }
}
.product-characteristics__text-block {
  padding: 0;
  font-size: 16px;
  color: var(--idf-color-neutral-500);
  white-space: pre-wrap;
  flex-grow: 1;
  word-break: break-word;
}
.product-characteristics__text-block--has-characteristics {
  color: black;
}
.product-characteristics__main-block textarea {
  width: 100%;
  height: 100px;
  font-size: 1em;
  outline: 0;
  box-shadow: none;
  border: none;
  padding: 0.8rem 0;
  resize: none;
  word-break: break-all;
}
.product-characteristics__buttons-block {
  display: flex;
  justify-content: flex-end;
  gap: var(--idf-spacing-2);
}
.product-characteristics__characters-left {
  flex-grow: 1;
  color: var(--idf-color-neutral-400);
}
</style>
