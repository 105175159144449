
import { computed, defineComponent, ref } from 'vue'
import { provideRouter } from '@invivodf/common/src/application/composables/useRouter'
import { provideSeller, Seller } from '@invivodf/common/src/application/composables/useSeller'
import { Context, provideContext } from '@invivodf/common/src/application/composables/useContext'
import {
  provideLogisticOfferFormSteps,
  useAladinFormSteps,
} from '@invivodf/common/src/contexts/logistic-offer/application/composables'
import { checkFeature, FeatureType } from '@invivodf/common/src/contexts/feature-flipping'
import { usePartner } from '@/composables/usePartner'
import { provideStore } from '@/composables/useStore'
import { getRouter } from './router'
import { getStore } from './store'

export default defineComponent({
  name: 'App',
  setup() {
    provideStore(getStore())
    const { partnerId, partnerName } = usePartner()
    const isFTTestEnabled = ref(false)
    checkFeature
      .execute({
        id: `offers:test`,
        type: FeatureType.Dynamic,
      })
      .then((active) => {
        isFTTestEnabled.value = active
      })

    provideSeller(
      computed(
        () =>
          ({
            id: partnerId.value,
            name: partnerName.value,
          }) as Seller,
      ),
    )
    provideRouter(getRouter())
    provideLogisticOfferFormSteps(useAladinFormSteps())
    provideContext(Context.B2ag)

    return {
      isFTTestEnabled,
    }
  },
  beforeMount() {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.href = `/icons/${window.env.VUE_APP_CONTEXT}/favicon.ico`
    document.getElementsByTagName('head')[0].appendChild(link)
  },
})
