<template>
  <c-upload-csv
    :csvProperties="{}"
    sectionTitle="Importer des frais de zones"
    label="Pour mettre à jour votre base de zone, importez le fichier CSV"
    data-test="c-upload-csv"
    @send-file="importCsv"
  >
    <template slot="template-download-link">
      <IdfButton
        type="button"
        kind="link"
        class="csv-download-link"
        @click="downloadZoneTemplate"
        icon-left="download"
        iconPosition="left"
      >
        Téléchargez le modèle
      </IdfButton>
    </template>
    <template slot="template-upload-link">
      <IdfButton type="submit" data-test="upload-btn">Charger le fichier .csv</IdfButton>
    </template>
  </c-upload-csv>
</template>

<script>
import { mapGetters } from 'vuex'
import { IdfButton } from '@invivodf/ui-kit'
import { zoneService } from '@/services'
import { CUploadCsv } from '@invivodf/common/src/contexts/parameters'

export default {
  name: 'CZoneUpload',
  components: { CUploadCsv, IdfButton },
  computed: {
    ...mapGetters(['cooperativeId']),
  },
  methods: {
    async downloadZoneTemplate() {
      const csvZoneTemplate = await zoneService.downloadZoneTemplate({ cooperativeId: this.cooperativeId })
      const url = window.URL.createObjectURL(new Blob([csvZoneTemplate]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', 'zones-charges.csv')
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    },
    importCsv(file) {
      this.$emit('send-file', file)
    },
  },
}
</script>
