/* eslint-disable class-methods-use-this */
import auth0 from 'auth0-js'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

class UserService {
  constructor({ userApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1/users`)
  }

  get() {
    return this.api.get('/me').then((response) => {
      return response.data
    })
  }

  get auth0() {
    return new auth0.WebAuth({
      domain: window.env.AUTH0_DOMAIN,
      clientID: window.env.AUTH0_CLIENT_ID,
    })
  }

  auth0Logout() {
    this.auth0.logout({ returnTo: window.env.FRONT_SELLER_URL })
  }
}

export default UserService
