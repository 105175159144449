const enrichOffers = (offers, productInvivoIdMap) => {
  if (!offers || !offers.length) return []

  return offers.map((offer) => {
    const product = productInvivoIdMap[offer.variantInvivoId]
    if (!product) return offer
    const productId = product.id
    const productKind = product.kind
    const productType = product.type
    const productHasImage = !!(product && product.images && product.images[0])
    const { isSoldAsUnit, measureQuantity, name } = product.variant
    return {
      ...offer,
      productId,
      productKind,
      productType,
      isSoldAsUnit,
      measureQuantity,
      productHasImage,
      variantName: name,
    }
  })
}

export default enrichOffers
