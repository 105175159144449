import { provideAssociationSelection } from './association/useEditAssociationCart'
import { provideFormSteps } from './useFormSteps'
import { provideForm } from './useForm'
import { provideMetaDiscountDiscountables } from './meta-discount/useMetaDiscount'
import { DiscountPolicy } from '../../domain/DiscountPolicy'

export const provideDiscountDuplicateForm = (id: string, policy: DiscountPolicy) => {
  provideFormSteps(policy)
  provideAssociationSelection()
  provideForm(policy, id, true)
  provideMetaDiscountDiscountables()
}
