import { HomeBanner } from '../domain/home-banner'
import HomeBannerService from './home-banner.service'

export interface HomeBannerRepository {
  findHomeBannerByPartnerId(partnerId: number): Promise<HomeBanner | null>
  createOrUpdateHomeBanner(partnerId: number, homeBanner: HomeBanner): Promise<HomeBanner>
}

interface Dependecy {
  homeBannerService: HomeBannerService
}

export function createHomeBannerRepository(deps: Dependecy): HomeBannerRepository {
  async function findHomeBannerByPartnerId(partnerId: number): Promise<HomeBanner | null> {
    return deps.homeBannerService.findByPartnerId(partnerId)
  }

  async function createOrUpdateHomeBanner(partnerId: number, homeBanner: HomeBanner): Promise<HomeBanner> {
    return deps.homeBannerService.createOrUpdateHomeBanner(partnerId, homeBanner)
  }

  return {
    findHomeBannerByPartnerId,
    createOrUpdateHomeBanner,
  }
}
