
import { defineComponent } from 'vue'
import { IdfIcon } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'PLogisticOfferStep',
  components: {
    IdfIcon,
  },
  emits: ['click'],
  props: {
    title: {
      required: true,
      default: '',
      type: String,
    },
    valid: {
      required: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    index: {
      required: true,
      default: 0,
      type: Number,
    },
    open: {
      required: false,
      default: false,
      type: Boolean,
    },
    isOptional: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit('click')
    }
    return { handleClick }
  },
})
