
import { defineComponent } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { useLogisticOffer, useLogisticOfferSteps, useLogisticOfferWording } from '../../composables'
import { PSummary } from '../../presenters'

export default defineComponent({
  name: 'CLogisticOfferSummaryStep',
  emits: ['submit-step'],
  components: { IdfButton, PSummary },
  setup(props, { emit }) {
    const { wording } = useLogisticOfferWording()
    const { logisticOffer } = useLogisticOffer()
    const { areAllStepsValid } = useLogisticOfferSteps()
    const onSubmit = (): void => {
      emit('submit-step')
    }
    return { areAllStepsValid, logisticOffer, onSubmit, wording }
  },
})
