
import { computed, defineComponent, reactive, watch, ref, Ref } from 'vue'
import { IdfButton, IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { createArticleUsecase } from '@/contexts/articles/dependency-injection'
import PHeader from '@invivodf/common/src/application/presenters/PHeader.vue'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { Article } from '@/contexts/articles/domain/article'
import PInfoMessage from '../../presenters/PInfoMessage.vue'

export default defineComponent({
  name: 'CArticleCreation',
  components: { PInfoMessage, IdfButton, IdfInput, IdfStandardInputs, PHeader },
  setup() {
    const store = useStore()
    const router = useRouter()

    const titleMaxLength = 50
    const contentMaxLength = 3000
    const isSaving: Ref<boolean> = ref(false)

    const breadcrumbsItems = [
      {
        label: 'Accueil',
        link: { name: 'home' },
        icon: 'home-filled',
      },
      {
        label: 'Paramètres',
        link: { name: 'parameters' },
      },
      {
        label: 'Articles et News',
        link: { name: 'articles' },
      },
      {
        label: 'Création',
      },
    ]

    const article: Article = reactive({
      title: '',
      content: '',
      image_url: '',
      published_at: new Date().toISOString(),
    })

    const submitDisabled = computed(() => {
      const { title, image_url, content } = article
      return !(title && content !== '' && image_url) || isSaving.value
    })

    const publishedAtIsVisible = ref(false)

    const titleMaxLengthReached = computed(() => {
      return titleMaxLength === article.title.length
    })

    watch(article, async () => {
      article.title = article.title.slice(0, titleMaxLength)
    })

    const goToArticlesList = () => {
      router.push({ name: 'articles' })
    }

    const wait = async () => {
      return new Promise((resolve) => setTimeout(resolve, 50))
    }

    const save = async () => {
      try {
        isSaving.value = true
        await createArticleUsecase({ partnerId: store.getters.cooperativeId.toString(), article })
        await wait()
        await router.push({ name: 'articles' })
      } catch (error) {
      } finally {
        isSaving.value = false
      }
    }

    return {
      article,
      breadcrumbsItems,
      submitDisabled,
      contentMaxLength,
      isSaving,
      titleMaxLength,
      titleMaxLengthReached,
      goToArticlesList,
      save,
      publishedAtIsVisible,
    }
  },
})
