import { Ref } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables/useBoToast'
import { validateGeneralInformations } from '../../dependency-injection'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import { useFormSteps } from './useFormSteps'

export const noAssociatedStructuresMessage = `Aucune strucuture n'a été associée à la promotion`

export const useValidateGeneralInformations = (validateForm: () => void, form: Ref<DiscountForm>) => {
  const { goToNextStep } = useFormSteps()
  const { showNotificationError } = useBoToast()

  const validateDiscountStep = async () => {
    validateForm()
    if (!form.value.hasErrors()) {
      try {
        if (!form.value.participants.length) throw new Error(noAssociatedStructuresMessage)
        await validateGeneralInformations.execute(form.value)
        goToNextStep()
      } catch (err) {
        showNotificationError(err.message)
      }
    }
  }

  return { validateDiscountStep }
}
