
import { useStore } from '@/composables/useStore'
import { computed, defineComponent, onMounted, ref, Ref } from 'vue'
import { createOrUpdateHomeBannerUsecase, findHomeBannerByPartnerIdUsecase } from '../../dependecy-injection'
import { HomeBanner } from '../../domain/home-banner'
import CImportHomeBanner from '../components/CImportHomeBanner.vue'

export default defineComponent({
  name: 'VImportPartnerBanner',
  components: {
    CImportHomeBanner,
  },
  setup() {
    const data: Ref<HomeBanner> = ref({ title: '', description: '', isLogoActive: false })
    const store = useStore()
    const partnerId = computed(() => store.getters.cooperativeId)
    const isLoading = ref(false)

    async function getBannerData() {
      isLoading.value = true
      const result = await findHomeBannerByPartnerIdUsecase({ partnerId: partnerId.value })
      if (result) {
        data.value = result
      }
      isLoading.value = false
    }

    onMounted(async () => {
      await getBannerData()
    })

    async function displayToast(toastObject: { type: string; message: string }) {
      await store.dispatch('setToast', toastObject)
    }

    async function onPublish(payload: HomeBanner) {
      isLoading.value = true
      try {
        const result = await createOrUpdateHomeBannerUsecase({ partnerId: partnerId.value, homeBanner: payload })
        if (result) {
          data.value = result
        }
        isLoading.value = false
        displayToast({
          type: 'success',
          message: 'Vos modifications ont bien été prises en compte',
        })
      } catch (error) {
        isLoading.value = false
        displayToast({ type: 'danger', message: 'Une erreur est survenue' })
      }
    }

    return {
      data,
      isLoading,
      partnerId,
      onPublish,
    }
  },
})
