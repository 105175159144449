import { AdvancedRule, AdvancedType, Pricing } from '../../domain'
import { useLogisticOffer } from './useLogisticOffer'

export function useLogisticOfferPricing() {
  const { logisticOffer, setLogisticOfferPricing } = useLogisticOffer()

  const addRule = (advancedType: AdvancedType): void => {
    if (!logisticOffer.value.pricing.advanced) return
    const lastRule: AdvancedRule | undefined = logisticOffer.value.pricing.advanced[advancedType].slice(-1)[0]
    const rule: AdvancedRule = {
      rangeStart: lastRule?.rangeEnd || '0',
      rangeEnd: '-',
      value: lastRule?.value || '0',
    }
    const pricing: Pricing = {
      ...logisticOffer.value.pricing,
      advanced: {
        ...logisticOffer.value.pricing.advanced,
        [advancedType]: (logisticOffer.value.pricing.advanced[advancedType] || []).concat(rule),
      },
    }
    setLogisticOfferPricing(pricing)
  }

  const updateRuleRangeEnd = (index: number, value: string, advancedType: AdvancedType): void => {
    if (!logisticOffer.value.pricing.advanced) return
    const nextRule: AdvancedRule | undefined = logisticOffer.value.pricing.advanced[advancedType][index + 1]
    if (!nextRule) return
    const rule: AdvancedRule = {
      ...nextRule,
      rangeStart: value,
    }
    const rulesToUpdate: AdvancedRule[] = [...logisticOffer.value.pricing.advanced[advancedType]]
    rulesToUpdate.splice(index + 1, 1, rule)
    const pricing: Pricing = {
      ...logisticOffer.value.pricing,
      advanced: {
        ...logisticOffer.value.pricing.advanced,
        [advancedType]: rulesToUpdate,
      },
    }
    setLogisticOfferPricing(pricing)
  }

  const deleteRule = (index: number, advancedType: AdvancedType): void => {
    if (!logisticOffer.value.pricing.advanced) return
    const deleted: AdvancedRule[] | undefined = logisticOffer.value.pricing.advanced[advancedType].splice(index, 1)
    updateRuleRangeEnd(index - 1, deleted![0].rangeStart, advancedType)
  }

  return { addRule, deleteRule, updateRuleRangeEnd }
}
