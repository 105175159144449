import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { ref } from 'vue'
import { deleteLogisticOffer, getLogisticOfferList } from '../../dependency-injection'
import { LogisticOfferLineItem } from '../../domain'
import { useLogisticOfferWording } from './useWording'

export function useLogisticOfferList() {
  const seller = useSeller()
  const logisticOfferList = ref<LogisticOfferLineItem[]>([])
  const isLoading = ref(false)
  const { wording } = useLogisticOfferWording()

  const loadLogisticOfferList = async () => {
    if (!seller.value.id) throw new Error('Cooperative id is undefined')
    isLoading.value = true
    try {
      logisticOfferList.value = await getLogisticOfferList.withoutAssociations(seller.value.id.toString())
    } catch (e) {
      throw new Error(wording.notification.error)
    } finally {
      isLoading.value = false
    }
  }

  const loadLogisticOfferListWithAssociations = async () => {
    if (!seller.value.id) throw new Error('Cooperative id is undefined')
    isLoading.value = true
    try {
      logisticOfferList.value = await getLogisticOfferList.withAssociations(seller.value.id.toString())
    } catch (e) {
      throw new Error(wording.notification.error)
    } finally {
      isLoading.value = false
    }
  }

  const deleteOffer = async (reference: string) => {
    if (!seller.value.id) throw new Error('Cooperative id is undefined')
    try {
      await deleteLogisticOffer.execute(seller.value.id.toString(), reference)
      logisticOfferList.value = logisticOfferList.value.filter((offer) => offer.reference !== reference)
    } catch (e) {
      throw new Error(wording.notification.error)
    }
  }

  return { loadLogisticOfferList, loadLogisticOfferListWithAssociations, logisticOfferList, isLoading, deleteOffer }
}
