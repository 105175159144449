<template>
  <div>
    <c-import-base
      :importFileService="shippingAddressesService"
      :csvProperties="csvProperties"
      pageTitle="Import adresses de livraison"
      uploadButtonLabel="Pour mettre à jour votre base d’adresses de livraison, importez le fichier CSV"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
      :importInformation="importInformation"
      @afterLoading="afterShippingAddressesImport"
    >
      <c-import-information
        title="Informations sur vos adresses de livraison"
        nbResultText="Nombre d'adresses :"
        exportText="Exporter vos adresses"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { cooperativeService, shippingAddressesService } from '../../services'

const csvFileProperties = {
  name: 'addresses.csv',
  full_path: '/assets/csv/addresses.csv',
}
export default {
  name: 'CImportShippingAddresses',
  computed: {
    ...mapGetters(['shippingAddressesImportInformation', 'cooperativeId', 'cooperative']),
    importInformation() {
      const { shippingAddressesImportInformation: { shippingAddressesCount, lastShippingAddressesImportAt } = {} } =
        this
      return { count: shippingAddressesCount, lastImportAt: lastShippingAddressesImportAt }
    },
  },
  components: {
    CImportBase,
    CImportInformation,
  },
  methods: {
    afterShippingAddressesImport() {
      this.$store.dispatch('setShippingAddressesImportInformation')
    },
    downloadCSV() {
      return cooperativeService.downloadShippingAddresses(this.cooperativeId, this.cooperative.name)
    },
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      shippingAddressesService,
      csvColumnDetails: [
        {
          header: 'member_address_code',
          format: 'VARCHAR',
          description: `Identifiant adresse dans le système ${i18n.t('message.partner')}`,
          mandatory: 'Oui',
        },
        {
          header: 'member_address_type',
          format: 'VARCHAR',
          exampleValue: 'shipping',
          description:
            'Type adresse: Shipping ou Billing - billing: adresse de facturation - shipping: adresse de livraison',
          mandatory: 'Oui',
        },
        {
          header: 'member_address_company_name',
          format: 'VARCHAR',
          description: 'Raison sociale ou dénomination',
          mandatory: 'Non',
        },
        {
          header: 'member_address_contact',
          format: 'VARCHAR',
          description: 'Identité destinataire (nom, prénom) ou service',
          mandatory: 'Oui',
        },
        {
          header: 'member_address_line1',
          format: 'VARCHAR',
          description: 'Numéro et libellé de la voie',
          mandatory: 'Oui',
        },
        {
          header: 'member_address_line2',
          format: 'VARCHAR',
          description: 'Mentions spéciales de distribution – Boite Postale, Tri Spécial Arrivée',
          mandatory: 'Non',
        },
        {
          header: 'member_address_line3',
          format: 'VARCHAR',
          description:
            'Complément d’identification du point géographique : entrée, tour, bâtiment, immeuble, résidence, zone industrielle, lieu dit',
          mandatory: 'Non',
        },
        {
          header: 'member_address_postal_code',
          format: 'VARCHAR',
          description: 'Code postal',
          mandatory: 'Oui',
        },
        {
          header: 'member_address_city',
          format: 'VARCHAR',
          description: 'Ville',
          mandatory: 'Oui',
        },
        {
          header: 'member_address_country_iso',
          format: 'VARCHAR',
          exampleValue: 'FRA',
          description: 'Code Iso Pays',
          mandatory: 'Oui',
        },
        {
          header: 'member_code',
          format: 'VARCHAR',
          exampleValue: '1234AZE',
          description: `Le code client avec lequel la raison sociale est enregistrée.`,
          mandatory: 'Oui',
        },
      ],
      descriptionLines: [
        'L’extension attendue est : <b>.csv</b>',
        'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
        'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
        "L'encoding attendu est : <b>UTF8</b>.",
      ],
    }
  },
}
</script>
