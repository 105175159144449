
import { computed, defineComponent, ref } from 'vue'
import { useMetaDiscountDiscountables } from '../../composables/meta-discount/useMetaDiscountDiscountables'
import CFileDownload from '../CFileDownload.vue'

export default defineComponent({
  name: 'CUploadedMetaDiscountsAssociations',
  components: { CFileDownload },
  setup() {
    const { discountables, downloadDiscountables } = useMetaDiscountDiscountables()
    const isLoading = ref(false)

    return {
      importedDiscountablesMessage: computed(() =>
        discountables.count.value === null
          ? `Télécharger les offres associées`
          : `${discountables.count.value} identifiants d'offres importés`,
      ),
      downloadDiscountables: async () => {
        isLoading.value = true
        await downloadDiscountables()
        isLoading.value = false
      },
      isLoading,
    }
  },
})
