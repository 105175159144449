import { FeatureType } from '@invivodf/common/src/application/composables/useFeatureFlip'
import { checkFeature } from '@invivodf/common/src/contexts/feature-flipping'

export async function usePrestaService() {
  const isPrestaServiceEnabled = await checkFeature.execute({
    id: `presta-service`,
    type: FeatureType.Dynamic,
  })

  return {
    isPrestaServiceEnabled,
  }
}
