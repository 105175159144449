
import { PropType } from 'vue'

import IdfQuotaCard from '@b2ag/design-system/dist/components/IdfQuotaCard/IdfQuotaCard.vue'
import IdfQuotaProgress from '@b2ag/design-system/dist/components/IdfQuotaProgress/IdfQuotaProgress.vue'
import AdnExpandV2 from '@b2ag/design-system/dist/components/AdnExpandV2/AdnExpandV2.vue'
import AdnHierarchicalList from '@b2ag/design-system/dist/components/AdnHierarchicalList/AdnHierarchicalList.vue'
import { formatDate, formatDateInterval, formatList, formatMeasureUnit } from '@invivodf/common/src/core/l10n'
import QuotaDashboard from '@/contexts/quota-dashboard/domain/quota-dashboard'
import PWarningMessage from '../../../../../presenters/PWarningMessage.vue'

export default {
  name: 'PQuota',
  components: { IdfQuotaCard, IdfQuotaProgress, AdnExpandV2, AdnHierarchicalList, PWarningMessage },
  props: {
    quota: {
      type: Object as PropType<QuotaDashboard>,
      required: true,
    },
  },
  methods: { formatDate, formatDateInterval, formatList, formatMeasureUnit },
}
