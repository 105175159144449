const DEFAULT_FAMILY_NAME = ''
const DEFAULT_CATEGORY_NAME = ''

const getDefaultState = () => {
  return {
    navigation: {
      selectedCategoryName: DEFAULT_CATEGORY_NAME,
      selectedFamilyName: DEFAULT_FAMILY_NAME,
      selectedSupplier: null,
    },
  }
}

export const actions = {
  setCategory(context, categoryName) {
    context.commit('SET_CATEGORY', categoryName)
  },
  setFamily(context, familyName) {
    context.commit('SET_FAMILY', familyName)
  },
  resetSelectedFamily(context) {
    context.commit('RESET_SELECTED_FAMILY')
  },
  resetSelectedCategory(context) {
    context.commit('RESET_SELECTED_CATEGORY')
  },
  setSelectedSupplier(context, supplier) {
    context.commit('SET_SELECTED_SUPPLIER', supplier)
  },
  resetSelectedSupplier(context) {
    context.commit('RESET_SELECTED_SUPPLIER')
  },
  resetSearchState(context) {
    context.commit('RESET_STATE')
  },
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.navigation.categories = categories
  },
  SET_FAMILY(state, familyName) {
    state.navigation.selectedFamilyName = familyName
  },
  RESET_SELECTED_FAMILY(state) {
    state.navigation.selectedFamilyName = DEFAULT_FAMILY_NAME
  },
  SET_CATEGORY(state, categoryName) {
    state.navigation.selectedCategoryName = categoryName
  },
  RESET_SELECTED_CATEGORY(state) {
    state.navigation.selectedCategoryName = DEFAULT_CATEGORY_NAME
  },
  SET_SELECTED_SUPPLIER(state, supplier) {
    state.navigation.selectedSupplier = supplier
  },
  RESET_SELECTED_SUPPLIER(state) {
    state.navigation.selectedSupplier = null
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

export const getters = {
  getCategories(state) {
    return state.navigation.categories
  },
  selectedFamilyName(state) {
    return state.navigation.selectedFamilyName
  },
  selectedCategoryName(state) {
    return state.navigation.selectedCategoryName
  },
  selectedSupplier(state) {
    return state.navigation.selectedSupplier
  },
  selectedSupplierName(state) {
    return (state.navigation.selectedSupplier && state.navigation.selectedSupplier.name) || ''
  },
}

const moduleSearch = {
  state: getDefaultState(),
  actions,
  mutations,
  getters,
}

export default moduleSearch
