
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { importAssociations, downloadAssociations } from '../dependency-injection'

export const associationColumnDetails = [
  {
    header: 'discount_reference',
    format: 'VARCHAR',
    exampleValue: 'exemple : <code>REF_REMISE_DEMO</code>',
    description: '<p>Référence de la remise.</p>',
    mandatory: 'Oui',
  },
  {
    header: 'discountable_id',
    format: 'VARCHAR',
    exampleValue: 'exemple : <code>6:INVXCKLET7V7CT16PHY:REF_OFR</code>',
    description: '<p>ID de l’objet métier sur lequel la remise s’applique.</p>',
    mandatory: 'Oui',
  },
]

export const associationDescriptionLines = [
  'L’extension attendue est : <b>.csv</b>',
  'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
  'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
  "L'encoding attendu est : <b>UTF8</b>.",
]

const csvFileProperties = {
  name: 'discount_association.csv',
  full_path: '/assets/csv/discount/discount_association.csv',
}
export default {
  name: 'VImportDiscountAssociations',
  components: {
    CImportBase,
    CImportInformation,
  },
  setup() {
    const seller = useSeller()
    const { showNotificationError } = useBoToast()

    const importBindingCSV = {
      async import(sellerId, file) {
        try {
          return await importAssociations.execute(sellerId, file)
        } catch (err) {
          showNotificationError(err.message)
          throw err
        }
      },
    }
    const downloadCSV = async () => {
      try {
        return await downloadAssociations.execute(seller.value.id, seller.value.name)
      } catch (err) {
        showNotificationError(err.message)
        throw err
      }
    }

    const importInformation = {
      count: null,
      lastImportAt: null,
    }
    return {
      csvProperties: csvFileProperties,
      csvColumnDetails: associationColumnDetails,
      descriptionLines: associationDescriptionLines,
      importBindingCSV,
      downloadCSV,
      importInformation,
    }
  },
}
