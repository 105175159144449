import { RequiredProperty, RequiredPropertyOptions } from './RequiredProperty'

export class RequiredString extends RequiredProperty<string> {
  constructor(
    public validatedValue: string,
    options: RequiredPropertyOptions,
  ) {
    super(validatedValue, options)
  }

  public validate() {
    this.errors = typeof this.validatedValue === 'string' && this.validatedValue.length > 0 ? [] : [this.errorMessage]
  }
}
