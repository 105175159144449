
import { defineComponent, ref, watch } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables/useBoToast'
import { IdfToast } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'PBoToast',
  components: {
    IdfToast,
  },
  setup() {
    const toast = ref<any>()
    const { notification } = useBoToast()
    watch(notification, () => {
      toast.value?.show()
    })
    return { toast, notification }
  },
})
