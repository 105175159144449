import { AxiosError, AxiosInstance } from 'axios'
import parse from 'parse-link-header'
import { DateTime } from 'luxon'
import { download } from '@invivodf/common/src/infrastructure/download.service'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import * as offerMapper from './offer.mapper'
import ImportService from './import.service'

const camelToSnake = (str) => str.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`).toLowerCase()

type FieldError = {
  field: string
  messages: string[]
}

const mapFieldErrorList = (fieldErrorList: FieldError[]) =>
  fieldErrorList.map((fieldError) => {
    if (fieldError.field === 'offer_reference') {
      return { ...fieldError, field: 'seller_custom_id' }
    }
    return fieldError
  })

export const mapErrors = (err) => {
  return err?.response?.data?.errors
    ? {
        ...err,
        response: {
          ...err.response,
          data: {
            ...err.response.data,
            errors: mapFieldErrorList(err.response.data.errors),
          },
        },
      }
    : err
}

class OfferService {
  private readonly productApiHost: string

  private readonly api: AxiosInstance

  constructor({ productApiHost }) {
    this.productApiHost = productApiHost
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1/offers`, { timeout: 20000 })
  }

  async listV3({
    cooperativeId,
    limit = 10 as number | undefined,
    page = 1 as number | undefined,
    filters = {},
    pagination = true,
    expiresAfter,
  }) {
    if (!cooperativeId) {
      return { totalPages: 0, offers: [] }
    }
    const filterQueryString = new URLSearchParams({ limit, page, pagination, partnerId: cooperativeId } as Record<
      string,
      any
    >)
    if (limit === Infinity) {
      filterQueryString.delete('limit')
      filterQueryString.delete('page')
      filterQueryString.set('pagination', 'false')
    }
    for (const key in filters) // eslint-disable-line
      if (filters[key] !== '' && filters[key] !== undefined) filterQueryString.set(key, filters[key])
    if (expiresAfter && DateTime.fromISO(expiresAfter).isValid) {
      filterQueryString.set('expiresAfter', expiresAfter)
    }
    const response = await this.api.get(`${this.productApiHost}/v1/offers-with-product?${filterQueryString.toString()}`)
    const parsedHeaders = parse(response.headers.link)
    const totalPages = +(parsedHeaders && parsedHeaders.last && parsedHeaders.last.page) || 1
    return { totalPages, offers: offerMapper.mapListFromApi(response.data) }
  }

  async upsert(offer, cooperativeId) {
    try {
      const response = await this.api.put(
        `${this.productApiHost}/v3/offers/${encodeURIComponent(offer.id)}?partner_id=${cooperativeId}&product_uid=${
          offer.variantInvivoId
        }`,
        offerMapper.mapToApiV3(offer),
      )
      if (response.status === 201) {
        return { createdOffer: offerMapper.mapFromApiV3(response.data) }
      }
      return { updatedOffer: offerMapper.mapFromApiV3(response.data) }
    } catch (error) {
      const err: AxiosError = error
      return Promise.reject(mapErrors(err))
    }
  }

  async delete(offerId) {
    await this.api.delete(`${this.productApiHost}/v3/offers/${encodeURIComponent(offerId)}`)
    return true
  }

  async import(cooperativeId, file) {
    return ImportService.import(`${this.productApiHost}/backoffice/v1/cooperatives/${cooperativeId}/offers`, file)
  }

  async downloadOffers(cooperativeId, cooperativeName) {
    const response = await this.api.get(`${this.productApiHost}/backoffice/v1/cooperatives/${cooperativeId}/offers`, {
      headers: { accept: 'text/csv' },
      responseType: 'blob',
      timeout: 120_000,
    })

    download(response.data, ImportService.createFileName(cooperativeName, 'offers'))
  }

  async getPrestaServicePriceType(partnerId, variantId) {
    const queryString = `partner_id=${partnerId}&variant_id=${variantId}`
    return (await this.api.get(`${this.productApiHost}/v1/offers/fields-options?${queryString}`)).data.price_type
  }
}

export default OfferService
