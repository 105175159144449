import { IFeatureFlipRepository } from '../domain/IFeatureFlipRepository'
import { Feature } from '../domain/Feature'

export class CheckFeature {
  constructor(private readonly featureFlipRepository: IFeatureFlipRepository) {}

  public async execute(feature: Feature, options: { forceUpdate?: boolean } = {}): Promise<boolean> {
    return this.featureFlipRepository.checkFeature(feature, options)
  }
}
