import {
  AdvancedRules,
  FeesSchedule,
  FeesScheduleRule,
  FeesScheduleType,
  LogisticOffer,
  LogisticOfferForConfig,
  LogisticOfferForSaleLogisticRoute,
  LogisticOfferForSaleDelayUnit,
  RuleMethod,
  RuleTarget,
  RuleUnit,
} from '@invivodf/module-logistic-interfaces'
import { enhanceLogisticOfferForConfig } from '@invivodf/module-logistic-offer-client-sdk'
import { AdvancedRule, LogisticOfferFormData, LogisticRoute, Pricing } from '../domain'
import { formatDate } from './date.formater'

const buildStandardFeesSchedule = (pricing: Pricing) => {
  const { type, standard: { value, unit } = {} } = pricing
  return {
    type: FeesScheduleType.Standard,
    standardRule: {
      target: type as RuleTarget,
      value: value!,
      unit: unit! as RuleUnit,
    },
  }
}

const buildFeeScheduleRule = (rule: AdvancedRule): FeesScheduleRule => {
  const { rangeStart, rangeEnd, method, value } = rule
  return {
    rangeStart,
    rangeEnd: rangeEnd === '-' ? undefined : rangeEnd,
    method: method as RuleMethod,
    value,
  }
}

const buildAdvancedFeesSchedule = (pricing: Pricing) => {
  const { advanced: { weight = [], revenue = [] } = {} } = pricing
  const advancedRules: AdvancedRules = {
    weight: weight.map(buildFeeScheduleRule),
    revenue: revenue.map(buildFeeScheduleRule),
  }
  return {
    type: FeesScheduleType.Advanced,
    advancedRules,
  }
}

const convertFormDataToLogisticOffer = (logisticOfferFormData: LogisticOfferFormData): LogisticOffer => {
  const { value: delayValue = undefined, unit: delayUnit = undefined } = logisticOfferFormData.deliveryDelay || {}
  const { type } = logisticOfferFormData.pricing
  let formFeesSchedule: FeesSchedule | undefined
  if (type) {
    formFeesSchedule =
      type === 'advanced'
        ? buildAdvancedFeesSchedule(logisticOfferFormData.pricing)
        : buildStandardFeesSchedule(logisticOfferFormData.pricing)
  }
  return {
    deliveryModeName: logisticOfferFormData.deliveryModeName!,
    logisticRoute: logisticOfferFormData.logisticRoute! as LogisticOfferForSaleLogisticRoute,
    delayValue,
    delayUnit: delayUnit as LogisticOfferForSaleDelayUnit,
    reference: logisticOfferFormData.reference!,
    name: logisticOfferFormData.name || logisticOfferFormData.reference!,
    description: logisticOfferFormData.description,
    isDeliveryDateFeatureActive: logisticOfferFormData.isDeliveryDateFeatureActive,
    feesSchedule: formFeesSchedule,
    exclusionZoneIds: logisticOfferFormData.exclusionZones,
    validityPeriod: logisticOfferFormData.validityPeriod?.start
      ? {
          start: formatDate(logisticOfferFormData.validityPeriod.start),
          end: logisticOfferFormData.validityPeriod?.end
            ? formatDate(logisticOfferFormData.validityPeriod.end)
            : undefined,
        }
      : undefined,
  }
}

const buildAdvancedRule = (feeSchedule: FeesScheduleRule): AdvancedRule => {
  const { rangeStart, rangeEnd, method, value } = feeSchedule
  return {
    rangeStart,
    rangeEnd: rangeEnd || '-',
    method,
    value,
  }
}

const convertLogisticOfferToFormData = (logisticOffer: LogisticOfferForConfig): LogisticOfferFormData => {
  const {
    deliveryModeName,
    logisticRoute,
    delayValue: value,
    delayUnit: unit,
    reference,
    name,
    description,
    isDeliveryDateFeatureActive = false,
    feesSchedule: { type, standardRule, advancedRules: { weight = [], revenue = [] } = {} } = {},
    exclusionZoneIds,
    validityPeriod,
  } = logisticOffer
  return {
    deliveryModeName,
    logisticRoute: logisticRoute as LogisticRoute,
    deliveryDelay: { value, unit },
    reference,
    name,
    description,
    isDeliveryDateFeatureActive,
    pricing: {
      type: type === 'advanced' ? type : standardRule?.target,
      standard: {
        value: standardRule?.value,
        unit: standardRule?.unit || 'euro',
      },
      advanced: {
        weight: weight.map(buildAdvancedRule),
        revenue: revenue.map(buildAdvancedRule),
      },
    },
    exclusionZones: exclusionZoneIds || [],
    validityPeriod: {
      start: validityPeriod?.start ? new Date(validityPeriod?.start) : undefined,
      end: validityPeriod?.end ? new Date(validityPeriod?.end) : undefined,
    },
  }
}

const convertLogisticOffertoLogisticOfferItem = (logisticOffer: LogisticOfferForConfig) => {
  const { reference, name, logisticableIds, deliveryModeBusinessName } = enhanceLogisticOfferForConfig(logisticOffer)
  return {
    reference,
    name,
    nbAssociatedProducts: logisticableIds?.length ?? 0,
    deliveryModeBusinessName,
  }
}

export { convertFormDataToLogisticOffer, convertLogisticOfferToFormData, convertLogisticOffertoLogisticOfferItem }
