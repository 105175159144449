
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { PropType } from 'vue'
import { IUseDiscountInput } from '../../composables/useDiscountInput'

export default {
  name: 'CDiscountInternalNameInput',
  components: { IdfInput, IdfStandardInputs },
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
}
