import { useAuthService } from '@invivodf/common/src/auth'
import { Zones } from '@invivodf/module-logistic-interfaces'
import sdk, { LogisticExclusionZoneSdk } from '@invivodf/module-logistic-exclusion-zone-sdk'

class ExclusionZonesRepository {
  private logisticExclusionZoneSdk: LogisticExclusionZoneSdk

  constructor({ logisticApiHost }) {
    this.logisticExclusionZoneSdk = sdk(logisticApiHost, { accessTokenSource: () => useAuthService().getAccessToken() })
  }

  getAll(): Promise<Zones> {
    return this.logisticExclusionZoneSdk.getExclusionZones()
  }
}

export default ExclusionZonesRepository
