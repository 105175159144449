
import { defineComponent, PropType } from 'vue'
import IdfStep from '../IdfStep/IdfStep.vue'
import CReadonlyDiscountAssociations from './CReadonlyDiscountAssociations.vue'
import CReadonlyMetaDiscountAssociations from './CReadonlyMetaDiscountAssociations.vue'
import { Discount } from '../../../domain/Discount'

export default defineComponent({
  name: 'CReadonlyAssociationsStep',
  components: { IdfStep, CReadonlyDiscountAssociations, CReadonlyMetaDiscountAssociations },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
})
