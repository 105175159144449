
import { defineComponent } from 'vue'
import { IdfInput, IdfSelect } from '@invivodf/ui-kit'
import { ValidationProvider } from 'vee-validate-last'
import PPricingScale from '../PPricingScale/PPricingScale.vue'
import { Pricing } from '../../../domain'
import { useLogisticOfferRules, usePriceUnits, usePricingTypes, useLogisticOfferWording } from '../../composables'

export default defineComponent({
  name: 'PPricingChoice',
  components: {
    IdfInput,
    IdfSelect,
    PPricingScale,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object as () => Pricing,
      required: true,
    },
    isStandardPricingType: {
      type: Boolean,
      required: true,
    },
    isAdvancedPricingType: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { wording } = useLogisticOfferWording()
    const { pricingTypes } = usePricingTypes()
    const { priceUnits } = usePriceUnits()
    const rules = useLogisticOfferRules()
    return { pricingTypes, priceUnits, rules, wording }
  },
})
