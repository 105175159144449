import { computed, Ref, ref } from 'vue'
import { Zones } from '@invivodf/module-logistic-interfaces'
import { getExclusionZones } from '../../dependency-injection'
import { useLogisticOfferWording } from './useWording'

export function useExclusionZones() {
  const exclusionZones: Ref<Zones> = ref<Zones>([])
  const isLoading: Ref<boolean> = ref(false)
  const { wording } = useLogisticOfferWording()

  const loadExclusionZones = async (): Promise<void> => {
    isLoading.value = true
    try {
      exclusionZones.value = await getExclusionZones.execute()
    } catch (e) {
      throw new Error(wording.notification.error)
    } finally {
      isLoading.value = false
    }
  }

  return { exclusionZones: computed(() => exclusionZones.value), isLoading, loadExclusionZones }
}
