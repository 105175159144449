import { AxiosInstance } from 'axios'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

export interface CroppingOptions {
  cropping: boolean
  croppingAspectRatio: number
  showSkipCropButton?: boolean
}

export interface UploadParams {
  folder: string
  // eslint-disable-next-line camelcase
  upload_preset: string
  timestamp: number
  source: string
  apiKey: string
  invalidate: boolean
  publicId: string
  overwrite: boolean
  tags: string[]
}

function createGetSignature(client: AxiosInstance, partnerId: string, overwrite: boolean) {
  return async (callback, paramsToSign) => {
    const response = await client.post(`/partners/${partnerId}/cloudinary/signature`, { ...paramsToSign, overwrite })
    const signature = response?.data?.signature
    callback(signature)
  }
}

class CloudinaryService {
  private readonly client: AxiosInstance

  constructor(
    private readonly cloudinaryTokenApiHost,
    private readonly cloudinaryBucketName?: string,
  ) {
    this.cloudinaryTokenApiHost = cloudinaryTokenApiHost
    this.client = createAxiosClientWithAladinBearer(`${this.cloudinaryTokenApiHost}/v1`)
  }

  async getProductMedias(partnerId: number, productId: string): Promise<any> {
    const { data } = await this.client.get(`/partners/${partnerId}/products/${productId}/medias`)
    return data
  }

  private getUploadParams(signatureUrlPath: string): Promise<UploadParams> {
    return this.client.get(signatureUrlPath).then((response) => {
      return response.data
    })
  }

  async openCloudinaryUploadWidget(
    signatureUrlPath: string,
    partnerId: string,
    croppingOptions?: CroppingOptions,
  ): Promise<any> {
    const uploadParams = await this.getUploadParams(signatureUrlPath)
    const getSignatureMethod = createGetSignature(this.client, partnerId, uploadParams.overwrite)
    return new Promise((resolve) => {
      const text = this.getCloudinaryWidgetText()
      const options = {
        ...uploadParams,
        cloudName: this.cloudinaryBucketName,
        uploadSignature: getSignatureMethod,
        sources: ['local', 'url'],
        cropping: croppingOptions?.cropping ?? true,
        croppingAspectRatio: croppingOptions?.croppingAspectRatio ?? 1,
        showSkipCropButton: croppingOptions?.showSkipCropButton ?? true,
        showPoweredBy: false,
        multiple: false,
        theme: 'minimal',
        resourceType: 'image',
        text,
      }
      window.cloudinary?.openUploadWidget(options, (error, result) => {
        if (!error && result.event === 'success') {
          resolve(result.info)
        }
      })
    })
  }

  /* eslint-disable class-methods-use-this */
  private getCloudinaryWidgetText() {
    return {
      en: {
        or: 'ou',
        local: {
          browse: 'Parcourir',
          dd_title_single: 'Glisser, déposer une image ici',
        },
        menu: {
          files: 'Mes fichiers',
          web: 'URL',
        },
        crop: {
          title: 'Recadrer',
          crop_btn: 'Recadrer et mettre à jour',
          skip_btn: 'Mettre à jour',
          reset_btn: 'Réinitialiser',
          close_btn: 'Oui',
          close_prompt: 'Fermer cette fenêtre annulera la mise à jour. Voulez vous continuer ?',
          image_error: "Erreur lors du chargement de l'image",
          corner_tooltip: 'Glisser le coin pour redimensionner',
        },
      },
    }
  }
}

export default CloudinaryService
