import { PriceUnitOption } from '../interfaces/price-unit-option'

export const priceUnits: PriceUnitOption[] = [
  {
    value: 'euro',
    label: '€',
  },
  {
    value: 'percent',
    label: '%',
  },
]
