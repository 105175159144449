import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import DeliveryModeRepository from '../infrastructure/delivery-mode.repository'

export default class GetDeliveryModeList {
  private readonly repository: DeliveryModeRepository

  constructor(repository: DeliveryModeRepository) {
    this.repository = repository
  }

  async execute(sellerId: string): Promise<DeliveryModeEntity[]> {
    return this.repository.getAll(sellerId)
  }
}
