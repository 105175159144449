import { OfferForQuota, QuotaForConfig } from '../quota-config.business'
import { Product } from '../product'

export const mergeVariantAndProduct = (product: Product) => {
  return product.variants.map(
    (variant) =>
      [
        variant.id,
        {
          ...variant,
          productType: product.type,
          productKind: product.kind,
          productSupplier: product.supplier,
          image: product.images?.[0],
          // eslint-disable-next-line no-underscore-dangle
          productId: product._id,
        },
      ] as const,
  )
}

export type FlatVariantForQuota = ReturnType<typeof mergeVariantAndProduct>[number][1]

export const mergeOfferIntoQuota =
  (offers: Map<string, OfferForQuota>) =>
  (quota): QuotaForConfig => {
    const correspondingOffer = offers.get(quota.meshId)
    return correspondingOffer
      ? {
          ...quota,
          offerValidityEndDate: correspondingOffer.validityEndDate,
          offerShippingStartDate: correspondingOffer.shippingStartDate,
          offerShippingEndDate: correspondingOffer.shippingEndDate,
          offerShippingMethods: correspondingOffer.shippingMethodList,
          offerSegments: correspondingOffer.segmentList,
        }
      : null
  }
