export const SHIPPING_PICKUP_POINT = 'pickup_point'
export const SHIPPING_PICKUP_POINT_TITLE = 'Relais'
export const SHIPPING_PICKUP_POINT_ICON = 'category-pick-up-point'
export const SHIPPING_FARM = 'farm'
export const SHIPPING_FARM_TITLE = 'Livraison à la ferme'
export const SHIPPING_FARM_ICON = 'category-delivery'
export const SHIPPING_CLICK_AND_COLLECT = 'click_and_collect'
export const SHIPPING_CLICK_AND_COLLECT_TITLE = 'Retrait magasin'
export const SHIPPING_CLICK_AND_COLLECT_ICON = 'category-cooperative'

export const SHIPPING_WITH_CONTACT = [SHIPPING_FARM, SHIPPING_PICKUP_POINT]

export const defaultShippingWays = (i18n) => [
  {
    title: `${SHIPPING_PICKUP_POINT_TITLE} ${i18n.t('message.partner')}`,
    kind: SHIPPING_PICKUP_POINT,
  },
  {
    title: SHIPPING_FARM_TITLE,
    kind: SHIPPING_FARM,
  },
  {
    title: SHIPPING_CLICK_AND_COLLECT_TITLE,
    kind: SHIPPING_CLICK_AND_COLLECT,
  },
]

export const SHIPPING_ICONS = {
  [SHIPPING_FARM]: SHIPPING_FARM_ICON,
  [SHIPPING_CLICK_AND_COLLECT]: SHIPPING_CLICK_AND_COLLECT_ICON,
  [SHIPPING_PICKUP_POINT]: SHIPPING_PICKUP_POINT_ICON,
}

export const SHIPPING_TITLES = {
  [SHIPPING_FARM]: SHIPPING_FARM_TITLE,
  [SHIPPING_CLICK_AND_COLLECT]: SHIPPING_CLICK_AND_COLLECT_TITLE,
  [SHIPPING_PICKUP_POINT]: SHIPPING_PICKUP_POINT_TITLE,
}
