import { AxiosInstance } from 'axios'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

export interface PaymentDueDatePayload {
  rate: number
  title: string
  reference: string
  description: string
}

export interface PaymentDueDateResponse {
  rate: number
  title: string
  reference: string
  description: string
}

export default class PaymentDueDateService {
  private readonly api: AxiosInstance

  constructor({ paymentDueDateApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${paymentDueDateApiHost}/v1`, { timeout: 20000 })
  }

  async create(partnerId: number, paymentDueDatePayload: PaymentDueDatePayload): Promise<PaymentDueDateResponse> {
    const response = await this.api.post(
      `/payment-due-dates?partner_id=${partnerId}`,
      { ...paymentDueDatePayload },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    return response.data
  }

  async update(partnerId: number, paymentDueDatePayload: PaymentDueDatePayload): Promise<PaymentDueDateResponse> {
    const response = await this.api.put(
      `/payment-due-dates?partner_id=${partnerId}`,
      { ...paymentDueDatePayload },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    return response.data
  }

  async delete(partnerId: number, reference: string): Promise<void> {
    // Using encodeURIComponent (https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent)
    // to avoid injection if reference contains special characters
    await this.api.delete(`/payment-due-dates/${encodeURIComponent(reference)}?partner_id=${partnerId}`)
  }

  async get(partnerId: number): Promise<PaymentDueDateResponse[]> {
    const response = await this.api.get(`/payment-due-dates?partner_id=${partnerId}`)
    return response.data
  }
}
