import stripUndefined from './tests/utils/strip-undefined'

export { stripUndefined }

export {
  defaultImagePerProductKind,
  getProductDefaultImageFull,
  getProductDefaultImage160Px,
} from './products-images.constants'
export { computeVariantListWithoutOffer } from './shop.business'
export { hasOffers, hasImage } from './product.business'

export {
  PRODUCT_KINDS,
  CROP_PROTECTION_KIND,
  SEED_KIND,
  NUTRITION_AMENDMENTS_KIND,
  SERVICE_KIND,
  FARMER_EQUIPMENTS_KIND,
  CULTURE_EQUIPMENT_KIND,
  VINE_EQUIPMENT_KIND,
  FARM_DEVELOPMENT_KIND,
  CATEGORY_LEVEL_0,
  CATEGORY_LEVEL_1,
} from './products.constants'

export {
  SHIPPING_PICKUP_POINT,
  SHIPPING_PICKUP_POINT_TITLE,
  SHIPPING_PICKUP_POINT_ICON,
  SHIPPING_FARM,
  SHIPPING_FARM_TITLE,
  SHIPPING_FARM_ICON,
  SHIPPING_CLICK_AND_COLLECT,
  SHIPPING_CLICK_AND_COLLECT_TITLE,
  SHIPPING_CLICK_AND_COLLECT_ICON,
  SHIPPING_ICONS,
  SHIPPING_TITLES,
  SHIPPING_WITH_CONTACT,
  defaultShippingWays,
} from './shipping-method.constants'

export { presentShippingMethods } from './shipping-method.business'

export { logUnit, formatUnit, formatVariableUnit } from './unit'
export * as l10n from '@invivodf/common/src/core/l10n'
export { getAddToCartTrackingData } from './dataAnalytics'

export type { StoreModel } from './store.business'
export { parseAccepts, storeAccepts, canSellVariantInClickAndCollect } from './store.business'

export { Packaging } from './packaging.business'

export type { CooperativeVariant } from './product'
export type { Quota } from './quota'
export type { QuotaForConfig, OfferForQuota } from './quota-config.business'
export { getPartnerQuota, getTechQuotas, sortQuotas, getShippingIcons } from './quota-config.business'
export type { PaymentDueDate } from './offer'

export { isQuotaReached, numberOfReachedQuotas, numberOfActiveQuotas } from './quota.business'
