import { CROP_PROTECTION_KIND } from './product-kind.const'

export const FIXED_PRICE = 'fixedPrice'
export const TEMPORARY_PRICE = 'temporaryPrice'
export const PREORDER_PRICE = 'preorderPrice'
export const INDICATIVE_PRICE = 'indicativePrice'

export const defaultPriceTypes = [
  {
    title: 'Prix ferme',
    name: FIXED_PRICE,
  },
  {
    title: 'Prix provisoire',
    name: TEMPORARY_PRICE,
  },
  {
    title: 'Précommande',
    name: PREORDER_PRICE,
  },
  {
    title: 'Prix indicatif',
    name: INDICATIVE_PRICE,
  },
]

export const disallowedPriceTypes = {
  [FIXED_PRICE]: new Set(),
  [TEMPORARY_PRICE]: new Set([CROP_PROTECTION_KIND]),
  [PREORDER_PRICE]: new Set([CROP_PROTECTION_KIND]),
  [INDICATIVE_PRICE]: new Set(),
}
