export enum DiscountableDTOPreventDiscountMessage {
  isPreOrder = 'IS_PREORDER',
  isPhyto = 'IS_PHYTO',
}

export interface DiscountableDTO {
  id: string
  name: string // offer.reference
  preventDiscount: DiscountableDTOPreventDiscountMessage | undefined // inclus product.isDiscountable + offer.isPreOrder
  unitPrice: number
  validityStartDate: string
  validityEndDate: string | undefined
  measureUnit: string | undefined
  measureQuantity: number
  zoneCharges: number[] | undefined
}

export interface ResultItemDTO {
  id: string
  name: string
}

export interface ResultGroupDTO {
  id: string
  name: string
  discountables: ResultItemDTO[]
}

export interface ResultPageDTO {
  total: number
  results: ResultGroupDTO[]
}

export interface IDiscountableDatasource {
  retrieveDiscountablesByIds(sellerId: number, discountableIds: string[]): Promise<DiscountableDTO[]>
  searchDiscountableGroups(sellerId: number, query: string, page?: number, count?: number): Promise<ResultPageDTO>
}
