/* eslint-disable camelcase */
import { CooperativeVariantSchema } from '@b2ag/types-api-schemas'
import * as taxesMapper from './taxes.mapper'
import * as zoneChargesMapper from './zone-charges.mapper'

/** @deprecated */
// eslint-disable-next-line import/prefer-default-export
export const mapFromApi = (variant: CooperativeVariantSchema = {} as CooperativeVariantSchema) => {
  const { taxes, zone_charges: zoneCharges } = variant
  return {
    id: variant.id,
    cooperativeVariantCode: variant.cooperative_variant_code,
    ean_code: variant.ean_code,
    supplier: variant.supplier,
    measure_quantity: variant.measure_quantity,
    measure_unit: variant.measure_unit,
    name: variant.name,
    type: variant.type,
    weight: variant.weight,
    published: variant.published,
    associated: variant.associated,
    cooperativeVariantId: variant.cooperative_variant_id,
    stockManagementType: variant.stock_management_type,
    isSoldAsUnit: variant.is_sold_as_unit,
    taxes: taxes && taxesMapper.mapFromApi(taxes),
    zoneCharges: zoneCharges && zoneCharges.map(zoneChargesMapper.mapFromApi),
    removedFromSource: variant.removed_from_source,
    sellable: variant.sellable,
    offline: variant.offline,
  }
}

export const mapFromApiV2 = (variant: CooperativeVariantSchema = {} as CooperativeVariantSchema) => {
  const { taxes = [], zone_charges: zoneCharges = [] } = variant
  return {
    id: variant.id,
    cooperativeVariantCode: variant.cooperative_variant_code,
    eanCode: variant.ean_code,
    supplier: variant.supplier,
    measureQuantity: variant.measure_quantity,
    measureUnit: variant.measure_unit,
    name: variant.name,
    type: variant.type,
    weight: variant.weight,
    published: variant.published,
    associated: variant.associated,
    stockManagementType: variant.stock_management_type,
    isSoldAsUnit: variant.is_sold_as_unit,
    taxes: taxesMapper.mapFromApi(taxes),
    zoneCharges: zoneCharges.map(zoneChargesMapper.mapFromApi),
    cooperativeVariantId: variant.cooperative_variant_id,
    removedFromSource: variant.removed_from_source,
    sellable: variant.sellable,
    offline: variant.offline,
    packaging: variant.packaging,
    articleReferenceSupplier: variant.article_reference_supplier,
    tearResistance: variant.tear_resistance,
    knotResistance: variant.knot_resistance,
    ballType: variant.ball_type,
    flowRateInLPerMn: variant.flow_rate_in_l_per_mn,
    weightInGrammPerSquareMeter: variant.weight_in_gramm_per_square_meter,
    perforationResistance: variant.perforation_resistance,
  }
}
