import { Discountable as DiscountableBase } from '@invivodf/discount-sdk/admin/Discountable'
import { getComplianceErrors } from '@invivodf/discount-sdk/admin'
import { Discount } from './Discount'

export type Discountable = DiscountableBase & {
  id: string
  discountReferences: string[]
  complianceErrors: string[]
}

export const setComplianceErrors = (discountable: Discountable, discount: Discount): Discountable => {
  return {
    ...discountable,
    complianceErrors: getComplianceErrors(
      {
        ...discountable,
        validityStartDate: discountable.validityStartDate,
        validityEndDate: discountable.validityEndDate ? discountable.validityEndDate : null,
        measureUnit: discountable.measureUnit,
      },
      {
        ...discount,
        type: discount.policy.type,
        scope: discount.policy.scope,
        validityEndDate: discount.validityEndDate ? new Date(discount.validityEndDate) : null,
        validityStartDate: new Date(discount.validityStartDate),
      },
    ).map((error) => error.message),
  }
}
