import { DiscountUnits, DiscountMeasureUnits, DiscountTypes } from '@invivodf/discount-sdk/admin'
import { Discount } from '../../domain/Discount'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import {
  DiscountResponseDTO,
  DiscountApiPayloadDTO,
  IDiscountFromApiWithAmount,
  IDiscountFromApiWithScale,
  DiscountPolicyDTO,
} from '../datasource/IDiscount.datasource'
import { DiscountFormWithAmount } from '../../domain/discountForm/DiscountFormWithAmount'
import { DiscountFormWithScale } from '../../domain/discountForm/DiscountFormWithScale'
import { DiscountPolicy } from '../../domain/DiscountPolicy'

export function mapDiscountFormToDiscountForApi(discountForm: DiscountForm): DiscountApiPayloadDTO {
  return {
    id: discountForm.id,
    reference: discountForm.reference.validatedValue,
    segment: discountForm.segment.validatedValue || null,
    internal_name: discountForm.internalName.validatedValue,
    public_name: discountForm.publicName.validatedValue,
    unit: discountForm.unit.validatedValue as DiscountUnits,
    validity_start_date: discountForm.validityStartDate.validatedValue!.toISOString(),
    validity_end_date: discountForm.validityEndDate.validatedValue
      ? discountForm.validityEndDate.validatedValue.toISOString()
      : null,
    measure_unit: discountForm.measureUnit ? (discountForm.measureUnit.validatedValue as DiscountMeasureUnits) : null,
    scope: discountForm.scope,
    type: discountForm.type,
    ...([DiscountTypes.FixedPrice, DiscountTypes.Simple].includes(discountForm.type)
      ? { amount: (discountForm as DiscountFormWithAmount).amount.validatedValue! }
      : {
          scale: (discountForm as DiscountFormWithScale).scale.validatedValue.map((slice) => ({
            min: slice.min!,
            max: slice.max,
            discount: slice.discount!,
          })),
        }),
    promo_code: discountForm.promoCode.validatedValue || null,
    is_flash: discountForm.isFlash.validatedValue || false,
    is_sales: discountForm.isSales.validatedValue || false,
    is_meta: discountForm.isMeta,
    participants: discountForm.participants,
    nb_overall_uses_max: discountForm.nbOverallUsesMax.validatedValue,
    nb_customer_uses_max: discountForm.nbCustomerUsesMax.validatedValue,
    group_size: discountForm.groupSize.validatedValue,
  }
}

export function mapDiscountFromApiToDiscount(discountFromApi: DiscountResponseDTO): Discount {
  return {
    id: discountFromApi.id,
    reference: discountFromApi.reference,
    segment: discountFromApi.segment,
    internalName: discountFromApi.internal_name,
    publicName: discountFromApi.public_name,
    unit: discountFromApi.unit,
    validityStartDate: new Date(discountFromApi.validity_start_date),
    validityEndDate: discountFromApi.validity_end_date ? new Date(discountFromApi.validity_end_date) : null,
    measureUnit: discountFromApi.measure_unit,
    ...([DiscountTypes.FixedPrice, DiscountTypes.Simple].includes(discountFromApi.type)
      ? { amount: (discountFromApi as IDiscountFromApiWithAmount).amount }
      : { scale: (discountFromApi as IDiscountFromApiWithScale).scale }),
    discountablesCount: discountFromApi.discountables_count ?? discountFromApi.discountables.length,
    discountableList: discountFromApi.discountables.map((discountable) => ({
      id: discountable.id,
      discountComplianceErrors: discountable.discount_compliance_errors,
      isActive: discountable.is_active,
    })),
    disabledDate: discountFromApi.disabled_date,
    promoCode: discountFromApi.promo_code,
    isFlash: discountFromApi.is_flash,
    isSales: discountFromApi.is_sales,
    isMeta: discountFromApi.is_meta,
    participants: discountFromApi.participants,
    nbOverallUsesMax: discountFromApi.nb_overall_uses_max,
    nbCustomerUsesMax: discountFromApi.nb_customer_uses_max,
    policy: new DiscountPolicy(discountFromApi.type, discountFromApi.scope),
    groupSize: discountFromApi.group_size,
  }
}

export function mapDiscountPolicyDTOToDomain(policy: DiscountPolicyDTO): DiscountPolicy {
  return new DiscountPolicy(policy.type, policy.scope)
}
