import { DateTime } from 'luxon'
import { computed, ref } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { useForm } from './useForm'
import { createDiscount, updateDiscount } from '../../dependency-injection'
import { DiscountRouteName } from '../routes/discountRouteName'
import { luxonDateDisplayFormat } from '../luxon.constants'
import { useMetaDiscount } from './meta-discount/useMetaDiscount'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import { FormModes } from '../../domain/constants/FormModes'
import { useUpdateAssociations } from './association/useUpdateAssociations'
import { useLoadedForm } from './useLoadedForm'
import { Discount } from '../../domain/Discount'

const getSuccessMessage = (mode: FormModes) =>
  `La remise a été ${mode === FormModes.CREATE ? 'créée' : 'mise à jour'} avec succès`

const formatDate = (date: Date) => DateTime.fromJSDate(date).toFormat(luxonDateDisplayFormat)

const executeUseCase = async (discountForm: DiscountForm): Promise<Discount> => {
  return discountForm.mode === FormModes.CREATE
    ? createDiscount.execute(discountForm)
    : updateDiscount.execute(discountForm)
}

export const useSummaryStep = () => {
  const { associationStepRequired } = useForm()
  const form = useLoadedForm()

  const { showNotificationSuccess, showNotificationError } = useBoToast()
  const router = useRouter()

  const { updateAssociations, associationsCount } = useUpdateAssociations()

  const { sellers: metaDiscountSellers, isUsingMetaDiscount } = useMetaDiscount()

  const isLoading = ref(false)
  const saveDiscount = async () => {
    try {
      isLoading.value = true
      const { id: discountId } = await executeUseCase(form.value)
      if (associationStepRequired.value) await updateAssociations(discountId)
      isLoading.value = false

      const successMessage = getSuccessMessage(form.value.mode)
      showNotificationSuccess(successMessage)

      router.push({ name: DiscountRouteName.LISTING })
    } catch (err) {
      showNotificationError(err.message)
    }
  }

  return {
    isUsingMetaDiscount,
    sellers: metaDiscountSellers,
    publicName: computed(() => form.value.publicName.validatedValue),
    reference: computed(() => form.value.reference.validatedValue),
    internalName: computed(() => form.value.internalName.validatedValue),
    promoCode: computed(() => form.value.promoCode.validatedValue),
    formattedStartDate: computed(() => formatDate(form.value.validityStartDate.validatedValue!)),
    formattedEndDate: computed(() =>
      form.value.validityEndDate.validatedValue ? formatDate(form.value.validityEndDate.validatedValue) : '-',
    ),
    associationsCount,
    saveDiscount,
    isLoading,
  }
}
