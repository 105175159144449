<script>
import { mapGetters } from 'vuex'
import { use18n } from '@invivodf/locales/src/use18n'

export default {
  name: 'PHierarchicalMenu',
  props: {
    categories: {
      type: Array,
    },
    refine: {
      type: Function,
    },
    nbHits: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMobile: false,
      isTagMode: false,
      isFullScreen: false,
      selectedLevel: null,
      hierarchyDepth: 2,
    }
  },
  computed: {
    ...mapGetters(['getCategories']),
    cssBase() {
      return this.isMobile ? 'mobile-menu' : 'desktop-menu'
    },
    rootLevel() {
      return !this.categories.find((e) => e.isRefined)
    },
    hasManyLevelsAfter() {
      return this.selectedLevel === 1 && this.hierarchyDepth === 4
    },
  },
  render(h) {
    let renderElements
    // elements
    const chevron = h('span', { class: ['adn-icon', 'adn-icon--navigation-direction-right'] })
    // css
    const rootClass = [`${this.cssBase}__root`]
    const selectedItem = this.categories.find((e) => e.isRefined)
    if (selectedItem) {
      renderElements = this.createSubMenu({ h, data: selectedItem })
      this.selectedLevel = this.findSelectedLevel(selectedItem)
      this.hierarchyDepth = this.getCategoryHierarchyDepth(this.getCategories, selectedItem.label)
      this.isFullScreen =
        this.isMobile &&
        (this.selectedLevel < this.hierarchyDepth - 2 || (this.selectedLevel === 0 && this.hierarchyDepth === 2))
      if (this.isFullScreen) rootClass.push(`${this.cssBase}__root--full-screen`)
    } else {
      this.selectedLevel = null
      this.isFullScreen = false
      renderElements = this.categories.map((data) =>
        this.createSubMenu({ level: 0, h, data, slot: this.isMobile && chevron }),
      )
      rootClass.push(`${this.cssBase}__root--normal-screen`)
    }

    return h('div', { class: this.cssBase }, [
      this.renderTitle(h, selectedItem && selectedItem.value),
      h('ul', { class: rootClass }, [renderElements]),
    ])
  },
  beforeMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    findSelectedLevel(selectedItem) {
      let level = 0
      let currentItem = selectedItem.data.find((e) => e.isRefined)
      while (currentItem) {
        currentItem = currentItem.data && currentItem.data.find((e) => e.isRefined)
        level += 1
      }
      return level
    },
    dispatchAndRefine(value, level = 0, isRefined = false) {
      // TODO remove and replace store
      const NO_FAMILY_SELECTED = ''
      const categories = value.split(' > ')
      if (level === 0) {
        this.$store.dispatch('setFamily', isRefined ? NO_FAMILY_SELECTED : value)
        this.$store.dispatch('resetSelectedCategory')
      } else if (level === 1) {
        this.$store.dispatch('setFamily', categories[0])
        if (isRefined) {
          this.$store.dispatch('resetSelectedCategory')
        } else {
          this.$store.dispatch('setCategory', categories[1])
        }
      } else {
        this.$store.dispatch('setFamily', categories[0])
        this.$store.dispatch(
          'setCategory',
          isRefined ? categories[categories.length - 2] : categories[categories.length - 1],
        )
      }
      return this.refine(value, level)
    },
    deselectCategory(value) {
      const NO_FAMILY_SELECTED = ''
      this.$store.dispatch('setFamily', NO_FAMILY_SELECTED)
      this.$store.dispatch('resetSelectedCategory')
      return this.refine(value, 0)
    },
    createSubMenu({ level = 0, h, data, slot }) {
      let list
      if (data.isRefined && data.data) {
        const refinedCategory = data.data.find((e) => e.isRefined)
        const elementsToRender =
          refinedCategory &&
          (refinedCategory.data ||
            (!refinedCategory.data && this.isMobile && (level < this.hierarchyDepth - 2 || level === 0)))
            ? [refinedCategory]
            : data.data
        // css group
        const subMenuCss = [`${this.cssBase}__sub-menu`, `sub-menu__${level}`]
        const lastSubMenu = this.hierarchyDepth !== 2 && this.hierarchyDepth === level + 2
        if (this.isMobile && lastSubMenu) subMenuCss.push('sub-menu--last')
        //
        list = h(
          'ul',
          { class: subMenuCss },
          elementsToRender.map((elem) => {
            return this.isFullScreen && this.hierarchyDepth !== 4
              ? this.renderNode({ level: level + 1, h, data: elem })
              : this.createSubMenu({ level: level + 1, h, data: elem, slot })
          }),
        )
      }
      return this.renderNode({ level, h, data, list, slot })
    },
    renderNode({ h, level, data, list, slot }) {
      let isLastRenderedGroup
      let hitsCount

      if (this.isMobile) {
        const titleLevel = this.selectedLevel >= 2 && this.hierarchyDepth === 4 && level === 2
        isLastRenderedGroup = this.isFullScreen
          ? level === 0 || (this.hierarchyDepth === 4 && level === 1 && this.selectedLevel === 1)
          : titleLevel || (level === 1 && this.hierarchyDepth <= 3)
        hitsCount =
          data.isRefined &&
          ((level === 1 && this.hierarchyDepth <= 3) || titleLevel) &&
          h('span', { class: `${this.cssBase}__hits` }, `${data.count} Produit${data.count > 1 ? 's' : ''}`)
      }
      const nodeType = data.data || isLastRenderedGroup ? 'node' : 'leaf'
      // css
      const elemClass = [`${this.cssBase}__group-node`, `group-node__${level}`]
      const leafClass = [`${this.cssBase}__${nodeType}`, `${nodeType}__${level}`]
      if (this.selectedLevel === null) {
        elemClass.push('root-group')
      }
      if (data.isRefined) {
        elemClass.push(`${this.cssBase}__group-node--selected`)
        leafClass.push(`${this.cssBase}__${nodeType}--selected`)
      }
      if (level === this.hierarchyDepth - 1 && this.hierarchyDepth > 2) {
        elemClass.push(`${this.cssBase}__last-level`)
        if (data.isRefined) elemClass.push(`${this.cssBase}__last-level--selected`)
      }
      if (this.isFullScreen) {
        if (level === 0) leafClass.push(`title`)
        elemClass.push('item')
      }
      // event
      const fn = () => this.dispatchAndRefine(data.value, level, data.isRefined)
      let lineFn
      let spanFn
      if (this.isMobile && (this.isFullScreen || this.rootLevel)) {
        lineFn = {
          click(event) {
            fn()
            event.stopPropagation()
          },
        }
        elemClass.push(`${this.cssBase}__group-node--pointer`)
      } else
        spanFn = {
          click() {
            fn()
          },
        }
      const backLink = h('span', {
        on: {
          click(event) {
            fn()
            event.stopPropagation()
          },
        },
        class: ['adn-icon', 'adn-icon--navigation-arrow-back', `${this.cssBase}__backlink`],
      })
      if (
        this.hierarchyDepth === 4 &&
        ((level === 1 && this.selectedLevel >= 2) || (level === 0 && this.selectedLevel === 1))
      ) {
        return h('li', [list])
      }
      return h('li', { on: lineFn, class: elemClass }, [
        isLastRenderedGroup && backLink,
        h('span', { on: spanFn, class: leafClass }, data.label),
        hitsCount,
        list,
        slot,
      ])
    },
    onResize() {
      this.isMobile = window.innerWidth < 992
    },
    renderTitle(h, title) {
      const i18n = use18n()
      return this.rootLevel
        ? h(
            'span',
            { class: `${this.cssBase}__title` },
            `${i18n.t('ctx.products.hierarchical-menu__category-render-title')}`,
          )
        : !this.isMobile &&
            h(
              'span',
              {
                class: [`${this.cssBase}__title`, `${this.cssBase}__title--link`],
                on: { click: () => this.deselectCategory(title) },
              },
              `${i18n.t('ctx.products.hierarchical-menu__render-title').toString()}`,
            )
    },
    getCategoryHierarchyDepth(list = [], value) {
      const targetCategory = list.find((category) => category.label === value)

      if (targetCategory) {
        return targetCategory.hierarchyDepth
      }

      for (let i = 0; i < list.length; i += 1) {
        const hierarchyDepth = this.getCategoryHierarchyDepth(list[i].children, value)
        if (hierarchyDepth) {
          return hierarchyDepth
        }
      }
      return targetCategory
    },
  },
}
</script>
<style lang="scss" src="./PHierarchicalMenu.scss" />
