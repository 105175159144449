import { computed, Ref, ref, watch } from 'vue'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'

export interface IUseDiscountInput {
  value: Ref
  error: Ref
  validate: () => void
  discountForm: Ref<DiscountForm>
}

const fillFormFromInput = (value, discountForm: Ref<DiscountForm>, inputName: string, validate: () => void) => {
  watch(value, (newVal) => {
    discountForm.value[inputName].validatedValue = newVal
    validate()
  })
}

export const useDiscountInput = (discountForm: Ref<DiscountForm>, inputName: string): IUseDiscountInput => {
  const value = ref(discountForm.value[inputName]?.validatedValue)

  const error = ref(discountForm.value[inputName]?.errors?.[0])

  const validate = () => {
    discountForm.value[inputName]?.validate()
    ;[error.value] = discountForm.value[inputName].errors
  }

  fillFormFromInput(value, discountForm, inputName, validate)

  return { error, value, validate, discountForm }
}
