
import { IdfButton } from '@invivodf/ui-kit'
import { defineComponent, PropType } from 'vue'

import CDiscountSliceForm from './inputs/CDiscountSliceForm.vue'
import { IUseDiscountScaleInput } from '../composables/useDiscountScaleInput'
import { IUseDiscountMeasureUnitInput } from '../composables/useDiscountMeasureUnitInput'

export default defineComponent({
  name: 'CDiscountScale',
  props: {
    unitInput: {
      type: Object,
      required: true,
    },
    measureUnitInput: {
      type: Object as PropType<IUseDiscountMeasureUnitInput | null>,
      required: true,
    },
    scale: {
      type: Object as PropType<IUseDiscountScaleInput>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  components: { CDiscountSliceForm, IdfButton },
  data({ scale }) {
    const { slices, addSlice, removeSlice } = scale
    return { slices, addSlice, removeSlice }
  },
})
