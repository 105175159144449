
import { defineComponent, onMounted, ref } from 'vue'
import { provideTabItemRegistrator, TabItem } from './utils'

export default defineComponent({
  name: 'AdnTab',
  setup() {
    const items = ref<TabItem[]>([])
    const activeHeader = ref()

    provideTabItemRegistrator({
      add(child: TabItem) {
        items.value = [...items.value, child]
      },
    })

    function showItem(name: string) {
      items.value.forEach((item) => {
        if (item.name !== name) {
          item.hide()
        } else {
          activeHeader.value = item.name
          item.show()
        }
      })
    }

    onMounted(() => {
      if (items.value.length > 0) {
        showItem(items.value[0].name)
      }
    })

    return {
      items,
      showItem,
      activeHeader,
    }
  },
})
