import { onMounted, ref } from 'vue'
import { usePartner } from '@/composables/usePartner'
import QuotaDashboard from '@/contexts/quota-dashboard/domain/quota-dashboard'
import { sortQuotas } from '@/contexts/quota-dashboard/domain/quota-dashboard.list'
import { getQuotaList } from '../../dependency-injection'

export function useQuotas() {
  const { partnerId } = usePartner()

  const quotas = ref<QuotaDashboard[]>([])
  const isLoading = ref(false)

  const loadQuotas = async () => {
    isLoading.value = true
    try {
      quotas.value = sortQuotas(await getQuotaList.execute(partnerId.value!))
    } finally {
      isLoading.value = false
    }
  }

  onMounted(loadQuotas)

  return {
    quotas,
    isLoading,
  }
}
