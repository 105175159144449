import { PluginFunction, PluginObject } from 'vue'

interface InstallOptions {
  clientId: string
  base: string
}

const install: PluginFunction<InstallOptions> = (Vue, opts) => {
  if (!opts?.clientId) return

  window.axeptioSettings = {
    clientId: opts.clientId,
    cookiesVersion: opts.base,
  }

  const firstScript = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')
  script.async = true
  script.src = '//static.axept.io/sdk.js'
  // eslint-disable-next-line no-unused-expressions
  firstScript?.parentNode?.insertBefore(script, firstScript)

  // eslint-disable-next-line no-param-reassign, no-multi-assign
  Vue.prototype.$axeptioPluginInstalled = Vue.$axeptioPluginInstalled = true
}

const plugin: PluginObject<InstallOptions> = { install }

export default plugin
