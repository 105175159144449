let previousUrl

export function download(blob, fileName = 'file') {
  if (previousUrl) URL.revokeObjectURL(previousUrl)
  previousUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = previousUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}
