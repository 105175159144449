
import { computed, defineComponent, ref } from 'vue'
import { formatDate, formatEuros, measureUnit } from '@invivodf/common/src/core/l10n'
import { IdfIcon } from '@invivodf/ui-kit'
import { Big } from 'big.js'
import CDiscountModal from '../../CDiscountModal.vue'
import CAddButton from './CAddButton.vue'
import CDiscountReferenceList from './CDiscountReferenceList.vue'
import { useDiscountableSelected } from '../../../composables/association/useDiscountableSelected'

export default defineComponent({
  name: 'CDiscountableRow',
  components: { CAddButton, IdfIcon, CDiscountModal, CDiscountReferenceList },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    complianceErrors: {
      type: Array,
      default: () => [],
    },
    unitPrice: { type: Number, required: true },
    nbMeasureInOneUnit: { type: Number },
    measureUnit: { type: String, required: false },
    validityStartDate: { type: Date, required: true },
    validityEndDate: { type: Date },
    discountReferences: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const formattedMeasurementPrice = computed(() =>
      props.measureUnit !== 'unit'
        ? `${formatEuros(+Big(props.unitPrice).div(Big(props.nbMeasureInOneUnit!)))} / ${measureUnit(
            props.measureUnit,
          )}`
        : '',
    )

    const { isSelected } = useDiscountableSelected()
    const added = computed(() => isSelected(props.id))

    const complianceErrorsModal = ref({ show: () => {} })

    return {
      uncompliant: computed(() => props.complianceErrors.length > 0),
      formattedPrice: computed(() => formatEuros(props.unitPrice)),
      formattedMeasurementPrice,
      formattedStartDate: computed(() => formatDate(props.validityStartDate)),
      formattedEndDate: computed(() => formatDate(props.validityEndDate) ?? '-'),
      discountableToggled: () => {
        emit('articleToggled', props.id)
      },
      added,
      complianceErrorsModal,
      showComplianceErrorsModal: () => {
        complianceErrorsModal.value.show()
      },
    }
  },
})
