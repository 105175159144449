
import { PropType, toRefs, computed } from 'vue'
import { DiscountScopes } from '@invivodf/discount-sdk/admin'
import { labeledScopes } from '@invivodf/common/src/contexts/discount/application/composables/labeledScopes'

export default {
  name: 'CDiscountScope',
  props: {
    scope: { type: String as PropType<DiscountScopes>, required: true },
  },
  setup(props) {
    const { scope } = toRefs(props)

    return {
      currentScopeText: computed(() => labeledScopes.find((s) => s.value === scope.value)?.text),
    }
  },
}
