/* eslint-disable camelcase */
import {
  AssociationApi,
  AssociationDTO,
  MetaDiscountAssociationsValidationDTO,
} from '@invivodf/common/src/contexts/discount/infra/datasource/IAssociation.datasource'
import { MetaDiscountAssociationsValidation } from '../../domain/MetaDiscountAssociationsValidation'

export const mapAssociationDTOFromApi = ({
  seller_id,
  discount_reference,
  discountable_id,
}: AssociationApi): AssociationDTO => ({
  sellerId: seller_id,
  discountableId: discountable_id,
  discountReference: discount_reference,
})

export const mapMetaDiscountAssociationsValidationFromDTO = (
  validation: MetaDiscountAssociationsValidationDTO,
): MetaDiscountAssociationsValidation => ({
  totalCount: validation.total_count,
  errors: validation.errors,
})
