
import { defineComponent, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { scrollToAnchor } from '@/utils/dom.utils'
import PQuota from '../../presenters/PQuota/PQuota.vue'
import { useQuotas } from '../../composables/useQuota'

export default defineComponent({
  name: 'CQuotaList',
  components: { PQuota },
  setup() {
    const { quotas, isLoading } = useQuotas()
    const route = useRoute()

    watch(quotas.value, (newVal) => {
      if (newVal) {
        const { hash } = route
        if (hash) scrollToAnchor(hash.substring(1))
      }
    })

    return { quotas, isLoading }
  },
})
