<template>
  <div class="parameters">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'VParameters',
}
</script>

<style scoped lang="scss">
.parameters {
  background-color: var(--idf-color-neutral-200);
}
</style>
