import { ImportReportDTO } from '../datasource/ImportReportDTO'

export class TranslateAndDateReportMapper {
  // eslint-disable-next-line class-methods-use-this
  map(report: ImportReportDTO) {
    return {
      ...report,
      translated_type: report.report.id_name === 'discounts' ? 'remises' : 'associations remise',
      imported_at: report.created_at,
    }
  }
}
