import { cloudinaryService } from '@/services'
import { getProductDefaultImageFull } from '@/core/products-images.constants'
import { computed, ComputedRef, inject, provide, ref } from 'vue'
import { useStore } from '@/composables/useStore'
import { Product } from './useProduct'

export function createProductImageService(product: ComputedRef<Product>) {
  const store = useStore()
  const customPartnerProductImage = ref('')

  const defaultImage = computed(() => getProductDefaultImageFull(product.value.kind, product.value.type))
  const productImage = computed(
    () => customPartnerProductImage.value || product?.value.images?.[0] || defaultImage.value || '',
  )

  async function dispatchCustomPartnerProductImage() {
    const result = await cloudinaryService.getProductMedias(store.getters.cooperativeId, product.value.id)
    customPartnerProductImage.value = result?.thumbnail?.url || ''
  }

  async function openUpdateProductWidget() {
    const result = await cloudinaryService.openCloudinaryUploadWidget(
      `/partners/${store.getters.cooperativeId}/media/products/${product.value.id}/upload-parameters`,
      store.getters.cooperativeId,
      { cropping: true, croppingAspectRatio: 1 },
    )
    customPartnerProductImage.value = result?.secure_url
  }

  return {
    productImage,
    customPartnerProductImage,
    dispatchCustomPartnerProductImage,
    openUpdateProductWidget,
  }
}

export const productImageSymbol = Symbol('productImage')

export function provideProductImage(product: ComputedRef<Product>) {
  provide(productImageSymbol, createProductImageService(product))
}

export function useProductImage() {
  return inject<any>(productImageSymbol)
}
