
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { ISlice } from '@invivodf/common/src/contexts/discount/domain/Discount'
import { DiscountMeasureUnits, DiscountUnits, DiscountTypes } from '@invivodf/discount-sdk/admin'
import { formatedMeasureUnits } from '@invivodf/common/src/contexts/discount/domain/formatedMeasureUnits'

export default defineComponent({
  name: 'CDiscountScaleDetail',
  props: {
    scale: {
      type: Array as PropType<ISlice[]>,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    measureUnit: {
      type: String as PropType<DiscountMeasureUnits>,
      required: true,
    },
    discountType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { discountType, measureUnit, unit } = toRefs(props)
    return {
      formatMeasureUnit(value = 0) {
        if (discountType.value === DiscountTypes.OnRevenue) {
          return DiscountUnits.Euro
        }
        if (value <= 1) return formatedMeasureUnits[measureUnit.value].single
        return formatedMeasureUnits[measureUnit.value].plural
      },
      discountUnit: computed(() =>
        unit.value === '€' ? `${unit.value}/${formatedMeasureUnits[measureUnit.value].single}` : unit.value,
      ),
    }
  },
})
