<template>
  <div class="product-description">
    <div class="product-description__main-block" :class="{ 'edit-description': isEditingDescription }">
      <h4 class="product-description__title">Descriptif</h4>

      <div
        v-if="!isEditingDescription && !this.hasDescription"
        class="product-description__empty-description"
        data-test-empty-description
      >
        <div class="product-description__text-block" data-test-description-txt>
          Vous n'avez pas encore écrit de descriptif pour cet article
        </div>
        <IdfButton @click="editDescription" data-test-description-btn="create">Écrire un descriptif</IdfButton>
      </div>

      <template v-else-if="!isEditingDescription && this.hasDescription">
        <adn-text-area :initialValue="inEditDescription" data-test-text-area :edition="false" />

        <div class="product-description__buttons-block">
          <IdfButton kind="link" @click="editDescription" data-test-description-btn="edit" icon-left="edit-outlined"
            >Modifier</IdfButton
          >
        </div>
      </template>

      <div v-else class="product-description__edit-description" data-test-edit-block>
        <adn-text-area
          v-model="inEditDescription"
          :initialValue="inEditDescription"
          :placeholder="this.placeholder"
          :maxContentLength="maxContentLength"
          :wysiwyg="true"
          data-test-text-area-edit
        >
          <template v-slot:content-length="{ visibleContentLength }">
            {{ charactersLeft(visibleContentLength) }}
          </template>
        </adn-text-area>
        <div class="product-description__buttons-block">
          <IdfButton kind="link" @click="cancelDescription" data-test-description-btn="cancel">Annuler</IdfButton>
          <IdfButton @click="saveDescription" data-test-description-btn="save">Enregistrer</IdfButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'CProductDescription',
  components: { IdfButton },
  data() {
    return {
      editedDescription: this.description || '',
      inEditDescription: this.description || '',
      isEditingDescription: false,
      placeholder: '',
      maxContentLength: 700,
    }
  },
  computed: {
    hasDescription() {
      return this.editedDescription !== ''
    },
  },
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    editDescription() {
      this.isEditingDescription = true
    },
    cancelDescription() {
      this.isEditingDescription = false
      this.inEditDescription = this.editedDescription
    },
    saveDescription() {
      this.$emit('save-description', this.inEditDescription)
      this.editedDescription = this.inEditDescription
      this.isEditingDescription = false
    },
    charactersLeft(contentLength) {
      const charactersLeftVar = this.maxContentLength - contentLength
      if (charactersLeftVar <= 1) {
        return `${charactersLeftVar} caractère restant`
      }
      return `${charactersLeftVar} caractères restants`
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.product-description {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  &__empty-description {
    display: flex;
    align-items: center;
  }
  &__edit-description {
    display: flex;
    flex-direction: column;
  }
}
.product-description__title {
  font-size: 18px;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-description__main-block {
  background-color: var(--idf-color-neutral-100);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 20px 25px;
  max-width: 1070px;
  &.edit-description {
    background: transparent;
    border: none;
  }
}
.product-description__text-block {
  padding: 0;
  font-size: 16px;
  color: var(--idf-color-neutral-500);
  white-space: pre-wrap;
  flex-grow: 1;
  word-break: break-word;
}
.product-description__text-block--has-description {
  color: black;
}
.product-description__main-block textarea {
  width: 100%;
  height: 100px;
  font-size: 1em;
  outline: 0;
  box-shadow: none;
  border: none;
  padding: 0.8rem 0;
  resize: none;
  word-break: break-all;
}
.product-description__buttons-block {
  display: flex;
  justify-content: flex-end;
  gap: var(--idf-spacing-2);
}
.product-description__characters-left {
  flex-grow: 1;
  color: var(--idf-color-neutral-400);
}
</style>
