import { LogisticRouteOption } from '../interfaces/logistic-route-option'

export const logisticRoutes: LogisticRouteOption[] = [
  {
    value: 'home_delivery',
    icon: 'truck-filled',
    label: 'Livraison',
    component: 'IdfIcon',
  },
  {
    value: 'pickup_point',
    icon: 'location-filled',
    label: 'Point relais',
    component: 'IdfIcon',
  },
  {
    value: 'click_and_collect',
    icon: 'cooperative-filled',
    label: 'Retrait (Click&Collect)',
    component: 'PIcon',
  },
]
