import Router from 'vue-router'
import { provide, inject } from 'vue'

export const routerSymbol = Symbol('router')

export function provideRouter(router: Router) {
  provide(routerSymbol, router)
}

/**
 * Returns the router instance. Equivalent to using `$router` inside
 * templates.
 */
export const useRouter = (): Router => inject(routerSymbol)!
