import Vue from 'vue'
import { productService } from '@/services'

const initialState = {
  productMap: {} as { [id: string]: any },
  productInvivoIdMap: {} as { [id: string]: any },
}

export const PRODUCT_LIST_ERROR = 'Cannot retrieve product list'

const NECESSARY_PRODUCT_PROPERTY = ['id', 'kind', 'type', 'images']
export const getNecessaryProductInfo = (product, invivoId) => {
  const variant = product.variants.find(({ id = '' }) => id === invivoId)
  return NECESSARY_PRODUCT_PROPERTY.reduce((acc, prop) => {
    return {
      ...acc,
      [prop]: product[prop],
      variant,
    }
  }, {})
}

type CatalogState = typeof initialState

const catalog = {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_PRODUCT(state: CatalogState, product) {
      Vue.set(state.productMap, product.id!, product)
    },
    DELETE_PRODUCT(state: CatalogState, productId: string) {
      Vue.delete(state.productMap, productId)
    },
    SET_PRODUCT_INVIVO_ID_MAP(state: CatalogState, productMap: {}) {
      state.productInvivoIdMap = productMap
    },
  },
  actions: {
    async deleteAssociatedProduct({ dispatch, state }, payload) {
      try {
        await productService.unassociateProducts(payload.cooperativeId, payload.productId, payload.associatedProductId)
        const product = state.productMap[payload.productId]
        const associatedProductIndex = product.associatedProducts.findIndex(
          // eslint-disable-next-line no-underscore-dangle
          (prod) => prod._id === payload.associatedProductId,
        )
        product.associatedProducts.splice(associatedProductIndex, 1)
        dispatch('setToast', { type: 'success', message: 'Le produit a été supprimé' }, { root: true })
      } catch (e) {
        dispatch(
          'setToast',
          { type: 'danger', message: 'Erreur lors de la suppression du produit suggéré' },
          { root: true },
        )
      }
    },
    async setAssociatedProducts({ dispatch, state }, payload) {
      try {
        await productService.associateProducts(payload.cooperativeId, payload.productId, payload.associatedProductId)
        const associatedProduct = await productService.getV2(payload.associatedProductId, payload.cooperativeId)
        const product = state.productMap[payload.productId]
        product.associatedProducts.push({ ...associatedProduct, sellable: true, _id: associatedProduct.id })
        dispatch('setToast', { type: 'success', message: 'Le produit a été suggéré' }, { root: true })
      } catch (e) {
        dispatch('setToast', { type: 'danger', message: 'Erreur lors de la suggestion du produit' }, { root: true })
      }
    },
    async fetchProductAndOffers({ dispatch }, productNationalId: string) {
      const productId = await dispatch('fetchProduct', productNationalId)
      if (productId)
        await dispatch(
          `offers/fetchOffers`,
          { filters: { productIdList: [productId] }, limit: Infinity },
          { root: true },
        )
    },
    async fetchVariant(
      { commit, rootState, dispatch },
      { productId, variantId }: { productId: string; variantId: string },
    ) {
      try {
        const product = await productService.getV2(productId, rootState.cooperative.id)
        commit('SET_PRODUCT', product)
        await dispatch(`variantOffers/fetchOffers`, { filters: { variantIdList: [variantId] } }, { root: true })
      } catch {
        try {
          const nationalProduct = await productService.getV2(productId)
          commit('SET_PRODUCT', nationalProduct)
        } catch {
          commit('DELETE_PRODUCT', productId)
        }
      }
    },
    // eslint-disable-next-line consistent-return
    async fetchProduct({ commit, rootState }, productId: string) {
      try {
        const product = await productService.getV2(productId, rootState.cooperative.id)
        commit('SET_PRODUCT', product)
        return product.id
      } catch (error) {
        try {
          const nationalProduct = await productService.getV2(productId)
          commit('SET_PRODUCT', nationalProduct)
        } catch {
          commit('DELETE_PRODUCT', productId)
        }
      }
    },
    async fetchProductsByVariantInvivoId({ getters, commit, rootState }, variantInvivoIdList: string[]) {
      const productInvivoIdMap = getters.getProductInvivoIdMap
      const missingInvivoIdInProductMap = variantInvivoIdList.filter(
        (invivoId) => !Object.keys(productInvivoIdMap).includes(invivoId),
      )
      if (!missingInvivoIdInProductMap.length) return
      try {
        const productList = await productService.getProductsByInvivoIds(
          missingInvivoIdInProductMap,
          rootState.cooperative.id,
        )
        if (!productList || !productList.length) throw Error(PRODUCT_LIST_ERROR)
        const productMap = missingInvivoIdInProductMap.reduce((acc, invivoId) => {
          const product = productList.find(({ variants = [] }) => variants.find(({ id = '' }) => id === invivoId))
          if (!product) return acc
          const productInfos = getNecessaryProductInfo(product, invivoId)
          return {
            ...acc,
            [invivoId]: { ...productInfos },
          }
        }, {})
        commit('SET_PRODUCT_INVIVO_ID_MAP', { ...getters.getProductInvivoIdMap, ...productMap })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Error while fetching products with variant invivo ids:', err)
        throw err
      }
    },
  },
  getters: {
    getProductById: (state: CatalogState, getters, rootState, rootGetters) => (id: string) => {
      const foundProduct = state.productMap[id]
      if (foundProduct === undefined || !Array.isArray(foundProduct.variants)) return foundProduct
      const variants = foundProduct.variants.map((variant) => {
        const offers = rootGetters[`offers/getOffersByVariantId`](variant.id)
        return { offers, ...variant }
      })
      return { ...foundProduct, variants }
    },
    getVariantById:
      (state: CatalogState, getters, rootState, rootGetters) => (productId: string, variantId: string) => {
        const {
          kind,
          variants = [],
          family,
          name: productName,
          exclusive: productExclusive,
          categories = [],
          supplier: productSupplier,
        } = state.productMap[productId] || {}
        const variant = variants.find((item) => item.id === variantId) || {}
        const offers = rootGetters[`variantOffers/getOffersByVariantId`](variant.id)
        const productHasAssociatedVariants = variants.some((item) => item.associated)
        const category = categories[0]
        return {
          ...variant,
          offers,
          productId,
          kind,
          family,
          productName,
          productExclusive,
          productHasAssociatedVariants,
          category,
          productSupplier,
        }
      },
    getProductInvivoIdMap: (state: CatalogState) => {
      return state.productInvivoIdMap
    },
  },
}

export default catalog
