import { ListingQuery } from '../domain/listing/ListingQuery'
import { ListingResponse } from '../domain/listing/ListingResponse'

export interface ListRepository {
  list: (query: ListingQuery, isRetrievingMetaDiscounts: boolean) => Promise<ListingResponse>
}

export class RetrieveDiscountList {
  constructor(private readonly repository: ListRepository) {}

  async execute(query: ListingQuery, isRetrievingMetaDiscounts: boolean): Promise<ListingResponse> {
    return this.repository.list(query, isRetrievingMetaDiscounts)
  }
}
