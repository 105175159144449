
import { IdfIcon } from '@invivodf/ui-kit'
import { defineComponent, toRefs } from 'vue'

export default defineComponent({
  name: 'IdfStep',
  components: { IdfIcon },
  props: {
    title: {
      required: true,
      default: '',
      type: String,
    },
    valid: {
      required: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    index: {
      required: false,
      type: String,
    },
  },
  setup(props, { emit }) {
    const { valid, disabled } = toRefs(props)
    return {
      emitUnfold() {
        if (valid.value && !disabled.value) emit('unfold')
      },
    }
  },
})
