<template>
  <div class="offer__container">
    <div v-if="hasOffers">
      <c-variant-offer-list-item
        v-for="offer in offers"
        :key="offer.id"
        :measureQuantity="measureQuantity"
        :measureUnit="measureUnit"
        :isSoldAsUnit="isSoldAsUnit"
        :offer="offer"
        :productKind="productKind"
        @editOffer="editOffer"
        data-cy="offerList"
      />
    </div>
    <div v-else>
      <div class="text-center">
        <img src="../../../assets/no_result.svg" class="page-not-found__illustration img-fluid" alt="" />
        <p class="text-center" data-cy="noOfferMessage">Attention, vous n‘avez pas encore créé d‘offre.</p>
      </div>
    </div>
    <b-modal hide-header hide-footer ref="offerFormModal" dialog-class="offer__right-modal" size="lg">
      <c-variant-offer-form
        :variantId="variantId"
        :measureQuantity="measureQuantity"
        :measureUnit="measureUnit"
        :isSoldAsUnit="isSoldAsUnit"
        :offer="editingOffer"
        :productKind="productKind"
        @cancel="$refs.offerFormModal.hide()"
        @success="$refs.offerFormModal.hide()"
        @fail="$refs.offerFormModal.hide()"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CVariantOfferForm from '../CVariantOfferForm/CVariantOfferForm.vue'
import CVariantOfferListItem from '../CVariantOfferListItem/CVariantOfferListItem.vue'

export default {
  name: 'CVariantOfferList',
  components: { CVariantOfferListItem, CVariantOfferForm },
  props: ['variantId', 'measureQuantity', 'measureUnit', 'isSoldAsUnit', 'productKind'],
  data() {
    return {
      isCreationFormVisible: false,
      editingOffer: undefined,
    }
  },
  computed: {
    ...mapGetters({
      offers: 'variantOffers/offers',
    }),
    isValidUnit() {
      const unit = this.measureUnit && this.measureUnit.toLowerCase()
      // wait for PIM-ETL merge request #303 and API-OFFER #256 to be deployed to PROD + 1 day at least before removing 'unité'
      return ['kg', 'l', 't', 'mg', 'diffuseurs', 'unité', 'unit'].includes(unit)
    },
    hasOffers() {
      return this.offers && this.offers.length > 0
    },
  },
  methods: {
    editOffer(id) {
      this.editingOffer = this.offers.find((offer) => offer.id === id)
      this.showModal()
    },
    showModal() {
      this.$refs.offerFormModal.show()
    },
  },
}
</script>

<style lang="scss">
.offer__container {
  width: 100%;
}
.offer__error-text--info {
  color: var(--idf-color-neutral-500);
}
.offer__content-text {
  display: flex;
  align-items: center;
}
.offer__content-text-unit {
  font-weight: bold;
}
.modal-dialog.offer__right-modal {
  position: absolute;
  right: 0;
  margin: 0;
  width: 50%;
}
</style>
