import { AxiosInstance } from 'axios'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

const ROOT_CATEGORY_CODE = 'master'

const isNotParentOfOneOfProductCategories = (categoryCode, categories) => {
  return categories.filter((aCategory) => aCategory.parent === categoryCode).length === 0
}

const retrieveHierarchyOrderedFromParentToChildren = (category, categoryByCode) => {
  const hierarchy: any[] = []
  let currentCategory = category
  hierarchy.push(currentCategory)
  while (ROOT_CATEGORY_CODE !== currentCategory.parent) {
    currentCategory = categoryByCode[currentCategory.parent]
    hierarchy.push(currentCategory)
  }
  return hierarchy.reverse()
}

class CategoryService {
  private api: AxiosInstance

  private productApiHost

  constructor({ productApiHost }) {
    this.productApiHost = productApiHost
    this.api = createAxiosClientWithAladinBearer(this.productApiHost, { timeout: 20000 })
  }

  async getV2Categories(excludeBranches: string[]) {
    const query = excludeBranches ? `?exclude_branches=${excludeBranches.join(',')}` : ''
    const response = await this.api.get(`/national-referential/v2/categories${query}`)
    return response.data
  }

  async downloadV2Categories(excludeBranches: string[]) {
    const query = excludeBranches ? `?exclude_branches=${excludeBranches.join(',')}` : ''
    const response = await this.api.get(`/national-referential/v2/categories${query}`, {
      headers: { accept: 'text/csv' },
    })

    const url = window.URL.createObjectURL(
      new Blob([
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        response.data,
      ]),
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createShortFileName('categories'))
    document.body.appendChild(link)
    link.click()
  }

  async get(family) {
    let response
    if (family) {
      response = await this.api.get(`/v1/categories`, { params: { family } })
    } else {
      response = await this.api.get(`/v1/categories`)
    }
    return response.data
  }

  static computeHierarchies = (categories) => {
    const hierarchyByCode: any = {}
    const categoryByCode = {}
    // eslint-disable-next-line no-return-assign
    categories.forEach((category) => (categoryByCode[category.id] = category))
    categories
      .map((category) => category.id)
      .filter((categoryCode) => isNotParentOfOneOfProductCategories(categoryCode, categories))
      .forEach(
        // eslint-disable-next-line no-return-assign
        (categoryCode) =>
          (hierarchyByCode[`${categoryCode}`] = retrieveHierarchyOrderedFromParentToChildren(
            categoryByCode[`${categoryCode}`],
            categoryByCode,
          )),
      )
    return hierarchyByCode
  }
}

export default CategoryService
