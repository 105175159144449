import { useAuthService } from '@invivodf/common/src/auth'
import sdk, { LogisticOfferSDK, LogisticOfferForConfig, LogisticOffer } from '@invivodf/module-logistic-offer-sdk'

class LogisticRepository {
  private logisticOfferSDK: LogisticOfferSDK

  constructor({ logisticApiHost }) {
    this.logisticOfferSDK = sdk(logisticApiHost, { accessTokenSource: () => useAuthService().getAccessToken() })
  }

  save(sellerId: string, logisticOffer: LogisticOffer): Promise<LogisticOffer> {
    return this.logisticOfferSDK.saveLogisticOffer(sellerId, logisticOffer)
  }

  getAll(sellerId: string): Promise<LogisticOffer[]> {
    return this.logisticOfferSDK.getLogisticOffersWithoutAssociation(sellerId)
  }

  getAllWithAssociations(sellerId: string): Promise<LogisticOfferForConfig[]> {
    return this.logisticOfferSDK.getLogisticOffersWithAssociations(sellerId)
  }

  get(sellerId: string, reference: string): Promise<LogisticOfferForConfig> {
    return this.logisticOfferSDK.getLogisticOffer(sellerId, reference)
  }

  delete(sellerId: string, reference: string): Promise<void> {
    return this.logisticOfferSDK.deleteLogisticOffer(sellerId, reference)
  }
}

export default LogisticRepository
