export const zoneChargesColumnDetails = () => [
  {
    header: 'assortment_product_uid',
    format: 'VARCHAR',
    exampleValue: '<code>INV6CFIRXF1S9LAWPHY</code>',
    description: `<strong>Identifiant unique de l’article (ID Invivo)</strong> <br>L’article doit exister dans le catalogue.`,
    mandatory: 'Oui',
  },
  {
    header: 'zone1_label',
    format: 'DECIMAL',
    exampleValue: '32.90',
    description: 'Frais applicable à la première zone',
    mandatory: 'Oui',
  },
  {
    header: 'zone2_label',
    format: 'DECIMAL',
    exampleValue: '50.90',
    description: 'Frais applicable à la deuxième zone',
    mandatory: 'Oui',
  },
  {
    header: 'zone3_label',
    format: 'DECIMAL',
    exampleValue: '60.90',
    description: 'Frais applicable à la troisième zone',
    mandatory: 'Oui',
  },
  {
    header: 'zone4_label',
    format: 'DECIMAL',
    exampleValue: '70.90',
    description: 'Frais applicable à la quatrième zone',
    mandatory: 'Oui',
  },
]

export const zoneChargesDescriptionLines = [
  'L’extension attendue est : <b>.csv</b>',
  'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
  'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
  "L'encoding attendu est : <b>UTF8</b>.",
  "Les <b>zone_label</b> sont récupérés depuis la section renseignement de zone ci-dessus. Il est donc obligatoire de renseigner ses zones de livraison avant d'importer les frais associés.",
]
