
import { ref, PropType } from 'vue'

import { PaymentDueDate } from '@/core'
import { usePaymentDueDates } from '@/composables/usePaymentDueDates'
import { IdfButton } from '@invivodf/ui-kit'
import CPaymentDueDatesForm from '../CPaymentDueDatesForm/CPaymentDueDatesForm.vue'

export default {
  name: 'CPaymentDueDatesItem',
  components: { CPaymentDueDatesForm, IdfButton },
  props: {
    paymentDueDate: {
      type: Object as PropType<PaymentDueDate>,
    },
  },
  setup(_, { emit }) {
    const { deletePaymentDueDate } = usePaymentDueDates()
    const editable = ref(false)

    const formEnable = () => {
      editable.value = !editable.value
      return editable.value
    }

    const removePaymentDueDate = async (reference) => {
      const toastObject = await deletePaymentDueDate(reference)
      emit('delete-payment-due-date', { reference, toastObject })
    }

    return {
      editable,
      formEnable,
      removePaymentDueDate,
    }
  },
}
