import { computed, ComputedRef, onMounted, Ref, ref } from 'vue'
import isEmpty from 'lodash/isEmpty'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { useServerPagination } from './pagination/useServerPagination'
import { retrieveDiscountList } from '../../dependency-injection'
import { ListingQuery } from '../../domain/listing/ListingQuery'
import { ListingFilter } from '../../domain/listing/ListingFilter'
import { Discount } from '../../domain/Discount'
import { useSeller } from '../../../../application/composables/useSeller'
import { isSuperAdmin } from './isSuperAdmin'

const resultOnError = { items: [], total: 0, page: 1 }

const useListingQuery = (currentPage: Ref<number>) => {
  const page = computed(() => currentPage.value)
  const seller = useSeller()

  const listingFilter: Ref<ListingFilter> = ref({})
  const listingQuery: ComputedRef<ListingQuery> = computed(() => ({
    limit: 10,
    sellerId: seller.value.id,
    filter: listingFilter.value,
    page: page.value,
  }))

  return { seller: seller.value.id, listingFilter, listingQuery }
}

export const useDiscountList = () => {
  const { currentItems, currentPage, numberOfPages, setResult, noItems } = useServerPagination<Discount>(10)
  const { listingFilter, listingQuery } = useListingQuery(currentPage)
  const loading = ref(true)
  const firstLoad = ref(true)

  const { showNotificationError } = useBoToast()

  const loadDiscounts = async () => {
    loading.value = true
    try {
      const retrieveMetaDiscounts = await isSuperAdmin()
      const { discountList, total } = await retrieveDiscountList.execute(listingQuery.value, retrieveMetaDiscounts)
      setResult({ items: discountList, total, page: currentPage.value })
    } catch (e) {
      showNotificationError(e.message)
      setResult(resultOnError)
    } finally {
      loading.value = false
    }
  }

  const changePage = (page: number) => {
    currentPage.value = page
    loadDiscounts()
  }

  const changeFilter = (eventQuery: ListingFilter) => {
    listingFilter.value = eventQuery
    currentPage.value = 1
    loadDiscounts()
  }

  onMounted(async () => {
    await loadDiscounts()
    firstLoad.value = false
  })

  const hasNeverCreateDiscountBefore = computed(() => noItems.value && isEmpty(listingFilter.value))

  return {
    discounts: currentItems,
    nbOfPages: numberOfPages,
    hasNeverCreateDiscountBefore,
    loading,
    firstLoad,
    currentPage,
    changePage,
    changeFilter,
    noResultFilter: noItems,
  }
}
