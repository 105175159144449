import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import * as cooperativeVariantMapper from './cooperative-variant.mapper'
import ImportService from './import.service'

export default class CooperativeVariantService {
  constructor({ productApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1/cooperative-variants`, { timeout: 20000 })
    this.productApiHost = productApiHost
  }

  async import(cooperativeId, file) {
    return ImportService.import(`${this.productApiHost}/v1/cooperatives/${cooperativeId}/variants`, file)
  }

  async downloadVariants(cooperativeId, cooperativeName) {
    const response = await this.api.get(`${this.productApiHost}/v1/cooperatives/${cooperativeId}/variants`, {
      headers: { accept: 'text/csv' },
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', ImportService.createFileName(cooperativeName, 'variants'))
    document.body.appendChild(link)
    link.click()
  }

  async update(cooperativeVariant = {}) {
    const response = await this.api.put(
      `/${cooperativeVariant.id}`,
      cooperativeVariantMapper.mapToApi(cooperativeVariant),
    )
    return cooperativeVariantMapper.mapFromApi(response.data)
  }
}
