import { computed, ref, Ref } from 'vue'
import { getNoItems, getNumberOfPages, Pagination } from './useClientPagination'

interface NewResult<T> {
  items: T[]
  page: number
  total: number
}

export type ServerPagination<T> = Pagination<T> & { setResult: (newResult: NewResult<T>) => void }

const guardsOverlap = <T>(newResult: NewResult<T>) =>
  newResult.total < newResult.page ? newResult.total : newResult.page

export const useServerPagination = <T>(range: number): ServerPagination<T> => {
  const currentItems: Ref<T[]> = ref([])
  const total = ref(0)
  const currentPage = ref(1)

  const noItems = computed(() => getNoItems(currentItems.value))

  const numberOfPages = computed(() => getNumberOfPages(total.value, range))

  const setResult = (newResult: NewResult<T>) => {
    currentItems.value = newResult.items
    total.value = newResult.total
    currentPage.value = guardsOverlap(newResult)
  }

  return {
    currentItems,
    currentPage,
    numberOfPages,
    noItems,
    setResult,
  }
}
