
import { defineComponent, Ref, ref } from 'vue'
import { ValidationObserver } from 'vee-validate-last'
import { IdfButton } from '@invivodf/ui-kit'
import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import CDeliveryModeChoice from '../CDeliveryModeChoice/CDeliveryModeChoice.vue'
import {
  useLogisticRoutes,
  useDelayUnits,
  useLogisticOfferRules,
  useLogisticOffer,
  useLogisticOfferWording,
} from '../../composables'
import { PInlineRadios, PLogisticDelay, PInput, PMandatory, PDeliveryNamePreview } from '../../presenters'

export default defineComponent({
  name: 'CLogisticOfferRouteStep',
  components: {
    CDeliveryModeChoice,
    IdfButton,
    PDeliveryNamePreview,
    PInput,
    PInlineRadios,
    PLogisticDelay,
    PMandatory,
    ValidationObserver,
  },
  emits: ['submit-step'],
  setup(props, { emit }) {
    const { logisticOffer, setLogisticOfferDeliveryMode } = useLogisticOffer()
    const { wording } = useLogisticOfferWording()
    const { delayUnits } = useDelayUnits()
    const { logisticRoutes } = useLogisticRoutes()
    const isDrawerVisible: Ref<boolean> = ref(false)
    const onSubmit = (): void => {
      emit('submit-step')
    }
    const onDrawerClose = (): void => {
      isDrawerVisible.value = false
      document.body.classList.remove('drawer-open')
    }
    const onDrawerOpen = (): void => {
      isDrawerVisible.value = true
      document.body.classList.add('drawer-open')
    }
    const onSelectDeliveryMode = (deliveryMode: DeliveryModeEntity): void => {
      setLogisticOfferDeliveryMode(deliveryMode)
      onDrawerClose()
    }
    return {
      delayUnits,
      isDrawerVisible,
      logisticOffer,
      logisticRoutes,
      onDrawerClose,
      onDrawerOpen,
      onSelectDeliveryMode,
      onSubmit,
      rules: useLogisticOfferRules(),
      wording,
    }
  },
})
