const STOCK_EMITTER = 'seller'

export function mapFromApi(stock = {}) {
  return {
    id: stock.id,
    type: stock.type,
    quantity: stock.quantity,
    movementQuantity: stock.movement_quantity,
    cooperativeVariantId: stock.cooperative_variant_id,
    orderId: stock.order_id,
    offerId: stock.offer_id,
    createdAt: stock.created_at,
  }
}

export function mapToApi(stock = {}) {
  return {
    type: stock.type,
    actual_quantity: stock.actualQuantity,
    new_quantity: stock.newQuantity,
    emitter: STOCK_EMITTER,
    cooperative_variant_id: stock.cooperativeVariantId,
  }
}
