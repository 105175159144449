import { ThresholdsStock } from '@/domain/ThresholdsStock'
import { AxiosInstance } from 'axios'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import { mapFromApiListThresholdsStocksResponse, mapThresholdsStockToApi } from './thresholds-stock.mapper'

class ThresholdsStockService {
  api: AxiosInstance

  orderApiHost: string

  constructor({ orderApiHost }) {
    this.orderApiHost = orderApiHost
    this.api = createAxiosClientWithAladinBearer(`${orderApiHost}/v1/stock-thresholds`)
  }

  async getAllByCooperativeId(cooperativeId: number): Promise<ThresholdsStock[]> {
    try {
      const response = await this.api.get(`?cooperative_id=${cooperativeId}`)
      return mapFromApiListThresholdsStocksResponse(response.data)
    } catch (error) {
      // TODO: fix when create get api
      return []
    }
  }

  async saveByCooperativeId(thresholdStock: ThresholdsStock, cooperativeId: number) {
    const response = await this.api.post(`?cooperative_id=${cooperativeId}`, mapThresholdsStockToApi(thresholdStock))
    return response.data
  }
}

export default ThresholdsStockService
