import QuotaDashboard from '@/contexts/quota-dashboard/domain/quota-dashboard'

function compareByRemainingPercentage(quota1, quota2) {
  return quota1.remaining / quota1.initial - quota2.remaining / quota2.initial
}

function invalidFirst(quota1, quota2) {
  if (quota1.isValid === quota2.isValid) {
    return 0
  }
  return quota1.isValid ? 1 : -1
}

function combine(quota1, quota2) {
  return invalidFirst(quota1, quota2) || compareByRemainingPercentage(quota1, quota2)
}

export function sortQuotas(quotas: QuotaDashboard[]): QuotaDashboard[] {
  return quotas.sort(combine)
}
