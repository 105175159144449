import { DiscountTypes } from '@invivodf/discount-sdk/admin'

export enum FilterStatusOption {
  Upcoming = 'a_venir',
  Active = 'en_cours',
  Expired = 'archivee',
}

export interface ListingFilter {
  keyWord?: string
  status?: FilterStatusOption
  discountType?: DiscountTypes
}
