import { LogisticOffer } from '@invivodf/module-logistic-interfaces'
import { LogisticOfferFormData } from '../domain'
import { isLogisticOfferValid } from '../domain/logistic-offer.validate'
import { convertFormDataToLogisticOffer } from '../infrastructure/logistic-offer.mapper'
import LogisticRepository from '../infrastructure/logistic.repository'

export default class SaveLogisticOffer {
  private readonly repository: LogisticRepository

  constructor(repository: LogisticRepository) {
    this.repository = repository
  }

  async execute(sellerId: string, logisticOfferFormData: LogisticOfferFormData): Promise<LogisticOffer> {
    if (!isLogisticOfferValid(logisticOfferFormData))
      throw new Error('Logistic offer is invalid: required fields are missing.')
    const logisticOffer = convertFormDataToLogisticOffer(logisticOfferFormData)
    return this.repository.save(sellerId, logisticOffer)
  }
}
