
import { cloudinaryService } from '@/services'
import { IdfButton, IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { computed, defineComponent, Ref, ref, toRef, watch, PropType } from 'vue'

import { HomeBanner } from '../../domain/home-banner'

export default defineComponent({
  name: 'VImportPartnerBanner',
  components: {
    IdfButton,
    IdfInput,
    IdfStandardInputs,
  },
  props: {
    banner: {
      type: Object as PropType<HomeBanner>,
      required: true,
    },
    partnerId: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const IMAGE_RATIO = 3.153

    const BANNER_PATH = 'home-banners'
    const homeBanner: Ref<HomeBanner> = ref(props.banner)
    const isLoading: Ref<boolean> = toRef(props, 'isLoading')

    const TITLE_MAX_LENGTH = 45
    const titleLength = computed(() => (homeBanner.value.title ? homeBanner.value.title.length : 0))
    const titleMaxLengthReached = computed(() => titleLength.value > TITLE_MAX_LENGTH)

    const DESCRIPTION_MAX_LENGTH = 150
    const descriptionLength = computed(() => (homeBanner.value.description ? homeBanner.value.description.length : 0))
    const descriptionMaxLengthReached = computed(() => descriptionLength.value > DESCRIPTION_MAX_LENGTH)

    const canPublish = computed(
      () => !(titleMaxLengthReached.value || descriptionMaxLengthReached.value) && !isLoading.value,
    )

    watch(
      () => props.banner,
      (newValue) => {
        homeBanner.value.title = newValue.title
        homeBanner.value.description = newValue.description
        homeBanner.value.isLogoActive = newValue.isLogoActive
      },
    )

    function getImageUrl() {
      return `https://res.cloudinary.com/${
        window.env.CLOUDINARY_BUCKET_NAME
      }/image/upload/h_704/${BANNER_PATH}/partner_${props.partnerId}.jpg?${Math.random().toString(36).substring(7)}`
    }

    const imageUrl = ref(getImageUrl())

    async function openCloudinaryUploadWidget() {
      await cloudinaryService.openCloudinaryUploadWidget(
        `/partners/${props.partnerId}/media/${BANNER_PATH}/upload-parameters`,
        props.partnerId.toString(),
        { cropping: true, croppingAspectRatio: IMAGE_RATIO, showSkipCropButton: false },
      )
      imageUrl.value = getImageUrl()
    }

    function onPublish() {
      context.emit('publish', homeBanner.value)
    }

    return {
      imageUrl,
      homeBanner,
      canPublish,
      descriptionLength,
      descriptionMaxLengthReached,
      DESCRIPTION_MAX_LENGTH,
      titleLength,
      titleMaxLengthReached,
      TITLE_MAX_LENGTH,
      onPublish,
      openCloudinaryUploadWidget,
    }
  },
})
