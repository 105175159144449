import { getLogisticOffer } from '../../dependency-injection'

export function useLogisticOfferUnicity() {
  const getOffer = async (sellerId: string, reference: string) => {
    const offer = await getLogisticOffer.execute(sellerId, reference)
    return offer
  }

  return { getOffer }
}
