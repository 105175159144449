import { Ref } from 'vue'
import { DiscountForm } from '../../domain/discountForm/DiscountForm'
import { useForm } from './useForm'

export const useLoadedForm = (): Ref<DiscountForm> => {
  const { discountForm } = useForm()

  if (!discountForm.value) {
    const error = new Error('DiscountForm not loaded')
    throw error
  }

  return discountForm as Ref<DiscountForm>
}
