import { createAxiosClientWithAladinBearer } from '@/utils/axios'

class StripeDashboardRedirect {
  constructor({ orderApiHost }) {
    this.api = createAxiosClientWithAladinBearer(orderApiHost)
  }

  async dashboardLink(cooperativeId) {
    const { data } = await this.api.post(`/generate-stripe-login-link?cooperative_id=${cooperativeId}`)
    return data
  }
}
export default StripeDashboardRedirect
