/* eslint-disable import/prefer-default-export */
import { InvalidScopesError } from './invalid-scope-error'

export class ScopesService {
  private readonly requiredScopes

  constructor(requiredScopes: string[]) {
    this.requiredScopes = requiredScopes
  }

  validateScopes(currentScopes: string[]): Boolean {
    if (this.requiredScopes.every((scope) => currentScopes.includes(scope))) return true
    throw new InvalidScopesError()
  }
}
