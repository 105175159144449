import { computed, ComputedRef, ref, Ref } from 'vue'

export interface Pagination<T> {
  currentItems: Ref<T[]>
  numberOfPages: ComputedRef<number>
  currentPage: Ref<number>
  noItems: ComputedRef<boolean>
}

const sliceItems = <T>(items: T[], currentPage: number, range: number) =>
  items.slice((currentPage - 1) * range, currentPage * range)

export const getNumberOfPages = <T>(length: number, range: number) => Math.ceil((length || 0) / range)

export const getNoItems = <T>(items: T[]) => items.length === 0

type ClientPagination<T> = Pagination<T> & { changeCurrentPage: (page: number) => void }

export const useClientPagination = <T>(items: Ref<T[]>): ClientPagination<T> => {
  const rowsPerPage = 5
  const currentPage = ref(1)

  const noItems = computed(() => getNoItems(items.value))

  const currentItems = computed(() => sliceItems(items.value, currentPage.value, rowsPerPage))

  const numberOfPages = computed(() => getNumberOfPages(items.value.length, rowsPerPage))

  const changeCurrentPage = (newPage) => {
    // eslint-disable-next-line no-param-reassign
    currentPage.value = newPage
  }

  return {
    currentPage,
    currentItems,
    numberOfPages,
    noItems,
    changeCurrentPage,
  }
}
