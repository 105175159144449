import { Step } from '../../domain'

export function useAladinFormSteps(): Step[] {
  return [
    'CLogisticOfferRouteStep',
    'CLogisticOfferDetailsStep',
    'CLogisticOfferPricingStep',
    'CLogisticOfferSummaryStep',
  ]
}
