// for older browsers like Safari 13
if (!('arrayBuffer' in Blob.prototype)) {
  Object.defineProperty(Blob.prototype, 'arrayBuffer', {
    value(this: Blob) {
      return new Response(this).arrayBuffer()
    },
  })
}

// TextDecoder and TextEncoder are not exposed by JSDOM and have a different error behaviour in Node
// so this code is really hard to unit-test properly in Jest. So I didn't. But it works.

export default {
  async isUtf8(file: File) {
    const fileContent = await file.arrayBuffer()
    try {
      new TextDecoder('utf-8', { fatal: true }).decode(fileContent)
    } catch (_) {
      return false
    }
    return true
  },
}
