
import { defineComponent } from 'vue'
import { IdfModal, IdfButton } from '@invivodf/ui-kit'
import { useLogisticOfferWording } from '../../composables'
import { LogisticOfferLineItem } from '../../../domain'

export default defineComponent({
  name: 'PDeletionModal',
  components: {
    IdfModal,
    IdfButton,
  },
  emits: ['cancel', 'confirm'],
  props: {
    isModalVisible: {
      type: Boolean,
    },
    logisticOffer: {
      type: Object as () => LogisticOfferLineItem,
      default: () => {
        return { nbAssociatedProducts: 0 }
      },
    },
  },
  setup(props, { emit }) {
    const { wording, variabilizeWording } = useLogisticOfferWording()
    const onConfirm = (): void => {
      emit('confirm')
    }
    const onCancel = (): void => {
      emit('cancel')
    }
    return {
      onCancel,
      onConfirm,
      variabilizeWording,
      wording,
    }
  },
})
