export enum EnvFeaturesEnum {
  DASHBOARD_CA_SUPPLIER_FEATURE = 'DASHBOARD_CA_SUPPLIER_FEATURE',
  IMPORT_QUOTA_FEATURE = 'IMPORT_QUOTA_FEATURE',
  DASHBOARD_CA_TC_FEATURE = 'DASHBOARD_CA_TC_FEATURE',
  CLICK_AND_COLLECT_FEATURE = 'CLICK_AND_COLLECT_FEATURE',
  DISCOUNT_PROMO_CODE_FEATURE = 'DISCOUNT_PROMO_CODE_FEATURE',
  DISCOUNT_SEGMENT_FEATURE = 'DISCOUNT_SEGMENT_FEATURE',
  DISCOUNT_FLASH_SALE_FEATURE = 'DISCOUNT_FLASH_SALE_FEATURE',
  DISCOUNT_USAGE_LIMIT_FEATURE = 'DISCOUNT_USAGE_LIMIT_FEATURE',
  DISCOUNT_SALES_FEATURE = 'DISCOUNT_SALES_FEATURE',
  PAYMENT_DUE_DATES_FEATURE = 'PAYMENT_DUE_DATES_FEATURE',
}
