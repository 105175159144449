interface Item {
  label: string
  link?: { name: string; query?: Record<string, string> }
  icon?: string
}

// TODO: Rework the breadcrumb system to not have to do this mess on a page
// Solutions can be to make it listen changes to the router.
export function buildBreadcrumbs(product, productCatalogRoute): Item[] {
  const breadcrumbsItems: Item[] = [
    {
      label: 'Accueil',
      link: { name: 'home' },
      icon: 'home-filled',
    },
  ]
  if (product.family) {
    const { family } = product
    const { categories } = product

    const categoryFirstLevel = (categories && categories[0] && categories[0][0] && categories[0][0].label) || family

    breadcrumbsItems.push({
      label: categoryFirstLevel,
      link: {
        name: productCatalogRoute,
        query: { 'hierarchicalMenu[categoriesLevel0][0]': categoryFirstLevel },
      },
    })

    if (categories && categories[0]) {
      const categorySecondLevel = categories[0][1] && categories[0][1].label
      breadcrumbsItems.push({
        label: categorySecondLevel,
        link: {
          name: productCatalogRoute,
          query: {
            'hierarchicalMenu[categoriesLevel0][0]': categoryFirstLevel,
            'hierarchicalMenu[categoriesLevel0][1]': categorySecondLevel,
          },
        },
      })
    }
  }
  breadcrumbsItems.push({
    label: product.name,
  })
  return breadcrumbsItems
}
