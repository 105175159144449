
import { defineComponent, onMounted } from 'vue'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { useLogisticOfferList, useLogisticOfferWording } from '../../composables'
import { PLoader, PLogisticOfferList } from '../../presenters'
import { LogisticOfferLineItem } from '../../../domain'

export default defineComponent({
  name: 'CLogisticOfferList',
  components: {
    PLoader,
    PLogisticOfferList,
  },
  setup() {
    const { deleteOffer, isLoading, loadLogisticOfferListWithAssociations, logisticOfferList } = useLogisticOfferList()
    const { wording } = useLogisticOfferWording()
    const { showNotificationSuccess, showNotificationError } = useBoToast()
    const deleteOfferFromList = (logisticOffer: LogisticOfferLineItem) => {
      try {
        deleteOffer(logisticOffer.reference)
        showNotificationSuccess(wording.notification.successDelete)
      } catch (e) {
        showNotificationError(e.message)
      }
    }
    onMounted(() => {
      try {
        loadLogisticOfferListWithAssociations()
      } catch (e) {
        showNotificationError(e.message)
      }
    })
    return {
      deleteOfferFromList,
      isLoading,
      logisticOfferList,
    }
  },
})
