export interface Quota {
  reference: string
  meshId: string
  initial: number
  remaining: number
}

const mapMeshIdFromOffer = (offer) => offer.id

export const mapMeshIdFromOffers = (offers): Quota['meshId'][] => {
  return offers.map(mapMeshIdFromOffer)
}
