
import { IdfPagination } from '@invivodf/ui-kit'
import { defineComponent, PropType } from 'vue'
import { useDiscountables } from '@invivodf/common/src/contexts/discount/application/composables/useDiscountables'
import CCollectionLoader from './articles/CCollectionLoader.vue'
import CDiscountableTable from './articles/CDiscountableTable.vue'
import { Discount } from '../../../domain/Discount'

export default defineComponent({
  name: 'CReadonlyDiscountAssociations',
  components: { CDiscountableTable, CCollectionLoader, IdfPagination },
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  setup(props) {
    const { discountables, numberOfPages, isLoading, noItems, currentPage, changeCurrentPage } = useDiscountables(
      props.discount,
    )

    return {
      discountables,
      numberOfPages,
      articlesLoading: isLoading,
      noItems,
      currentPage,
      changeCurrentPage,
    }
  },
})
