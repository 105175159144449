import { computed, ComputedRef } from 'vue'
import { useMetaDiscount } from './useMetaDiscount'
import { validateMetaDiscountParticipantFile } from '../../../dependency-injection'

export interface IUseMetaDiscountSellers {
  sellers: ComputedRef<number[]>
  hasSellers: ComputedRef<boolean>
  setSellers: (sellers: number[]) => void
  placeholderMessage: ComputedRef<string>
}

export const parseSellerIdsFromFile = async (csvFile: File): Promise<number[]> => {
  return validateMetaDiscountParticipantFile.execute(csvFile)
}

export const useMetaDiscountSellers = (): IUseMetaDiscountSellers => {
  const { sellers, setParticipation } = useMetaDiscount()

  const placeholderMessage = computed(() => {
    return sellers.value.length
      ? `Sélectionner autre un fichier pour modifier la liste de magasins associés`
      : 'Sélectionner un fichier csv contenant les identifiants de magasins'
  })

  const hasSellers = computed(() => Boolean(sellers.value.length))

  return {
    sellers,
    hasSellers,
    setSellers: setParticipation,
    placeholderMessage,
  }
}
