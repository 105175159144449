import { DateTime } from 'luxon'
import { createAxiosClientWithAladinBearer } from '@/utils/axios'

export default class ImportService {
  static createFileName(cooperativeName, exportName) {
    const date = DateTime.now().toFormat('yMMddHHmm')
    return `${cooperativeName.replaceAll('*', '').replaceAll(' ', '_')}_export_${exportName}_${date}.csv`
  }

  static createShortFileName(exportName) {
    const date = DateTime.now().toFormat('yMMddHHmm')
    return `${exportName}_export_${date}.csv`
  }

  static async import(url, file) {
    const formData = new FormData()
    formData.append('file', file)
    const api = createAxiosClientWithAladinBearer(url)
    const response = await api.put('', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }
}
