<template>
  <article>
    <IdfIcon kind="exclamation-triangle-filled" class="warning-icon" />
    ATTENTION : Ce produit est concerné par le Règlement (UE) 2019/1148 du 20 juin 2019 sur les engrais à base de
    nitrate entré en vigueur le 1er février 2021 (<IdfButton
      kind="link"
      href="https://eur-lex.europa.eu/legal-content/FR/TXT/PDF/?uri=CELEX:32019R1148"
      target="_blank"
      >voir le règlement</IdfButton
    >) <br /><br />
    En tant que vendeur, vous êtes soumis au respect d’un certain nombre d’exigences réglementaires. Vous devez
    notamment effectuer des vérifications auprès de vos acheteurs avant de procéder à la vente des produits concernés
    (notamment preuve de l’identité du client potentiel, activité commerciale, adresse, numéro TVA, utilisation prévue
    des précurseurs d’explosifs faisant l’objet de restrictions).
    <br /><br />
    Le Règlement (UE) 2019/1148 prévoit notamment la signature d’une déclaration relative à l’usage des produits faisant
    l’objet de restrictions.
    <br /><br />
    En tant que vendeur, vous êtes tenu de procéder au blocage de la vente, s' il doit avoir lieu, en dehors de la
    plateforme {{ $t('message.frontAgri') }} comme convenu avec vos équipes référentes du fait de l’implémentation en
    cours d’évolution de cette réglementation dans vos processus et outils.
  </article>
</template>
<script>
import { IdfIcon, IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'PProductExclusivesPrecursorMessageArticle',
  components: { IdfIcon, IdfButton },
}
</script>
<style scoped lang="scss">
.warning-icon.idf-icon::v-deep path {
  fill: var(--idf-color-warning-400);
}
</style>
