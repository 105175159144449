import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'

export class NotRequiredPromoCode extends RequiredProperty<string | null> {
  constructor(validatedValue: string | null) {
    super(validatedValue, { errorMessage: '' })
  }

  validate() {
    this.errors = []
    if (NotRequiredPromoCode.hasValue(this.validatedValue)) {
      if (this.validatedValue.length > 20) {
        this.errors.push('un code promo doit faire moins de 20 caractères')
      }
      if (!/^[A-Z\d]+$/g.test(this.validatedValue)) {
        this.errors.push('un code promo ne peut contenir que des chiffres ou des majuscules')
      }
    }
  }

  private static hasValue(value): value is string {
    return value !== null && value?.length !== 0
  }
}
