<template>
  <div v-if="variant.productId" class="v-variant">
    <div class="container-white">
      <div class="container">
        <PHeader :breadcrumb="breadcrumbsItems" :title="variant.name" />
        <div class="variant-header container">
          <span class="variant-header__supplier">{{ variant.productSupplier }}</span>
          <div class="variant-header__data">
            <div class="row">
              <div class="col-3" data-test-variant-ean-code v-if="variant.eanCode">
                <span class="variant-header__label">EAN :</span>
                <span class="variant-header__value"> {{ variant.eanCode }}</span>
              </div>
              <div class="col-3" data-test-variant-invivo-id>
                <span class="variant-header__label">ID Invivo :</span>
                <span class="variant-header__value"> {{ variant.id }}</span>
              </div>
              <div class="col-3" data-test-variant-exclusive v-if="variant.cooperativeVariantCode">
                <span class="variant-header__label">Code article :</span>
                <span class="variant-header__value"> {{ variant.cooperativeVariantCode }}</span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-3" data-test-variant-measure-unit>
                <span class="variant-header__label">Unité de mesure :</span>
                <span class="variant-header__value"> {{ variant.measureUnit | formatMeasureUnit }}</span>
              </div>
              <div class="col-3" data-test-variant-selling-unit>
                <span class="variant-header__label">Unité de vente :</span>
                <span class="variant-header__value"> €/{{ sellingUnit }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!variant.associated || !isAvailable" class="variant-header__info"></div>
        <div v-else class="variant-header__info container">
          <IdfTextIcon class="variant__associated">Dans le catalogue {{ $t('message.partner') }}</IdfTextIcon>
          <c-toggle
            @input="updateVariantPublishedStatus()"
            class="variant__published-switch"
            v-model="published"
            data-test-published-toggle
          >
            <span class="adn-icon adn-icon--category-published" aria-hidden="true"></span>
            <span v-if="variant.published"> Publié</span>
            <span v-else> Dépublié</span>
          </c-toggle>
          <span class="variant__switch-stock-wrapper">
            <div class="form-check-inline"><p>Stock:</p></div>
            <div class="form-check custom-control custom-radio form-check-inline">
              <input
                v-model="stockManagementType"
                @click.stop.prevent="updateVariantStockManagementType('managed')"
                class="custom-control-input"
                name="stockManagementType"
                type="radio"
                id="managed-stock"
                value="managed"
              />
              <label class="custom-control-label form-control-label align-middle" for="managed-stock">Géré</label>
            </div>
            <div class="form-check form-check-inline custom-control custom-radio">
              <input
                v-model="stockManagementType"
                @click.stop.prevent="showResetStockConfirmModal"
                class="custom-control-input"
                name="stockManagementType"
                type="radio"
                id="not-managed-stock"
                value="notManaged"
              />
              <label class="custom-control-label form-control-label align-middle" for="not-managed-stock"
                >Non géré</label
              >
            </div>
            <c-confirm-modal
              ref="resetStockModal"
              title="Reinitialisation du stock"
              message="En passant en stock non géré, vous perdez l’information de quota"
              confirmText="Passer en stock non géré"
              cancelText="Annuler"
              @cancel="hideResetStockConfirmModal()"
              @confirm="hideResetStockConfirmModal() & updateVariantStockManagementType('notManaged')"
            />
          </span>
        </div>
      </div>
    </div>

    <div class="bg-grey">
      <div class="container">
        <PInfoMessage v-if="!isAvailable" data-variant-not-available-error
          >Cet article a été retiré du catalogue national et il n'est plus possible d'y associer de nouvelles
          offres.</PInfoMessage
        >
        <PInfoMessage v-if="!variant.associated && isAvailable" data-variant-error
          >Cet article n'est pas dans votre catalogue {{ $t('message.partner') }}.</PInfoMessage
        >
        <div v-else>
          <div v-if="isAvailable || hasActiveOffer">
            <PWarningMessage v-if="!published" data-test-info-message>
              Votre article est dépublié, vos offres ne seront donc pas visibles par les
              {{ $t('message.memberships') }}.
            </PWarningMessage>
            <c-tabs class="variant__content" :tabs="tabs" :activeTab="activeTab" @tabChanged="activeTab = $event">
              <slot v-if="activeTab == 'Offres'">
                <div class="variant__offer-tab-content row">
                  <!-- <c-sidebar title="" class="col-3 variant__offer-filters">
                    <slot>
                      <p class="variant__offer-filters-content">Les filtres seront présents dans une version ultérieure</p>
                    </slot>
                  </c-sidebar> -->
                  <div class="col-12 variant__offer-manage">
                    <div class="row variant__offer-create-row">
                      <div class="col">
                        <div>
                          <c-create-variant-offer
                            :variantId="variant.id"
                            :measureQuantity="variant.measureQuantity"
                            :measureUnit="variant.measureUnit"
                            :isSoldAsUnit="variant.isSoldAsUnit"
                            :productKind="productKind"
                            :disabled="!isAvailable"
                          ></c-create-variant-offer>
                        </div>
                      </div>
                    </div>
                    <c-variant-offer-list
                      :variantId="variant.id"
                      :measureQuantity="variant.measureQuantity"
                      :measureUnit="variant.measureUnit"
                      :isSoldAsUnit="variant.isSoldAsUnit"
                      :productKind="productKind"
                    />
                    <IdfPagination
                      class="offers__pagination"
                      v-if="totalPages > 1"
                      data-test="paginate-block"
                      :currentPage="currentPage - 1"
                      :totalPages="totalPages"
                      @click="handleChangePage"
                    />
                  </div>
                </div>
              </slot>
              <slot v-if="activeTab === 'Taxes'">
                <c-variant-taxes :taxes="variant.taxes"></c-variant-taxes>
              </slot>
              <slot v-if="activeTab === 'Stock'">
                <c-variant-stock
                  :variantId="variant.id"
                  :cooperativeId="cooperativeId"
                  :cooperativeVariantId="variant.cooperativeVariantId"
                  :measureQuantity="variant.measureQuantity"
                  :measureUnit="variant.measureUnit"
                  :quantity="variant.stockQuantity"
                  :isSoldAsUnit="variant.isSoldAsUnit"
                  v-if="variant.associated && stockManagementType !== 'notManaged'"
                ></c-variant-stock>
              </slot>
              <slot v-if="activeTab === 'Frais de zone'">
                <c-variant-zone-charges :zoneCharges="zoneChargesWithName" :measureUnit="variant.measureUnit" />
              </slot>
            </c-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="v-product container">
    <p>Chargement…</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PHeader from '@invivodf/common/src/application/presenters/PHeader.vue'
import { IdfPagination, IdfTextIcon } from '@invivodf/ui-kit'
import CConfirmModal from '@/components/CConfirmModal/CConfirmModal.vue'
import { cooperativeVariantService, zoneService } from '../../services'
import { STOCK_MANAGED, STOCK_NOT_MANAGED } from '../../services/stock.const'
import measureUnit from '../../filters/unit/measure.filter'
import CVariantOfferList from '../../components/variant/CVariantOfferList/CVariantOfferList.vue'
import CVariantStock from '../../components/variant/CVariantStock/CVariantStock.vue'
import CVariantTaxes from '../../components/variant/CVariantTaxes/CVariantTaxes.vue'
import CVariantZoneCharges from '../../components/variant/CVariantZoneCharges/CVariantZoneCharges.vue'
import CToggle from '../../components/CToggle/CToggle.vue'
import CTabs from '../../components/CTabs/CTabs.vue'
import CCreateVariantOffer from '../../components/variant/CCreateVariantOffer/CCreateVariantOffer.vue'
import { mapMeshIdFromOffers } from '../../domain/Quota'
import PInfoMessage from '../../presenters/PInfoMessage.vue'
import PWarningMessage from '../../presenters/PWarningMessage.vue'

export default {
  name: 'VVariant',
  components: {
    IdfTextIcon,
    PInfoMessage,
    PWarningMessage,
    PHeader,
    CVariantOfferList,
    CVariantStock,
    CVariantTaxes,
    CVariantZoneCharges,
    CConfirmModal,
    CToggle,
    CCreateVariantOffer,
    CTabs,
    IdfPagination,
  },
  data() {
    return {
      isCreatingOffer: false,
      activeTab: 'Offres',
      zones: [],
      published: false,
      stockManagementType: STOCK_NOT_MANAGED,
      currentPage: 1,
      filters: { variantIdList: this.$route.params.variantInvivoId },
      variantFetcher: 'catalog/fetchVariant',
      offersFetcher: 'variantOffers/fetchOffers',
    }
  },
  computed: {
    ...mapGetters({
      cooperativeId: 'cooperativeId',
      offers: 'variantOffers/offers',
      totalPages: 'variantOffers/totalPages',
    }),
    isAvailable() {
      return this.variant.sellable
    },
    sellingUnit() {
      return this.variant.isSoldAsUnit || this.variant.measureUnit === 'unit' ? 'unité' : this.variant.measureUnit
    },
    tabs() {
      return this.stockManagementType === STOCK_MANAGED
        ? ['Offres', 'Taxes', 'Stock', 'Frais de zone']
        : ['Offres', 'Taxes', 'Frais de zone']
    },
    hasActiveOffer() {
      return this.offers && this.offers.filter((offer) => offer.validityStatus === 'active').length >= 1
    },
    zoneChargesWithName() {
      return (
        this.variant.zoneCharges &&
        this.variant.zoneCharges
          .map(({ zoneId, cost }) => {
            const zone = this.zones.find(({ id }) => id === zoneId)
            return (
              zone && {
                zoneName: zone.name,
                cost,
              }
            )
          })
          .filter((zoneCharge) => !!zoneCharge)
      )
    },
    variant() {
      return this.$store.getters['catalog/getVariantById'](
        this.$route.params.productId,
        this.$route.params.variantInvivoId,
      )
    },
    productKind() {
      return this.variant && this.variant.kind
    },
    productCatalogRoute() {
      if (this.variant.productExclusive) return 'search-cooperative-referential'
      if (this.variant.productHasAssociatedVariants) return 'search-cooperative'
      return 'search-national'
    },
    breadcrumbsItems() {
      const breadcrumbsItems = [
        {
          label: 'Accueil',
          link: { name: 'home' },
          icon: 'home-filled',
        },
      ]
      if (this.variant.family) {
        breadcrumbsItems.push({
          label: this.variant.family,
          link: {
            name: this.productCatalogRoute,
            query: { 'hierarchicalMenu[categoriesLevel0][0]': this.variant.family },
          },
        })

        if (this.variant.category) {
          const categoryLabel = this.variant.category[1] && this.variant.category[1].label
          breadcrumbsItems.push({
            label: categoryLabel,
            link: {
              name: this.productCatalogRoute,
              query: {
                'hierarchicalMenu[categoriesLevel0][0]': this.variant.family,
                'hierarchicalMenu[categoriesLevel0][1]': categoryLabel,
              },
            },
          })
        }
      }
      if (this.variant.productName && this.variant.productId) {
        breadcrumbsItems.push({
          label: this.variant.productName,
          link: {
            name: 'product',
            params: { productId: this.variant.productId },
          },
        })
      }
      breadcrumbsItems.push({
        label: this.variant.name,
      })
      return breadcrumbsItems
    },
  },
  filters: {
    formatMeasureUnit: measureUnit,
  },
  methods: {
    update() {
      return cooperativeVariantService.update({
        id: this.variant.cooperativeVariantId,
        published: this.published,
        stockManagementType: this.stockManagementType,
      })
    },
    async updateVariantPublishedStatus() {
      try {
        await this.update()
      } catch (error) {
        setTimeout(() => {
          this.published = !this.published
        }, 0)
      }
    },
    hideResetStockConfirmModal() {
      this.$refs.resetStockModal.hide()
    },
    showResetStockConfirmModal() {
      this.$refs.resetStockModal.show()
    },
    async updateVariantStockManagementType(stockManagementType) {
      try {
        await cooperativeVariantService.update({
          id: this.variant.cooperativeVariantId,
          published: this.published,
          stockManagementType,
        })

        this.stockManagementType = stockManagementType
        if (this.stockManagementType === STOCK_MANAGED) {
          this.activeTab = 'Stock'
        } else if (this.stockManagementType === STOCK_NOT_MANAGED) {
          this.$store.dispatch('setStockQuantity', 0)
          if (this.activeTab === 'Stock') this.activeTab = 'Offres'
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    back() {
      window.history.back()
    },
    async fetchAllZones() {
      this.zones = await zoneService.findAll({ cooperativeId: this.cooperativeId })
    },
    async handleChangePage(value) {
      this.currentPage = value
      await this.$store.dispatch(this.offersFetcher, { page: this.currentPage, filters: this.filters })
      await this.fetchQuotas()
    },
    async fetchQuotas() {
      if (!this.offers) {
        return
      }

      const meshIds = mapMeshIdFromOffers(this.offers)
      await this.$store.dispatch('quota/fetch', meshIds)
    },
  },
  async mounted() {
    const { productId, variantInvivoId } = this.$route.params
    await this.$store.dispatch(this.variantFetcher, { productId, variantId: variantInvivoId })

    // non-blocking loading: do not await
    this.fetchQuotas()

    this.published = this.variant.published
    this.stockManagementType = this.variant.stockManagementType

    this.$store.dispatch('setStockQuantity', this.variant.stockQuantity || 0)

    await this.fetchAllZones()
  },
}
</script>

<style scoped lang="scss">
@import '../../lib/_variables';
.variant-header {
  margin: 2rem 0 2.5rem;
}
.variant-header__supplier {
  color: var(--idf-color-neutral-500);
}
.variant-header__data {
  margin-top: 12px;
}
.variant-header__label {
  color: var(--idf-color-neutral-500);
}
.variant-header__value {
  color: black;
}
.variant-header__info {
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  height: 25px;
  line-height: 25px;
}
.variant__associated {
  width: 300px;
}
.variant__associated .adn-icon {
  margin-right: 3px;
}
.variant__content {
  margin-top: var(--idf-spacing-4);
}
.variant__published-switch {
  width: 200px;
}
.variant__switch-stock-wrapper {
  padding-left: 3.6rem;
  display: inline-flex;
}
.form-check-label {
  padding-left: 0.64rem;
}
.variant__offer-tab-content {
  background-color: var(--idf-color-neutral-100);
  min-height: 400px;
}
.col-3.variant__offer-filters {
  padding: 0;
}
.variant__offer-filters-content {
  color: var(--idf-color-neutral-500);
  font-size: 12px;
}
.col-12.variant__offer-manage {
  padding: 30px;
}
.offer__title {
  font-weight: bold;
  font-size: 18px;
  padding: 15px 0;
}
.variant__offer-create-row {
  margin-bottom: 20px;
}
.container-white {
  background-color: var(--idf-color-neutral-100);
  width: 100%;
  padding: 2rem 0;
}
</style>
