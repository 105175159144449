<template>
  <div class="attribute-filter">
    <input type="checkbox" id="attribute-filter-term" data-test="attribute-filter-term" :checked="value.isRefined" />
    <label for="attribute-filter-term" class="attribute-filter__label" data-test="attribute-filter__label">{{
      label
    }}</label>
    <span class="attribute-filter__count" v-if="!value.isRefined" data-test="attribute-filter__count"></span>
  </div>
</template>

<script>
export default {
  name: 'CAttributeFilter',
  props: ['value', 'refine', 'label'],
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.attribute-filter {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
  & > input[type='checkbox'] {
    opacity: 0;
  }
  & > input[type='checkbox'] + label::after {
    content: none;
  }
  & > input[type='checkbox']:checked + label::after {
    content: '';
  }
  .attribute-filter__label::before,
  .attribute-filter__label::after {
    position: absolute;
    content: '';
    display: inline-block;
  }
  .attribute-filter__label::before {
    height: 18px;
    width: 18px;
    border: 1px solid var(--idf-color-neutral-400);
    border-radius: 2px;
    left: 10px;
    top: 9px;
  }
  .attribute-filter__label:hover::before,
  .attribute-filter__label:checked::before {
    border: 1px solid var(--idf-color-main-400);
  }
  .attribute-filter__label::after {
    height: 5px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 14px;
    top: 14px;
    color: var(--idf-color-main-400);
  }
  & > input[type='checkbox']:checked + label::before {
    border: 1px solid var(--idf-color-main-400);
  }
  .attribute-filter__label:nth-child(2) {
    margin-left: 18px;
  }
  .attribute-filter__label {
    display: flex;
    flex-direction: row;
    line-height: 34px;
    height: 24px;
  }
  .attribute-filter__count {
    margin-left: auto;
  }
}
</style>
