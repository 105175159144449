import { ArticleResponseApiV2, PartnerArticleApiClient } from '@aladin/shared-universcoop-sdk'
import { Article } from '../domain/article'
import { articletoArticleApiV2 } from './article.mapper'
import { ArticleRepository } from '../domain/article.repository'

interface Dependencies {
  articleClient: PartnerArticleApiClient
}

export function createArticleRepository(deps: Dependencies): ArticleRepository {
  async function createArticle(params: { partnerId: number; article: Article }): Promise<ArticleResponseApiV2> {
    return deps.articleClient.createArticle(params.partnerId, articletoArticleApiV2(params.article))
  }

  async function getArticles(params: { partnerId: number }): Promise<ArticleResponseApiV2[]> {
    return deps.articleClient.findAllArticlesByPartnerId(params.partnerId)
  }

  async function getAnchoredArticle(params: { partnerId: number }): Promise<ArticleResponseApiV2 | null> {
    return deps.articleClient.findAnchoredArticleByPartnerId(params.partnerId)
  }

  function getOneArticle(params: { partnerId: number; slug: string }): Promise<ArticleResponseApiV2> {
    return deps.articleClient.findOneArticleByPartnerIdAndSlug(params.partnerId, params.slug)
  }

  async function updateArticle(params: { partnerId: number; slug: string; article: Article }): Promise<void> {
    return deps.articleClient.updateArticleByPartnerIdAndSlug(
      params.partnerId,
      params.slug,
      articletoArticleApiV2(params.article),
    )
  }

  async function deleteArticle(params: { partnerId: number; slug: string }): Promise<void> {
    return deps.articleClient.deleteArticle(params.partnerId, params.slug)
  }

  return {
    createArticle,
    getArticles,
    getAnchoredArticle,
    getOneArticle,
    updateArticle,
    deleteArticle,
  }
}
