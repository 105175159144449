
import { defineComponent, Ref, ref, toRefs, watch, PropType } from 'vue'

import { IdfIcon } from '@invivodf/ui-kit'
import CDiscountableTable from './CDiscountableTable.vue'
import { Discountable } from '../../../../domain/Discountable'

export default defineComponent({
  name: 'CResultFolder',
  components: { CDiscountableTable, IdfIcon },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    folded: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    offers: {
      type: Array as PropType<Discountable[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { folded, id } = toRefs(props)

    const productElement = ref(null) as unknown as Ref<HTMLElement>
    const discountableListElement = ref(null) as unknown as Ref<HTMLElement>
    watch(folded, () => {
      if (folded.value) {
        productElement.value!.removeAttribute('style')
      } else {
        productElement.value!.style.height = `${
          productElement.value!.clientHeight + discountableListElement.value!.clientHeight + 16
        }px`
      }
    })

    const articleToggled = (discountableId) => {
      emit('articleToggled', discountableId)
    }

    return {
      setActive: () => {
        emit('toggle', id)
      },
      articleToggled,
      productElement,
      discountableListElement,
    }
  },
})
