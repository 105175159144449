import { HomeBanner } from '../domain/home-banner'
import { HomeBannerRepository } from '../infrastructure/home-banner.repository'

interface FindHomeBannerByPartnerIdUsecase {
  (params: { partnerId: number }): Promise<HomeBanner | null>
}

interface Dependencies {
  homeBannerRepository: HomeBannerRepository
}

export function createFindHomeBannerByPartnerIdUsecase(deps: Dependencies): FindHomeBannerByPartnerIdUsecase {
  return async (params: { partnerId: number }): Promise<HomeBanner | null> => {
    return deps.homeBannerRepository.findHomeBannerByPartnerId(params.partnerId)
  }
}
