import { DiscountScopes, DiscountTypes } from '@invivodf/discount-sdk/admin'

type PolicySeparator = '_'

export class DiscountPolicy {
  public readonly id: `${DiscountTypes}${PolicySeparator}${DiscountScopes}`

  constructor(
    public readonly type: DiscountTypes,
    public readonly scope: DiscountScopes,
  ) {
    if (!Object.values(DiscountTypes).includes(type)) throw new Error(`Invalid discount type: ${type}`)
    if (!Object.values(DiscountScopes).includes(scope)) throw new Error(`Invalid discount scope: ${scope}`)
    this.id = `${type}${DiscountPolicy.separator}${scope}`
  }

  static fromId(id: DiscountPolicy['id']): DiscountPolicy {
    const [type, scope] = id.split(this.separator) as [DiscountTypes, DiscountScopes]
    return new DiscountPolicy(type, scope)
  }

  static separator: PolicySeparator = '_'
}
