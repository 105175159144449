
import { defineComponent, ref } from 'vue'
import { formatMeasureUnit } from '@invivodf/common/src/core/l10n'
import CTooltip from '@/components/CTooltip/CTooltip.vue'

export default defineComponent({
  name: 'PVariantStock',
  components: {
    CTooltip,
  },
  props: {
    availableQuantity: {
      type: Number,
      required: true,
    },
    bookedQuantity: {
      type: Number,
      required: true,
    },
    importedQuantity: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const tooltipRef = ref()
    const toggleTooltip = () => tooltipRef.value.toggleShow()

    return {
      tooltipRef,
      toggleTooltip,
      formatMeasureUnit,
    }
  },
})
