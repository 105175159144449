import { DateTime } from 'luxon'

interface Period {
  startDate: Date
  endDate: Date | null
}

export const StatusDiscountableEnum = {
  ACTIVE: 'active',
  UPCOMING: 'upcoming',
  PAST: 'past',
}

export enum DiscountPeriodStatusEnum {
  ACTIVE = 'discount-active',
  UPCOMING = 'discount-upcoming',
  EXPIRED = 'discount-expired',
}

const statusForExpirableDateTimePeriod = (period: Period) => {
  const { startDate, endDate } = period
  if (endDate && endDate < new Date()) return DiscountPeriodStatusEnum.EXPIRED
  return startDate > new Date() ? DiscountPeriodStatusEnum.UPCOMING : DiscountPeriodStatusEnum.ACTIVE
}

const statusForDatePeriod = (period: Period) => {
  const { startDate, endDate } = period
  if (endDate && DateTime.fromJSDate(endDate).toISODate() < DateTime.now().toISODate())
    return StatusDiscountableEnum.PAST
  return DateTime.fromJSDate(startDate).toISODate() > DateTime.now().toISODate()
    ? StatusDiscountableEnum.UPCOMING
    : StatusDiscountableEnum.ACTIVE
}

export const getDiscountPeriodStatus = <T extends { validityStartDate: Date; validityEndDate: Date | null }>(
  period: T,
) => {
  return statusForExpirableDateTimePeriod({
    startDate: period.validityStartDate,
    endDate: period.validityEndDate ? period.validityEndDate : null,
  })
}

export const getDiscountablePeriodStatus = <T extends { validityStartDate: Date; validityEndDate: Date | null }>(
  period: T,
) => {
  return statusForDatePeriod({
    startDate: period.validityStartDate,
    endDate: period.validityEndDate ?? null,
  })
}
