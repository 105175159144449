<template>
  <div>
    <c-import-base
      :importFileService="segmentService"
      :csvProperties="csvProperties"
      :pageTitle="`Import segmentation ${$t('message.memberships')}`"
      :uploadButtonLabel="`Pour mettre à jour votre segmentation ${$t('message.memberships')}, importez le fichier CSV`"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        :title="`Informations sur votre segmentation ${$t('message.memberships')}`"
        :nbResultText="`Nombre d'association segment/${$t('message.membership')}:`"
        :exportText="`Exporter votre segmentation ${$t('message.memberships-app-d')}`"
        :importInformation="importInformation"
        :downloadCSV="downloadCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { use18n } from '@invivodf/locales/src/use18n'
import { segmentService } from '../../services'

const csvFileProperties = {
  name: 'segments.csv',
  full_path: '/assets/csv/segments.csv',
}
export default {
  name: 'CImportSegments',
  components: {
    CImportBase,
    CImportInformation,
  },
  data() {
    const i18n = use18n()
    return {
      csvProperties: csvFileProperties,
      segmentService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
      csvColumnDetails: [
        {
          header: 'segment_code',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>premium</code>',
          description:
            "<p>Le <code>segment_code</code> est un identifiant unique correspondant à un segment dans votre système d'information.</p><p>La référence peut comprendre tous les caratères unicode à l'exception du point-virgule et de la virgule.</p>",
          mandatory: 'Oui',
        },
        {
          header: 'member_code',
          format: 'VARCHAR',
          exampleValue: 'exemple : <code>1234AZE</code>',
          description: `<p>${i18n.t('ctx.user.member-code')}</p>`,
          mandatory: 'Oui',
        },
      ],
      descriptionLines: [
        'L’extension attendue est : <b>.csv</>',
        'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
        'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
        "L'encoding attendu est : <b>UTF8</b>.",
      ],
    }
  },
  computed: {
    ...mapGetters(['cooperativeId', 'cooperative']),
  },
  methods: {
    downloadCSV() {
      return segmentService.download(this.cooperativeId, this.cooperative.name)
    },
  },
}
</script>
