
import { computed, PropType } from 'vue'

import CDiscountableRow from './CDiscountableRow.vue'
import { Discountable } from '../../../../domain/Discountable'

const headers = ['REF ARTICLE / TYPE', 'PRIX', 'VALIDITÉ', 'CONFORMITÉ', 'PROMO EXIS.']
const uneditableHeaders = headers.filter((header) => header !== 'PROMO EXIS.')

export default {
  name: 'CDiscountableTable',
  components: { CDiscountableRow },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    discountables: {
      type: Array as PropType<Discountable[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const articleToggled = (id) => {
      emit('articleToggled', id)
    }

    return {
      headers: computed(() => (props.editable ? headers : uneditableHeaders)),
      articleToggled,
    }
  },
}
