const isoStringSeparator = 'T'

export const formatDate = (date: Date): string => {
  return date.toISOString().split(isoStringSeparator)[0]
}

export const formatDateToLocale = (date: Date): string => {
  return date.toLocaleDateString('fr-FR')
}

export const formatPeriodToLocale = (start?: Date, end?: Date): string => {
  if (!start) return ''
  if (!end) return formatDateToLocale(start)
  return `${formatDateToLocale(start)} au ${formatDateToLocale(end)}`
}
