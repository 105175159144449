import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

class StockService {
  constructor({ orderApiHost }) {
    this.orderApiHost = orderApiHost
    this.api = createAxiosClientWithAladinBearer(`${orderApiHost}`)
  }

  async import(cooperativeId, file) {
    return ImportService.import(
      `${this.orderApiHost}/backoffice/v1/stocks/import?cooperative_id=${cooperativeId}`,
      file,
    )
  }

  async downloadStocks(cooperativeId) {
    const response = await this.api.get('/backoffice/v1/stocks', {
      params: {
        cooperative_id: cooperativeId,
      },
      headers: {
        accept: 'text/csv',
      },
    })
    return response.data
  }

  async getStockByVariant(cooperativeId, variantId) {
    try {
      const response = await this.api.get(`/backoffice/v1/stocks/${variantId}?cooperative_id=${cooperativeId}`)
      return response.data
    } catch (error) {
      return null
    }
  }
}

export default StockService
