<template>
  <div class="product-review">
    <div class="product-review__main-block" :class="{ 'product-review__main-block--editing': isEditingReview }">
      <div class="product-review__title">{{ this.title }}</div>
      <div v-if="!isEditingReview && !this.hasReview" class="product-review__main-block--empty" data-test-empty-review>
        <div class="product-review__text-block" data-test-review-txt>
          Vous n'avez pas encore écrit d'avis pour cet article
        </div>
        <IdfButton @click="editReview" data-test-review-btn="create">Écrire un avis</IdfButton>
      </div>

      <template v-else-if="!isEditingReview && this.hasReview">
        <adn-text-area :initialValue="editedReview" data-test-text-area :edition="false" />
        <div class="product-review__buttons-block">
          <IdfButton kind="link" @click="deleteReview" data-test-review-btn="delete">Supprimer</IdfButton>
          <IdfButton kind="secondary" @click="editReview" icon-left="edit-outlined" data-test-review-btn="edit"
            >Modifier</IdfButton
          >
        </div>
      </template>
      <div v-else class="product-review__main-block--editing" data-test-edit-block>
        <adn-text-area
          v-model="inEditReview"
          :initialValue="isEditingReview ? inEditReview : editedReview"
          :placeholder="this.placeholder"
          :maxContentLength="maxContentLength"
          :wysiwyg="true"
          data-test-text-area-edit
        >
          <template v-slot:content-length="{ visibleContentLength }">
            {{ charactersLeft(visibleContentLength) }}
          </template>
        </adn-text-area>

        <div class="product-review__buttons-block">
          <IdfButton @click="cancelReview" data-test-review-btn="cancel" kind="link" icon-left="close"
            >Annuler</IdfButton
          >
          <IdfButton @click="saveReview" data-test-review-btn="save">Enregistrer</IdfButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfButton } from '@invivodf/ui-kit'

export default {
  name: 'CProductReview',
  components: {
    IdfButton,
  },
  data() {
    return {
      editedReview: this.review || '',
      inEditReview: this.review || '',
      isEditingReview: false,
      maxContentLength: 700,
    }
  },
  computed: {
    hasReview() {
      return this.editedReview !== ''
    },
  },
  props: {
    review: {
      type: String,
      default: '',
    },
    title: String,
    placeholder: String,
  },
  methods: {
    editReview() {
      this.isEditingReview = true
    },
    cancelReview() {
      this.isEditingReview = false
      this.inEditReview = this.editedReview
    },
    saveReview() {
      this.$emit('save-review', this.inEditReview)
      this.editedReview = this.inEditReview
      this.isEditingReview = false
    },
    deleteReview() {
      this.$emit('delete-review')
      this.editedReview = ''
      this.inEditReview = ''
    },
    charactersLeft(contentLength) {
      const charactersLeftVar = this.maxContentLength - contentLength
      if (charactersLeftVar <= 1) {
        return `${charactersLeftVar} caractère restant`
      }
      return `${charactersLeftVar} caractères restants`
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.product-review__title {
  font-size: 18px;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-review__main-block {
  background-color: white;
  border: 1px solid var(--idf-color-neutral-200);
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 20px 25px;
  width: 100%;
  max-width: 1140px;
  &--empty {
    display: flex;
    align-items: center;
  }
  &--editing {
    border: 1px solid black;
    background: transparent;
    border: none;
  }
}
.product-review__text-block {
  padding: 0;
  font-size: 16px;
  color: var(--idf-color-neutral-500);
  white-space: pre-line;
  flex-grow: 1;
  word-break: break-word;
}
.product-review__text-block--has-review {
  color: black;
}
.product-review__main-block textarea {
  width: 100%;
  height: 100px;
  font-size: 16px;
  outline: 0;
  box-shadow: none;
  border: none;
  padding: 0.8rem 0;
  resize: none;
  word-break: break-all;
}
.product-review__buttons-block {
  display: flex;
  justify-content: flex-end;
  gap: var(--idf-spacing-2);
}
.product-review__characters-left {
  flex-grow: 1;
  color: var(--idf-color-neutral-400);
}
</style>
