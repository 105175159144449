import { ImportReport } from '@invivodf/common/src/contexts/parameters'
import { IImportReportRepository } from '../domain/IImportReportRepository'
import { TranslateAndDateReportMapper } from './mappers/TranslateAndDateReportMapper'
import { ImportReportDTO } from './datasource/ImportReportDTO'

interface DiscountImportReportDataSource {
  findOneDiscountImportReport(sellerId: number, id: number): Promise<ImportReportDTO>
  findAllDiscountImportReports(sellerId: number): Promise<ImportReportDTO[]>
}

export default class DiscountImportReportRepository implements IImportReportRepository {
  private readonly mapper = new TranslateAndDateReportMapper()

  constructor(private readonly dataSource: DiscountImportReportDataSource) {}

  async listImportReports(sellerId: number): Promise<ImportReport[]> {
    const reports = await this.dataSource.findAllDiscountImportReports(sellerId)
    return reports.map((report) => this.mapper.map(report))
  }

  async retrieveImportReport(sellerId: number, id: number): Promise<ImportReport> {
    const report = await this.dataSource.findOneDiscountImportReport(sellerId, id)
    return this.mapper.map(report)
  }
}
