import { DiscountPolicy } from '../domain/DiscountPolicy'
import { IDiscountRepository } from '../domain/IDiscountRepository'

export class RetrieveAuthorizedDiscountPolicies {
  constructor(public readonly discountRepository: IDiscountRepository) {}

  async execute(partnerId: number): Promise<DiscountPolicy[]> {
    return this.discountRepository.retrieveAuthorizedDiscountPolicy(partnerId)
  }
}
