import { IFeatureFlipRepository } from '../domain/IFeatureFlipRepository'
import { Feature } from '../domain/Feature'
import { FeatureType } from '../domain/FeatureType'
import { FeatureFlipIzanamiDataSource } from './FeatureFlipIzanamiDataSource'
import { FeatureFlipEnvDataSource } from './FeatureFlipEnvDataSource'
import { FeatureFlipCacheDataSource } from './FeatureFlipCacheDataSource'

export class FeatureFlipRepository implements IFeatureFlipRepository {
  constructor(
    protected readonly izanamiDataSource: FeatureFlipIzanamiDataSource,
    protected readonly configDataSource: FeatureFlipEnvDataSource,
    protected readonly cacheDataSource: FeatureFlipCacheDataSource,
  ) {}

  public async checkFeature(feature: Feature, { forceUpdate }: { forceUpdate?: boolean } = {}): Promise<boolean> {
    const featureNamePrefix = window.env.VUE_APP_CONTEXT || 'aladin'
    const featureName = `${featureNamePrefix}:${feature.id}`
    if (!forceUpdate) {
      const cachedResult = this.cacheDataSource.check(feature.id)
      if (cachedResult !== undefined) return cachedResult
    }

    const isActive =
      feature.type === FeatureType.Dynamic
        ? await this.izanamiDataSource.check(featureName, feature.context)
        : this.configDataSource.check(feature.id)

    this.cacheDataSource.store(feature.id, isActive)

    return isActive
  }
}
