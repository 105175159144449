import { ref, Ref } from 'vue'

export interface QueryPage {
  filled: () => boolean
  word: Ref<string>
  page: Ref<number>
  count: number
}

export const useQueryPage = (): QueryPage => {
  const query = ref('')
  const page = ref(1)
  const count: number = 5

  const filled = () => {
    return query.value.length > 0
  }

  return {
    filled,
    word: query,
    page,
    count,
  }
}
