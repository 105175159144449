import { createAxiosClientWithAladinBearer } from '@/utils/axios'

export default class ShippingRatesService {
  constructor({ orderApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${orderApiHost}/v1/shipping-rates`)
  }

  async list({ cooperativeId } = {}) {
    return this.api
      .get('', {
        params: {
          cooperative_id: cooperativeId,
        },
      })
      .then((response) => response.data)
  }

  async create(shippingRate) {
    const result = await this.api.post('', shippingRate)
    return result.data
  }

  async update(shippingRate) {
    const result = await this.api.put(`/${shippingRate.id}`, shippingRate)
    return result.data
  }
}
