
import { IdfButton } from '@invivodf/ui-kit'
import PTreeView from '@invivodf/front-seller-b2ag/src/presenters/PTreeView/PTreeView.vue'
import { TreeCategory } from '@invivodf/front-seller-b2ag/src/presenters/PTreeView/PTreeViewComposables'
import { categoryService as aladinCategoryService } from '@invivodf/front-seller-b2ag/src/services'
import { defineComponent, Ref, ref, onMounted } from 'vue'
import { buildCategories } from '@invivodf/common/src/contexts/parameters/application/components/CImportCustomProductCategories/CImportCustomProductCategoriesHelper'
import { use18n } from '@invivodf/locales/src/use18n'

export default defineComponent({
  name: 'CImportCustomProductCategories',

  components: { PTreeView, IdfButton },

  setup() {
    const i18n = use18n()
    const categoriesNiveau0: Ref<TreeCategory[]> = ref([])
    const branchesToExclude: string[] = [
      'sante_vegetal',
      'amenagement_exploitation_exclusifs',
      'equipement_vigne_arbo_exclusifs',
      'equipements_culture_exclusifs',
      'equipements_exploitant_exclusifs',
      'nutrition_vegetal_amendements_exclusifs',
      'semences_exclusifs',
      'services_exclusifs',
    ]
    const showSelecteur = ref(false)
    const selecteurToggleLabel = ref(`${i18n.t('ctx.import.custom-products__show-selector')}`)
    const selecteurToggleIcon = ref('chevron-down')

    onMounted(async () => {
      const categories = await buildCategories(branchesToExclude)
      categoriesNiveau0.value = categories
    })

    const downloadCSV = () => {
      aladinCategoryService.downloadV2Categories(branchesToExclude)
    }

    const toggleSelecteur = () => {
      showSelecteur.value = !showSelecteur.value
      if (showSelecteur.value) {
        selecteurToggleLabel.value = `${i18n.t('ctx.import.custom-products__hide-selector')}`
        selecteurToggleIcon.value = 'chevron-up'
      } else {
        selecteurToggleLabel.value = `${i18n.t('ctx.import.custom-products__show-selector')}`
        selecteurToggleIcon.value = 'chevron-down'
      }
    }

    return {
      categoriesNiveau0,
      showSelecteur,
      selecteurToggleLabel,
      selecteurToggleIcon,
      branchesToExclude,
      downloadCSV,
      toggleSelecteur,
    }
  },
})
