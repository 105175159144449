import { reactive, Ref, UnwrapRef } from 'vue'
import { SearchResultGroup } from '../../../domain/SearchResultPage'
import { Discountable } from '../../../domain/Discountable'

export class Folder<T> {
  public folded: boolean = true

  constructor(
    readonly id: string,
    public readonly label: string,
    public readonly items: T[],
  ) {}

  public toggle() {
    this.folded = !this.folded
  }

  public fold() {
    this.folded = true
  }
}

export function useFolders(articles: Ref<SearchResultGroup[]>): UnwrapRef<Folder<Discountable>[]> {
  return reactive(articles.value.map((article) => new Folder(article.id, article.label, article.discountables)))
}
