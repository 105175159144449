import { RequiredProperty } from '@invivodf/common/src/contexts/discount/domain/discountForm/fields/validator/RequiredProperty'

export class NullableRequiredGroupSize extends RequiredProperty<number | null> {
  constructor(validatedValue: number | null) {
    super(validatedValue, { errorMessage: '' })
  }

  validate() {
    this.errors = []
    if (this.validatedValue !== null && this.validatedValue <= 1) {
      this.errors.push(`la taille du groupe doit être supérieure à 1`)
    }
  }
}
