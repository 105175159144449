import { HomeBannerApi } from '@aladin/shared-universcoop-types'
import { HomeBanner } from '../domain/home-banner'

export function mapHomeBannerApiToDomain(dto: HomeBannerApi): HomeBanner {
  return {
    title: dto.title,
    description: dto.description,
    isLogoActive: dto.isLogoActive,
  }
}
