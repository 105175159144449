import { AxiosInstance } from 'axios'
import { getCustomOrGenericError } from './getCustomOrGenericError'
import { DiscountableDTO, IDiscountableDatasource, ResultGroupDTO, ResultPageDTO } from './IDiscountable.datasource'

interface ContentRange {
  start: number
  end: number
  total: number
}

const parseContentRangeFromHeaders = (headers): ContentRange => {
  const contentRange = headers['content-range']
  const parserResult = /^(\d+)-(\d+)\/(\d+)$/.exec(contentRange)
  if (!parserResult) {
    throw new Error(`Invalid content-range: ${contentRange}`)
  }

  const [_, start, end, total] = parserResult
  return {
    start: +start,
    end: +end,
    total: +total,
  }
}

export class DiscountableDatasource implements IDiscountableDatasource {
  constructor(private readonly httpClient: AxiosInstance) {}

  async retrieveDiscountablesByIds(sellerId: number, discountableIds: string[]): Promise<DiscountableDTO[]> {
    try {
      if (!discountableIds.length) {
        return []
      }
      const url = encodeURI('/v1/discountables/query')
      const params = { sellerId }
      const { data } = await this.httpClient.post<DiscountableDTO[]>(url, discountableIds, { params })
      return data
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }

  async searchDiscountableGroups(
    sellerId: number,
    query: string,
    page: number = 1,
    count: number = 5,
  ): Promise<ResultPageDTO> {
    try {
      const url = encodeURI('/v1/discountables/search')
      const params = { partner_id: sellerId, query, page, limit: count }
      const { data, headers, status } = await this.httpClient.get<ResultGroupDTO[]>(url, { params })
      if (status === 200) {
        return { total: data.length, results: data }
      }

      const { total } = parseContentRangeFromHeaders(headers)

      return { total, results: data }
    } catch (err) {
      throw getCustomOrGenericError(err.response)
    }
  }
}
