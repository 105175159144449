import { DiscountTypes, DiscountUnits } from '@invivodf/discount-sdk/admin'
import { MEASURE_UNITS } from './constants/MEASURE_UNITS'

export interface IMeasureUnitDisplayed {
  text: string
  value: string
}

const isForDiscountOnRevenuOrSimpleInPercent = (discountType: DiscountTypes, unit: DiscountUnits): boolean =>
  (discountType === DiscountTypes.OnRevenue || discountType === DiscountTypes.Simple) &&
  unit === DiscountUnits.Percentage

const isFixedPriceDiscount = (discountType: DiscountTypes): boolean => discountType === DiscountTypes.FixedPrice

export const allMeasureUnitsExceptAny = Object.values(MEASURE_UNITS).filter(
  (measureUnit) => measureUnit.value !== MEASURE_UNITS.ANY.value,
)

export const getAllowedMeasureUnits = (discountType: DiscountTypes, unit: DiscountUnits): IMeasureUnitDisplayed[] => {
  if (isForDiscountOnRevenuOrSimpleInPercent(discountType, unit)) return [MEASURE_UNITS.ANY]
  if (isFixedPriceDiscount(discountType)) return [MEASURE_UNITS.UNIT]
  return allMeasureUnitsExceptAny
}
