export const productLabels = {
  sante_vegetal: 'Santé du végétal',
  semences: 'Semences',
  equipements_exploitant: "Equipements de l'exploitant",
  equipements_culture: 'Equipement culture',
  engrais: 'Engrais',
  services: 'Services',
  amenagement_exploitation: "Aménagement de l'exploitation",
  nutrition_vegetal_amendements: 'Nutrition du végétal et amendements',
  default: 'Autre catégorie',
}

export function productKindLabel(kind) {
  return productLabels[kind]
}
