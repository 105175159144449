
import { computed, defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue'
import { IdfButton, IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { useRoute } from 'vue-router/composables'
import { getOneArticleUsecase, updateArticleUsecase } from '@/contexts/articles/dependency-injection'

import PHeader from '@invivodf/common/src/application/presenters/PHeader.vue'
import { useStore } from '@/composables/useStore'
import { useRouter } from '@invivodf/common/src/application/composables/useRouter'
import { Article } from '@/contexts/articles/domain/article'
import PInfoMessage from '../../presenters/PInfoMessage.vue'

export default defineComponent({
  name: 'CArticleEdit',
  components: { PInfoMessage, IdfButton, IdfInput, IdfStandardInputs, PHeader },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const isSaving: Ref<boolean> = ref(false)

    const titleMaxLength = 50
    const contentMaxLength = 3000

    const breadcrumbsItems = [
      {
        label: 'Accueil',
        link: { name: 'home' },
        icon: 'home-filled',
      },
      {
        label: 'Paramètres',
        link: { name: 'parameters' },
      },
      {
        label: 'Articles et News',
        link: { name: 'articles' },
      },
      {
        label: 'Modification',
      },
    ]

    const article: Article = reactive({
      title: '',
      image_url: '',
      published_at: '',
      content: '',
    })

    const publishedAtIsVisible = ref(false)

    const submitDisabled = computed(() => {
      const { title, image_url, content } = article
      return !(title && content !== '' && image_url) || isSaving.value
    })

    const titleMaxLengthReached = computed(() => {
      return titleMaxLength === article.title.length
    })

    watch(article, async () => {
      article.title = article.title.slice(0, titleMaxLength)
    })

    const goToArticlesList = () => {
      router.push({ name: 'articles' })
    }

    const wait = async () => {
      return new Promise((resolve) => setTimeout(resolve, 50))
    }

    const update = async () => {
      try {
        isSaving.value = true
        await updateArticleUsecase({
          partnerId: store.getters.cooperativeId.toString(),
          slug: route.params.articleSlug,
          article,
        })
        await wait()
        await router.push({ name: 'articles' })
      } catch (error) {
      } finally {
        isSaving.value = false
      }
    }

    const formatDate = (date) => {
      try {
        return new Date(date).toISOString()
      } catch (_err) {
        return date ?? ''
      }
    }

    onMounted(async () => {
      const slug = route.params.articleSlug
      const contentfulArticle = await getOneArticleUsecase({
        partnerId: store.getters.cooperativeId.toString(),
        slug,
      })
      if (contentfulArticle) {
        article.title = contentfulArticle.title
        article.image_url = contentfulArticle.image_url
        article.published_at = formatDate(contentfulArticle.published_at)
        article.content = contentfulArticle.content
      } else {
        router.push({ name: 'articles' })
      }
    })

    return {
      article,
      breadcrumbsItems,
      submitDisabled,
      contentMaxLength,
      titleMaxLength,
      titleMaxLengthReached,
      goToArticlesList,
      update,
      publishedAtIsVisible,
    }
  },
})
