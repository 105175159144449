
import { IdfButton, IdfImage } from '@invivodf/ui-kit'
import { defineComponent, onMounted, ref } from 'vue'
import { useProductImage } from '../useProductImage'

export default defineComponent({
  components: {
    IdfButton,
    IdfImage,
  },
  setup() {
    const { customPartnerProductImage, dispatchCustomPartnerProductImage, openUpdateProductWidget } = useProductImage()
    const showUpdateButton = ref<boolean>(false)

    onMounted(async () => {
      await dispatchCustomPartnerProductImage()
    })

    return {
      customPartnerProductImage,
      openUpdateProductWidget,
      showUpdateButton,
    }
  },
})
