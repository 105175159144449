
import { defineComponent, ref, watch } from 'vue'
import { IdfIcon, IdfTextIcon, IdfLoader } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'CFileUpload',
  components: { IdfIcon, IdfTextIcon, IdfLoader },
  props: {
    isLoading: { required: false, type: Boolean, default: () => false },
    errors: { required: false, type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const emitChange = (event) => emit('change', event)

    const fileUpload = ref<HTMLLabelElement | null>(null)
    const draggingOver = ref(false)

    const stopHandle = watch(fileUpload, () => {
      if (fileUpload.value === null) return
      stopHandle()
      fileUpload.value!.addEventListener('dragover', () => {
        draggingOver.value = true
      })
      fileUpload.value!.addEventListener('dragleave', () => {
        draggingOver.value = false
      })
    })

    return { emitChange, fileUpload, draggingOver }
  },
})
