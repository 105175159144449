import { useBoToast } from '@invivodf/common/src/application/composables'
import { useSeller } from '@invivodf/common/src/application/composables/useSeller'
import { ImportReportFetcher } from '@invivodf/common/src/contexts/parameters'
import { retrieveDiscountImportReport, retrieveDiscountImportReports } from '../../../dependency-injection'

export const useDiscountImportReport = (): ImportReportFetcher => {
  const seller = useSeller()
  const { showNotificationError } = useBoToast()

  const fetchOne = async (importId: number) => {
    try {
      return await retrieveDiscountImportReport.execute(seller.value.id, importId)
    } catch (err) {
      showNotificationError(err.message)
      throw err
    }
  }

  const fetchAll = async () => {
    try {
      return await retrieveDiscountImportReports.execute(seller.value.id)
    } catch (err) {
      showNotificationError(err.message)
      return []
    }
  }

  return { fetchOne, fetchAll, type: 'discount' }
}
