<template>
  <div class="container">
    <PHeader
      :breadcrumb="breadcrumbs"
      title="Échéances de paiement"
      :description="'Paramétrage des échéances de paiement proposées à vos ' + $t('message.memberships')"
    />

    <section v-if="showNoSearchFound" class="payment-due-dates">
      <img src="@/assets/no_result.svg" alt="Aucun résultat trouvé" aria-hidden="true" />
      <h3>Vous n'avez pas encore d'échéances de paiement paramétrées</h3>
      <p>
        Ajouter des échéances de paiement pour proposer plus de modalités de paiement à vos
        {{ $t('message.memberships') }}
      </p>
      <IdfButton @click="showPaymentDueDates">Ajouter des échéances de paiement</IdfButton>
    </section>

    <section v-else class="payment-due-dates">
      <IdfButton
        icon-left="plus"
        kind="link"
        @click="displayForm"
        v-if="!formIsVisible"
        :data-test="`payment-due-date-add-button`"
      >
        Ajouter une échéance
      </IdfButton>
      <c-payment-due-dates-form
        v-if="formIsVisible"
        @paymentDueDateAdded="getExistingPaymentDueDate"
        @canceledForm="hideForm"
        step="create"
      >
        <template v-slot:header>
          <h3 class="payment-due-date__form-legend">Nouvelle échéance</h3>
        </template>
      </c-payment-due-dates-form>
    </section>

    <section v-if="paymentDueDates.length" class="payment-due-dates">
      <CPaymentDueDatesItem
        v-for="item in paymentDueDates"
        :key="item.reference"
        :paymentDueDate="item"
        @delete-payment-due-date="deletePaymentDueDate"
        step="create"
        @update-payment-due-date="getExistingPaymentDueDate"
        :data-test="`payment-due-date-${item.reference}`"
      />
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import PHeader from '@invivodf/common/src/application/presenters/PHeader.vue'
import { usePaymentDueDates } from '@/composables/usePaymentDueDates'
import { useStore } from '@/composables/useStore'
import CPaymentDueDatesForm from '../CPaymentDueDatesForm/CPaymentDueDatesForm.vue'
import CPaymentDueDatesItem from '../CPaymentDueDatesItem/CPaymentDueDatesItem.vue'

export default {
  name: 'CPaymentDueDatesList',
  components: { PHeader, CPaymentDueDatesForm, CPaymentDueDatesItem, IdfButton },
  setup() {
    const store = useStore()
    const breadcrumbs = [
      {
        label: 'Accueil',
        link: { name: 'home' },
        icon: 'home-filled',
      },
      {
        label: 'Paramètres',
        link: { name: 'parameters' },
      },
      {
        label: 'Échéances de paiement',
      },
    ]
    const { getPaymentDueDates } = usePaymentDueDates()

    const showNoSearchFound = ref(true)
    const showPaymentDueDates = () => {
      showNoSearchFound.value = false
    }

    const formIsVisible = ref(false)
    const displayForm = () => {
      formIsVisible.value = true
    }
    const hideForm = () => {
      formIsVisible.value = false
    }

    const paymentDueDates = ref([])

    const getExistingPaymentDueDate = async () => {
      const result = await getPaymentDueDates()
      if (result.length !== 0) {
        paymentDueDates.value = result
        showNoSearchFound.value = false
      }
    }

    const deletePaymentDueDate = async ({ reference, toastObject }) => {
      const paymentDueDateIndex = paymentDueDates.value.findIndex(
        (paymentDueDate) => paymentDueDate.reference === reference,
      )
      await store.dispatch('setToast', toastObject)
      if (toastObject.type === 'success') {
        paymentDueDates.value.splice(paymentDueDateIndex, 1)
      }
    }

    onMounted(getExistingPaymentDueDate)
    return {
      breadcrumbs,
      showNoSearchFound,
      showPaymentDueDates,
      displayForm,
      hideForm,
      formIsVisible,
      paymentDueDates,
      getExistingPaymentDueDate,
      deletePaymentDueDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-due-dates__not-found {
  text-align: center;
}
.payment-due-dates {
  margin-top: var(--idf-spacing-2);
}
</style>
