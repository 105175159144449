import { DiscountTypes } from '@invivodf/discount-sdk/admin'

export interface DisplayedDiscountType {
  readonly value: DiscountTypes
  readonly text: string
  readonly description: string
  readonly title: string
  readonly example: string
  readonly dataTest: string
}

export const DISCOUNT_TYPES_TEXT: { [key: string]: DisplayedDiscountType } = {
  QUANTITATIVE: {
    value: DiscountTypes.Quantitative,
    text: 'Quantitative',
    description: 'La remise quantitative est accordée selon le volume commandé',
    title: 'Remise quantitative',
    example: '200 kg de produits achetés = 10€ offerts',
    dataTest: 'link-to-create-quantitative-discount',
  },
  ON_REVENUE: {
    value: DiscountTypes.OnRevenue,
    text: 'CA',
    description: 'La remise CA est accordée selon le chiffre d’affaires commandé',
    title: 'Remise CA',
    example: 'À partir de 100€ dans le panier = 10€ offerts',
    dataTest: 'link-to-create-discount-on-revenue',
  },
  SIMPLE: {
    value: DiscountTypes.Simple,
    text: 'Simple',
    description: 'La remise simple ne dépend d’aucun autre facteur (chiffre d’affaires ou volume commandé)',
    title: 'Remise simple',
    example: '<strike>300€</strike> 200€',
    dataTest: 'link-to-create-simple-discount',
  },
  FIXED_PRICE: {
    value: DiscountTypes.FixedPrice,
    text: 'Prix barré',
    description: 'Le prix barré est un prix fixe qui remplace le prix initial',
    title: 'Remise prix barré',
    example: '<strike>300€</strike> 200€',
    dataTest: 'link-to-create-fixed-price-discount',
  },
} as const
