import { SearchResultGroup } from '@invivodf/common/src/contexts/discount/domain/SearchResultPage'
import { DiscountableMapper } from '@invivodf/common/src/contexts/discount/infra/mappers/Discountable.mapper'
import { DiscountableDTO, ResultGroupDTO } from '../datasource/IDiscountable.datasource'
import { AssociationDTO } from '../datasource/IAssociation.datasource'

export class SearchResultGroupMapper {
  // eslint-disable-next-line class-methods-use-this
  fromDTO(
    groups: ResultGroupDTO[],
    discountables: DiscountableDTO[],
    associations: AssociationDTO[],
  ): SearchResultGroup[] {
    const discountableMapperMadeWithAssociations = new DiscountableMapper(associations)
    const mapGroupById = SearchResultGroupMapper.getMapGroupById(groups)

    return groups.map((group) => ({
      label: group.name,
      id: group.id,
      discountables: discountableMapperMadeWithAssociations.fromDTO(
        discountables.filter((dto) => mapGroupById.get(group.id)!.find((id) => dto.id === id)),
      ),
    }))
  }

  private static getMapGroupById(groups: ResultGroupDTO[]) {
    return new Map(groups.map((group) => [group.id, group.discountables.map((discountable) => discountable.id)]))
  }
}
