const toasts = {
  state: {
    toast: null,
  },
  mutations: {
    SET_TOAST(state, toast) {
      state.toast = toast
    },
  },
  actions: {
    setToast(context, toast) {
      context.commit('SET_TOAST', toast)
    },
  },
  getters: {
    toast(state) {
      return state.toast
    },
  },
}

export default toasts
