<template>
  <div class="container-grey">
    <div class="container">
      <div class="offers__header">
        <h1>Suivi de vos offres</h1>
        <p>Dans cet espace, retrouvez toutes les offres</p>
      </div>

      <div class="offers__content">
        <COfferFilters @filterOffers="filterOffers" />

        <div>
          <CElementLoading v-if="isLoading" data-test="offer-list-loader"
            >Chargement de vos offres en cours
          </CElementLoading>

          <COfferList v-else :offers="offers" data-test="offer-list"></COfferList>

          <IdfPagination
            class="offers__pagination"
            v-if="offers.length !== 0"
            data-test="paginate-block"
            :currentPage="currentPage - 1"
            :totalPages="totalPages"
            @click="handleChangePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfPagination } from '@invivodf/ui-kit'
import { mapGetters } from 'vuex'
import CElementLoading from '@/components/CElementLoading/CElementLoading.vue'
import COfferList from '../../components/offer/COfferList.vue'
import COfferFilters from '../../components/offer/COfferFilters.vue'
import { mapMeshIdFromOffers } from '../../domain/Quota'

export default {
  name: 'VOffers',
  components: { CElementLoading, COfferList, IdfPagination, COfferFilters },
  async mounted() {
    await this.fetchOffersAndQuotas()
  },
  computed: {
    ...mapGetters({
      offers: 'offers/offers',
      totalPages: 'offers/totalPages',
      cooperativeId: 'cooperativeId',
    }),
  },
  data() {
    return {
      currentPage: 1,
      filters: {},
      isLoading: false,
    }
  },
  methods: {
    async handleChangePage(value) {
      this.currentPage = value
      await this.fetchOffersAndQuotas()
    },
    filterOffers(filters) {
      this.filters = filters
      this.handleChangePage(1)
    },
    async fetchOffersAndQuotas() {
      this.isLoading = true
      await this.$store.dispatch('offers/fetchOffers', { page: this.currentPage, limit: 10, filters: this.filters })

      const meshIds = mapMeshIdFromOffers(this.offers)
      await this.$store.dispatch('quota/fetch', meshIds)
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.offers__header {
  margin-bottom: 1em;
}
.offers__pagination {
  text-align: center;
  padding: 1em;
}
.offers__content {
  display: flex;
}
</style>
