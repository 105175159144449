import QuotaDashboardDatasource from '@/contexts/quota-dashboard/infrastructure/quota-dashboard.datasource'
import QuotaDashboardRepository from '@/contexts/quota-dashboard/infrastructure/quota-dashboard.repository'
import { productApiHost, quotaApiHost, offerApiHost } from '@/contexts/quota-dashboard/config'
import GetQuotaList from '@/contexts/quota-dashboard/use-cases/get-quota-list'

const quotaDashboardDatasource = new QuotaDashboardDatasource({ productApiHost, quotaApiHost, offerApiHost })
const quotaDashboardRepository = new QuotaDashboardRepository(quotaDashboardDatasource)
const getQuotaList = new GetQuotaList(quotaDashboardRepository)

export { getQuotaList }
