
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { pluralize } from '@invivodf/common/src/core/l10n'

export default defineComponent({
  name: 'CDiscountReferenceList',
  components: { IdfButton },
  props: {
    discountReferences: { type: Array as PropType<string[]>, required: true },
  },
  setup(props) {
    const { discountReferences } = toRefs(props)

    const showFullReferenceList = ref<boolean>(false)

    const toggleReferenceList = () => {
      showFullReferenceList.value = !showFullReferenceList.value
    }

    const discountReferenceList = computed(() =>
      showFullReferenceList.value ? discountReferences.value : discountReferences.value.slice(0, 1),
    )

    const toggleButtonText = computed(() => {
      if (discountReferences.value.length < 2) return null
      if (showFullReferenceList.value === true) return 'Réduire'
      const otherReferencesCount = discountReferences.value.length - 1
      return `+${otherReferencesCount} ${pluralize('autre', otherReferencesCount)}`
    })

    return {
      toggleButtonText,
      toggleReferenceList,
      discountReferenceList,
    }
  },
})
