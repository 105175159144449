import { AppContext } from '@invivodf/common/app.context'

export const STATE_INVALID = '`state` does not match.'
export const COOPERATIVE_NOT_FOUND_ERROR = 'ssoNotActivatedForThisCooperative'
export const MEMBERSHIP_NOT_FOUND_ERROR = 'unknownMembershipNumber'
export const ZERO_PROFILE_FOUND_ERROR = 'noUserFoundForThisMembershipNumber'
export const TOO_MANY_PROFILE_FOUND_ERROR = 'moreThanOneUserForThisMembershipNumber'
export const INVALID_SCOPE = 'invalidScope'

export interface ContentOnAuthError {
  title: string
  description: string
  button: {
    title: string
    linkName: string
  }
}

export const CONTENT_ON_OAUTH_ERROR = {
  [COOPERATIVE_NOT_FOUND_ERROR]: {
    title: 'Votre partenaire ne permet pas de se connecter depuis son Extranet.',
    description:
      "Vous pouvez contacter aladin ou votre partenaire pour leur demander d'ajouter cette fonctionnalité. En attendant veuillez vous connecter manuellement.",
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
  [MEMBERSHIP_NOT_FOUND_ERROR]: {
    title: "Votre partenaire n'a pas transmis votre profil à aladin",
    description:
      "Pour utiliser aladin, vous devez d'abord demander directement à votre partenaire d'activer le service.",
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
  [ZERO_PROFILE_FOUND_ERROR]: {
    title: 'Vous y êtes presque !',
    description:
      'Il ne reste plus qu’à vous inscrire. Pour bénéficier de la connexion automatique, cliquez sur le lien ci-dessous.',
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
  [TOO_MANY_PROFILE_FOUND_ERROR]: {
    title: 'Impossible de vous connecter automatiquement',
    description: `Plusieurs comptes aladin sont associés au même numéro d'${
      window.env.VUE_APP_CONTEXT === AppContext.farmi ? 'exploitation agricole' : 'adhérent'
    } ou de client. Veuillez vous connecter manuellement.`,
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
  [STATE_INVALID]: {
    title: 'Authentification impossible',
    description: "aladin n'est pas à l'origine de cette requête",
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
  [INVALID_SCOPE]: {
    title: 'Interdit',
    description: "Vous n'êtes pas autorisé à accéder à ce site",
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
  default: {
    title: 'Une erreur est survenue',
    description: 'Nous sommes désolé pour la gêne occasionnée. Veuillez réessayer plus tard.',
    button: {
      title: 'Se connecter avec vos identifiants',
      linkName: 'login',
    },
  },
}
