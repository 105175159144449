import { computed } from 'vue'
import { injectDiscountablesSelection } from './useEditAssociationCart'

export const useDiscountableSelected = () => {
  const selection = injectDiscountablesSelection()

  const ids = computed(() => (selection ? selection.value.map((discountable) => discountable.id) : []))

  const isSelected = (discountableId: string) => ids.value.some((id) => discountableId === id)

  return { ids, isSelected }
}
