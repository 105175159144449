
import { computed, ComputedRef, defineComponent, onMounted } from 'vue'
import { IdfButton, IdfDrawer } from '@invivodf/ui-kit'
import { DeliveryModeEntity } from '@invivodf/module-logistic-delivery-mode-sdk'
import { useBoToast } from '@invivodf/common/src/application/composables'
import { useDeliveryModeList, useLogisticOfferWording } from '../../composables'
import { PDeliveryModeList } from '../../presenters'

export default defineComponent({
  name: 'CDeliveryModeChoice',
  components: {
    IdfButton,
    IdfDrawer,
    PDeliveryModeList,
  },
  emits: ['cancel', 'confirm'],
  props: {
    isDrawerVisible: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const { isLoading, deliveryModeList, loadDeliveryModeList, selectedDeliveryMode, setSelectedDeliveryMode } =
      useDeliveryModeList()
    const { showNotificationError } = useBoToast()
    const { wording } = useLogisticOfferWording()
    const onConfirm = (): void => {
      emit('confirm', selectedDeliveryMode.value)
    }
    const onCancel = (): void => {
      emit('cancel')
    }
    const showDrawer: ComputedRef<boolean> = computed({
      get: () => props.isDrawerVisible,
      set: () => {
        onCancel()
      },
    })
    const onSelectDeliveryMode = (deliveryMode: DeliveryModeEntity): void => {
      setSelectedDeliveryMode(deliveryMode)
    }
    onMounted(() => {
      try {
        loadDeliveryModeList()
      } catch (e) {
        showNotificationError(e.message)
      }
    })
    return {
      isLoading,
      deliveryModeList,
      onConfirm,
      onCancel,
      onSelectDeliveryMode,
      selectedDeliveryMode,
      showDrawer,
      wording,
    }
  },
})
