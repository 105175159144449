
import { defineComponent } from 'vue'

import { IdfStandardInputs } from '@invivodf/ui-kit'

export default defineComponent({
  name: 'PTextArea',
  components: {
    IdfStandardInputs,
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const onInput = (event: Event & { target: HTMLInputElement }) => {
      emit('input', event!.target!.value)
    }
    return { onInput }
  },
})
