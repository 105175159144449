import { ScopesService } from './scopes.service'

let scopesService: ScopesService

export function provideScopesService(scopes: string[]) {
  scopesService = new ScopesService(scopes)
}

export function useScopesService(): ScopesService {
  if (!scopesService) {
    throw new Error('ScopesService was not initialized')
  }
  return scopesService
}
