/* eslint-disable */

// waiting on https://github.com/microsoft/TypeScript/issues/29129
declare namespace Intl {
  class ListFormat {
    constructor(locale?: string, options?: { style?: string; type?: 'conjunction' | 'disjunction' })

    format(list: string[]): string
  }
}

if (!('ListFormat' in Intl)) {
  const words = {
    conjunction: 'et',
    disjunction: 'ou',
  }
  Intl.ListFormat = class ListFormat {
    constructor(
      public locale: string = 'fr',
      public readonly options: { style?: string; type?: 'conjunction' | 'disjunction' } = {
        style: 'normal',
        type: 'conjunction',
      },
    ) {}

    format(list: string[]) {
      if (this.options.style === 'narrow') return list.join(', ')
      const last = list[list.length - 1]
      return `${list.slice(0, -1).join(', ')} ${words[this.options.type || 'conjunction']} ${last}`
    }
  }
}

const listFormatter = new Intl.ListFormat('fr', { style: 'narrow' })
const andListFormatter = new Intl.ListFormat('fr', { type: 'conjunction' })
const orListFormatter = new Intl.ListFormat('fr', { type: 'disjunction' })

export const formatList = listFormatter.format.bind(listFormatter)
export const formatAndList = andListFormatter.format.bind(andListFormatter)
export const formatOrList = orListFormatter.format.bind(orListFormatter)
