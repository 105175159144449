import dictionaryLast from '../../core/dictionaries'

export const translateError = (errorMsg) => {
  const definedFieldNames = Object.keys(dictionaryLast.fr.names)
  let translatedMsg = errorMsg
  definedFieldNames.forEach((name) => {
    if (errorMsg.includes(name)) {
      translatedMsg = translatedMsg.replace(name, dictionaryLast.fr.names[name])
    }
  })
  return translatedMsg
}

export const formatErrorMessage = (errorMsg, isService) => {
  if (!isService) return errorMsg
  return errorMsg
    .replace('date de début de livraison', "date de début d'intervention")
    .replace('date de fin de livraison', "date de fin d'intervention")
    .replace('mode de livraison', "type d'intervention")
}
