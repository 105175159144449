import { download } from '@invivodf/common/src/infrastructure/download.service'
import { MetaDiscountDiscountablesProvided, useMetaDiscount } from './useMetaDiscount'
import { useLoadedForm } from '../useLoadedForm'
import { downloadMetaDiscountAssociations } from '../../../dependency-injection'

export interface IUseMetaDiscountDiscountables {
  discountables: MetaDiscountDiscountablesProvided
  downloadDiscountables: () => Promise<void>
  setDiscountablesCount: (count: number) => void
  setDiscountablesFile: (file: File) => void
}

export const useMetaDiscountDiscountables = (): IUseMetaDiscountDiscountables => {
  const { discountables } = useMetaDiscount()
  const form = useLoadedForm()

  const downloadDiscountables = async () => {
    if (discountables.file.value) download(discountables.file.value, 'discount_associations.csv')
    else await downloadMetaDiscountAssociations.execute(form.value.reference.validatedValue, form.value.participants)
  }

  const setDiscountablesCount = (count: number) => {
    discountables.count.value = count
  }

  const setDiscountablesFile = (file: File) => {
    discountables.file.value = file
  }

  return { discountables, downloadDiscountables, setDiscountablesCount, setDiscountablesFile }
}
