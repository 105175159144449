
import { defineComponent } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import { StepNames } from '../composables/StepNames'
import { useFormSteps } from '../composables/useFormSteps'
import IdfStep from './IdfStep/IdfStep.vue'
import { useGeneralInformationStep } from '../composables/useGeneralInformationStep'
import CEditInformation from './CEditInformation.vue'

export default defineComponent({
  name: 'CGeneralInformationsStep',
  components: { CEditInformation, IdfButton, IdfStep },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { discountFormStep, goBackToStep } = useFormSteps()
    const { validateDiscountStep, inputs, isDiscountWithAmount, discountType, discountScope } =
      useGeneralInformationStep()

    return {
      goBackToDiscountStep: () => {
        goBackToStep(StepNames.DiscountForm)
      },
      discountFormStep,
      validateDiscountStep,
      inputs,
      isDiscountWithAmount,
      discountType,
      discountScope,
    }
  },
})
