interface HttpError<T> {
  status: number
  data: T
}

export const ERROR_MESSAGE = `Une erreur inattendue s'est produite, réessayez plus tard ou contactez nous si le problème persiste.`

export const getCustomOrGenericError = (error?: HttpError<any>, defaultErrMessage = ERROR_MESSAGE): Error => {
  if (error && error.status === 400 && error.data.errors?.[0]?.messages?.[0]) {
    return Error(error.data.errors[0].messages[0])
  }
  return Error(defaultErrMessage)
}
