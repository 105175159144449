import { Discount } from '../domain/Discount'
import { IDiscountRepository } from '../domain/IDiscountRepository'

export class RetrieveDiscount {
  constructor(public readonly discountRepository: IDiscountRepository) {}

  async execute(id: string, partnerId: number, isRetrievingMeta: boolean): Promise<Discount> {
    return this.discountRepository.retrieve(id, partnerId, isRetrievingMeta)
  }
}
