import QuotaDashboard from '@/contexts/quota-dashboard/domain/quota-dashboard'
import QuotaDTO from '@/contexts/quota-dashboard/infrastructure/quotaDTO'
import { FlatVariantForQuota, mergeVariantAndProduct } from '@/core/mapper/quotaConfigMapper'
import QuotaDashboardDatasource from '@/contexts/quota-dashboard/infrastructure/quota-dashboard.datasource'
import { OfferDTO } from '@/contexts/quota-dashboard/infrastructure/offerDTO'

function getQuotaByMeshId(quotaRawList: QuotaDTO[]): Map<string, QuotaDTO[]> {
  return quotaRawList.reduce((acc, quota) => {
    const quotasForOffer: QuotaDTO[] = acc.get(quota.meshId) || []
    quotasForOffer.push(quota)
    acc.set(quota.meshId, quotasForOffer)
    return acc
  }, new Map<string, QuotaDTO[]>())
}

export default class QuotaDashboardRepository {
  private readonly datasource: QuotaDashboardDatasource

  constructor(quotaService: QuotaDashboardDatasource) {
    this.datasource = quotaService
  }

  async getQuota(partnerId: number): Promise<QuotaDashboard[]> {
    const quotaRawList: QuotaDTO[] = await this.datasource.findQuotas(partnerId)
    const offersMap = await this.getOfferMap(quotaRawList, partnerId)
    const variantMap = await this.getVariantMap(offersMap, partnerId)
    const quotaByMeshId = getQuotaByMeshId(quotaRawList)

    return Array.from(quotaByMeshId.entries()).map(([meshId, quotasWithSameMeshId]) => {
      const offer = offersMap.get(meshId)
      return QuotaDashboard.buildFrom(
        quotasWithSameMeshId,
        offer ? variantMap.get(offer.productUId) : undefined,
        offersMap.get(meshId),
      )
    })
  }

  private async getOfferMap(quotaRawList: QuotaDTO[], partnerId: number) {
    const offerIdList = Array.from(new Set(quotaRawList.map((quota) => quota!.meshId)))
    const offers = quotaRawList.length ? await this.datasource.findOffers(offerIdList, partnerId) : []
    return new Map(offers.map((offer) => [offer.offerId, offer]))
  }

  private async getVariantMap(
    offersMap: Map<string, OfferDTO>,
    partnerId: number,
  ): Promise<Map<string, FlatVariantForQuota>> {
    const variantIdList = Array.from(new Set(Array.from(offersMap.values()).map((offer) => offer.productUId)))
    const products = variantIdList.length ? await this.datasource.findProducts(variantIdList, partnerId) : []

    return new Map(products.flatMap(mergeVariantAndProduct))
  }
}
