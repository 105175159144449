import { computed, ComputedRef, Ref, ref, watch } from 'vue'
import { useDiscountInput } from './useDiscountInput'
import { DiscountFormWithAmount } from '../../domain/discountForm/DiscountFormWithAmount'

export interface IUseDiscountAmountInput {
  error: Ref<string>
  value: Ref<number | null>
  validate: () => void
  unit: ComputedRef<string>
}

export const useDiscountAmountInput = (discountForm: Ref<DiscountFormWithAmount>): IUseDiscountAmountInput => {
  const { error } = useDiscountInput(discountForm, 'amount')

  const value = ref(discountForm.value.amount.validatedValue)

  const discountFormValue = computed(() => discountForm.value.amount.validatedValue)
  watch(discountFormValue, () => {
    value.value = discountFormValue.value
  })

  const validate = () => {
    discountForm.value.amount.validate(discountForm.value.unit.validatedValue)
    ;[error.value] = discountForm.value.amount.errors
  }

  watch(value, (newVal) => {
    // eslint-disable-next-line no-param-reassign
    discountForm.value.amount.validatedValue = newVal
    validate()
  })

  const unit = computed(() => discountForm.value.unit.validatedValue)

  return { error, value, validate, unit }
}
