import { createAxiosClientWithAladinBearer } from '@/utils/axios'
import ImportService from './import.service'

export default class ZoneChargesService {
  constructor({ zoneChargesApiHost }) {
    this.zoneChargesApiHost = zoneChargesApiHost
    this.api = createAxiosClientWithAladinBearer(`${zoneChargesApiHost}/v1/cooperatives`)
  }

  async import(cooperativeId, file) {
    return ImportService.import(
      `${this.zoneChargesApiHost}/backoffice/v1/cooperatives/${cooperativeId}/zone-charges`,
      file,
    )
  }

  async downloadZoneCharges(cooperativeId) {
    const response = await this.api.get(
      `${this.zoneChargesApiHost}/backoffice/v1/cooperatives/${cooperativeId}/zone-charges`,
      {
        headers: { accept: 'text/csv' },
      },
    )
    return response.data
  }
}
