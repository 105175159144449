
import { computed, PropType, ref, toRefs, watch } from 'vue'
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { IUseDiscountInput } from '../../composables/useDiscountInput'

export default {
  name: 'CPromoCodeInput',
  components: { IdfStandardInputs, IdfInput },
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
  setup(props) {
    const { input } = toRefs(props)
    const isPromoCodeEnabled = ref(!!input.value.value.value)

    watch(isPromoCodeEnabled, () => {
      if (!isPromoCodeEnabled.value) input.value.value.value = ''
    })

    const isEditable = computed(() => input.value.discountForm.value.isPromoCodeEditable())

    return { isPromoCodeEnabled, isEditable }
  },
}
