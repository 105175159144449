import { DiscountTypes, DiscountUnits } from '@invivodf/discount-sdk'
import { DISCOUNT_UNITS } from './constants/DISCOUNT_UNITS'

export type DiscountUnitDisplayed = {
  value: DiscountUnits
  text: string
}

export const getAllowedUnits = (discountType: DiscountTypes): DiscountUnitDisplayed[] => {
  if (discountType === DiscountTypes.FixedPrice)
    return DISCOUNT_UNITS.filter((unit) => unit.value === DiscountUnits.Euro)
  return DISCOUNT_UNITS
}
