import { computed, Ref } from 'vue'
import { injectDiscountablesSelection } from './useEditAssociationCart'
import { SearchResultGroup } from '../../../domain/SearchResultPage'
import { useDiscountableSelected } from './useDiscountableSelected'
import { useSearchDiscountables } from './useSearchDiscountables'

const byId = (id) => (item) => item.id === id

const findGroup = (resultGroups: Ref<SearchResultGroup[]>, id: string) =>
  resultGroups.value.find((article) => article.discountables.find(byId(id)))

export const useCartAssociations = () => {
  const { result } = useSearchDiscountables()

  const selection = injectDiscountablesSelection()
  const { isSelected } = useDiscountableSelected()

  const addDiscountable = (id: string) => {
    const group = findGroup(result, id)
    if (group) {
      const discountableToAdd = group.discountables.find(byId(id))
      if (discountableToAdd) {
        selection.value.push(discountableToAdd)
      }
    }
  }

  const removeDiscountable = (id: string) => {
    const indexToRemove = selection.value.findIndex(byId(id))
    if (indexToRemove !== -1) {
      selection.value.splice(indexToRemove, 1)
    }
  }

  const toggleAddable = (id: string) => {
    const articleToPush = findGroup(result, id)
    if (articleToPush) {
      const discountableToAdd = articleToPush.discountables.find(byId(id))
      if (discountableToAdd && isSelected(discountableToAdd.id)) {
        removeDiscountable(id)
      } else {
        addDiscountable(id)
      }
    }
  }

  const emptyCart = computed(() => selection.value.length === 0)

  return {
    cart: selection,
    toggleAddable,
    emptyCart,
    addDiscountable,
    removeDiscountable,
  }
}
