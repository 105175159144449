
import { defineComponent, PropType, computed } from 'vue'

import { EnvFeaturesEnum } from '@invivodf/common/EnvFeaturesEnum'
import { IUseDiscountInput } from '../../composables/useDiscountInput'

export default defineComponent({
  name: 'CDiscountSalesInput',
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
  setup() {
    return {
      isSalesEnabled: computed(() => window.env[EnvFeaturesEnum.DISCOUNT_SALES_FEATURE] === 'yes'),
    }
  },
})
