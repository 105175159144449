
import { defineComponent } from 'vue'
import { ValidationObserver } from 'vee-validate-last'
import { IdfButton, IdfToggle } from '@invivodf/ui-kit'
import { useLogisticOfferRules, useLogisticOffer, useLogisticOfferWording } from '../../composables'
import { PDateRangePicker, PInput, PMandatory, PTextArea } from '../../presenters'

export default defineComponent({
  name: 'CLogisticOfferDetailsStep',
  emits: ['submit-step'],
  components: {
    IdfButton,
    IdfToggle,
    PDateRangePicker,
    PInput,
    PMandatory,
    PTextArea,
    ValidationObserver,
  },
  setup(_, { emit }) {
    const { cooperativeId, disableReference, logisticOffer } = useLogisticOffer()
    const { wording } = useLogisticOfferWording()
    const onSubmit = (): void => {
      emit('submit-step')
    }

    return {
      cooperativeId,
      disableReference,
      logisticOffer,
      onSubmit,
      rules: useLogisticOfferRules(),
      wording,
    }
  },
})
