import { AuthService, Config } from './auth.service'
// Note: Not using provide/inject for compatibility reasons with
// vue router, as we need to call the authService within the
// beforeEach guard, and injection is not available there.
let authService: AuthService

export function provideAuthService(config: Config) {
  authService = new AuthService(config)
}

export function useAuthService(): AuthService {
  if (!authService) {
    throw new Error('AuthService was not initialized')
  }
  return authService
}
