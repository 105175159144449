import { useStore } from '@/composables/useStore'
import { paymentDueDateService } from '../services'

import PaymentDueDateService, {
  PaymentDueDatePayload,
  PaymentDueDateResponse,
} from '../services/payment-due-date/payment-due-date.service'

export interface ToastObject {
  type: string
  message: string
}

export const makeUsePaymentDueDates = (service: PaymentDueDateService) => {
  return function usePaymentDueDate() {
    const store = useStore()

    const savePaymentDueDate = async (paymentDueDate: PaymentDueDatePayload): Promise<ToastObject> => {
      try {
        await service.create(store.getters.cooperative.id, paymentDueDate)
        return {
          type: 'success',
          message: `L'échéance ${paymentDueDate.reference} a bien été créée.`,
        }
      } catch ({ response }) {
        if (response.data.statusCode === 409) {
          return {
            type: 'danger',
            message: `Une échéance avec l'ID ${paymentDueDate.reference} existe déjà.`,
          }
        }
        return {
          type: 'danger',
          message: `Une erreur est survenue.`,
        }
      }
    }

    const updatePaymentDueDate = async (paymentDueDate: PaymentDueDatePayload): Promise<ToastObject> => {
      try {
        await service.update(store.getters.cooperative.id, paymentDueDate)
        return {
          type: 'success',
          message: `L'échéance ${paymentDueDate.reference} a bien été modifiée.`,
        }
      } catch ({ response }) {
        if (response.data.statusCode === 404) {
          return {
            type: 'danger',
            message: `L'échéance avec l'ID ${paymentDueDate.reference} n'existe pas.`,
          }
        }
        return {
          type: 'danger',
          message: `Une erreur est survenue.`,
        }
      }
    }

    const deletePaymentDueDate = async (reference): Promise<ToastObject> => {
      try {
        await service.delete(store.getters.cooperative.id, reference)
        return {
          type: 'success',
          message: `L'échéance ${reference} a bien été supprimée.`,
        }
      } catch ({ response }) {
        if (response.data.statusCode === 404) {
          return {
            type: 'danger',
            message: `L'échéance avec l'ID ${reference} n'existe pas.`,
          }
        }
        return {
          type: 'danger',
          message: `Une erreur est survenue.`,
        }
      }
    }

    const getPaymentDueDates = async (): Promise<PaymentDueDateResponse[]> => {
      return service.get(store.getters.cooperative.id)
    }

    return {
      getPaymentDueDates,
      savePaymentDueDate,
      updatePaymentDueDate,
      deletePaymentDueDate,
    }
  }
}

export const usePaymentDueDates = makeUsePaymentDueDates(paymentDueDateService)
