import { Discount } from '../domain/Discount'
import { DiscountForm } from '../domain/discountForm/DiscountForm'
import { IDiscountRepository } from '../domain/IDiscountRepository'

export class CreateDiscount {
  constructor(public readonly discountRepository: IDiscountRepository) {}

  async execute(discount: DiscountForm): Promise<Discount> {
    return this.discountRepository.create(discount)
  }
}
