
import Vue, { computed, defineComponent, ref } from 'vue'

import { IdfIcon } from '@invivodf/ui-kit'
import PLink from '@invivodf/common/src/application/presenters/PLink/PLink.vue'
import PIcon from '@invivodf/common/src/application/presenters/PIcon/PIcon.vue'
import { usePartner } from '@/composables/usePartner'

interface ISelectItem {
  id: string
  label: string
  icon?: string
  isExternal?: boolean
}

const sizeAllowedValues = ['m', 'l']
const sizeDefaultValue = 'm'

export default defineComponent({
  name: 'PSelectAladin',
  components: {
    IdfIcon,
    PLink,
    PIcon,
  },
  props: {
    current: {
      type: String,
      default: null,
    },
    items: {
      type: Array as () => ISelectItem[],
      default: () => [],
    },
    size: {
      type: String,
      default: sizeDefaultValue,
      validator: (value: string) => sizeAllowedValues.includes(value),
    },
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const focusedIndex = ref(0)
    const isOpen = ref(false)

    const currentItem = computed(() => props.items.find((item) => item.id === props.current) || props.items[0] || null)

    const { partnerName: cooperativeName } = usePartner()

    const getAladinIcon = () => {
      const logo = 'logo-aladin'
      const environment = window.env.ENVIRONMENT_NAME ?? ''

      if (['DEV', 'QUA', 'TEST'].includes(environment)) {
        return `${logo}-${environment.toLowerCase()}`
      }
      return logo
    }

    return { currentItem, focusedIndex, isOpen, getAladinIcon, cooperativeName }
  },
  methods: {
    close(): void {
      this.handleOpenStatus(false)
    },
    getItemId(value: string): string {
      return `select__entry--${value}`
    },
    goToItem(index: number): void {
      const selectLinks = this.$refs.select__link as Vue
      const element: HTMLButtonElement = Boolean(selectLinks[index]) && selectLinks[index].$el
      if (element) {
        this.focusedIndex = index
        element.focus()
      }
    },
    goToNextItem(): void {
      this.goToItem(Math.min(this.focusedIndex + 1, this.items.length - 1))
    },
    goToPreviousItem(): void {
      this.goToItem(Math.max(this.focusedIndex - 1, 0))
    },
    handleOpenStatus(value): void {
      this.isOpen = value
      if (this.isOpen) {
        this.goToItem((this.currentItem && this.items.indexOf(this.currentItem)) || 0)
      } else {
        const button = (this.$refs.select__current as Vue).$el as HTMLButtonElement
        if (button) {
          button.focus()
        }
      }
    },
    onLinkClick(id: string): void {
      this.$emit('selected', id)
      this.close()
    },
    onListKeyDown(event: KeyboardEvent): void {
      switch (event?.key) {
        case 'ArrowDown':
          this.goToNextItem()
          break
        case 'ArrowUp':
          this.goToPreviousItem()
          break
        case 'Escape':
          this.close()
          break
        case 'Tab':
          event.preventDefault()
          break
        default:
          break
      }
    },
    onToggleClick(): void {
      this.$emit('toggled')
      this.handleOpenStatus(!this.isOpen)
    },
  },
})
