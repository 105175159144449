import { useAuthService } from './useAuth'

export enum GuardAuthType {
  everyOf = 'every',
  someOf = 'some',
}

export type AuthRequirements = { permissions: string[]; type: GuardAuthType }

export const isAuthorized = async (requirements: AuthRequirements) => {
  const scopes = await useAuthService().getUserScopes()
  const { permissions, type } = requirements

  return permissions[type]((permission) => scopes.includes(permission))
}
