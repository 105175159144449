import { Zones } from '@invivodf/module-logistic-interfaces'
import ExclusionZonesRepository from '../infrastructure/exclusion-zones.repository'

export default class GetExclusionZones {
  private readonly repository: ExclusionZonesRepository

  constructor(repository: ExclusionZonesRepository) {
    this.repository = repository
  }

  async execute(): Promise<Zones> {
    const exclusionZones = await this.repository.getAll()
    return exclusionZones
  }
}
