import { computed, ComputedRef } from 'vue'
import { use18n } from '@invivodf/locales/src/use18n'
import { ParametersDescription, SectionName } from '@invivodf/common/src/contexts/parameters'
import { DiscountRouteName } from '../routes/discountRouteName'

export const useDiscountAssociationParameters = (): ComputedRef<ParametersDescription>[] => {
  const i18n = use18n()

  const associationsImportParameter = computed(() => {
    return {
      title: `${i18n.t('ctx.parameters.product__import-discount-associations__title')}`,
      description: `${i18n.t('ctx.parameters.product__import-discount-associations__title')}`,
      route: DiscountRouteName.IMPORT_ASSOCIATION,
      icon: 'promotion-outlined',
      section: SectionName.Product,
    }
  })

  return [associationsImportParameter]
}
