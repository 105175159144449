<template>
  <div class="import-stock" xmlns:v-on="http://www.w3.org/1999/xhtml">
    <c-import-base
      :importFileService="stockService"
      :csvProperties="csvProperties"
      pageTitle="Import stocks"
      uploadButtonLabel="Pour mettre à jour votre base de stocks, importez le fichier CSV"
      :csvColumnDetails="csvColumnDetails"
      :descriptionLines="descriptionLines"
    >
      <c-import-information
        title="Informations sur vos stocks"
        nbResultText="Nombre de stocks :"
        exportText="Exporter vos stocks"
        :importInformation="importInformation"
        :downloadCSV="downloadStocksCSV"
      ></c-import-information>
    </c-import-base>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CImportBase, CImportInformation } from '@invivodf/common/src/contexts/parameters'
import { stockService } from '../../services'
import ImportService from '../../services/import.service'

export default {
  name: 'CImportStock',
  components: {
    CImportBase,
    CImportInformation,
  },
  methods: {
    async downloadStocksCSV() {
      const storesCSV = await stockService.downloadStocks(this.cooperative.id)
      const url = window.URL.createObjectURL(new Blob([storesCSV]))
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', ImportService.createFileName(this.cooperative.name, 'stocks'))
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    },
  },
  computed: {
    ...mapGetters(['cooperative']),
  },
  data() {
    return {
      // TODO (LOBI 31/07) Don't forget feature flip
      csvProperties: {
        name: 'stocks.csv',
        full_path: '/assets/csv/stocks.csv',
      },
      stockService,
      importInformation: {
        count: null,
        lastImportAt: null,
      },
      csvColumnDetails: [
        {
          header: 'variant_id',
          description: 'ID Invivo',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: 'INV0001112223334SEM',
        },
        {
          header: 'store_code',
          description: 'Identifiant du point de retrait dans le SI Partenaire',
          format: 'VARCHAR',
          mandatory: 'Oui',
          exampleValue: '1211',
        },
        {
          header: 'store_quantity',
          description: 'Quantité de la variante',
          format: 'DECIMAL',
          mandatory: 'Oui',
          exampleValue: '15.7',
        },
      ],
      descriptionLines: [
        'L’extension attendue est : <b>.csv</b>',
        'La première ligne de votre fichier CSV doit inclure les entêtes décrites dans la table ci-dessous, dans le même ordre, séparées par un point-virgule <code>;</code>.',
        'Les lignes suivantes doivent contenir les données correspondantes aux entêtes dans l’ordre défini. (Les données pour besoin futur ne seront pas traitées, il est cependant nécessaire de respecter le nombre de colonnes attendu en mettant le nombres de séparateurs nécessaires pour chacune des lignes.)',
        "L'encoding attendu est : <b>UTF8</b>.",
      ],
    }
  },
}
</script>
