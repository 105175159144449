export class FeatureFlipCacheDataSource {
  protected cache: Map<string, boolean> = new Map()

  check(id: string): boolean | undefined {
    return this.cache.get(id)
  }

  store(id: string, isActive: boolean) {
    this.cache.set(id, isActive)
  }
}
