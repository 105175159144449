import { invariable, frenchPlural, pluralize } from '../pluralize/pluralize'

export const unitLabel = {
  l: invariable('L'),
  kg: invariable('kg'),
  unit: frenchPlural('unité'),
  t: invariable('t'),
  mg: invariable('MG'),
  dose: frenchPlural('dose'),
  bigdose: frenchPlural('Bigdose'),
  sac: frenchPlural('sac'),
  bigbag: frenchPlural('Bigbag'),
  // shouldn't be here but *someone* decided to be lazy and use measureUnit for something else
  any: invariable('Toutes les unités'),
}

export function measureUnit(unit = '', n = 1) {
  return pluralize(unitLabel[unit.toLowerCase()] || unit, n)
}
