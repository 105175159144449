<template>
  <div class="offer-list-item__container">
    <div class="offer-list-item__product-wrapper" @click="showOfferVariant()" data-test-product-wrapper>
      <div class="offer-list-item__product">
        <!-- On cache les images tant que le DAM n'est pas en place -->
        <!-- <div v-if="!hasImage" class="offer-list-item__product-image" :style="{ 'background-image': `url(${defaultImage})` }"></div>
        <img v-if="hasImage" class="offer-list-item__product-image" :src="product.images[0]" /> -->
        <p class="offer-list-item__product-name" data-test-product-name>{{ variantName }}</p>
      </div>
    </div>

    <c-variant-offer-list-item
      :offer="offer"
      :measureQuantity="measureQuantity"
      :measureUnit="measureUnit"
      :isSoldAsUnit="isSoldAsUnit"
      :productKind="offer.productKind"
      storeNamespace="offers"
      @editOffer="editOffer"
    ></c-variant-offer-list-item>
  </div>
</template>

<script>
import { getProductDefaultImageFull } from '@/core/products-images.constants'
import CVariantOfferListItem from '../variant/CVariantOfferListItem/CVariantOfferListItem.vue'

export default {
  name: 'COfferListItem',
  props: ['offer'],
  components: { CVariantOfferListItem },
  methods: {
    editOffer() {
      this.$emit('editOffer', this.offer.id)
    },
    showOfferVariant() {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({
        name: 'variant',
        params: { productId: this.offer.productId, variantInvivoId: this.offer.variantInvivoId },
      })
    },
  },
  computed: {
    isSoldAsUnit() {
      return this.offer.isSoldAsUnit
    },
    variantName() {
      return this.offer.variantName
    },
    hasImage() {
      return this.offer.productHasImages
    },
    defaultImage() {
      return getProductDefaultImageFull(this.offer.productKind, this.offer.productType)
    },
    isACropProtectionPack() {
      return this.offer.productKind === 'sante_vegetal' && this.offer.productType === 'Pack'
    },
    measureQuantity() {
      return this.offer && this.offer.measureQuantity
    },
    measureUnit() {
      return this.offer && this.offer.measureUnit
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables.scss';
.offer-list-item__container {
  box-shadow: -3px 3px 5px 0px var(--idf-color-neutral-200);
  background-color: var(--idf-color-neutral-100);
  .offer-list-item {
    padding-top: 0;
  }
}
.offer-list-item__product-wrapper {
  border: 1px solid var(--idf-color-neutral-200);
  border-bottom-width: 0;
  padding: 10px 20px;
}
.offer-list-item__product {
  display: flex;
  height: 60px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--idf-color-neutral-200);
}
img.offer-list-item__product-image {
  object-fit: contain;
  margin-right: 0.5em;
}
.offer-list-item__product-image {
  display: block;
  height: 40px;
  width: 40px;
  img {
    background-size: contain;
  }
}
</style>
