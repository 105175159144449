
import { computed, defineComponent, PropType } from 'vue'
import { EnvFeaturesEnum } from '@invivodf/common/EnvFeaturesEnum'
import { IUseDiscountInput } from '../../composables/useDiscountInput'

export default defineComponent({
  name: 'CDiscountFlashSaleInput',
  props: {
    input: {
      type: Object as PropType<IUseDiscountInput>,
      required: true,
    },
  },
  setup() {
    return {
      isFlashSaleEnabled: computed(() => window.env[EnvFeaturesEnum.DISCOUNT_FLASH_SALE_FEATURE] === 'yes'),
    }
  },
})
