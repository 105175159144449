export const CROP_PROTECTION_KIND = 'sante_vegetal'
export const SEED_KIND = 'semences'
export const NUTRITION_AMENDMENTS_KIND = 'nutrition_vegetal_amendements'
export const SERVICE_KIND = 'services'
export const FARMER_EQUIPMENTS_KIND = 'equipements_exploitant'
export const CULTURE_EQUIPMENT_KIND = 'equipements_culture'
export const VINE_EQUIPMENT_KIND = 'equipement_vigne_arbo'
export const FARM_DEVELOPMENT_KIND = 'amenagement_exploitation'

export const CROP_PROTECTION_NAME = 'Santé du végétal'
export const SEED_FAMILY_NAME = 'Semences'
export const NUTRITION_AMENDMENTS_NAME = 'Nutrition du végétal et amendements'
export const SERVICE_NAME = 'Service'
export const FARMER_EQUIPMENT_FAMILY_NAME = "Equipements de l'exploitant"
export const CULTURE_EQUIPMENT_FAMILY_NAME = 'Equipements culture'
export const VINE_EQUIPMENT_FAMILY_NAME = 'Equipements vigne et arbo'
export const FARM_DEVELOPMENT_FAMILY_NAME = "Aménagement de l'exploitation"

export const CATEGORY_LEVEL_0 = 'hierarchicalMenu[categoriesLevel0][0]'
export const CATEGORY_LEVEL_1 = 'hierarchicalMenu[categoriesLevel0][1]'

export const PRODUCT_KINDS = [
  CROP_PROTECTION_KIND,
  SEED_KIND,
  NUTRITION_AMENDMENTS_KIND,
  SERVICE_KIND,
  FARMER_EQUIPMENTS_KIND,
  CULTURE_EQUIPMENT_KIND,
  VINE_EQUIPMENT_KIND,
  FARM_DEVELOPMENT_KIND,
] as const

interface ProductDisplay {
  label: string
  color: string
  icon: string
}

interface ProductDisplay {
  label: string
  color: string
  icon: string
}

// TODO synchronize with dashboardCategoryMap
export const PRODUCT_KIND_DISPLAY: Record<string, ProductDisplay> = {
  [FARM_DEVELOPMENT_KIND]: {
    label: FARM_DEVELOPMENT_FAMILY_NAME,
    color: '#273d59',
    icon: 'category-silo',
  },
  [CULTURE_EQUIPMENT_KIND]: {
    label: CULTURE_EQUIPMENT_FAMILY_NAME,
    color: '#347252',
    icon: 'category-circle',
  },
  [FARMER_EQUIPMENTS_KIND]: {
    label: FARM_DEVELOPMENT_FAMILY_NAME,
    color: '#a69992',
    icon: 'category-equipment',
  },
  [NUTRITION_AMENDMENTS_KIND]: {
    label: NUTRITION_AMENDMENTS_NAME,
    color: '#5bb3f0',
    icon: 'category-fertilizer',
  },
  [CROP_PROTECTION_KIND]: {
    label: CROP_PROTECTION_NAME,
    color: '#7dc12f',
    icon: 'category-health-plant',
  },
  [SEED_KIND]: {
    label: SEED_FAMILY_NAME,
    color: '#fec200',
    icon: 'category-seeds',
  },
  [SERVICE_KIND]: {
    label: SERVICE_NAME,
    color: '#0511f2',
    icon: 'category-services',
  },
  [VINE_EQUIPMENT_KIND]: {
    label: VINE_EQUIPMENT_FAMILY_NAME,
    color: '#347252',
    icon: 'category-circle',
  },
}
