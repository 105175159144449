import { HomeBannerApiClient } from '@aladin/shared-universcoop-sdk'
import { HomeBanner } from '../domain/home-banner'
import { mapHomeBannerApiToDomain } from './home-banner.mapper'

class HomeBannerService {
  constructor(public readonly homeBannerClient: HomeBannerApiClient) {}

  async findByPartnerId(partnerId: number): Promise<HomeBanner | null> {
    const response = await this.homeBannerClient.findByPartnerId(partnerId)
    return response && mapHomeBannerApiToDomain(response)
  }

  async createOrUpdateHomeBanner(partnerId: number, homeBanner: HomeBanner): Promise<HomeBanner> {
    const response = await this.homeBannerClient.createOrUpdateHomeBanner(partnerId, homeBanner)
    return mapHomeBannerApiToDomain(response)
  }
}

export default HomeBannerService
