
import { IdfInput, IdfStandardInputs } from '@invivodf/ui-kit'
import { ref, watch } from 'vue'

export default {
  name: 'CSearchKeywordInput',
  components: { IdfStandardInputs, IdfInput },
  setup(_, { emit }) {
    const keyWord = ref('')

    watch(keyWord, () => {
      emit('input', keyWord)
    })

    return {
      keyWord,
    }
  },
}
