import QuotaDashboard from '@/contexts/quota-dashboard/domain/quota-dashboard'
import QuotaDashboardRepository from '@/contexts/quota-dashboard/infrastructure/quota-dashboard.repository'

export default class GetQuotaList {
  private readonly repository: QuotaDashboardRepository

  constructor(repository: QuotaDashboardRepository) {
    this.repository = repository
  }

  async execute(partnerId: number): Promise<QuotaDashboard[]> {
    if (!partnerId) throw new Error('Cooperative id is undefined')
    return this.repository.getQuota(partnerId)
  }
}
