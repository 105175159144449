
import { defineComponent, ref } from 'vue'
import { IdfButton } from '@invivodf/ui-kit'
import CAssociatedParticipants from '../../CAssociatedParticipants.vue'
import CFileUpload from '../../CFileUpload.vue'
import PDiscountConfirmationModalFooter from '../../presenters/PDiscountConfirmationModalFooter.vue'
import { parseSellerIdsFromFile } from '../../../composables/meta-discount/useMetaDiscountSellers'

export default defineComponent({
  name: 'CDiscountSellersModal',
  components: { IdfButton, CAssociatedParticipants, CFileUpload, PDiscountConfirmationModalFooter },
  setup(_, { emit }) {
    const newSellers = ref<number[]>([])
    const fileErrors = ref<string[]>([])

    const setNewSellers = async (e: { target: { value: string; files: FileList | null } }) => {
      const { files } = e.target
      if (!files) return

      try {
        newSellers.value = await parseSellerIdsFromFile(files[0])
        fileErrors.value = []
      } catch (err) {
        fileErrors.value = [err.message]
      }

      e.target.value = ''
    }

    const validate = () => {
      emit('validate', newSellers.value)
      newSellers.value = []
      fileErrors.value = []
    }

    const cancel = () => {
      emit('cancel')
      newSellers.value = []
      fileErrors.value = []
    }

    return { newSellers, fileErrors, setNewSellers, validate, cancel }
  },
})
