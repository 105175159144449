import { ArticleResponseApiV2 } from '@aladin/shared-universcoop-sdk'
import { ArticleRepository } from '../domain/article.repository'

interface GetOneArticleUsecase {
  (params: { partnerId: number; slug: string }): Promise<ArticleResponseApiV2>
}

interface Dependencies {
  articleRepository: ArticleRepository
}

export function createGetOneArticleUseCase(deps: Dependencies): GetOneArticleUsecase {
  return deps.articleRepository.getOneArticle
}
