<template>
  <div class="vertically-aligned">
    <input
      class="tree-checkbox"
      type="checkbox"
      :checked="checked"
      :indeterminate.prop="indeterminate"
      :disabled="indeterminate"
      @input="onChange"
    />
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'PCheckbox',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    checked: {
      type: Boolean,
      required: false,
    },
    indeterminate: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    onChange() {
      this.$emit('input')
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-checkbox {
  width: 20px;
  height: 20px;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.tree-checkbox:indeterminate {
  background-color: #1e87f0;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E');
}
.tree-checkbox:checked {
  background-color: #1e87f0;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
}
.vertically-aligned {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
}
</style>
