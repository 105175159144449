<template>
  <div class="tooltip-container" data-test-tooltip>
    <IdfIcon v-if="iconVisible" aria-label="Informations" title="informations" kind="info-circle-filled" />
    <div v-if="isVisible" class="tooltip-content" :class="{ show: isVisible }" data-cy="tooltip-content">
      <p class="tooltip-title">{{ title }}</p>
      <div class="tooltip-slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { IdfIcon } from '@invivodf/ui-kit'

export default {
  name: 'CTooltip',
  components: { IdfIcon },
  data: () => ({
    isVisible: false,
  }),
  props: {
    title: {
      type: String,
    },
    iconVisible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleShow() {
      this.isVisible = !this.isVisible
    },
  },
}
</script>

<style lang="scss">
@import '../../lib/_variables';
.tooltip-container {
  $tooltip-width: 380px;
  $icon-width: 24px;
  $arrow-size: 10px;
  position: relative;
  display: inline-block;
  text-align: left;
  .idf-icon {
    color: var(--idf-color-main-600);
    cursor: pointer;
  }
  .tooltip-content {
    visibility: hidden;
    z-index: 1;
    background-color: var(--idf-color-neutral-100);
    position: absolute;
    border-radius: 4px;
    top: $icon-width + $arrow-size;
    left: $icon-width/2 - $tooltip-width/2;
    box-shadow: 0 0 7px rgba(black, 0.2);
    width: $tooltip-width;
  }
  .tooltip-content::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border: $arrow-size solid transparent;
    border-bottom-color: var(--idf-color-main-600);
  }
  .show {
    visibility: visible;
    animation: fadeIn 0.5s;
  }
  .tooltip-title {
    padding: 10px 30px;
    background-color: var(--idf-color-main-600);
    color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-transform: uppercase;
    font-size: 1em;
  }
  .tooltip-slot {
    padding: 15px 30px;
    font-size: 1em;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
